import { useSelector } from "react-redux";
import {
  useDeleteBranchMutation,
  useGetCertificateListDataQuery,
  useUpdateBranchTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Source/sourceSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCertificateData } from "./api";

const useCertificate = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const queryState = useSelector((state) => state.branches);
  const {
    data: queryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCertificateListDataQuery({
    page_size: queryState.currentPageSize,
    page: queryState.currentPage,
    sort_by: queryState.sortBy,
    sort_order: queryState.sortOrder,
    start: queryState.startDate,
    end: queryState.endDate,
    search: queryState.search,
  });

  const [deleteBranch] = useDeleteBranchMutation();
  const [updateBranchFields] = useUpdateBranchTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = queryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [queryList]);

  useEffect(() => {
    if (queryState?.selectedBranch && queryState?.isEdit) {
      fillBranchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.isEdit, queryState?.showAddBranchModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = !state.showAddBranchModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleDelete = () => {
    deleteBranch(queryState?.selectedBranch).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Branch deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillBranchData = () => {
    getCertificateData(queryState?.selectedBranch).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.branchData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch brach data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedBranch = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = true;
        state.selectedBranch = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (queryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = queryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    queryState,
    showEditModal,
    tableFields,
    queryList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useCertificate;
