import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddBilling from "./useAddBilling";
import { fromUnixTime } from "date-fns";

const AddBilling = ({ refetch }) => {
  const {
    formik,
    queryState,
    basicData,
    serviceItems,
    getFieldError,
    handleCloseModal,
    handleTypeChange,
    handleItemTypeChange,
    handleCalculationTypeChange,
    invoiceTypes
  } = useAddBilling({ refetch });

  return (
    <div className={`row`}>
      <div className="input-wrap pro-mb-4">
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="format_type"
            name="pro-checkbox"
            checked={formik.values?.format_type === 1 ? true : false}
            onChange={() => {
              formik.setFieldValue(
                `format_type`,
                formik.values?.format_type === 1 ? 2 : 1
              );
            }}
            disabled={false}
          />
          <label htmlFor="format_type" className="pro-check-label">
            Default
          </label>
        </div>
      </div>
      {(formik?.values?.format_type === 2 ||
        formik.values?.format_type === "") && (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Supplier
            </label>
            <Select
              id="supplier"
              name="supplier"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("supplier") && " error"
              }`}
              classNamePrefix="pro-input"
              options={basicData?.supplier ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.supplier}
              onBlur={formik.handleBlur("supplier")}
              onChange={(value) =>
                formik?.setFieldValue("supplier", value || null)
              }
              menuPlacement="auto"
            />
            {getFieldError("supplier") && (
              <span className="error-text">{getFieldError("supplier")}</span>
            )}
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Shop
            </label>
            <Select
              id="shop"
              name="shop"
              placeholder={"Select"}
              className={`pro-input lg  ${getFieldError("shop") && " error"}`}
              classNamePrefix="pro-input"
              options={basicData?.shop ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.shop}
              onBlur={formik.handleBlur("shop")}
              onChange={(value) => formik?.setFieldValue("shop", value || null)}
              menuPlacement="auto"
            />
            {getFieldError("shop") && (
              <span className="error-text">{getFieldError("shop")}</span>
            )}
          </div>
        </>
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type"
          name="item_type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("item_type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.item_type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.item_type}
          onBlur={formik.handleBlur("item_type")}
          onChange={(value) => {
            handleItemTypeChange(value);
          }}
          menuPlacement="auto"
        />
        {getFieldError("item_type") && (
          <span className="error-text">{getFieldError("item_type")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type
        </label>
        <Select
          id="type"
          name="type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.type}
          onBlur={formik.handleBlur("type")}
          onChange={(value) => {
            handleTypeChange(value);
          }}
          menuPlacement="auto"
        />
        {getFieldError("type") && (
          <span className="error-text">{getFieldError("type")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Invoice Type
        </label>
        <Select
          id="invoice_type"
          name="invoice_type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("invoice_type") && " error"}`}
          classNamePrefix="pro-input"
          options={invoiceTypes ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.invoice_type}
          onBlur={formik.handleBlur("invoice_type")}
          onChange={(value) => {
            formik?.setFieldValue("invoice_type", value || "");
          }}
          menuPlacement="auto"
        />
        {getFieldError("invoice_type") && (
          <span className="error-text">{getFieldError("invoice_type")}</span>
        )}
      </div>

      {formik?.values?.type?.id !== 3 && (
        <Input
          label={"Invoice Description"}
          type="text"
          id="invoice_description"
          name="invoice_description"
          classinvoice_description={`pro-input lg ${
            getFieldError("invoice_description") && " error"
          }`}
          {...formik.getFieldProps("invoice_description")}
          error={getFieldError("invoice_description")}
          errorMessage={getFieldError("invoice_description")}
        />
      )}
      {formik?.values?.type !== "" && (
        <div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              {formik?.values?.type?.id === 1
                ? "Service type"
                : formik?.values?.type?.id === 2 ||
                  formik?.values?.type?.id === 4
                ? "Certificate type"
                : formik?.values?.type?.id === 3
                ? "Packing type"
                : "category Type"}
            </label>
            <Select
              id="service_type"
              name="service_type"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("service_type") && " error"
              }`}
              classNamePrefix="pro-input"
              options={serviceItems ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.service_type}
              onBlur={formik.handleBlur("service_type")}
              onChange={(value) =>
                formik?.setFieldValue("service_type", value || "")
              }
              menuPlacement="auto"
            />
            {getFieldError("service_type") && (
              <span className="error-text">
                {getFieldError("service_type")}
              </span>
            )}
          </div>
          {formik?.values?.type?.id === 1 && (
            <div>
              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Calculation Type
                </label>
                <Select
                  id="calculation_type"
                  name="calculation_type"
                  placeholder={"Select"}
                  className={`pro-input lg  ${
                    getFieldError("calculation_type") && " error"
                  }`}
                  classNamePrefix="pro-input"
                  options={basicData?.calculation_types ?? []}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  value={formik?.values?.calculation_type}
                  onBlur={formik.handleBlur("calculation_type")}
                  onChange={(value) => {
                    handleCalculationTypeChange(value);
                  }}
                  menuPlacement="auto"
                />
                {getFieldError("calculation_type") && (
                  <span className="error-text">
                    {getFieldError("calculation_type")}
                  </span>
                )}
              </div>
              {((formik?.values?.calculation_type?.id === 1 &&
                (formik?.values?.item_type?.slug !== "gemstone_jewellery" &&
                  formik?.values?.item_type?.slug !== "gemstone_loose")) ||
                formik?.values?.calculation_type?.id === 3 ||
                formik?.values?.calculation_type?.id === 4) && (
                <Input
                  label={"Carat Slab"}
                  type="text"
                  id="carat_slab"
                  name="carat_slab"
                  className={`pro-input lg ${
                    getFieldError("carat_slab") && " error"
                  }`}
                  {...formik.getFieldProps("carat_slab")}
                  error={getFieldError("carat_slab")}
                  errorMessage={getFieldError("carat_slab")}
                />
              )}
              {((formik?.values?.calculation_type?.id === 1 &&
                (formik?.values?.item_type?.slug !== "gemstone_jewellery" &&
                  formik?.values?.item_type?.slug !== "gemstone_loose")) ||
                formik?.values?.calculation_type?.id === 2 ||
                formik?.values?.calculation_type?.id === 4 || 
                formik?.values?.calculation_type?.id === 6) && (
                <>
                  <Input
                    label={"Rate Per Piece"}
                    type="text"
                    id="rate_per_piece"
                    name="rate_per_piece"
                    className={`pro-input lg ${
                      getFieldError("rate_per_piece") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_piece")}
                    error={getFieldError("rate_per_piece")}
                    errorMessage={getFieldError("rate_per_piece")}
                  />
                </>
              )}

              {((formik?.values?.calculation_type?.id === 1 &&
                (formik?.values?.item_type?.slug !== "gemstone_jewellery" &&
                  formik?.values?.item_type?.slug !== "gemstone_loose")) ||
                formik?.values?.calculation_type?.id === 4) && (
                <Input
                  label={"Rate Per Carat"}
                  type="text"
                  id="rate_per_carat"
                  name="rate_per_caret"
                  className={`pro-input lg ${
                    getFieldError("rate_per_carat") && " error"
                  }`}
                  {...formik.getFieldProps("rate_per_carat")}
                  error={getFieldError("rate_per_carat")}
                  errorMessage={getFieldError("rate_per_carat")}
                />
              )}
              {((formik?.values?.calculation_type?.id === 1 &&
                (formik?.values?.item_type?.slug === "gemstone_jewellery" ||
                  formik?.values?.item_type?.slug === "gemstone_loose")) ||
                formik?.values?.calculation_type?.id === 4) && (
                <>
                  <Input
                    label={"Single Stone Charge"}
                    type="text"
                    id="single_stone_charge"
                    name="single_stone_charge"
                    className={`pro-input lg ${
                      getFieldError("single_stone_charge") && " error"
                    }`}
                    {...formik.getFieldProps("single_stone_charge")}
                    error={getFieldError("single_stone_charge")}
                    errorMessage={getFieldError("single_stone_charge")}
                  />

                  <Input
                    label={"Other Stone Charge"}
                    type="text"
                    id="other_stone_charge"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("other_stone_charge") && " error"
                    }`}
                    {...formik.getFieldProps("other_stone_charge")}
                    error={getFieldError("other_stone_charge")}
                    errorMessage={getFieldError("other_stone_charge")}
                  />
                </>
              )}

              {formik?.values?.calculation_type?.id === 3 && (
                <>
                  <Input
                    label={"Rate Per Piece(Singe Grade)"}
                    type="text"
                    id="rate_per_piece_single_grade"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_piece_single_grade") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_piece_single_grade")}
                    error={getFieldError("rate_per_piece_single_grade")}
                    errorMessage={getFieldError("rate_per_piece_single_grade")}
                  />
                  <Input
                    label={"Rate Per Piece(Double Grade)"}
                    type="text"
                    id="rate_per_piece_double_grade"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_piece_double_grade") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_piece_double_grade")}
                    error={getFieldError("rate_per_piece_double_grade")}
                    errorMessage={getFieldError("rate_per_piece_double_grade")}
                  />
                  <Input
                    label={"Rate Per Piece(Natural Gold)"}
                    type="text"
                    id="rate_per_piece_natural_gold"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_piece_natural_gold") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_piece_natural_gold")}
                    error={getFieldError("rate_per_piece_natural_gold")}
                    errorMessage={getFieldError("rate_per_piece_natural_gold")}
                  />
                  <Input
                    label={"Rate Per Carat(Single Grade) "}
                    type="text"
                    id="rate_per_carat_single_grade"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_carat_single_grade") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_carat_single_grade")}
                    error={getFieldError("rate_per_carat_single_grade")}
                    errorMessage={getFieldError("rate_per_carat_single_grade")}
                  />
                  <Input
                    label={"Rate Per Carat(Double Grade)"}
                    type="text"
                    id="rate_per_carat_double_grade"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_carat_double_grade") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_carat_double_grade")}
                    error={getFieldError("rate_per_carat_double_grade")}
                    errorMessage={getFieldError("rate_per_carat_double_grade")}
                  />
                  <Input
                    label={"Rate Per Carat(Natural Gold)"}
                    type="text"
                    id="rate_per_carat_natural_gold"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_carat_natural_gold") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_carat_natural_gold")}
                    error={getFieldError("rate_per_carat_natural_gold")}
                    errorMessage={getFieldError("rate_per_carat_natural_gold")}
                  />
                </>
              )}
              {(formik?.values?.calculation_type?.id === 5 || formik?.values?.calculation_type?.id === 6) && (
                <>
                  <Input
                    label={"Rate Per Character"}
                    type="text"
                    id="rate_per_character"
                    name="rate_per_caret"
                    className={`pro-input lg ${
                      getFieldError("rate_per_character") && " error"
                    }`}
                    {...formik.getFieldProps("rate_per_character")}
                    error={getFieldError("rate_per_character")}
                    errorMessage={getFieldError("rate_per_character")}
                  />
                </>
              )}

              {/* <Input
                label={"Number of stone slab"}
                type="text"
                id="number_of_stones_slab"
                name="number_of_stones_slab"
                className={`pro-input lg ${
                  getFieldError("number_of_stones_slab") && " error"
                }`}
                {...formik.getFieldProps("number_of_stones_slab")}
                error={getFieldError("number_of_stones_slab")}
                errorMessage={getFieldError("number_of_stones_slab")}
              />
              <Input
                label={"Rate per stones"}
                type="rate_per_stones"
                id="rate_per_stones"
                name="rate_per_stones"
                className={`pro-input lg ${
                  getFieldError("rate_per_stones") && " error"
                }`}
                {...formik.getFieldProps("rate_per_stones")}
                error={getFieldError("rate_per_stones")}
                errorMessage={getFieldError("rate_per_stones")}
              />
              <Input
                label={"Rate per card"}
                type="rate_per_card"
                id="rate_per_card"
                name="rate_per_card"
                className={`pro-input lg ${
                  getFieldError("rate_per_card") && " error"
                }`}
                {...formik.getFieldProps("rate_per_card")}
                error={getFieldError("rate_per_card")}
                errorMessage={getFieldError("rate_per_card")}
              /> */}
            </div>
          )}
          {(formik?.values?.type?.id === 2 ||
            formik?.values?.type.id === 3 ||
            formik?.values?.type.id === 4) && (
            <Input
              label={"Rate Per Piece"}
              type="text"
              id="rate_per_piece"
              name="rate_per_piece"
              className={`pro-input lg ${
                getFieldError("rate_per_piece") && " error"
              }`}
              {...formik.getFieldProps("rate_per_piece")}
              error={getFieldError("rate_per_piece")}
              errorMessage={getFieldError("rate_per_piece")}
            />
          )}
        </div>
      )}

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {queryState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddBilling;
