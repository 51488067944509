import { Dropdown } from "react-bootstrap";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import Select from "react-select";
import Assets from "../../../../assets/Assets";

const HeaderActions = () => {
  const {
    globalState,
    localItem,
    userType,
    userDetails,
    currentUrl,
    handleBranchChange,
    handleLogout,
  } = useHeaderActions();
  const ImageUrl =
    localStorage?.getItem("user_type") === "Super_Admin"
      ? Assets?.ADMIN_USER
      : Assets?.NORMAL_USER;

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      {(userType === "Super_Admin" ||
        userType === "Branch" ||
        userType === "Employee") && (
        <Select
          id="branch"
          placeholder={"Select"}
          className={`pro-input ${Style.selectBox}`}
          classNamePrefix="pro-input"
          name="branch"
          options={globalState?.branchList?.branch_list}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.branch_name}
          value={globalState?.currentBranch?.[0]}
          onChange={(value) => handleBranchChange(value)}
          menuPlacement="auto"
        />
      )}
      {localItem &&
      currentUrl !== "/home" &&
      currentUrl !== "/overview" &&
      currentUrl !== "/overview/recheck" &&
      currentUrl !== "/orders" &&
      currentUrl !== "/orders/recheck" &&
      currentUrl !== "/verification" &&
      !currentUrl.includes("master") &&
      !currentUrl.includes("report") &&
      !currentUrl.includes("billing") ? (
        <span className={`pro-mx-5 no-wrap ${Style.item_name}`}>
          {localItem}
        </span>
      ) : (
        <div className="pro-mx-2"></div>
      )}
      <Dropdown className={`pro-d-flex pro-items-center`}>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            <img src={Assets.USER3} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={Style.dropdown}>
          <Dropdown.Item>
            <div className={Style.user_wrapper}>
              <div className={Style.image_wrapper}>
                <img src={ImageUrl ?? ""} alt="user" />
              </div>
              <div className={Style.content_wrapper}>
                <h3
                  className={Style.staff_name}
                >{`${userDetails?.first_name}  ${userDetails?.last_name}`}</h3>
                <p className={Style.staff_number}>Role :{userDetails?.role}</p>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className={`pro-btn pro-btn-outline-primary pro-items-center ${Style.logout_btn}`}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderActions;
