import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  cardPendingStatus: "idle",
  cardAssignedStatus: "idle",
  cardData: {
    Pending: [],
    Assigned: [],
  },
  tableFields: {},
  formData: [],
  showFilter: false,
  showTable: false,
  search: "",
  assignedSearch: "",
  pendingSearch: "",
  gliData: {
    name: "",
    id: "",
    shop: "",
  },
  from_date: "",
  to_date: "",
  item_type: [],
  shop: [],
  status: [],
  date: [],
  certificateData: [],
  showAssignedTable: false,
  curretPageSize: 10,
  currentPage: 1,
  PendingTotal: "",
  AssignedTotal: "",
};

export const getCardPendingList = createAsyncThunk(
  "card/pending-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCardAssignedList = createAsyncThunk(
  "card/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => initialState,
  },
  extraReducers: (builder) => {
    // pending list
    builder.addCase(getCardPendingList.pending, (state) => {
      state.cardPendingStatus = "pending";
    });
    builder.addCase(getCardPendingList.fulfilled, (state, action) => {
      state.cardPendingStatus = "fulfilled";

      // Extract the payload data
      const newData = action.payload.data.data.data;
      

      // Create an object to track unique items based on _id
      const uniqueItems = {};

      // Combine existing data with new data, removing duplicates
      const combinedPending = state.cardData?.Pending || [];
      
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      
      if(state.search != "") {
        
        if(state.currentPage == 1) {
          state.cardData.Pending = newData;
        }
        else {
          if(state.cardData.Pending?.length <= action.payload.data.data.total_count) {
            state.cardData.Pending = [...state.cardData.Pending, ...newData];
          }  
        }
        
      }
      else {
        state.cardData.Pending = combinedPending;
    }
      state.PendingTotal = action.payload.data.data.total_count;
    });

    builder.addCase(getCardPendingList.rejected, (state) => {
      state.cardPendingStatus = "failed";
    });
    // assigned list

    builder.addCase(getCardAssignedList.pending, (state) => {
      state.cardAssignedStatus = "pending";
    });
    builder.addCase(getCardAssignedList.fulfilled, (state, action) => {
      state.cardAssignedStatus = "fulfilled";

      // Extract the payload data
      const newData = action.payload.data.data.data;

      // Create an object to track unique items based on _id
      const uniqueItems = {};

      // Combine existing data with new data, removing duplicates
      const combinedAssigned = state.cardData?.Assigned || [];

      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedAssigned.push(item);
        }
      });

      if(state.search != "") {
        
        if(state.currentPage == 1) {
          state.cardData.Assigned = newData;
        }
        else {
          if(state.cardData.Assigned?.length <= action.payload.data.data.total_count) {
            state.cardData.Assigned = [...state.cardData.Assigned, ...newData];
          }  
        }
        
      }
      else {
        state.cardData.Assigned = combinedAssigned;
      }

      
      state.AssignedTotal = action.payload.data.data.total_count;
    });

    builder.addCase(getCardAssignedList.rejected, (state) => {
      state.cardAssignedStatus = "failed";
    });
  },
});

export const { updateConfig, resetConfig } = staffSlice.actions;

export default staffSlice.reducer;
