import React from "react";
import VisitingCard from "./VisitingCard";
import Bigcard from "./BigCard";
import PapperCard from "./PapperCard";
import useVerifiCertificate from "./useVerifiCertificate";
import ShimmerCard from "./Shimmer";
import FoldableCard from "./FoldableCard";

const VerifyCertificate = () => {
  const { error, certificateData } = useVerifiCertificate();
  return (
    <div>
      {!!error ? (
        <div>
          <h3>{error}</h3>
        </div>
      ) : (
        <div className="pro-mt-5">
          {certificateData ? (
            <div>
              {certificateData?.certificate_type === 1 && (
                <VisitingCard data={certificateData} />
              )}
              {certificateData?.certificate_type === 2 && (
                <Bigcard data={certificateData} />
              )}
              {certificateData?.certificate_type === 3 && (
                <PapperCard data={certificateData} />
              )}
              {certificateData?.certificate_type === 4 && (
                <FoldableCard data={certificateData} />
              )}
            </div>
          ) : (
            <div>
              <ShimmerCard />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerifyCertificate;
