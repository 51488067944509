import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        if (key === "receipt_id") {
          return `${key}=[${params[key]}]&`;
        } else return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("receipt_id")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&per_page=${params?.page_size || "10"}&${getParams("search")}page=${
            params?.page || 1
          }&${getParams("process_slug")}&${getParams(
            "stock_receipt_id"
          )}&${getParams("id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}${getParams("receipt_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const printing = createApi({
  reducerPath: "printingApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["printing"],
  endpoints: (builder) => ({
    // form data
    getPrintingTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/printing-report/list`,
      }),
    }),
    getPrintingRecheckTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/printing-report/recheck/list`,
      }),
    }),
    getPrintingReissueTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/reissue/list`,
      }),
    }),
    getCertificateLists: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/printing-report/certificate-list`,
      }),
    }),
    getRecheckCertificateLists: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/printing-report/recheck-certificate-list`,
      }),
    }),
    updatePrintingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["printing"],
    }),
    updatePrintingReissueTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["printing"],
    }),
    getGliLists: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/employee-dashboard/order/bulk-pending-queue`,
      }),
    }),
    getGliListsRecheck: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/employee-dashboard/recheck/bulk-pending-queue`,
      }),
    }),
    getGliListsReissue: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/reissue/bulk-pending-queue`,
      }),
    }),
  }),
});

export const {
  useGetGliListsQuery,
  useGetCertificateListsQuery,
  useGetPrintingTableDataQuery,
  useGetGliListsRecheckQuery,
  useGetGliListsReissueQuery,
  useGetPrintingRecheckTableDataQuery,
  useUpdatePrintingTableHeadDataMutation,
  useGetPrintingReissueTableDataQuery,
  useUpdatePrintingReissueTableHeadDataMutation,
  useGetRecheckCertificateListsQuery,
} = printing;
