import React from "react";
import BillAddress from "./BillAddress";
import { FaSort } from "react-icons/fa";
import {
  Button,
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useInvoice from "./useInvoice";
import Style from "./invoice.module.scss";
import EstimatePdf from "./Print/EstimatePdf";
// import InvoicePdf from "./Print/InvoicePdf";
// import ReceiptPdf from "./Print/ReceiptPdf";

export const Invoice = () => {
  const {
    estimateList,
    estimateRef,
    // invoiceRef,
    // receiptRef,
    paginationOptions,
    // handleInvoicePrint,
    // handleReceiptPrint,
    getRow,
    handleSort,
    handleEstimatePrint,
    handlePageSize,
    handlePagination,
  } = useInvoice();

  const invoiceNo = `#${estimateList?.estimate_number}`;
  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <HeadingGroup title={`Invoice${invoiceNo}`} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-0 flex-fill pro-d-flex pro-flex-column">
        <BillAddress />
        <Table
          multiSelect={false}
          data={estimateList?.data || []}
          uniqueID={"_id"}
          fields={estimateList?.fields || []}
          showCheckBox={false}
          SortIcon={<FaSort />}
          getRow={getRow}
          handleSort={handleSort}
          perpage={10}
          assignText={"Print Estimate"}
          assignIcon={<span className="material-symbols-outlined">print</span>}
          assignable={false}
          editable={false}
          deletable={false}
        />
        {estimateList?.data?.length > 0 && (
          <div className="pro-my-4">
            <Pagination
              currentPage={estimateList?.current_page}
              totalPageCount={Math.ceil(
                estimateList?.total_count / estimateList?.per_page
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
            />
          </div>
        )}
        <div
          className={`pro-d-flex pro-justify-end pro-py-4 pro-px-1 pro-mt-auto pro-items-center ${Style.footer_btn_wrap}`}
        >
          <h6 className="pro-mb-0 pro-ttl h6">
            Total: &#x20B9;{estimateList?.total_amount}
          </h6>
          {/* <Button
            className={"pro-btn-secondary pro-ms-4 pro-px-5"}
            type="button"
            onClick={() => handleReceiptPrint(estimateList?.invoice_id)}
          >
            <span className="material-symbols-outlined">print</span>&nbsp;
            {"Receipt"}
          </Button>
          <Button
            className={"pro-btn-secondary pro-ms-4 pro-px-5"}
            type="button"
            onClick={() => handleInvoicePrint(estimateList?.invoice_id)}
          >
            <span className="material-symbols-outlined">print</span>&nbsp;
            {"Invoice"}
          </Button> */}
          <Button
            className={"pro-btn-primary pro-ms-4 pro-px-5"}
            type="button"
            onClick={() => handleEstimatePrint(estimateList?.estimate_id)}
          >
            <span className="material-symbols-outlined">print</span>&nbsp;
            {"Estimate"}
          </Button>
        </div>
        <div className={Style.pdf_container}>
          {/* <InvoicePdf invoiceRef={invoiceRef} />
          <ReceiptPdf receiptRef={receiptRef} /> */}
          <EstimatePdf estimateRef={estimateRef} />
        </div>
      </div>
    </div>
  );
};

export default Invoice;
