import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("receipt_id")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || 1
          } &${getParams("process_slug")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const staff = createApi({
  reducerPath: "staffApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["pendingStaff", "assignedStaff"],
  endpoints: (builder) => ({
    // form data
    getPendingTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/employee-dashboard/order/list-certifiate-number`,
      }),
      providesTags: ["pendingStaff"],
    }),
    getAssignedTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/employee-dashboard/order/list-assigned-certificate`,
      }),
      providesTags: ["assignedStaff"],
    }),
    updatePendingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["pendingStaff"],
    }),
    updateAssignedTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["assignedStaff"],
    }),
  }),
});

export const {
  useGetPendingTableDataQuery,
  useGetAssignedTableDataQuery,
  useUpdatePendingTableHeadDataMutation,
  useUpdateAssignedTableHeadDataMutation,
} = staff;
