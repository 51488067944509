import { getAxiosInstance } from "../../api";

export const getPrintData = async (id, index) => {
  const api = await getAxiosInstance();
  try {
    let response = "";
    switch (index) {
      case 1:
        response = await api.get(
          `/qc-reports/chemmannur-model?receipt_id=${id}`,
          {
            responseType: "blob",
          }
        );
        break;
      case 2:
        response = await api.get(`/cvd-reports/create?receipt_id=${id}`, {
          responseType: "blob",
        });
        break;
      case 3:
        response = await api.get(
          `/qc-reports/diamond-loose-report?receipt_id=${id}`,
          {
            responseType: "blob",
          }
        );
        break;
      case 4:
        response = await api.get(`/qc-reports/old-gold?receipt_id=${id}`, {
          responseType: "blob",
        });
        break;
      case 5:
        response = await api.get(`/qc-reports/gemstone?receipt_id=${id}`, {
          responseType: "blob",
        });
        break;
      case 6:
        response = await api.get(
          `/qc-reports/josco-loose-diamond?receipt_id=${id}`,
          {
            responseType: "blob",
          }
        );
        break;
      case 7:
        response = await api.get(`/qc-reports/common?receipt_id=${id}`, {
          responseType: "blob",
        });
        break;
      case 8:
        response = await api.get(`/qc-reports/recheck?receipt_id=${id}`);
        break;
      case 9:
        response = await api.get(`/qc-reports/original-copy?receipt_id=${id}`, {
          responseType: "blob",
        });
        break;
      case 10:
        response = await api.get(
          `/qc-reports/additional-document?receipt_id=${id}`,
          {
            responseType: "blob",
          }
        );
        break;
      default:
        break;
    }
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadExcelFiles = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc-reports/upload-qc-report`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const downloadFile = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/internal-reports/dwsr-excel-download`,
      params,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const downloadPercentageReport = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/internal-reports/percentage-export`,
      params,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error?.response;
  }
};

export const downloadShopReport = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/internal-reports/other-reports/branch-or-shop-and-supplier/stock-receipt-report`,
      params,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error?.response;
  }
};
