import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentRecheckPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  currentRecheckPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  // showAddRecheckModal: false,
  showReassignModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedRecheck: [],
  isEdit: false,
  recheckData: [],
  logoPreview: "",
  item_type: [],
  shop: [],
  status: [],
  date: [],
  from_date: "",
  to_date: "",
  from_range: "",
  to_range: "",
  gliData: {
    name: "",
    id: "",
    shop: "",
  },
  assignedSearch: "",
  pendingSearch: "",
  selectedOption: false,
  activeTab: "orders",
  stockPrintData:"",
  selectedId: "",
  excelUploadModal: false
};

const reassignSlice = createSlice({
  name: "reassign",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => {
      return initialState;
    },
  },
});

export const { updateConfig, resetConfig } = reassignSlice.actions;

export default reassignSlice.reducer;
