import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Branches/branchesSlice";
import * as Yup from "yup";
import { emailRegExp } from "../../../../utils/functions/table";
import countryData from "../../../../utils/components/countryCode";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  useGetFormDataQuery,
  useUpdateBranchDataMutation,
} from "../../../../store/queries/Master";
import { toast } from "react-toastify";
import Basic from "./Basic";
import Address from "./Address";
import Contact from "./Contact";

const useAddBranch = ({ refetch }) => {
  const [moveToTab, setMoveToTab] = useState(false);
  const dispatch = useDispatch();
  const {
    showPassword,
    branchData,
    isEdit,
    selectedBranch,
    logoPreview,
    activeTab,
    completedTabs,
  } = useSelector((state) => state.branches);
  const { data: formData = {}, isLoading } = useGetFormDataQuery();
  const [updateBranchData] = useUpdateBranchDataMutation();

  useEffect(() => {
    setPhoneCodes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, formData, branchData]);

  useEffect(() => {
    if (branchData && isEdit) {
      Object.keys(branchData || {}).forEach((key) => {
        if (key === "branch_type") {
          const result = formData?.data?.branch_type.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?.id == branchData?.[key]
          );
          formik?.setFieldValue("branch_type", result?.[0]);
        } else if (key === "branch_address") {
          const Country = formData?.data?.country_list.filter(
            (value) => value?.id === branchData?.[key]?.country_id
          );
          const State = formData?.data?.state_list.filter(
            (value) => value?.id === branchData?.[key]?.state_id
          );
          formik?.setFieldValue("city", branchData?.[key]?.city);
          formik?.setFieldValue("pin_code", branchData?.[key]?.postal_code);
          formik?.setFieldValue("address1", branchData?.[key]?.address_line1);
          formik?.setFieldValue("country", branchData?.[key]?.address_line2);
          formik?.setFieldValue("address2", branchData?.[key]?.address_line2);
          formik?.setFieldValue("country", Country?.[0]);
          formik?.setFieldValue("state", State?.[0]);
        } else if (key === "bank_details") {
          formik?.setFieldValue("bank_name", branchData?.[key]?.[0]?.bank_name);
          formik?.setFieldValue(
            "ac_no",
            branchData?.[key]?.[0]?.account_number
          );
          formik?.setFieldValue("ifsc", branchData?.[key]?.[0]?.ifsc);
          formik?.setFieldValue(
            "bank_address",
            branchData?.[key]?.[0]?.bank_address
          );
        } else if (key === "contact_person") {
          const codeOne = countryData.filter(
            (value) => value?.value === branchData?.[key]?.[0]?.country_code
          );
          const codeTwo = countryData.filter(
            (value) => value?.value === branchData?.[key]?.[1]?.country_code
          );
          formik?.setFieldValue("person_1_name", branchData?.[key]?.[0]?.name);
          formik?.setFieldValue(
            "person_1_email",
            branchData?.[key]?.[0]?.email
          );
          formik?.setFieldValue(
            "person_1_phone",
            branchData?.[key]?.[0]?.phone_number
          );
          formik?.setFieldValue("person_2_name", branchData?.[key]?.[1]?.name);
          formik?.setFieldValue(
            "person_2_email",
            branchData?.[key]?.[1]?.email
          );
          formik?.setFieldValue(
            "person_2_phone",
            branchData?.[key]?.[1]?.phone_number
          );
          formik?.setFieldValue("person_1_phone_code", codeOne?.[0]);
          if (
            branchData?.[key]?.[1]?.country_code === undefined ||
            branchData?.[key]?.[1]?.country_code === null
          ) {
            formik?.setFieldValue("person_1_phone_code", {
              name: "India",
              flag: "🇮🇳",
              code: "IN",
              label: "+91",
              value: "+91",
            });
          } else {
            formik?.setFieldValue("person_2_phone_code", codeTwo?.[0]);
          }
        } else {
          formik.setFieldValue(key, branchData?.[key]);
        }
      });
    }

    dispatch(
      updateConfig((state) => (state.logoPreview = branchData?.branch_logo))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData, isLoading]);

  const validation = Yup.object({
    branch_name: Yup.string()
      .trim()
      .required("Enter Branch Name")
      .max(80, "Enter Valid Branch Name"),
    branch_prefix: Yup.string()
      .required("Enter Prefix of your branch")
      .min(2, "Enter Valid Prefix")
      .max(5, "Enter Valid Prefix")
      .matches(/[A-Z]/, "Enter Valid Prefix"),
    branch_email: Yup.string()
      .required("Enter Branch Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
    branch_type: Yup.object().required("Select Branch Type"),
    gst_number: Yup.string()
      .required("Enter GST Number")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Enter Valid GST Number"
      ),
    pan_number: Yup.string()
      .required("Enter PAN Number")
      .matches(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/, "Enter valid PAN Number"),
    sac_number: Yup.string()
      .required("Enter SAC number")
      .matches(/^[0-9]*$/, "Enter Valid SAC Number")
      .min(6, "Enter Valid SAC Number")
      .max(20, "Enter Valid SAC Number"),
    logo:
      !isEdit &&
      Yup.mixed()
        .required("Upload Branch Logo")
        .test("fileType", "Only image files are allowed", (value) => {
          if (!value) return false; // handle empty file
          return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        })
        .test("fileSize", "The Logo size must be less than 2MB", (value) => {
          return value && value.size <= 2000000;
        }),
    address1: Yup.string()
      .trim()
      .required("Enter Address line 1")
      .max(300, "Not Valid Address"),
    address2: Yup.string()
      .trim()
      .required("Enter Address line 2")
      .max(300, "Not Valid Address"),
    country: Yup.object().required("Select Country"),
    state: Yup.object().required("Select State"),
    city: Yup.string().trim().required("Select City"),
    pin_code: Yup.string()
      .required("Enter Pin code")
      .matches(/^[1-9][0-9]{5}$/, "Enter Valid Pin Code"),
    bank_name: Yup.string()
      .required("Enter Bank Name")
      .max(70, "Enter Valid Bank Name"),
    ac_no: Yup.string()
      .required("Enter Account Number")
      .matches(/^\d{9,18}$/, "Enter Valid Account Number"),
    ifsc: Yup.string()
      .required("Enter IFSC Code ")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Enter Valid IFSC Code"),
    bank_address: Yup.string()
      .required("Enter Bank Address")
      .max(100, "Not A Valid Address"),
    country_code: Yup.string().required("Select The Country Of The Branch"),
    contact_number_1: Yup.string()
      .required("Enter Contact Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/[0-9 ]+/, "Enter Valid Number"),
    contact_number_2: Yup.string()
      .required("Enter Contact Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number")
      .notOneOf([Yup.ref("contact_number_1")], "Both Numbers cannot be same"),
    person_1_name: Yup.string()
      .required("Enter name")
      .matches(/^[a-zA-Z ]*$/, "Enter valid name"),
    person_1_email: Yup.string()
      .required("Enter Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
    person_1_phone_code: Yup.object().required("Select Country Code"),
    person_1_phone: Yup.string()
      .required("Enter Contact Number")
      .min(7, "Enter Valid Contact Number")
      .max(14, "Enter Valid Contact Number")
      .matches(/^[0-9]*$/, "Enter Valid Contact Number"),
    // person_2_name: Yup.string()
    //   .required("Enter name")
    //   .matches(/^[a-zA-Z]+$/, "Enter Valid name"),
    person_2_email: Yup.string()
      .nullable()
      .matches(emailRegExp, "Enter Valid Email ID"),
    person_2_phone_code: Yup.object().required("Select Country code"),
    person_2_phone: Yup.string()
      .nullable()
      .min(7, "Enter Valid Contact Number")
      .max(14, "Enter Valid Contact Number")
      .matches(/^[0-9]*$/, "Enter Valid Contact Number"),
    user_name:
      !isEdit &&
      Yup.string()
        .required("Enter User Name")
        .max(40, "Not A Valid User Name")
        .min(5, "Please enter at least 5 letters"),
    password:
      !isEdit &&
      Yup.string()
        .required("Enter Password")
        .min(5, "Please enter at least 5 letters"),

    serial_number:
      !isEdit &&
      Yup.string()
        .required("Enter Serial Number")
        .max(10, "Enter Valid Serial Number")
        .matches(/^[0-9]*$/, "Enter Valid Serial Number"),
  });

  const initialValues = {
    branch_name: "",
    branch_prefix: "",
    branch_email: "",
    branch_type: "",
    gst_number: "",
    pan_number: "",
    logo: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    sac_number: "",
    pin_code: "",
    bank_name: "",
    ac_no: "",
    ifsc: "",
    bank_address: "",
    country_code: "",
    contact_number_1: "",
    contact_number_2: "",
    person_1_name: "",
    person_1_email: "",
    person_1_phone_code: "",
    person_1_phone: "",
    person_2_name: "",
    person_2_email: "",
    person_2_phone_code: "",
    person_2_phone: "",
    user_name: "",
    password: "",
    serial_number: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        branch_name: values?.branch_name,
        branch_prefix: values?.branch_prefix,
        branch_email: values?.branch_email,
        branch_type: values?.branch_type?.id,
        gst_number: values?.gst_number,
        pan_number: values?.pan_number,
        sac_number: values?.sac_number,
        branch_logo: values?.logo,
        serial_number: values?.serial_number,
        "branch_address[address_line1]": values?.address1,
        "branch_address[address_line2]": values?.address2,
        "branch_address[country_id]": values?.country?.id,
        "branch_address[state_id]": values?.state?.id,
        "branch_address[city]": values?.city,
        "branch_address[postal_code]": values?.pin_code,
        "bank_details[0][bank_name]": values?.bank_name,
        "bank_details[0][account_number]": values?.ac_no,
        "bank_details[0][ifsc]": values?.ifsc,
        "bank_details[0][bank_address]": values?.bank_address,
        country_code: values?.country_code,
        contact_number_1: values?.contact_number_1,
        contact_number_2: values?.contact_number_2,
        "contact_person[0][name]": values?.person_1_name,
        "contact_person[0][email]": values?.person_1_email,
        "contact_person[0][country_code]": values?.person_1_phone_code?.value,
        "contact_person[0][phone_number]": values?.person_1_phone,
        "contact_person[1][name]": values?.person_2_name,
        "contact_person[1][email]": values?.person_2_email,
        "contact_person[1][country_code]": values?.person_2_phone
          ? values?.person_2_phone_code?.value
          : "",
        "contact_person[1][phone_number]": values?.person_2_phone,
        user_name: values?.user_name,
        password: values?.password,
      };

      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      let formData = new FormData();
      for (let key in newObj) {
        formData.append(key, newObj[key]);
      }

      if (isEdit) {
        formData.append("branch_id", selectedBranch);
        if (values?.logo === "") {
          formData?.delete("branch_logo");
        }
      }

      updateBranchData(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          if (isEdit) {
            toast.success("Branch Details Updated");
          } else {
            toast.success("New Branch Created");
          }
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetch();
        } else if (response?.error?.data?.status_code === 422) {
          // displays backend errors
          let errors = response?.error?.data?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
          handleMoveToErrorTab();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = false;
      })
    );
  };

  const tabs = [
    {
      label: "Basic",
      completed: calculateCompletionStatus([
        "branch_name",
        "branch_prefix",
        "branch_email",
        "branch_type",
        "gst_number",
        "logo",
      ]),
    },
    {
      label: "Address",
      completed: calculateCompletionStatus([
        "address1",
        "address2",
        "country",
        "state",
        "city",
        "sac_number",
        "pin_code",
        "bank_name",
        "ac_no",
        "ifsc",
        "bank_address",
      ]),
    },
    {
      label: "Contact",
      completed: calculateCompletionStatus([
        "country_code",
        "contact_number_1",
        "contact_number_2",
        "person_1_name",
        "person_1_email",
        "person_1_phone_code",
        "person_1_phone",
        "person_2_name",
        "person_2_email",
        "person_2_phone_code",
        "person_2_phone",
        "user_name",
        "password",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Basic",
      fields: [
        "branch_name",
        "branch_prefix",
        "branch_email",
        "branch_type",
        "gst_number",
        "logo",
      ],
    },
    {
      label: "Address",
      fields: [
        "address1",
        "address2",
        "country",
        "state",
        "city",
        "sac_number",
        "pin_code",
        "bank_name",
        "ac_no",
        "ifsc",
        "bank_address",
      ],
    },
    {
      label: "Contact",
      fields: [
        "country_code",
        "contact_number_1",
        "contact_number_2",
        "person_1_name",
        "person_1_email",
        "person_1_phone_code",
        "person_1_phone",
        "person_2_name",
        "person_2_email",
        "person_2_phone_code",
        "person_2_phone",
        "user_name",
        "password",
      ],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields?.filter(
      (value) =>
        value !== "person_1_phone_code" && value !== "person_2_phone_code"
    );
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      return !!formik?.values[field];
    });
    return (completedFields.length / fields.length) * 115;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };
  const setPhoneCodes = () => {
    if (!isEdit) {
      formik?.setFieldValue("person_1_phone_code", {
        name: "India",
        flag: "🇮🇳",
        code: "IN",
        label: "+91",
        value: "+91",
      });
      formik?.setFieldValue("person_2_phone_code", {
        name: "India",
        flag: "🇮🇳",
        code: "IN",
        label: "+91",
        value: "+91",
      });
      formik?.setFieldValue("country_code", "+91");
    } else {
      Object.keys(branchData || {}).forEach((key) => {
        if (key === "contact_person") {
          if (
            branchData?.[key]?.[0]?.country_code === "" ||
            branchData?.[key]?.[0]?.country_code === null ||
            branchData?.[key]?.[0]?.country_code === undefined
          ) {
            formik?.setFieldValue("person_1_phone_code", {
              name: "India",
              flag: "🇮🇳",
              code: "IN",
              label: "+91",
              value: "+91",
            });
          }
          if (
            branchData?.[key]?.[1]?.country_code === "" ||
            branchData?.[key]?.[1]?.country_code === null ||
            branchData?.[key]?.[1]?.country_code === undefined
          ) {
            formik?.setFieldValue("person_2_phone_code", {
              name: "India",
              flag: "🇮🇳",
              code: "IN",
              label: "+91",
              value: "+91",
            });
          }
        }
      });
    }
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Basic":
        return (
          <Basic
            formik={formik}
            tabs={tabs}
            formData={formData?.data}
            getFieldError={getFieldError}
          />
        );
      case "Address":
        return (
          <Address
            formik={formik}
            formData={formData?.data}
            tabs={tabs}
            getFieldError={getFieldError}
          />
        );
      case "Contact":
        return (
          <Contact
            formik={formik}
            formData={formData?.data}
            getFieldError={getFieldError}
            tabs={tabs}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    // Move activeTab to the first tab with errors
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    tabs,
    activeTab,
    completedTabs,
    isEdit,
    showPassword,
    formData: formData?.data,
    countryData,
    logoPreview,
    handleCloseModal,
    getFieldError,
    renderTabContent,
    handleTabClick,
    handleMoveToErrorTab,
  };
};

export default useAddBranch;
