import React from "react";
import { useSelector } from "react-redux";

const DeliverySlip = React.forwardRef(({ slipRef }, ref) => {
  const { printData } = useSelector((state) => state.delivery);

  const inputDate = new Date();

  const day = inputDate.getDate();
  const month = inputDate.toLocaleString("en-US", { month: "long" });
  const year = inputDate.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  const items = printData?.delivery_items?.slice(0, -1);
  const total =
    printData?.delivery_items?.[printData?.delivery_items?.length - 1];

  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime = formattedHours + ":" + formattedMinutes + " " + ampm;

  return (
    <table
      border={0}
      cellSpacing={0}
      role="presentation"
      cellPadding={0}
      style={{
        backgroundColor: "#ffffff",
        maxWidth: "450px",
        width: "100%",
        borderCollapse: "collapse",
        margin: "0 auto",
        // border: "1px solid #000000",
        fontSize: 10,
      }}
      ref={slipRef}
    >
      <tbody>
        <tr>
          <td>
            <table
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  {printData?.branch_logo && (
                    <td style={{ width: 48 }}>
                      <img
                        src={printData?.branch_logo}
                        alt="logo"
                        style={{
                          maxWidth: 48,
                          maxHeight: 48,
                          objectFit: "contain",
                        }}
                      />
                    </td>
                  )}
                  <td
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <table
                      align="center"
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      width="100%"
                      style={{
                        borderCollapse: "collapse",
                        backgroundColor: "#ffffff",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: 15,
                              fontWeight: 800,
                              textAlign: "center",
                              lineHeight: "28px",
                            }}
                          >
                            {printData?.branch_name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            {printData?.branch_address?.address_line1}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              fontWeight: 600,
                              textAlign: "center",
                              paddingBottom: 10,
                            }}
                          >
                            {printData?.branch_address?.address_line2}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td style={{ padding: "0px 147px 8px" }}>
            <table
              align="center"
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              width="100%"
              style={{
                borderCollapse: "collapse",
                backgroundColor: "#000000",
                boxSizing: "border-box",
                color: "#ffffff",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      textTransform: "uppercase",
                      fontWeight: 600,
                      padding: "2px 0",
                    }}
                  >
                    DELIVERY SLIP
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                    >
                      <tbody>
                        <tr>
                          {/* <td>R.No</td> */}
                          <td
                            style={{
                              width: 6,
                              padding: "2px",
                              fontWeight: 500,
                            }}
                          >
                            {/* : */}
                          </td>
                          <td
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {/* {printData?.gli_number} */}
                          </td>
                        </tr>
                        <tr>
                          <td>GLI.No</td>
                          <td
                            style={{
                              width: 6,
                              padding: "2px",
                              fontWeight: 500,
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {printData?.gli_number}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        marginLeft: "auto",
                        marginRight: 0,
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>Date</td>
                          <td
                            style={{
                              width: 6,
                              padding: "2px 2px",
                              fontWeight: 500,
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {formattedDate}
                          </td>
                        </tr>
                        <tr>
                          <td>Time</td>
                          <td
                            style={{
                              width: 6,
                              padding: "2px 2px",
                              fontWeight: 500,
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {formattedTime}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
                maxWidth: 292,
                verticalAlign: "top",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      width: 72,
                      verticalAlign: "top",
                      padding: "6px 0",
                    }}
                  >
                    Supplier Name
                  </td>
                  <td
                    style={{
                      padding: "6px 4px",
                      fontWeight: 500,
                      width: 10,
                      verticalAlign: "top",
                    }}
                  >
                    :
                  </td>
                  <td
                    style={{
                      fontWeight: 600,
                      padding: "6px 0",
                    }}
                  >
                    {printData?.supplier?.name}
                    <br />
                    {printData?.supplier?.address?.address_line2}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: 72,
                      verticalAlign: "top",
                      padding: "6px 0",
                    }}
                  >
                    Shop Name
                  </td>
                  <td
                    style={{
                      padding: "6px 4px",
                      fontWeight: 500,
                      width: 10,
                      verticalAlign: "top",
                    }}
                  >
                    :
                  </td>
                  <td
                    style={{
                      fontWeight: 600,
                      padding: "6px 0",
                    }}
                  >
                    {printData?.shop?.name} <br />
                    {printData?.shop?.address?.address_line2}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "10px 0 15px" }}>
                    <table
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        width: "100%",
                        border: "0.5px solid #C8C8C8",
                        borderRadius: 1,
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "left",
                              verticalAlign: "top",
                              width: 32,
                            }}
                          >
                            Sl. No
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "left",
                              verticalAlign: "top",
                              width: 62,
                            }}
                          >
                            Item
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                              width: 65,
                            }}
                          >
                            Certified/ Passed
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                              width: 60,
                            }}
                          >
                            Rejection
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Marked
                          </th>
                          <th
                            colSpan={2}
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                              width: 92,
                            }}
                          >
                            Total
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          ></th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          ></th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Pieces
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Weight
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Pieces
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Weight
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Pieces
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Weight
                          </th>
                          <th
                            style={{
                              padding: "3.5px 2px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Total Pieces
                          </th>
                          <th
                            style={{
                              padding: "3.5px 3px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            Total Wt (Cts)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "left",
                                }}
                              >
                                {value?.sl_no}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "left",
                                }}
                              >
                                {value?.item}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.passed_pieces}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.passed_weight}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.rejected_pieces}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.rejected_weight}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.marked_pieces}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.marked_weight}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total_item_pieces}
                              </td>
                              <td
                                style={{
                                  padding: "5.5px 5px",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total_item_weight}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>

                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 8,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "left",
                            }}
                          ></th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 8,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "left",
                            }}
                          >
                            TOTAL
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_passed_pieces}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_passed_weight}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_rejected_pieces}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_rejected_weight}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_marked_pieces}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_marked_weight}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 700,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_pieces}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 800,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            {total?.total_weight}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
                maxWidth: 292,
                verticalAlign: "top",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      width: 72,
                      verticalAlign: "top",
                      padding: "6px 0",
                    }}
                  >
                    Vendor Name
                  </td>
                  <td
                    style={{
                      padding: "6px 4px",
                      fontWeight: 500,
                      width: 10,
                      verticalAlign: "top",
                    }}
                  >
                    :
                  </td>
                  <td
                    style={{
                      fontWeight: 600,
                      padding: "6px 0",
                    }}
                  >
                    {printData?.delivery_details?.person}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: 72,
                      verticalAlign: "top",
                      padding: "6px 0",
                    }}
                  >
                    Address
                  </td>
                  <td
                    style={{
                      padding: "6px 4px",
                      fontWeight: 500,
                      width: 10,
                      verticalAlign: "top",
                    }}
                  >
                    :
                  </td>
                  <td
                    style={{
                      fontWeight: 600,
                      padding: "6px 0",
                    }}
                  >
                    {printData?.delivery_details?.address}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: 72,
                      verticalAlign: "top",
                      padding: "6px 0",
                    }}
                  >
                    Phone
                  </td>
                  <td
                    style={{
                      padding: "6px 4px",
                      fontWeight: 500,
                      width: 10,
                      verticalAlign: "top",
                    }}
                  >
                    :
                  </td>
                  <td
                    style={{
                      fontWeight: 600,
                      padding: "6px 0",
                    }}
                  >
                    {printData?.delivery_details?.contact_number}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td
            style={{
              paddingTop: 20,
              paddingBottom: 2,
            }}
          >
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                    >
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 500 }}>Signature</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        marginRight: 0,
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 500 }}>
                            Authorised Signatory
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default DeliverySlip;
