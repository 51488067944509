import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { PrintCertficate } from "./api";
import {
  useGetCertificateListsQuery,
  useGetRecheckCertificateListsQuery,
} from "../../../../store/queries/Printing";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment/moment";
import { useState } from "react";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const usePrintType = ({
  visitingOneFrontPrintRef2,
  visitingOneBackPrintRef,
  papperFrontPrintRef,
  papperBackPrintRef,
  bigFrontPrintRef,
  vistingDuplexRef,
  papperDuplexRef,
  foldableCardRef,
}) => {
  const { selectedItems, selectedId, isMultiPrint } = useSelector(
    (state) => state.printing
  );
  const { data: basicData } = useGetRecheckCertificateListsQuery({
    id: selectedId,
    is_bulk: isMultiPrint,
  });
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    certificate_type: Yup.object().required("Select Certificate Type"),
    report_id: Yup.object().required("Select report Type"),
    side: Yup.object().test(
      "conditional-validation",
      "Select Side of Certificate",
      function (value) {
        const certificateTypeId = this.parent.certificate_type?._id;
        if (certificateTypeId === 1 || certificateTypeId === 3) {
          return value !== undefined && value !== null;
        }
        return true; // No validation if certificate_type is not 1 or 3
      }
    ),
    card_size: Yup.object().test(
      "conditional-validation",
      "Select Card Size of Certificate",
      function (value) {
        const certificateTypeId = this.parent.certificate_type?._id;
        if (certificateTypeId === 1) {
          return value !== undefined && value !== null;
        }
        return true; // No validation if certificate_type is not 1
      }
    ),
  });
  const formik = useFormik({
    initialValues: {
      certificate_type: "",
      side: "",
      remarks: "",
      report_id: "",
      card_size: {
        name: "Normal (7.8)",
        id: 1,
      },
      certificate_format: "",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      let errors = {};
      if (
        (values?.certificate_type?._id === 1 && values?.side?.id === 1) ||
        values?.certificate_type?._id === 2
      ) {
        if (!values?.certificate_format) {
          errors.certificate_format = "Select one";
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      let data = {
        item_details_id: selectedItems,
        certificate_type: values?.certificate_type?._id,
        remarks: values?.remarks,
        report_id: values?.report_id?._id ?? "",
      };
      PrintCertficate(data).then((response) => {
        if (response?.data?.status_code === 200) {
          // handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.printingData = response?.data?.data;
              state.printUI = true;
              state.clear = true;
            })
          );
          if (values?.certificate_format?.id === 2) {
            handleImagePrints(values);
          } else {
            handlePDFPrints(values);
            const timeOut = setTimeout(() => {
              dispatch(updateConfig((state) => (state.clear = false)));
              clearTimeout(timeOut);
            }, 1000);
          }
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
      // setIsLoading(false);
    },
  });

  function handlePDFPrints(values) {
    if (values?.certificate_type?._id === 1) {
      if (values?.side?.id === 1) {
        handleVistitingTypeOneFrontPrintClick();
      } else if (values?.side?.id === 2) {
        handlevisitingTypeOneBackPrintClick();
      } else {
        handleVisitingDuplexPrint();
      }
    }
    if (values?.certificate_type?._id === 2) {
      handleBigClick();
    }
    if (values?.certificate_type?._id === 3) {
      if (values?.side?.id === 1) {
        handlePapperFrontClick();
      } else if (values?.side?.id === 2) {
        handlePapperBackClick();
      } else {
        handlePapperDuplexPrint();
      }
    }
    if (values?.certificate_type?._id === 4) {
      handleFoldableCardPrint();
    }
  }

  function handleImagePrints(values) {
    if (values?.certificate_type?._id === 1) {
      if (values?.side?.id === 1) {
        handleVisitingCardImage();
      }
    } else if (values?.certificate_type?._id === 2) {
      handleBigCardImage();
    }
  }

  function handleBigCardImage() {
    setTimeout(() => {
      const element = bigFrontPrintRef?.current;

      if (!element) {
        toast.error("No elements in the DOM");
        return;
      }

      const items = element.querySelectorAll(".item-class-big-card");

      if (items.length === 0) {
        toast.error("No elements found with the class 'item-class'.");
        return;
      }

      const images = [];
      const zip = new JSZip();

      const processImage = (item, index) => {
        // Find the element that contains "SUMMARY NO"
        const summaryNoElement = Array.from(item?.querySelectorAll("p")).find(
          (p) => {
            return p.textContent.trim() === "SUMMARY NO";
          }
        );

        // Access the next sibling of the ":" element, which contains the value "051BDBEKI"
        const summaryNoValue =
          summaryNoElement &&
          summaryNoElement.parentElement
            .querySelectorAll("p")[2]
            .textContent.trim();
        return new Promise((resolve) => {
          html2canvas(item, {
            scale: 4,
            useCORS: true,
            logging: true,
            scrollY: 0,
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            images.push(imgData);
            zip.file(`${summaryNoValue}.png`, imgData.split(",")[1], {
              base64: true,
            });
            resolve();
          });
        });
      };

      const processAllImages = async () => {
        for (let i = 0; i < items.length; i++) {
          await processImage(items[i], i);
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(
            content,
            `big_cards_images_${moment(Date.now()).format(
              "MM_DD_YYYY_HH_mm_ss"
            )}.zip`
          );
          handleCloseModal();
        });
      };
      processAllImages();
    }, 1000);
  }

  function handleVisitingCardImage() {
    setTimeout(() => {
      const element = visitingOneFrontPrintRef2?.current;

      if (!element) {
        toast.error("No elements in the DOM");
        return;
      }

      const items = element.querySelectorAll(".item-class-recheck");

      if (items.length === 0) {
        toast.error("No elements found with the class 'item-class-rechek'.");
        return;
      }

      const images = [];
      const zip = new JSZip();
      const processImage = (item, index) => {
        // Find the element that contains "SUMMARY NO"
        const summaryNoElement = Array.from(item?.querySelectorAll("p")).find(
          (p) => {
            return p.textContent.trim() === "SUMMARY NO";
          }
        );

        // Access the next sibling of the ":" element, which contains the value "051BDBEKI"
        const summaryNoValue =
          summaryNoElement &&
          summaryNoElement.parentElement
            .querySelectorAll("p")[2]
            .textContent.trim();
        return new Promise((resolve) => {
          html2canvas(item, {
            scale: 4,
            useCORS: true,
            logging: true,
            scrollY: 0,
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            images.push(imgData);
            zip.file(`${summaryNoValue}.png`, imgData.split(",")[1], {
              base64: true,
            });
            resolve();
          });
        });
      };

      const processAllImages = async () => {
        for (let i = 0; i < items.length; i++) {
          await processImage(items[i], i);
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(
            content,
            `visiting_cards_images_${moment(Date.now()).format(
              "MM_DD_YYYY_HH_mm_ss"
            )}.zip`
          );
          handleCloseModal();
        });
      };
      processAllImages();
    }, 1000);
  }

  const handleVistitingTypeOneFrontPrintClick = () => {
    const element = visitingOneFrontPrintRef2?.current;
    html2pdf(element, {
      margin: [0, 9, 0, 9],
      filename: `${
        "visiting card certificate front" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 720,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: false,
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
          state.card_size = 1;
        })
      );
    }, 500);
  };

  const handlevisitingTypeOneBackPrintClick = () => {
    const element = visitingOneBackPrintRef?.current;
    html2pdf(element, {
      margin: [0, 9, 0, 9],
      filename: `${
        "visiting card certificate" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 720,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: false,
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handlePapperFrontClick = () => {
    const element = papperFrontPrintRef?.current;
    html2pdf(element, {
      margin: [0, 5, 0, 5],
      filename: `${
        "papper card certificate front" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      // pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handlePapperBackClick = () => {
    const element = papperBackPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 5, 5],
      filename: `${
        "papper card certificate back" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handleBigClick = () => {
    const element = bigFrontPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 5, 5],
      filename: `${
        "big card certificate front" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handleVisitingDuplexPrint = () => {
    const element = vistingDuplexRef?.current;
    html2pdf(element, {
      margin: [0, 9, 0, 9],
      filename: `${
        "visiting card duplex" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 720,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
          state.card_size = 1;
        })
      );
    }, 500);
  };

  const handlePapperDuplexPrint = () => {
    const element = papperDuplexRef?.current;
    html2pdf(element, {
      margin: [0, 5, 0, 5],
      filename: `${
        "big card certificate duplex" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handleFoldableCardPrint = () => {
    const element = foldableCardRef?.current;
    html2pdf(element, {
      margin: [12, 5, 0, 5],
      filename: `${
        "foldable_card" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a5", orientation: "landscape" },
      DisablePdfCompression: true,
    })
      .from("element-to-print")
      .outputPdf() // add this to replace implicite .save() method, which triggers file download
      .get("pdf")
      .then(function (pdfObj) {
        pdfObj.autoPrint();
        window.open(pdfObj.output("bloburl"), "F");
      });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.printModal = false)));
    setIsLoading(false);
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const setCardSize = (value) => {
    formik?.setFieldValue("card_size", value || null);
    dispatch(
      updateConfig((state) => {
        state.card_size = value?.id;
      })
    );
  };

  let reportListData = basicData?.data?.report_list?.filter(
    (item) => item?.certificate_type === formik?.values?.certificate_type?._id
  );
  return {
    formik,
    basicData,
    reportListData,
    handleCloseModal,
    getFieldError,
    setCardSize,
    isLoading,
    setIsLoading,
  };
};

export default usePrintType;
