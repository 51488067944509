import Style from "./orderCardListing.module.scss";
import OrderCard from "../OrderCard";
import CardShimmer from "../CardShimmer";
import useGliCardListing from "./useGliCardListing";
import React from "react";

const GliCardListing = ({
  data,
  title,
  scrollRef,
  isOverView = false,
  handleChange,
  activeCard,
  hasButton,
  extraClassName,
  apiStatus,
  refBottom = false,
  photoUpload = false,
  extaClassName,
  handleLoadMore,
  isLoading,
  setIsLoading,
  totalLength,
  checkedIds = [],
  handleCheck = {},
}) => {
  const { scrollRefs, mouseHover, handleMouseOver } = useGliCardListing({
    data: isOverView ? { overView: data } : data,
    handleLoadMore,
    setIsLoading,
    totalLength,
    scrollRef,
    isOverView,
  });
  return (
    <>
      {isOverView ? (
        <>
          {" "}
          <div
            className={`${Style.root_list} ${Style.dashboard_list} ${extraClassName}`}
          >
            {" "}
            <h6 className="pro-ttl pro-pb-3 pro-pt-4 pro-font-regular">
              {title}
            </h6>{" "}
            {/* <div className={`${Style.root_list_item}`}></div> */}{" "}
            <div className={`${Style.root_list_item}`}>
              {data?.map((item, index) => (
                <OrderCard
                  onClick={() => handleChange(item.id, item.gli_number)}
                  title={item.gli_number}
                  subTitle={`${item.shop ?? ""}`}
                  curretNumber={item.total_pending}
                  tottalNumber={item.total}
                  badgeText={item.priority}
                  id={item._id}
                  key={index}
                  extraClassName={Style.dashboard_card}
                  activeCard={{ id: "", key: "" }}
                  supplier={item?.supplier}
                  isOverView={isOverView}
                  item={item}
                />
              ))}
              {mouseHover === "overView" &&
                isLoading &&
                data?.["overView"]?.length !== totalLength?.["overView"] && (
                  <div className={Style.spinnerContainer}>
                    <div className={Style.loader}></div>
                  </div>
                )}
            </div>
            {/* <div className={`${Style.root_list_item}`}></div> */}{" "}
          </div>
        </>
      ) : (
        <>
          {Object.keys(data ? data : {})?.map((key, mainIndex) => (
            <div
              key={mainIndex}
              className={`${Style.root_list} ${extaClassName} ${
                hasButton ? "pro-pb-0" : ""
              }`}
              ref={(ref) => !refBottom && (scrollRefs.current[key] = ref)}
              onMouseEnter={() => handleMouseOver(key)}
            >
              <h6 className="pro-ttl pro-mb-2 pro-font-regular">
                {key ?? "Title"}
              </h6>

              {/* <div className={`${Style.root_list_item}`}></div> */}
              {apiStatus?.[key] === "pending" && data?.[key]?.length <= 0 ? (
                [...Array(10)]?.map((_, i) => <CardShimmer key={i} />)
              ) : (
                <div
                  className={`${Style.root_list_item} ${Style.scroll_list}`}
                  ref={(ref) => refBottom && (scrollRefs.current[key] = ref)}
                >
                  {data[key] &&
                    data[key]?.map((item, index) => (
                      <div className={Style.card_wrapper} key={index}>
                        {photoUpload && key === "Pending" && (
                          <div className={`${Style.checkbox} pro-check-box`}>
                            <input
                              type="checkbox"
                              className={`pro-check ${Style.input}`}
                              id="day_check"
                              name="pro-checkbox"
                              onChange={() => handleCheck(item)}
                              checked={checkedIds?.includes(item?._id)}
                            />
                          </div>
                        )}
                        <OrderCard
                          onClick={() => {
                            handleChange(item._id, item.gli_number, key);
                            photoUpload &&
                              key === "Pending" &&
                              handleCheck(item);
                          }}
                          title={
                            photoUpload && key !== "PendingQueue"
                              ? item?.certificate_number
                              : item.gli_number
                          }
                          subTitle={`${item.shop}`}
                          curretNumber={item.total_pending}
                          tottalNumber={item.total}
                          badgeText={item.priority}
                          activeCard={activeCard?.key === key && activeCard}
                          id={item._id}
                          key={index}
                          supplier={item?.supplier}
                          isOverview={isOverView}
                          item={item}
                          title2={
                            photoUpload &&
                            key !== "PendingQueue" &&
                            item?.gli_number
                          }
                        />
                      </div>
                    ))}
                  {mouseHover === key &&
                    isLoading &&
                    data?.[key]?.length !== totalLength?.[key] &&
                    [...Array(2)]?.map((_, i) => <CardShimmer key={i} />)}
                </div>
              )}
              {/* <div className={`${Style.root_list_item}`}></div> */}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default GliCardListing;
