import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Packings/packingsSlice";
import { useEffect, useState } from "react";
import { getPackingTypeList } from "./api";
import { useSelector } from "react-redux";

const usePassAll = ({ handlePassAll }) => {
  const dispatch = useDispatch();
  const [packingTypeData, setPackingTypeData] = useState();
  const { packingTypeId } = useSelector((state) => state.packings);

  useEffect(() => {
    getPackingTypeList().then((response) => {
      setPackingTypeData(response?.data?.data);
    });
    //eslint-disable-next-line
  }, []);

  const handleConfirm = () => {
    handlePassAll();
  };
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showPassAllModal = false;
        state.showPassAllRecheckModal = false;
        state.packingTypeId = "";
      })
    );
  };
  const handlePackingTypeChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.packingTypeId = value;
      })
    );
  };

  const handleRemarkChange = (e) => {
    dispatch(updateConfig((state) => (state.remark = e?.target?.value)));
  };
  return {
    packingTypeId,
    packingTypeData,
    handlePackingTypeChange,
    handleRemarkChange,
    handleConfirm,
    handleClose,
  };
};

export default usePassAll;
