import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Reports/reportsSlice";
import { useGetOtherReportEmployeeTableDataQuery } from "../../../../store/queries/Reports";
import { useNavigate } from "react-router-dom";

const useEmployeeReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];


  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportState = useSelector((state) => state.reports);
  const formik = useFormik({
    initialValues: {
      branch: "",
    },
  });

  const {
    data: employeeReportData = {},
    isFetching,
    isLoading,
  } = useGetOtherReportEmployeeTableDataQuery({
    per_page: reportState?.otherReport?.currentPageSize,
    page: reportState?.otherReport?.currentPage,
    sort_by: reportState?.otherReport?.sortBy,
    sort_order: reportState?.otherReport?.sortOrder,
    search: reportState?.otherReport?.search,
    branch_id: formik?.values?.branch?._id,
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.search = e.target.value;
        state.otherReport.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPageSize = page_size;
        state.otherReport.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.otherReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortOrder =
            reportState.otherReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortBy = label;
          state.otherReport.sortOrder = "asc";
        })
      );
    }
  };

  const handleNameClick = (item) => {
    navigate({
      pathname: "/report/other/detail",
      search: `?id=${item?.user_id}&bid=${item?.branch_id}`,
    });
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      first_name: (field, data) => (
        <p className="pro-mb-0 pro-pnt" onClick={() => handleNameClick(data)}>
          {data[field]}
        </p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    formik,
    isFetching,
    isLoading,
    reportState,
    employeeReportData,
    paginationOptions,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
  };
};

export default useEmployeeReport;
