import { getAxiosInstance } from "../../../api";

export const assignWork = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/employee-dashboard/recheck/work-assign`,
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const assignedCertificate = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/employee-dashboard/recheck/list-assigned-certificate?receipt_id=${id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const assignRecheckAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/recheck/bulk-assign`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
