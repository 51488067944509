import React from "react";
import { useSelector } from "react-redux";

const Back = React.forwardRef(({ visitingOneBackPrintRef }, ref) => {
  const { printingData } = useSelector((state) => state.printing);
  return (
    <div
      ref={visitingOneBackPrintRef}
      style={{ boxSizing: "border-box" }}
    >
      <title>DHC Card Back</title>

      <div style={{ height: "1113.5px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "start",
              columnGap: 62,
              flexDirection: "row-reverse",
              paddingRight: 8,
              paddingLeft: 8,
              rowGap: 14,
              paddingTop: 10,
              fontFamily: "'Graphik',san-serif",
              width: 720,
              fontSize: 8,
            }}
          >
            {printingData?.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    maxWidth: 321,
                    minWidth: 321,
                    width: 321,
                    maxHeight: 207,
                    minHeight: 207,
                    height: 207,
                    lineHeight: 0,
                    border: "1px solid #f3f3f3",
                    padding: 0,
                    margin: 0,
                    overflow: "hidden",
                    transform: "scale(1.03)",
                    backgroundImage: `url(${value?.back_image})`,
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src={value?.back_image}
                    width={321}
                    height={207}
                    alt="back_image"
                    style={{
                      width: "100%",
                      height: "100%",
                      lineHeight: 0,
                      padding: 0,
                      margin: 0,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
    </div>
  );
});

export default Back;
