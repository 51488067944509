import { getAxiosInstance } from "../../../../../../api";

export const updateFields = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/qc/multi-color-clarity-update", body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getEditDetails = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/multi-color-clarity-detail?process_slug=${params?.process_slug}&id=${params?.id}`,
      params
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
