import { useSelector } from "react-redux";
import {
  useGetEmployeeListDataQuery,
  useUpdateEmployeeTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Employee/employeeSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { approveEmployeeRequest, deleteEmployee, getEmployeeData } from "./api";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useEmployee = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const employeeState = useSelector((state) => state.employee);

  const {
    data: employeeList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetEmployeeListDataQuery({
    page_size: employeeState?.currentPageSize,
    page: employeeState?.currentPage,
    sort_by: employeeState?.sortBy,
    sort_order: employeeState?.sortOrder,
    start: employeeState?.startDate,
    end: employeeState?.endDate,
    search: employeeState?.search,
  });

  const [updateEmployeeFields] = useUpdateEmployeeTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = employeeList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [employeeList]);

  useEffect(() => {
    if (employeeState?.selectedEmployee && employeeState?.isEdit) {
      fillEmployeeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeState?.isEdit, employeeState?.showAddBranchModal]);
  useEffect(() => {
    if (employeeState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [employeeState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddEmployeeModal = !state.showAddEmployeeModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (field, data) =>
        data?.status === "Pending" && employeeList?.data?.approve_permission ? (
          <Button
            className={"pro-btn-primary sm"}
            type="button"
            onClick={() => handleApprovePermission(data?._id)}
          >
            {"Approve"}
          </Button>
        ) : data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleApprovePermission = (approveId) => {
    let params = {
      employee_id: approveId,
    };
    approveEmployeeRequest(params).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        toast.success("Approved successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleDelete = () => {
    deleteEmployee(`employee/delete`, employeeState?.selectedEmployee).then(
      (response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Employee deleted successfully");
          handleDeleteModal();
          refetch();
        } else {
          toast.error("Something went wrong");
          handleDeleteModal();
        }
      }
    );
  };

  const fillEmployeeData = () => {
    getEmployeeData(employeeState?.selectedEmployee).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.employeeData = response?.data?.data))
        );
      } else {
        updateEmployeeFields();
        toast.error("Something went wrong to fetch employee data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedEmployee = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddEmployeeModal = true;
        state.selectedEmployee = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (employeeState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = employeeState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    employeeState,
    showEditModal,
    tableFields,
    employeeList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateEmployeeFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useEmployee;
