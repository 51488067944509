import React from "react";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "./employeeMaster.module.scss";

import Select from "react-select";
import { useEmployeeMaster } from "./useEmployeeMaster";

export const EmployeeMaster = (refetch) => {
  const {
    formik,
    basicData,
    isEdit,
    showPassword,
    countryData,
    handleShowPassword,
    handleCloseModal,
    getFieldError,
  } = useEmployeeMaster(refetch);

  return (
    <div className="row">
      <Input
        label={"First Name"}
        type="text"
        id="first_name"
        name="first_name"
        className={`pro-input lg ${getFieldError("first_name") && " error"}`}
        {...formik.getFieldProps("first_name")}
        error={getFieldError("first_name")}
        errorMessage={getFieldError("first_name")}
      />
      <Input
        label={"Last Name"}
        type="text"
        id="last_name"
        name="last_name"
        className={`pro-input lg ${getFieldError("last_name") && " error"}`}
        {...formik.getFieldProps("last_name")}
        error={getFieldError("last_name")}
        errorMessage={getFieldError("last_name")}
      />

      <Input
        label={"Email Id"}
        type="email"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Phone Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryData}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
                menuPlacement="auto"
              ></Select>
            </div>
            <input
              type="text"
              id="phone"
              name="phone"
              className={`pro-input lg ${
                formik.errors.phone && formik.touched.phone && " error"
              }`}
              {...formik.getFieldProps("phone")}
            />
            {formik.errors.phone && formik.touched.phone && (
              <>
                <span className="error-text">{formik.errors.phone}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          User Role
        </label>
        <Select
          id="role_id"
          name="role_id"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("role_id") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.data?.roles_list}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.role_id}
          onBlur={formik.handleBlur("role_id")}
          onChange={(value) => {
            formik?.setFieldValue("role_id", value || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("role_id") && (
          <span className="error-text">{getFieldError("role_id")}</span>
        )}
      </div>
      {!isEdit ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Appointed In Department
          </label>
          <Select
            id="registerd_department"
            name="registerd_department"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("registerd_department") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.data?.department_list}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={formik?.values?.registerd_department}
            onBlur={formik.handleBlur("registerd_department")}
            onChange={(value) =>
              formik?.setFieldValue("registerd_department", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("registerd_department") && (
            <span className="error-text">
              {getFieldError("registerd_department")}
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      {!isEdit ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Appointed In Branch
          </label>
          <Select
            id="registred_branch"
            name="registred_branch"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("registred_branch") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.data?.branch_list}
            getOptionLabel={(option) => option?.branch_name}
            getOptionValue={(option) => option?._id}
            value={formik?.values?.registred_branch}
            onBlur={formik.handleBlur("registred_branch")}
            onChange={(value) =>
              formik?.setFieldValue("registred_branch", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("registred_branch") && (
            <span className="error-text">
              {getFieldError("registred_branch")}
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Branches
        </label>
        <Select
          id="branches"
          name="branches"
          isMulti={true}
          placeholder="Select"
          className={`pro-input lg  ${getFieldError("branches") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.data?.branch_list}
          getOptionLabel={(option) => option?.branch_name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.branches}
          onBlur={formik.handleBlur("branches")}
          onChange={(value) => formik?.setFieldValue("branches", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("branches") && (
          <span className="error-text">{getFieldError("branches")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Immediate Supervisor
        </label>
        <Select
          id="supervisor_id"
          name="supervisor_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("supervisor_id") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.supervisor_list ?? []}
          getOptionLabel={(option) => option?.first_name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.supervisor_id}
          onBlur={formik.handleBlur("supervisor_id")}
          onChange={(value) =>
            formik?.setFieldValue("supervisor_id", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("supervisor_id") && (
          <span className="error-text">{getFieldError("supervisor_id")}</span>
        )}
      </div>
      <div>
        <Input
          label={"User Name"}
          type="text"
          id="user_name"
          name="user_name"
          className={`pro-input lg ${getFieldError("user_name") && " error"}`}
          {...formik.getFieldProps("user_name")}
          error={getFieldError("user_name")}
          errorMessage={getFieldError("user_name")}
          disabled={isEdit}
          placeholder={isEdit ? "Username can't be changed" : ""}
        />
        {!isEdit && (
          <div className="col-12 p-re">
            <Input
              label={"Password"}
              type="password"
              id="password"
              autoComplete="new-password"
              name="password"
              className={`pro-input lg ${
                getFieldError("password") && " error"
              }`}
              {...formik.getFieldProps("password")}
              error={getFieldError("password")}
              errorMessage={getFieldError("password")}
              disabled={isEdit}
              placeholder={isEdit ? "Password can't be changed" : ""}
            />
            {showPassword ? (
              <button
                className={`hide-icon`}
                onClick={(e) => handleShowPassword(e)}
              >
                <span className="material-symbols-outlined"> visibility </span>
              </button>
            ) : (
              <button
                className={`hide-icon`}
                onClick={(e) => handleShowPassword(e)}
              >
                <span className="material-symbols-outlined">
                  {" "}
                  visibility_off{" "}
                </span>
              </button>
            )}
          </div>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn ${Style.button}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default EmployeeMaster;
