import {
  Button,
  IconText,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useExcelUpload from "./useExcelUpload";

const ExcelUpload = () => {
  const { formik, handleClose, handleExcelUpload, getFieldError } =
    useExcelUpload();
  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Excel Upload
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="placeholder"
            className={`pro-input lg ${getFieldError("qc_report") && " error"}`}
            id="qc_report"
            onBlur={formik.handleBlur("qc_report")}
            onChange={(e) => handleExcelUpload(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {formik?.values?.qc_report?.name ? (
              formik?.values?.qc_report?.name
            ) : formik?.values?.qc_report ? (
              formik?.values?.qc_report
            ) : (
              <IconText title={`Drop files to attach or browse`} />
            )}
          </span>
          {getFieldError("qc_report") && (
            <span className="error-text">{getFieldError("qc_report")}</span>
          )}
        </div>
      </div>
      <Input
        label={`Remarks`}
        id="remarks"
        name="remarks"
        type="text"
        {...formik.getFieldProps(`remarks`)}
        className={`pro-input lg`}
      />
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </>
  );
};

export default ExcelUpload;
