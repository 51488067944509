import React from "react";
import useService from "./useService";
import Select from "react-select";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const Service = ({
  isStickyFooter,
  formik,
  // isEdit,
  optionsData,
  getFieldError,
}) => {
  const {
    handleNext,
    handleCloseModal,
    // handleDocumentChange,
    // handleDocumentChangeFirst,
  } = useService({ formik });

  // let serviceObj = optionsData?.services?.filter(
  //   (item) => item?._id === formik?.values?.service_type
  // );
  let colorObj = optionsData?.color?.filter(
    (item) => item?._id === formik?.values?.color
  );
  let clarityObj = optionsData?.clarity?.filter(
    (item) => item?._id === formik?.values?.clarity
  );
  let cutObj = optionsData?.common_list?.filter(
    // eslint-disable-next-line eqeqeq
    (item) => item?.id == formik?.values?.cut
  );
  let finishObj = optionsData?.finish?.filter(
    (item) => item?._id === formik?.values?.finish
  );
  let packageObj = optionsData?.packing_types?.filter(
    (item) => item?._id === formik?.values?.packaging_type
  );
  let certificateObj = optionsData?.certificate_type_list?.filter(
    // eslint-disable-next-line
    (item) => item?.id == formik?.values?.certificate_type
  );
  let paymentObj = optionsData?.payment_mode?.filter(
    // eslint-disable-next-line
    (item) => item?.id == formik?.values?.payment_mode
  );
  let serviceTypeOptions = optionsData?.services.filter(
    (item) => item?.parent_id === formik?.values?.item_type
  );
  let preparedObj = optionsData?.prepared_by?.filter(
    (item) => item?._id === formik?.values?.prepared_by
  );

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Service Type
        </label>
        <Select
          isMulti={true}
          id="service_type"
          name="service_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("service_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={serviceTypeOptions?.[0]?.services ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          onChange={(value) =>
            formik?.setFieldValue("service_type", value || "")
          }
          onBlur={formik?.handleBlur("service_type")}
          value={formik?.values?.service_type}
          menuPlacement="auto"
        />
        {getFieldError("service_type") && (
          <span className="error-text">{getFieldError("service_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Color
        </label>
        <Select
          id="color"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("color") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.color ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          onChange={(value) => formik?.setFieldValue("color", value?._id || "")}
          onBlur={formik?.handleBlur("color")}
          value={colorObj}
          menuPlacement="auto"
        />
        {getFieldError("color") && (
          <span className="error-text">{getFieldError("color")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Clarity
        </label>
        <Select
          id="clarity"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("clarity") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.clarity ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          onChange={(value) =>
            formik?.setFieldValue("clarity", value?._id || "")
          }
          onBlur={formik?.handleBlur("clarity")}
          value={clarityObj}
          menuPlacement="auto"
        />
        {getFieldError("clarity") && (
          <span className="error-text">{getFieldError("clarity")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Cut
        </label>
        <Select
          id="cut"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("cut") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.common_list ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?.id}
          onChange={(value) => formik?.setFieldValue("cut", value?.id || "")}
          onBlur={formik?.handleBlur("cut")}
          value={cutObj}
          menuPlacement="auto"
        />
        {getFieldError("cut") && (
          <span className="error-text">{getFieldError("cut")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Finish
        </label>
        <Select
          id="finish"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("finish") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.finish ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          onChange={(value) =>
            formik?.setFieldValue("finish", value?._id || "")
          }
          onBlur={formik?.handleBlur("finish")}
          value={finishObj}
          menuPlacement="auto"
        />
        {getFieldError("finish") && (
          <span className="error-text">{getFieldError("finish")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="double_grade"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Grade
        </label>
        <Select
          id="double_grade"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("double_grade") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.grade_list ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?.id}
          onChange={(value) =>
            formik.setFieldValue(
              "double_grade",
              value.map((v) => v.id)
            )
          }
          isMulti
          onBlur={formik?.handleBlur("double_grade")}
          value={optionsData?.grade_list?.filter((m) =>
            formik.values.double_grade?.includes?.(m.id)
          )}
          menuPlacement="auto"
        />
        {getFieldError("double_grade") && (
          <span className="error-text">{getFieldError("double_grade")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Packaging Type
        </label>
        <Select
          id="packaging_type"
          name="packaging_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("packaging_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.packing_types ?? []}
          getOptionLabel={(options) => options?.packing_type}
          getOptionValue={(options) => options?._id}
          onChange={(value) =>
            formik?.setFieldValue("packaging_type", value?._id || "")
          }
          onBlur={formik?.handleBlur("packaging_type")}
          value={packageObj}
          menuPlacement="auto"
        />
        {getFieldError("packaging_type") && (
          <span className="error-text">{getFieldError("packaging_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Certificate type
        </label>
        <Select
          id="certificate_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg`}
          classNamePrefix="pro-input"
          options={optionsData?.certificate_type_list ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?.id}
          onChange={(value) =>
            formik?.setFieldValue("certificate_type", value?.id || "")
          }
          value={certificateObj}
          menuPlacement="auto"
        />
        {getFieldError("certificate_type") && (
          <span className="error-text">
            {getFieldError("certificate_type")}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Prepared By
        </label>
        <Select
          id="prepared_by"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("prepared_by") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.prepared_by ?? []}
          getOptionLabel={(options) => {
            return options?.first_name + " " + options?.last_name;
          }}
          getOptionValue={(options) => options?._id}
          onChange={(value) =>
            formik?.setFieldValue("prepared_by", value?._id || "")
          }
          onBlur={formik?.handleBlur("prepared_by")}
          value={preparedObj}
          menuPlacement="auto"
          isDisabled
        />
        {getFieldError("prepared_by") && (
          <span className="error-text">{getFieldError("prepared_by")}</span>
        )}
      </div>
      {/* <Input
        label={"Prepared By"}
        type="text"
        id="prepared_by"
        name="prepared_by"
        className={`pro-input lg ${getFieldError("prepared_by") && " error"}`}
        {...formik.getFieldProps("prepared_by")}
        error={getFieldError("prepared_by")}
        errorMessage={getFieldError("prepared_by")}
      /> */}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Payment Mode
        </label>
        <Select
          id="payment_mode"
          name="payment_mode"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("payment_mode") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.payment_mode ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?.id}
          onChange={(value) =>
            formik?.setFieldValue("payment_mode", value?.id || "")
          }
          onBlur={formik?.handleBlur("payment_mode")}
          value={paymentObj}
          menuPlacement="auto"
        />
        {getFieldError("payment_mode") && (
          <span className="error-text">{getFieldError("payment_mode")}</span>
        )}
      </div>

      <>
        {/* changed as per review */}
        {/* <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Excel Upload
            </label>
            <div className="input-drag">
              <input
                type="file"
                multiple
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                placeholder="placeholder"
                // ref={documentRef}
                className={`pro-input lg ${
                  getFieldError("excel_file") && " error"
                }`}
                id="excel_file"
                onBlur={formik.handleBlur("excel_file")}
                onChange={(e) => handleDocumentChangeFirst(e)}
              />

              <span className="input-drag-box" style={{ padding: "50px" }}>
                {formik?.values?.excel_file?.name ? (
                  formik?.values?.excel_file?.name
                ) : formik?.values?.excel_file ? (
                  formik?.values?.excel_file
                ) : (
                  <IconText title={`Drop files to attach or browse`} />
                )}
              </span>
              {getFieldError("excel_file") && (
                <span className="error-text">
                  {getFieldError("excel_file")}
                </span>
              )}
            </div>
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Additional Document
            </label>
            <div className="input-drag">
              <input
                type="file"
                multiple
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                placeholder="placeholder"
                // ref={documentRef}
                className={`pro-input lg ${
                  getFieldError("additional_document") && " error"
                }`}
                id="additional_document"
                onBlur={formik.handleBlur("additional_document")}
                onChange={(e) => handleDocumentChange(e)}
              />
              <span className="input-drag-box" style={{ padding: "50px" }}>
                {formik?.values?.additional_document?.name ? (
                  formik?.values?.additional_document?.name
                ) : formik?.values?.additional_document ? (
                  formik?.values?.additional_document
                ) : (
                  <IconText title={`Drop files to attach or browse`} />
                )}
              </span>
              {getFieldError("additional_document") && (
                <span className="error-text">
                  {getFieldError("additional_document")}
                </span>
              )}
            </div>
          </div>
        </> */}
        {/*
          removed as per review
          <>
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor=""
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                Image for Certificate
              </label>
              <div className="input-drag">
                <input
                  type="file"
                  multiple
                  accept="image/png, image/jpeg"
                  placeholder="placeholder"
                  // ref={documentRef}
                  className={`pro-input lg ${
                    getFieldError("image_for_certificate") && " error"
                  }`}
                  id="image_for_certificate"
                  onBlur={formik.handleBlur("image_for_certificate")}
                  onChange={(e) => handleCertificateChange(e)}
                />

                <span className="input-drag-box" style={{ padding: "50px" }}>
                  {!isEdit && formik?.values?.image_for_certificate === "" ? (
                    <IconText title={`Drop files to attach or browse`} />
                  ) : (
                    formik?.values?.image_for_certificate?.name
                  )}
                  {isEdit &&
                    (orderData?.image_for_certificate_name ?? (
                      <IconText
                        title={`${"Drop files to attach or browses"}`}
                      />
                    ))}
                </span>

                {getFieldError("image_for_certificate") && (
                  <span className="error-text">
                    {getFieldError("image_for_certificate")}
                  </span>
                )}
                <div className={`col-2 pro-my-2`}>
                  <div className={`${""}`}>
                    <Image
                      width={100}
                      height={100}
                      src={certificateImage}
                      alt={`image - 01`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </> */}
      </>
      <Input
        label={"Remarks"}
        type="text"
        id="remarks"
        name="remarks"
        className={`pro-input lg ${getFieldError("remarks") && " error"}`}
        {...formik.getFieldProps("remarks")}
        error={getFieldError("remarks")}
        errorMessage={getFieldError("remarks")}
      />
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btn"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-ms-3"} onClick={handleNext}>
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Service;
