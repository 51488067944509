import { useSelector } from "react-redux";
import {
  useGetBillingReissueCertificateListDataQuery,
  useUpdateBillingReissueCertificateTableHeadDataMutation,
} from "../../../../store/queries/Billing";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Billing/billingReissueSlice";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { toast } from "react-toastify";
import { getEstimate } from "./api";

const useCertificateList = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const estimateRef = useRef();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const billingReissueState = useSelector((state) => state.billingReissue);
  const {
    data: billingReissueCertificateList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBillingReissueCertificateListDataQuery({
    page_size: billingReissueState.currentPageSize,
    page: billingReissueState.currentPage,
    sort_by: billingReissueState.sortBy,
    sort_order: billingReissueState.sortOrder,
    search: billingReissueState.search,
    receipt_id:
      localStorage.getItem("receiptId") ?? billingReissueState?.selectedId,
  });

  const [updateBillingReissueCertificateFields] =
    useUpdateBillingReissueCertificateTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = billingReissueCertificateList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [billingReissueCertificateList]);
  useEffect(() => {
    if (billingReissueState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [billingReissueState.clearSelection]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (billingReissueState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            billingReissueState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleReissueCertificate = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showPrintConfirmModal = !state.showPrintConfirmModal;
        state.selectedIds = e;
      })
    );
  };
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showPrintConfirmModal = false;
      })
    );
  };
  const handleCertificatePrint = () => {
    let data = {
      item_details_id: billingReissueState.selectedIds,
    };
    getEstimate(data).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.estimatePrintData = response?.data?.data)
          )
        );
        handleClose();
        setTimeout(() => {
          const element = estimateRef?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Estimate" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    estimateRef,
    showEditModal,
    paginationOptions,
    billingReissueState,
    billingReissueCertificateList,
    updateBillingReissueCertificateFields,
    handleReissueCertificate,
    handleEditColumnsClick,
    handleCertificatePrint,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleClose,
    handleSort,
    refetch,
    getRow,
  };
};

export default useCertificateList;
