import React from "react";
import { useSelector } from "react-redux";

const EstimateReport = React.forwardRef(({ estimateRef }, ref) => {
  const { supportingPrintData } = useSelector((state) => state.staffBilling);
  const Data = supportingPrintData?.result ?? [];
  const Total = supportingPrintData?.grand_total ?? {};

  function getCurrentDateIndianFormat() {
    const today = new Date();
    return `${today.getDate().toString().padStart(2, "0")}/${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;
  }
  const currentDate = getCurrentDateIndianFormat();
  return (
    <div ref={estimateRef}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          backgroundColor: "#ffffff",
          maxWidth: "95%",
          width: "100%",
          borderCollapse: "collapse",
          margin: "0 auto",
          // border: "1px solid #000000",
          fontSize: 20,
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontSize: 20,
                textTransform: "upper case",
                fontWeight: 600,
                padding: "3px 0",
              }}
            >
              ESTIMATE BILL REPORT
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: "right",
                fontSize: 12,
                textTransform: "upper case",
                fontWeight: 400,
                padding: "3px 0",
              }}
            >
              Date :{currentDate}
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontSize: 15,
                textTransform: "upper case",
                fontWeight: 400,
                padding: "3px 0",
                innerHeight: 20,
              }}
            >
              {supportingPrintData?.address_type === 1 ? `Supplier Name : ${supportingPrintData?.supplier_name}` : (
                `Shop Name : ${supportingPrintData?.shop_name}`
              )}
            </td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "top" }}>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "15px 0px" }}>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          borderRadius: 1,
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Est. No
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              GLI No
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                             {
                                supportingPrintData?.address_type === 1 ? "Shop / Agent" : "Supplier / Agent"
                              }
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              ItemQty
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Cts
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Price
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "2px solid #C8C8C8",
                                borderTop: "2px solid #C8C8C8",
                                textAlign: "right",
                              }}
                            >
                              Total Amt
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Data.map((value, index) => (
                            <>
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    verticalAlign: "top",
                                    borderBottom: "0.5px solid #C8C8C8",
                                  }}
                                >
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {val?.date}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {/* {value?.data?.estimate_number} */}
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {val?.estimate_number}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {/* {value?.data?.gli_number} */}
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {val?.gli_number}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                  <br />
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {/* {value?.data?.supplier} */}
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <span key={index}>
                                        {val?.supplier}
                                        {(index !== value?.table?.length - 1 && !!val?.supplier) && (
                                          <br />
                                        )}
                                      </span>
                                    );
                                  })}
                                  {/* {value?.data?.collection_agent} */}
                                  {/* {value?.table?.map((val, index) => {
                                    return (
                                      <span key={index}>
                                        {val?.collection_agent}
                                        {(index !== value?.table?.length - 1 && !!val?.collection_agent) && (
                                          <br />
                                        )}
                                      </span>
                                    );
                                  })} */}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <span key={index}>
                                        {val?.item_quantity}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </span >
                                    );
                                  })}

                                  {/* {value?.above?.item_quantity}
                                  {value?.above?.item_quantity && <br />}
                                  {value?.below?.item_quantity}
                                  {value?.below?.item_quantity && <br />}
                                  {value?.other?.item_quantity}
                                  {value?.other?.item_quantity && <br />}
                                  {value?.reissue?.item_quantity}
                                  {value?.reissue?.item_quantity && <br />}
                                  {value?.mixed?.item_quantity} */}
                                  {/* <br /> */}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {val?.carat}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                  {/* {value?.above?.carat}
                                  {value?.above?.carat && <br />}
                                  {value?.below?.carat}
                                  {value?.below?.carat && <br />}
                                  {value?.other?.carat}
                                  {value?.other?.carat && <br />}
                                  {value?.reissue?.carat}
                                  {value?.reissue?.carat && <br />}
                                  {value?.mixed?.carat}
                                  <br /> */}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {val?.prices}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                  {/* {value?.above?.prices}
                                  {value?.above?.prices && <br />}
                                  {value?.below?.prices}
                                  {value?.below?.prices && <br />}
                                  {value?.other?.prices}
                                  {value?.other?.prices && <br />}
                                  {value?.reissue?.prices}
                                  {value?.reissue?.prices && <br />}
                                  {value?.mixed?.prices}
                                  <br /> */}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    fontSize: 15,
                                    fontWeight: 400,
                                    textAlign: "right",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {value?.table?.map((val, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {val?.total}
                                        {index !== value?.table?.length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                  {/* {value?.above?.total}
                                  {value?.above?.total && <br />}
                                  {value?.below?.total}
                                  {value?.below?.total && <br />}
                                  {value?.other?.total}
                                  {value?.other?.total && <br />}
                                  {value?.reissue?.total}
                                  {value?.reissue?.total && <br />}
                                  {value?.mixed?.total}
                                  <br /> */}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>

                        {/* tottal  */}
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 18,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "left",
                                textTransform: "uppercase",
                              }}
                            >
                              Grand Total
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 15,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                            colSpan={3}
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 18,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "right",
                              }}
                            >
                              {Total?.total}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default EstimateReport;
