import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import { useGetPrintingReissueTableDataQuery } from "../../../../store/queries/Printing";
import { toast } from "react-toastify";
import { getFormData } from "../api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Style from "../printing.module.scss";

const usePrintingTable = () => {
  let paginationOptions = [
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
    { label: "80 Per page", value: 80 },
    { label: "100 Per page", value: 100 },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state.printing);
  const { tableFields } = useSelector((state) => state.global);

  const slug = localStorage?.getItem("slug");
  const {
    data: tableData,
    isFetching,
    isLoading,
  } = useGetPrintingReissueTableDataQuery({
    receipt_id: state?.selectedId,
    search: state.search,
    page_size: state.currentReissuePageSize,
    page: state.currentReissuePage,
    process_slug: slug,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [tableData]);


  const handleCopyText = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success("Copied");
    } catch (error) {
      toast.error("Failed to copy");
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      certificate_number: (feild, data) => (
        <p className="pro-mb-0 pro-d-flex pro-justify-between pro-items-center pro-gap-4">
          <span>{data[feild]}</span>
          <OverlayTrigger
            placement="auto"
            onToggle={(toggle) => {
              // const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
              const body = document.body;
              body.style.overflow = toggle ? "hidden" : null;
              // body.style.paddingRight = toggle ? `${scrollWidth}px` : null;
            }}
            overlay={
              <Tooltip id="button-tooltip" className={Style.tooltip}>
                <table className="">
                  <tbody>
                    <tr>
                      <td>Summary No.</td>
                      <td> : </td>
                      <td>{data[feild]}</td>
                    </tr>
                    {data["graded_values"]?.qc_data?.map(
                      (item, index) =>
                        !!item.name && (
                          <tr key={index}>
                            <td>{item?.name}</td>
                            <td> : </td>
                            <td>{item?.value || "-"}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </Tooltip>
            }
          >
            <span className={Style.info_btn}>
              <span className="material-symbols-outlined pro-fw-medium">
                {" "}
                info{" "}
              </span>
            </span>
          </OverlayTrigger>
        </p>
      ),
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
        grading_status: (_, data) =>
          data?.grading_status === "Rejected" ? (
            <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
              {"Rejected"}
            </span>
          ) : data?.grading_status === "Marked" ? (
            <span className={"outline-primary pro-badge pro-d-inline-block"}>
              {"Marked"}
            </span>
          ) : data?.grading_status === "Completed" ? (
            <span className={"outline-success pro-badge pro-d-inline-block"}>
              {"Completed"}
            </span>
          ) : data?.grading_status === "Pending" ? (
            <span className={"outline-warning pro-badge pro-d-inline-block"}>
              {"Pending"}
            </span>
          ) : (
            <span className={"outline pro-badge pro-d-inline-block"}>
              {data?.grading_status}
            </span>
          ),
          url :(field, data)=>
            data?.[field] ? (
              <div className="pro-d-flex pro-items-center pro-gap-2 no-wrap">
                <p className="pro-mb-0">{data[field]}</p>
                <span
                  className={Style.copy_text}
                  onClick={() => handleCopyText(data[field])}
                >
                  <span className="material-symbols-outlined">content_copy</span>
                </span>
              </div>
            ) : (
              ""
            ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentReissuePageSize = page_size;
        state.currentReissuePage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentReissuePage = page;
      })
    );
  };

  const handlePrintClick = (items) => {
    dispatch(
      updateConfig((state) => {
        state.printModal = true;
        state.selectedItems = items;
      })
    );
  };
  const handleEditClick = (item) => {
    if (item?.length > 1) {
      toast.warning("Select one for editing");
    } else {
      dispatch(
        updateConfig((state) => {
          state.selectedListId = item?.[0];
        })
      );
      let data = {
        item_details_id: item?.[0],
        item_type_id: state?.itemTypeId,
        process_slug: "qc",
      };
      dispatch(async (dispatch) => {
        try {
          const response = await getFormData(data);
          if (response?.data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.formData = response?.data?.data;
                state.showForm = true;
              })
            );
          } else {
            toast.error("Failed to fetch form data");
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error("An error occurred while fetching form data");
        }
      });
    }
  };

  const handleBulkPass = () => {
    dispatch(
      updateConfig((state) => (state.bulkPassModal = !state.bulkPassModal))
    );
  };

  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.search = e?.target?.value)));
  };
  return {
    isFetching,
    isLoading,
    tableData,
    tableFields,
    paginationOptions,
    state,
    getRow,
    handleBulkPass,
    handlePageSize,
    handlePagination,
    handlePrintClick,
    handleEditClick,
    handleSearch,
  };
};

export default usePrintingTable;
