import {
  OffCanvasLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderCardListing from "../OrderCardListing";
import useStaffWindow from "./useStaffWindow";
import Style from "./staffWindow.module.scss";
import Filter from "./Filter";
import PendingTable from "./PendingTable";
import AssignedTable from "./AssignedTable";

const StaffWindow = () => {
  const {
    staffState,
    isLoading,
    totalLength,
    activeCard,
    apiStatus,
    handleLoadMore,
    setIsLoading,
    handleFilter,
    handleCardClick,
    handleStartClick,
    handleSearch,
  } = useStaffWindow();
  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-4 col-5 ${Style.card_main_wrapper}`}>
            <div className="row pro-mb-4 gx-2">
              <div className="col-auto">
                <SearchFilters
                  showDateRange={false}
                  onSearchInput={handleSearch}
                  showFilters={true}
                  showActions={false}
                  searchInputProps={{ value: staffState?.search }}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                />
              </div>
              <div className="col">
                <button
                  className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
                  onClick={handleFilter}
                >
                  <span className="material-symbols-outlined">tune</span>
                  <span>Filter</span>
                </button>
              </div>
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                activeCard={activeCard}
                hasButton={true}
                isLoading={isLoading}
                refBottom={true}
                totalLength={totalLength}
                data={staffState?.cardData}
                extaClassName={Style.card_listing}
                handleChange={handleCardClick}
                handleStartClick={handleStartClick}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          {staffState?.showTable && (
            <div className={`col ${Style.table_wrapper}`}>
              <h5 className="pro-ttl h5">{staffState?.gliData?.name}</h5>
              <h6 className="pro-ttl h6 pro-font-sm">
                {staffState?.gliData?.shop}
              </h6>
              {true ? (
                <>
                  <PendingTable />
                </>
              ) : (
                <div className="col">
                  <h5 className="pro-ttl h5">table is empty</h5>
                </div>
              )}
            </div>
          )}
          {staffState?.showAssignedTable && (
            <div className={`col ${Style.table_wrapper}`}>
              <h5 className="pro-ttl h5">{staffState?.gliData?.name}</h5>
              <h6 className="pro-ttl h6 pro-font-sm">
                {staffState?.gliData?.shop}
              </h6>
              {true ? (
                <>
                  <AssignedTable />
                </>
              ) : (
                <div className="col">
                  <h5 className="pro-ttl h5">table is empty</h5>
                </div>
              )}
            </div>
          )}
          {!staffState?.showTable && !staffState?.showAssignedTable && (
            <div className="col">
              {/* <h5 className="pro-ttl h5">Select one</h5> */}
            </div>
          )}
        </div>
        <OffCanvasLayout
          centered={true}
          show={staffState?.showFilter}
          handleClose={handleFilter}
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default StaffWindow;
