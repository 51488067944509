import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Report/reportSlice";
import {
  getBasicData,
  getPostReportData,
  getReportData,
  updateReportData,
} from "../api";

export const useAddReport = ({ refetch, reportState }) => {
  const { reportData, isEdit, showPassword } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();

  const [basicData, setBasicData] = useState();

  const [frontTopImage, setFrontTopImage] = useState("");
  const [frontFootImage, setFrontFootImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [shopLogo, setShopLogo] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [backgroundImage, setBackGroundImage] = useState("");
  const [certificateType, setCertificateType] = useState("");
  const footerTypes = [
    {
      name: "Barcode",
      _id: "1",
    },
    {
      name: "Image",
      _id: "2",
    },
  ];
  //creating state for checking whether it is qc or not
  const [isQc, setIsQc] = useState(false);

  const [initialData, setInitialData] = useState({
    name: "",
    supplier_shop_id: "",
    certificate_type: "",
    item_type_id: "",
    item_fields: [
      {
        field_id: "",
        text: "",
        key_font_case: "",
        value_font_case: "",
        priority: "",
        section: "",
        bold: "",
        unit: "",
        prefix: "",
      },
    ],
    status: "",
    shop_logo: "",
    front_top_image: "",
    front_foot_image: "",
    back_image: "",
    format_type: false,
    bold: false,
    footer_type: "",
    front_image: "",
    background_image: "",
    qr_and_image_format: "",
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddReportModal = false;
      })
    );
  };
  useEffect(() => {
    getBasicData().then((response) => {
      setBasicData(response?.data);
    });
  }, []);

  useEffect(() => {
    if (reportState?.selectedReport && reportState?.isEdit) {
      fillReportData();
    }
    // eslint-disable-next-line
  }, [reportState?.isEdit, reportState?.showAddBranchModal]);

  useEffect(() => {
    if (reportData && isEdit) {
      
      if (reportData.center_stone_fields) {
        setIsQc(true);
      }
      const ImageType = basicData?.data?.qr_and_image_format?.filter(
        (obj) => obj?.id === +reportData?.qr_and_image_format
      )?.[0];
      const status = basicData?.data?.status_list?.filter(
        (value) => value?.id === reportData?.status
      )?.[0];
      setInitialData({
        supplier_shop_id: reportData?.supplier_shop_id,
        name: reportData?.name,
        certificate_type: reportData?.certificate_type,
        item_type_id: reportData?.item_type_id,
        item_fields: reportData?.fields,
        item_fields_CS: reportData?.center_stone_fields,
        item_fields_SS: reportData?.side_stone_fields,
        shop_logo: reportData?.shop_logo,
        front_top_image: reportData?.front_top_image,
        front_foot_image: reportData?.front_foot_image,
        back_image: reportData?.back_image,
        format_type: reportData?.format_type,
        bold: reportData?.bold,
        footer_type: reportData?.footer_type,
        front_image: reportData?.front_image,
        qr_and_image_format: ImageType,
        status: status

      });
    }
    setFrontTopImage(reportData?.front_top_image?.path);
    setFrontFootImage(
      reportData?.footer_type === "2" ? reportData?.front_foot_image?.path : ""
    );
    setBackImage(reportData?.back_image?.path);
    setShopLogo(reportData?.shop_logo?.path);
    setBackGroundImage(reportData?.background_image?.path ?? "");
    setFrontImage(reportData?.front_image?.path ?? "");
  }, [reportData, basicData, isEdit, isQc]);

  const fillReportData = () => {
    getReportData(reportState?.selectedReport).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.reportData = response?.data?.data))
        );
      } else {
        // updateEmployeeFields();
        toast.error("Something went wrong to fetch report data");
      }
    });
  };

  const handleAddField = () => {
    let { item_fields } = formik?.values;
    item_fields = [
      ...item_fields,
      {
        field_id: "",
        text: "",
        key_font_case: "",
        value_font_case: "",
        priority: "",
        section: "",
        bold: "",
        unit: "",
        prefix: "",
      },
    ];
    formik.setFieldValue("item_fields", item_fields);
  };
  const handleAddFieldCenter = () => {
    let { item_fields_CS } = formik?.values;
    item_fields_CS = [
      ...item_fields_CS,
      {
        field_id: "",
        text: "",
        key_font_case: "",
        value_font_case: "",
        priority: "",
        bold: "",
        unit: "",
        prefix: "",
      },
    ];
    formik.setFieldValue("item_fields_CS", item_fields_CS);
  };

  const handleAddFieldSide = () => {
    let { item_fields_SS } = formik?.values;
    item_fields_SS = [
      ...item_fields_SS,
      {
        field_id: "",
        text: "",
        key_font_case: "",
        value_font_case: "",
        priority: "",
        bold: "",
        unit: "",
        prefix: "",
      },
    ];
    formik.setFieldValue("item_fields_SS", item_fields_SS);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: Yup.object({
      supplier_shop_id: Yup.string().when("format_type", {
        is: false,
        then: () => Yup.string().required("Shop Name is required"),
      }),
      certificate_type: Yup.string().required("Choose Certificate Type"),
      item_type_id: Yup.string().required("Choose Item Type"),
      name: Yup.string().required("Enter Name"),
      status: Yup.object().required("Select One"),
      item_fields: Yup.array().of(
        Yup.object().shape({
          field_id: Yup.string().required("Select QC Field Type"),
          text: Yup.string().required("Code Name of QC field"),
          key_font_case: Yup.string().required("Choose Font Case"),
          value_font_case: Yup.string().required("Choose Font Case"),
          priority: Yup.string().required("Choose Priority"),
          section: Yup.string().required("Choose Section"),
          bold: Yup.boolean(),
        })
      ),
      item_fields_CS:
        isQc &&
        Yup.array().of(
          Yup.object().shape({
            field_id: Yup.string().required("Select Center Stone Field Type"),
            text: Yup.string().required("Short Name of Center Stone field"),
            key_font_case: Yup.string().required("Choose Font Case"),
            value_font_case: Yup.string().required("Choose Font Case"),
            priority: Yup.string().required("Choose Priority"),
            bold: Yup.boolean(),
          })
        ),
      item_fields_SS:
        isQc &&
        Yup.array().of(
          Yup.object().shape({
            field_id: Yup.string().required("Select Side Stone Field Type"),
            text: Yup.string().required("Short Name of Side Stone field"),
            key_font_case: Yup.string().required("Choose Font Case"),
            value_font_case: Yup.string().required("Choose Font Case"),
            priority: Yup.string().required("Choose Priority"),
            bold: Yup.boolean(),
          })
        ),
      shop_logo: Yup.mixed(),
      front_top_image: Yup.mixed().required("Select front top image"),
      back_image:
        certificateType !== 4 && Yup.mixed().required("Select back image"),
      footer_type: Yup.string().required("Select footer type"),
      front_foot_image: Yup.mixed().test(
        "conditional-validation",
        "Select front foot image",
        function (value) {
          const footerType = this.resolve(Yup.ref("footer_type"));
          if (footerType === "2") {
            return value !== undefined && value !== null;
          }
          return true;
        }
      ),
    }),
    validate: (values) => {
      let errors = {};
      if (values?.certificate_type === 4) {
        if (!values?.front_image) {
          errors.front_image = "Upload front image";
        }
      }
      if (!values?.qr_and_image_format) {
        errors.qr_and_image_format = "Select Image Type";
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      let checked;
      if (values?.format_type === 1 || values?.format_type === true) {
        checked = true;
      } else {
        checked = false;
      }
      let is_bold;
      if (values?.bold === 1 || values?.bold === true) {
        is_bold = true;
      } else {
        is_bold = false;
      }
      const data = {
        supplier_shop_id: checked === false ? values?.supplier_shop_id : "",
        name: values?.name,
        certificate_type: values?.certificate_type,
        item_type_id: values?.item_type_id,
        format_type: checked ? 1 : 2,
        bold: is_bold ? 1 : 0,
        footer_type: values?.footer_type,
        front_image:
          values?.certificate_type === 4
            ? !values?.front_image?.path
              ? values?.front_image
              : ""
            : "",
        background_image: values?.background_image,
        qr_and_image_format: values?.qr_and_image_format?.id,
        status: values?.status?.id,
      };
      if (values?.shop_logo?.name) {
        data.shop_logo = values?.shop_logo;
      }
      if (values?.front_image?.name) {
        data.front_image = values?.front_image;
      }
      if (values?.background_image?.name) {
        data.background_image = values?.background_image;
      }
      if (values?.front_top_image?.name) {
        data.front_top_image = values?.front_top_image;
      }
      if (values?.footer_type === "2" && values?.front_foot_image?.name) {
        data.front_foot_image = values?.front_foot_image;
      }
      if (values?.back_image?.name) {
        data.back_image = values?.back_image;
      }
      //convert to formData
      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      const formData = new FormData();
      Object.keys(newObj).forEach((key) => {
        formData.append(key, data[key]);
      });
      values?.item_fields?.map((value, index) => {
        return (
          formData.append(`fields[${index}][text]`, value?.text),
          formData.append(`fields[${index}][field_id]`, value?.field_id),
          formData.append(`fields[${index}][priority]`, value?.priority),
          formData.append(`fields[${index}][section]`, value?.section),
          formData.append(
            `fields[${index}][key_font_case]`,
            value?.key_font_case
          ),
          formData.append(
            `fields[${index}][value_font_case]`,
            value?.value_font_case
          ),
          formData.append(`fields[${index}][unit]`, value?.unit ?? ""),
          formData.append(`fields[${index}][prefix]`, value?.prefix ?? ""),
          formData.append(`fields[${index}][bold]`, value?.bold ? 1 : 0)
        );
      });
      values?.item_fields_CS?.forEach((value, index) => {
        if (
          value?.text ||
          value?.field_id ||
          value?.priority ||
          value?.font_case ||
          value?.bold
        ) {
          formData.append(`center_stone_fields[${index}][text]`, value?.text);
          formData.append(
            `center_stone_fields[${index}][field_id]`,
            value?.field_id
          );
          formData.append(
            `center_stone_fields[${index}][priority]`,
            value?.priority
          );
          formData.append(
            `center_stone_fields[${index}][key_font_case]`,
            value?.key_font_case
          );
          formData.append(
            `center_stone_fields[${index}][value_font_case]`,
            value?.value_font_case
          );
          formData.append(
            `center_stone_fields[${index}][bold]`,
            value?.bold ? 1 : 0
          );
          formData.append(
            `center_stone_fields[${index}][unit]`,
            value?.unit ?? ""
          );
          formData.append(
            `center_stone_fields[${index}][prefix]`,
            value?.prefix ?? ""
          );
        }
      });

      values?.item_fields_SS?.forEach((value, index) => {
        if (
          value?.text ||
          value?.field_id ||
          value?.priority ||
          value?.font_case ||
          value?.bold
        ) {
          formData.append(`side_stone_fields[${index}][text]`, value?.text);
          formData.append(
            `side_stone_fields[${index}][field_id]`,
            value?.field_id
          );
          formData.append(
            `side_stone_fields[${index}][priority]`,
            value?.priority
          );
          formData.append(
            `side_stone_fields[${index}][key_font_case]`,
            value?.key_font_case
          );
          formData.append(
            `side_stone_fields[${index}][value_font_case]`,
            value?.value_font_case
          );
          formData.append(
            `side_stone_fields[${index}][bold]`,
            value?.bold ? 1 : 0
          );
          formData.append(
            `side_stone_fields[${index}][unit]`,
            value?.unit ?? ""
          );
          formData.append(
            `side_stone_fields[${index}][prefix]`,
            value?.prefix ?? ""
          );
        }
      });

      if (!isEdit) {
        try {
          const response = await getPostReportData(`/report/create`, formData);
          if (response?.status === 200) {
            toast.success("Report details added");
            handleCloseModal();
            refetch();
            resetForm();
          } else {
            handleBackendError(response);
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        try {
          formData.append(`report_id`, reportData?._id);
          const response = await updateReportData(`report/update`, formData);
          if (response?.status === 200) {
            toast.success("Report details updated");
            resetForm();
            handleCloseModal();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            refetch();
          } else {
            handleBackendError(response);
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    },
  });
  let supplierData = basicData?.data?.shop?.filter(
    (item) => item?._id === formik?.values?.supplier_shop_id
  )[0];
  let certificateData = basicData?.data?.certificate_type?.filter(
    (item) => item?.id === formik?.values?.certificate_type
  )[0];
  let itemTypeData = basicData?.data?.item_type?.filter(
    (item) => item?._id === formik?.values?.item_type_id
  )[0];

  let footerTypeValue = footerTypes?.filter(
    (value) => value?._id === formik?.values?.footer_type
  )[0];

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleBackendError = (response) => {
    if (response?.status_code === 422) {
      let errors = response?.errors;
      let errorField = Object.keys(errors);
      errorField.forEach((field) => {
        formik?.setFieldError(field, errors[field]);
      });
    }
  };

  const handleShopLogoChange = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("shop_logo", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setShopLogo(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "shop_logo",
        "The image must be less than 5MB in size."
      );
    }
  };

  const handleFrontTopImageChange = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("front_top_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setFrontTopImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "front_top_image",
        "The image must be less than 5MB in size."
      );
    }
  };
  const handleFrontFootImageChange = (e) => {
    const files = e.target.files;
    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("front_foot_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setFrontFootImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "front_foot_image",
        "The image must be less than 5MB in size."
      );
    }
  };

  const handleBackImageChange = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("back_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setBackImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "back_image",
        "The image must be less than 5MB in size."
      );
    }
  };

  const tempFilteredData = basicData?.data?.item_type?.filter(
    (item) => item?._id === formik?.values?.item_type_id
  );

  const handleItemTypeChange = (value) => {
    if (value?.cstone_sstone === 1) {
      setIsQc(true);
    } else {
      setIsQc(false);
    }
    // formik?.setFieldValue("item_type_id", value?._id || null);
    formik?.setFieldValue("item_fields", [
      {
        field_id: "",
        text: "",
        key_font_case: "",
        value_font_case: "",
        priority: "",
        section: "",
        unit: "",
        prefix: "",
      },
    ]);

    if (value?.cstone_sstone === 1) {
      formik?.setFieldValue("item_fields_CS", [
        {
          field_id: "",
          text: "",
          key_font_case: "",
          value_font_case: "",
          priority: "",
          unit: "",
          prefix: "",
        },
      ]);

      formik?.setFieldValue("item_fields_SS", [
        {
          field_id: "",
          text: "",
          key_font_case: "",
          value_font_case: "",
          priority: "",
          unit: "",
          prefix: "",
        },
      ]);
    }
  };

  const handleFrontImageChange = (e) => {
    const file = e.target.files[0];

    if (file?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("front_image", file);

      const reader = new FileReader();

      reader.onloadend = () => {
        setFrontImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (file?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "front_image",
        "The image must be less than 5MB in size."
      );
    }
  };

  const handleBackgroundImageChange = (e) => {
    const file = e.target.files[0];
    if (file?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("background_image", file);

      const reader = new FileReader();

      reader.onloadend = () => {
        setBackGroundImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (file?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "background_image",
        "The image must be less than 5MB in size."
      );
    }
  };
  const handleCertificateType = (value) => {
    formik?.setFieldValue("certificate_type", value?.id || null);
    setCertificateType(value?.id);
  };
  return {
    isQc,
    formik,
    isEdit,
    shopLogo,
    backImage,
    basicData,
    reportData,
    frontImage,
    footerTypes,
    showPassword,
    supplierData,
    itemTypeData,
    frontTopImage,
    frontFootImage,
    backgroundImage,
    certificateData,
    footerTypeValue,
    tempFilteredData,
    getFieldError,
    handleAddField,
    handleCloseModal,
    handleAddFieldSide,
    handleAddFieldCenter,
    handleItemTypeChange,
    handleShopLogoChange,
    handleBackImageChange,
    handleFrontImageChange,
    handleFrontTopImageChange,
    handleFrontFootImageChange,
    handleBackgroundImageChange,
    handleCertificateType,
  };
};

export default useAddReport;
