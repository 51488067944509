import { useSelector } from "react-redux";
import {
  useGetMainReportTableDataQuery,
  useUpdateMainReportTableHeadDataMutation,
} from "../../../store/queries/MainReport";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/MainReport/mainReportSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useReports = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const reportState = useSelector((state) => state.mainReport);

  const {
    data: reportList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetMainReportTableDataQuery({
    page_size: reportState?.currentPageSize,
    page: reportState?.currentPage,
    sort_by: reportState?.sortBy,
    sort_order: reportState?.sortOrder,
    start: reportState?.startDate,
    end: reportState?.endDate,
    search: reportState?.search,
  });

  const [updateReportFields] = useUpdateMainReportTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = reportList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [reportList]);

  useEffect(() => {
    if (reportState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [reportState?.clearSelection]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      service: (feild, data) => (
        <ul>
          {data?.[feild]?.map((value, index) => {
            return <li key={index}>{value?.name} </li>;
          })}
        </ul>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewReport = (e) => {
    navigate(`/view-report`);
    localStorage.setItem("selectedReport", e?.[0]);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handlePrintClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.printModal = true;
        state.selectedItem = e?.[0];
      })
    );
  };
  const handleExcelUpload = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showExcelUploadModal = !state.showExcelUploadModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleClosePrintModal = () => {
    dispatch(updateConfig((state) => (state.printModal = false)));
  };
  return {
    reportState,
    showEditModal,
    tableFields,
    reportList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleViewReport,
    handleSearch,
    updateReportFields,
    handlePrintClick,
    handleClearClick,
    handlePageSize,
    handleClosePrintModal,
    handleExcelUpload,
    refetch,
  };
};

export default useReports;
