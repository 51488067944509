import { Input } from "@wac-ui-dashboard/wac_component_library";
import React, { useEffect } from "react";
import Select from "react-select";
import Style from "./Additem.module.scss";

const AddItem = ({
  index,
  isEdit,
  optionsData,
  itmIndex,
  values,
  // isDLoose,
  tempIds,
  formik,
}) => {
  useEffect(() => {
    formik?.setFieldValue("item_quantity", values?.item_quantity);
    formik?.setFieldValue("total_certificate", values?.total_certificate);
    // eslint-disable-next-line
  }, [isEdit]);
  let itemData = optionsData?.items?.filter(
    (item) => !tempIds?.includes(item?._id)
  );
  let itemObj = optionsData?.items?.filter(
    (item) => item?._id === formik?.values?.group[itmIndex].item_id
  );
  const handleRemoveField = (index) => {
    const data = formik?.values?.group?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("group", data);
  };

  return (
    <div className={`${Style.container}`} key={index}>
      {formik?.values?.group?.length !== 1 && (
        <span
          className={`${Style.corner_button} btn-close`}
          onClick={() => handleRemoveField(itmIndex)}
        ></span>
      )}
      <div className={`row gx-3`}>
        <div className="col-md-6">
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Item
            </label>
            <Select
              id={`item${itmIndex}`}
              name={`item${itmIndex}`}
              isClearable={true}
              placeholder={"Select"}
              className={`pro-input lg ${
                formik.touched?.group?.[itmIndex]?.item_id &&
                formik.errors?.group?.[itmIndex]?.item_id &&
                "error"
              }`}
              classNamePrefix="pro-input"
              options={itemData ?? []}
              getOptionLabel={(options) => options?.name}
              getOptionValue={(options) => options?._id}
              onChange={(value) =>
                formik?.setFieldValue(
                  `group.${itmIndex}.item_id`,
                  value?._id || ""
                )
              }
              value={itemObj}
              onBlur={formik?.handleBlur(`group.${itmIndex}.item_id`)}
              menuPlacement="auto"
            />
            {formik.touched?.group?.[itmIndex]?.item_id &&
              formik.errors?.group?.[itmIndex]?.item_id && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.item_id}
                </span>
              )}
          </div>
        </div>
        <div className="col-md-6">
          <Input
            label={"Number Of Items"}
            type="text"
            id="item_quantity"
            name="item_quantity"
            className={`pro-input lg ${
              formik.touched?.group?.[itmIndex]?.item_quantity &&
              formik.errors?.group?.[itmIndex]?.item_quantity &&
              " error"
            }`}
            error={
              formik.touched?.group?.[itmIndex]?.item_quantity &&
              formik.errors?.group?.[itmIndex]?.item_quantity && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.item_quantity}
                </span>
              )
            }
            errorMessage={
              formik.touched?.group?.[itmIndex]?.item_quantity &&
              formik.errors?.group?.[itmIndex]?.item_quantity && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.item_quantity}
                </span>
              )
            }
            onChange={(e) =>
              formik?.setFieldValue(
                `group.${itmIndex}.item_quantity`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(`group.${itmIndex}.item_quantity`)}
            value={values?.item_quantity}
          />
        </div>
      </div>

      <div className={`row gx-3`}>
        <div className="col-md-6">
          <Input
            label={"Number of Certificate"}
            type="text"
            id="total_certificate"
            name="total_certificate"
            className={`pro-input lg ${
              formik.touched?.group?.[itmIndex]?.total_certificate &&
              formik.errors?.group?.[itmIndex]?.total_certificate &&
              " error"
            }`}
            error={
              formik.touched?.group?.[itmIndex]?.total_certificate &&
              formik.errors?.group?.[itmIndex]?.total_certificate && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.total_certificate}
                </span>
              )
            }
            errorMessage={
              formik.touched?.group?.[itmIndex]?.total_certificate &&
              formik.errors?.group?.[itmIndex]?.total_certificate && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.total_certificate}
                </span>
              )
            }
            onChange={(e) =>
              formik?.setFieldValue(
                `group.${itmIndex}.total_certificate`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(`group.${itmIndex}.total_certificate`)}
            value={values?.total_certificate}
          />
        </div>

        <div className="col-md-6">
          <Input
            label={"Total Weight"}
            type="text"
            id="total_weight"
            name="total_weight"
            className={`pro-input lg ${
              formik.touched?.group?.[itmIndex]?.total_weight &&
              formik.errors?.group?.[itmIndex]?.total_weight &&
              " error"
            }`}
            error={
              formik.touched?.group?.[itmIndex]?.total_weight &&
              formik.errors?.group?.[itmIndex]?.total_weight && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.total_weight}
                </span>
              )
            }
            errorMessage={
              formik.touched?.group?.[itmIndex]?.total_weight &&
              formik.errors?.group?.[itmIndex]?.total_weight && (
                <span className="error-text">
                  {formik.errors?.group?.[itmIndex]?.total_weight}
                </span>
              )
            }
            onChange={(e) =>
              formik?.setFieldValue(
                `group.${itmIndex}.total_weight`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(`group.${itmIndex}.total_weight`)}
            value={values?.total_weight}
            // disabled={!isDLoose}
          />
        </div>
      </div>
    </div>
  );
};

export default AddItem;
