import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBranchList, updateConfig } from "../../../../store/slices/Global";
import { changeDepartment, departmentsList, logout } from "./api";
import { toast } from "react-toastify";

const useHeaderActions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);
  const userDetails = JSON?.parse(localStorage?.getItem("user_details"));

  const localItem = localStorage
    ?.getItem("slug")
    ?.toUpperCase()
    ?.replace(/[^a-zA-Z]/g, " ", /\s/g, " ", /[0-9]/g, " ");

  const userType = localStorage?.getItem("user_type");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen(true);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleLogout = () => {
    handleLogoutClick();
    logout().then((response) => {
      localStorage.clear("USER_ACCESS_TOKEN");
      localStorage.clear("dashboard_permissions");
      localStorage.clear("user_type");
      localStorage.clear("slug");
      localStorage.clear("invoice_id");
      localStorage.clear("invoice");
      localStorage.clear("billing");
      if (response?.data?.status_code === 200) {
        toast.success("Logout Success");
        navigate("/");
      } else {
        navigate("/");
      }
    });
  };

  const handleLogoutClick = () => {
    dispatch(updateConfig((state) => (state.logoutModal = !state.logoutModal)));
  };

  const handleSettings = () => {
    departmentsList().then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.formData = response?.data?.data))
        );
      }
    });
    dispatch(
      updateConfig((state) => {
        state.showSettingsModal = !state.showSettingsModal;
      })
    );
  };

  const handleBranchChange = (value) => {
    let data = {
      branch_id: value?._id,
    };
    changeDepartment(data).then((response) => {
      if (response?.data?.status_code === 200) {
        localStorage.setItem("USER_ACCESS_TOKEN", response?.data?.data?.token);
        localStorage.setItem(
          "user_type",
          response?.data?.data?.user?.user_type
        );
        const refetch = setTimeout(() => {
          navigate(0);
          clearTimeout(refetch);
        }, 500);
      }
      dispatch(
        getBranchList({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    });
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    localItem,
    userType,
    userDetails,
    currentUrl: location?.pathname,
    handleBranchChange,
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleSettings,
    handleLogoutClick,
  };
};

export default useHeaderActions;
