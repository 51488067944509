import { useSelector } from "react-redux";

const useCardDetails = () => {
  const { empDetails } = useSelector((state) => state.overview);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return { empDetails, getRow };
};

export default useCardDetails;
