import React from "react";
import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import useAddBranch from "./useAddBranch";
import Style from "./addBranch.module.scss";

const AddBranch = (refetch) => {
  const {
    tabs,
    activeTab,
    formik,
    renderTabContent,
    handleTabClick,
    handleMoveToErrorTab,
  } = useAddBranch(refetch);
  return (
    <div>
      <FormTabs
        propStyle={{ root: Style.formTab_root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      <form
        onSubmit={(e) => {
          formik.handleSubmit(e);
          handleMoveToErrorTab();
        }}
      >
        {renderTabContent()}
      </form>
    </div>
  );
};

export default AddBranch;
