import React, { useRef } from "react";
import { PaperStaticA } from "../../Employees/Printing/CertificateTypes/PapperCard/PaperStaticA";
import { PaperStaticC } from "../../Employees/Printing/CertificateTypes/PapperCard/PaperStaticC";
import { PaperStaticD } from "../../Employees/Printing/CertificateTypes/PapperCard/PaperStaticD";
import { PaperStaticE } from "../../Employees/Printing/CertificateTypes/PapperCard/PaperStaticE";
import html2canvas from "html2canvas";
import Assets from "../../../assets/Assets";
import Style from "../VerifyCertificate.module.scss"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Printing/printingSlice";
import { toast } from "react-toastify";

const PapperCard = ({ data: printData }) => {

  const { verifyCertificateBtn } = useSelector(
    (state) => state.printing
  );

  const { data } = printData; // Extract the "data" object from printData
  const cardRef = useRef(null);
  const dispatch = useDispatch();
  
  const handleDownload = () => {
    dispatch(updateConfig((state) => (state.verifyCertificateBtn = true)));
    html2canvas(cardRef.current).then(function (canvas) {
      const link = document.createElement("a");
      link.download = `${data?.certificate_number}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
      dispatch(updateConfig((state) => (state.verifyCertificateBtn = false)))
    }).catch(() => {
      dispatch(updateConfig((state) => (state.verifyCertificateBtn = false)));
      toast.error("Something went wrong")
    });
  };
  return (
    <div className="pro-d-flex pro-flex-column pro-items-center pro-py-3">
        <div ref={cardRef} className={Style.overflow_wrap}>
            { !data?.shop_logo && data?.center_stone_side_stone ? (
                <PaperStaticC value={data}/>
            ) : (!!data?.shop_logo && data?.center_stone_side_stone) ? (
                <PaperStaticE value={data}/>
            ) 
            : (!!data?.shop_logo && !data?.center_stone_side_stone) ? (
                <PaperStaticD value={data}/>
            ) : (
                <PaperStaticA value={data}/>
            )}
        </div>
        <div className="pro-d-flex pro-justify-center pro-pt-3 pro-gap-3">
        <button
          className="pro-btn pro-btn-primary pro-px-5"
          onClick={() => handleDownload()}
          disabled={verifyCertificateBtn}
        >
          { verifyCertificateBtn ? "Loading..." : "Download"}
        </button>
      </div>
      <p className="pro-pt-3">For more details, <a href="https://dhcinternational.com">click here</a></p>
      <a href="https://dhcinternational.com" className={Style.logo_link}>
        <figure>
          <img src={Assets.LOGONEW} alt="DHC" />
        </figure>
      </a>

    </div>
  );
};

export default PapperCard;
