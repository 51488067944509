import { useSelector } from "react-redux";
import {
  useGetProcessListDataQuery,
  useUpdateProcessTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Process/processSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getProcessData } from "./api";

const useProcess = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const processState = useSelector((state) => state.process);
  const {
    data: processList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetProcessListDataQuery({
    page_size: processState.currentPageSize,
    page: processState.currentPage,
    sort_by: processState.sortBy,
    sort_order: processState.sortOrder,
    start: processState.startDate,
    end: processState.endDate,
    search: processState.search,
  });

  const [updateProcessFields] = useUpdateProcessTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = processList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [processList]);

  useEffect(() => {
    if (processState?.showAddProcessModal) {
      fillProcessData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processState?.showAddProcessModal]);
  useEffect(() => {
    if (processState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [processState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddProcessModal = !state.showAddProcessModal;
      })
    );
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (field, data) =>
        data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const fillProcessData = () => {
    getProcessData(processState?.selectedItem).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch data");
      }
    });
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddProcessModal = true;
        state.selectedItem = e?.[0];
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (processState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = processState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    processState,
    showEditModal,
    tableFields,
    processList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleEditAction,
    handleSearch,
    updateProcessFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useProcess;
