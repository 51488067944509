import { useSelector } from "react-redux";

const useView = () => {
  const { viewData } = useSelector((state) => state.packings);
  return {
    state: viewData,
  };
};

export default useView;
