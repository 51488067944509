import { useFormik } from "formik";
import { useGetFilterListDataQuery } from "../../../../store/queries/Verification";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Orders/orderSlice";

const useFilter = () => {
  const orderState = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const { data: FilterData = {} } = useGetFilterListDataQuery({});
  const formik = useFormik({
    initialValues: {
      item_type: orderState?.filter?.item_type,
      shop: orderState?.filter?.shop,
      status: orderState?.filter?.status,
    },
    onSubmit: (values) => {
      const { item_type, shop, status } = values;
      if (item_type === "" && shop === "" && status === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        dispatch(
          updateConfig((state) => {
            state.showFilter = false;
            state.filter.item_type = values?.item_type;
            state.filter.shop = values?.shop;
            state.filter.status = values?.status;
          })
        );
      }
    },
  });

  const handleReset = () => {
    formik?.handleReset();
    dispatch(
      updateConfig((state) => {
        state.showFilter = false;
        state.filter.item_type = "";
        state.filter.shop = "";
        state.filter.status = "";
      })
    );
  };
  return {
    formik,
    FilterData,
    handleReset,
  };
};

export default useFilter;
