import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEngraving from "./useEngraving";
import Select from "react-select";

const Engraving = ({
  formik,
  getFieldError,
  optionsData,
  handleMoveToErrorTab,
  isChecked,
  orderData,
  handleChecked,
}) => {
  const {
    isEdit,
    engravingImage,
    orderStatus,
    buttonLoader,
    handleCloseModal,
    handleImageChange,
    handleSubmit,
  } = useEngraving(formik, orderData, handleMoveToErrorTab);

  let engravingObj = optionsData?.engraving_type?.filter(
    // eslint-disable-next-line eqeqeq
    (item) => item?._id == formik?.values?.engraving_format
  );
  let fontObj = optionsData?.engraving_fonts?.filter(
    // eslint-disable-next-line eqeqeq
    (item) => item?.id == formik?.values?.engraving_font
  );

  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <div className="pro-check-box">
          <input
            type="checkbox"
            className="pro-check"
            id="engraving"
            name="pro-checkbox"
            onChange={handleChecked}
            // eslint-disable-next-line eqeqeq
            checked={isChecked}
          />
          <label htmlFor="engraving" className="pro-check-label">
            {" Has Engraving "}
          </label>
        </div>
      </div>
      {isChecked ? (
        <div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Engraving Format
            </label>
            <Select
              id="engraving_format"
              isClearable={true}
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("engraving_format") && " error"
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.engraving_type ?? []}
              getOptionLabel={(options) => options?.engraving_type_name}
              getOptionValue={(option) => option?._id}
              value={engravingObj}
              onChange={(value) =>
                formik?.setFieldValue("engraving_format", value?._id || "")
              }
              onBlur={formik?.handleBlur("engraving_format")}
              menuPlacement="auto"
            ></Select>
            {getFieldError("engraving_format") && (
              <span className="error-text">
                {getFieldError("engraving_format")}
              </span>
            )}
            <small>{formik?.values?.engraving_format?.format ?? ""}</small>
          </div>
          <Input
            label={"Engraving Text"}
            type="text"
            id="engraving_text"
            name="engraving_text"
            className={`pro-input lg ${
              getFieldError("engraving_text") && " error"
            }`}
            {...formik.getFieldProps("engraving_text")}
            error={getFieldError("engraving_text")}
            errorMessage={getFieldError("engraving_text")}
          />
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Engraving Font
            </label>
            <Select
              id="engraving_font"
              isClearable={true}
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("engraving_font") && " error"
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.engraving_fonts ?? []}
              getOptionLabel={(options) => options?.name}
              getOptionValue={(option) => option?.id}
              value={fontObj}
              onChange={(value) =>
                formik?.setFieldValue("engraving_font", value?.id || "")
              }
              onBlur={formik?.handleBlur("engraving_font")}
              menuPlacement="auto"
            ></Select>
            {getFieldError("engraving_font") && (
              <span className="error-text">
                {getFieldError("engraving_font")}
              </span>
            )}
            <small>{formik?.values?.engraving_font?.format ?? ""}</small>
          </div>
          <Input
            label={"Engraving Remarks"}
            type="text"
            id="engraving_remarks"
            name="engraving_remarks"
            className={`pro-input lg ${
              getFieldError("engraving_remarks") && " error"
            }`}
            {...formik.getFieldProps("engraving_remarks")}
            error={getFieldError("engraving_remarks")}
            errorMessage={getFieldError("engraving_remarks")}
          />

          {!false ? (
            <>
              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                >
                  Image
                </label>
                <div className="input-drag">
                  <input
                    type="file"
                    name="engraving_image"
                    accept=".jpeg, .jpg"
                    placeholder="placeholder"
                    // ref={profilefileInputRef}
                    className={`pro-input lg ${
                      getFieldError("engraving_image") && " error"
                    }`}
                    id="engraving_image"
                    onBlur={formik.handleBlur("engraving_image")}
                    onChange={(e) => handleImageChange(e)}
                  />

                  {isEdit ? (
                    <span
                      className="input-drag-box"
                      style={{ padding: "50px" }}
                    >
                      <IconText
                        title={`${
                          orderData?.engraving?.engraving_logo_name ??
                          "Drop files to attach or browse"
                        }`}
                      />
                    </span>
                  ) : (
                    <span
                      className="input-drag-box"
                      style={{ padding: "50px" }}
                    >
                      {formik?.values?.engraving_image === "" ? (
                        <IconText title={`Drop files to attach or browse`} />
                      ) : (
                        <IconText
                          title={`${formik?.values?.engraving_image?.name} `}
                        />
                      )}
                    </span>
                  )}
                  {getFieldError("engraving_image") && (
                    <span className="error-text">
                      {getFieldError("engraving_image")}
                    </span>
                  )}
                  <div className={`col-2 pro-my-2`}>
                    <div className={`${""}`}>
                      <Image
                        width={100}
                        height={100}
                        src={engravingImage}
                        alt={`image - 01`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        ""
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${buttonLoader ? "loading" : ""}`}
          type="submit"
          onClick={handleSubmit}
          disabled={buttonLoader}
        >
          {orderStatus === "pending" ? (
            "..."
          ) : (
            <>{isEdit ? "Update" : "Complete"}</>
          )}
        </Button>
      </div>
    </div>
  );
};

export default Engraving;
