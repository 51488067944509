import { FormTabs } from "../../../Global/FormTabs";
import React from "react";
import Style from "./addOrder.module.scss";
import useAddOrder from "./useAddOrder";

const AddOrder = ({ refetch }) => {
  const { tabs, activeTab, formik, renderTabContent, handleTabClick } =
    useAddOrder({ refetch });
  return (
    <div>
      <FormTabs
        propStyle={{ root: Style.root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      <form onSubmit={formik.handleSubmit}>{renderTabContent()}</form>
    </div>
  );
};

export default AddOrder;
