import { getAxiosInstance } from "../../../api";

export const getProcessData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/process/edit?process_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateProcessData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/process/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
