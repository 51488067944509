import { useEffect, useState } from "react";
import { getCertificateDetails } from "./api";

const useVerifiCertificate = () => {
  const [certificateData, setCertificateData] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const queryString = window?.location?.search;
    const certificateId = queryString?.slice(1);
    getCertificateDetails(certificateId).then((response) => {
      if (response?.data?.status_code === 200) {
        setCertificateData(response?.data?.data);
        setError(null);
      } else if (response?.status_code === 422) {
        setError("Invalid Certificate");
      } else {
        setError("Something went wrong");
      }
    });
  }, []);
  return {
    error,
    certificateData,
  };
};

export default useVerifiCertificate;
