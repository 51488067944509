import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Master/Branches/branchesSlice";
import { useSelector } from "react-redux";
import countryData from "../../../../../utils/components/countryCode";

const useContact = () => {
  const dispatch = useDispatch();
  const { showPassword, isEdit } = useSelector((state) => state.branches);
  const handleShowPassword = (e) => {
    e.preventDefault();
    dispatch(
      updateConfig((state) => {
        state.showPassword = !state.showPassword;
      })
    );
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = false;
      })
    );
  };

  return {
    isEdit,
    countryCodeOptions: countryData,
    showPassword,
    handleShowPassword,
    handleCloseModal,
  };
};

export default useContact;
