import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import VerificationFilterForm from "./VerificationFilterForm";
import { useGetFilterListDataQuery } from "../../../../store/queries/Verification";
import {
  updateConfig,
  resetConfig,
} from "../../../../store/slices/Staff/recheckStaffSlice";

const useFilterForm = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.recheckStaff);
  const { data: FilterData = {} } = useGetFilterListDataQuery({});

  const validationSchema = Yup.object({
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
    item_type: Yup.array().nullable(),
    shop: Yup.array().nullable(),
    status: Yup.array().nullable(),
  });

  // Set the initial values based on whether edit is true or false
  const initialValues = {
    from_date: state.from_date,
    to_date: state.to_date,
    item_type: state.item_type,
    shop: state.shop,
    status: state.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { from_date, to_date, item_type, shop, status } = values;
      if (
        from_date === "" &&
        to_date === "" &&
        item_type === "" &&
        shop === "" &&
        status === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        let date = [];
        if (values?.from_date && values?.to_date) {
          date.push(...[values?.from_date, values?.to_date]);
        }
        if (values) {
          dispatch(
            updateConfig((state) => {
              state.date = date;
              state.item_type = values?.item_type;
              state.shop = values?.shop;
              state.status = values?.status;
              state.from_date = values?.from_date;
              state.to_date = values?.to_date;
            })
          );
          dispatch(updateConfig((state) => (state.showFilter = false)));
        }
      }
    },
  });

  const renderTabContent = () => {
    return (
      <VerificationFilterForm
        FilterData={FilterData}
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetConfig());
    dispatch(updateConfig((state) => (state.showFilter = false)));
  };

  return {
    renderTabContent,
  };
};

export default useFilterForm;
