import useFilter from "./useFilter";

function Filter({ setShowform }) {
  const { renderTabContent } = useFilter(setShowform);

  return (
    <div>
      {/* eslint-disable-next-line no-script-url */}
      <form action="javascript:void(0)">{renderTabContent()}</form>
    </div>
  );
}

export default Filter;
