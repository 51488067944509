import React from "react";
import StaffWindow from "../../Global/StaffWindow";

const Staff = () => {
  return (
    <>
      <StaffWindow />
    </>
  );
};

export default Staff;
