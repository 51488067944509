import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddShopModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedShop: "",
  isEdit: false,
  shopData: [],
  logoPreview: "",
  count: "",
  activeTab: "Basic",
  completedTabs: {
    Basic: false,
    Billing: false,
    Contact: false,
  },
  CompletedPercentage: {
    Basic: 0,
    Billing: 0,
    Contact: 0,
  },
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = shopSlice.actions;

export default shopSlice.reducer;
