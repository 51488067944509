import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../api";
import { useFormik } from "formik";
import useValidations from "../../../utils/hooks/useValidations";
import { toast } from "react-toastify";

const useForgotPassword = (emailRef) => {
  const navigate = useNavigate();
  const { validateEmail } = useValidations();

  const [emailStatus, setEmailStatus] = useState("idle");
  const [emailInfo, setEmailInfo] = useState({});

  useEffect(() => {
    if (emailStatus === "success") {
      toast.success("OTP send successfully")
      navigate("/login/reset-password");
    } else if (emailStatus === "failed") {
      let errorFields = Object.keys(emailInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, emailInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [emailStatus]);

  const handleLoginClick = () => {
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email is Required";
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => {
      setEmailStatus("pending");
      forgotPassword(values).then((response) => {
        if (response?.status === 200) {
          setEmailStatus("success");
          emailRef.current = values.email;
        } else if (response.status_code === 422) {
          setEmailStatus("failed");
          setEmailInfo({ email: response?.message });
        } else {
          setEmailStatus("failed");
          setEmailInfo({ email: response?.message });
        }
      });
    },
  });
  return {
    formik,
    emailStatus,
    handleLoginClick,
  };
};

export default useForgotPassword;
