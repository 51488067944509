import { getAxiosInstance } from "../../../api";

export const getDeliveryCertificate = async (id, slug) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `delivery/list?receipt_id=${id}&process_slug=${slug}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const approveCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`delivery/submit`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrintSlip = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/delivery/slip?stock_receipt_id=${id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrintChellan = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/delivery/create-challan?stock_receipt_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const passAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/order/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePrintData = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/delivery/update`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
