import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetRecheckInvoiceHistoryListDataQuery,
  useUpdateRecheckInvoiceHistoryTableHeadDataMutation,
} from "../../../../store/queries/Billing";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { useEffect, useRef } from "react";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { paymentModes } from "../api";
import { getInvoice, getReceipt } from "../../Invoice/api";
import moment from "moment";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
const useRecheckInvoices = () => {
  const invoiceRef2 = useRef(null);
  const receiptRef2 = useRef(null);
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const queryState = useSelector((state) => state.staffBilling);

  const {
    data: queryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRecheckInvoiceHistoryListDataQuery({
    page_size: queryState.currentPageSize,
    page: queryState.currentPage,
    sort_by: queryState.sortBy,
    sort_order: queryState.sortOrder,
    start: queryState.startDate,
    end: queryState.endDate,
    search: queryState.search,
  });

  const [updateRecheckInvoiceHistoryFields] =
    useUpdateRecheckInvoiceHistoryTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = queryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [queryList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = (label) => {
    if (queryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = queryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditAction = (e) => {
    if (!queryState?.paymentData) {
      paymentModes().then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => (state.paymentData = response?.data?.data))
          );
        }
      });
    }
    dispatch(
      updateConfig((state) => {
        state.invoiceModal = !state.invoiceModal;
        state.slectedInvoice = e?.[0];
      })
    );
  };

  const handleInvoiceClick = (invoiceId) => {
    getInvoice(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.invoicePrintData = response?.data?.data)
          )
        );
        setTimeout(() => {
          const element = invoiceRef2?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Invoice" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  const handleSlipClick = (invoiceId) => {
    getReceipt(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.receiptPrintData = response?.data?.data)
          )
        );
        setTimeout(() => {
          const element = receiptRef2?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Receipt" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  return {
    queryList,
    paginationOptions,
    isLoading,
    isFetching,
    tableFields,
    queryState,
    showEditModal,
    invoiceRef2,
    receiptRef2,
    handleEditColumnsClick,
    handlePageSize,
    handlePagination,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handleEditAction,
    handleInvoiceClick,
    handleSlipClick,
    updateRecheckInvoiceHistoryFields,
  };
};

export default useRecheckInvoices;
