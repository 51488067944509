import React from "react";
import Select from "react-select";
import useAddShop from "./useAddShop";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const AddShop = () => {
  const { formik, basicData, countryCodeOptions, getFieldError, handleClose } =
    useAddShop();

  return (
    <div>
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Prefix"}
        type="text"
        id="prefix"
        name="prefix"
        className={`pro-input lg ${getFieldError("prefix") && " error"}`}
        {...formik.getFieldProps("prefix")}
        error={getFieldError("prefix")}
        errorMessage={getFieldError("prefix")}
      />
      <Input
        label={"Billing Address Line 1"}
        type="text"
        id="billing_address_1"
        name="billing_address_1"
        className={`pro-input lg ${
          getFieldError("billing_address_1") && " error"
        }`}
        {...formik.getFieldProps("billing_address_1")}
        error={getFieldError("billing_address_1")}
        errorMessage={getFieldError("billing_address_1")}
      />
      <Input
        label={"Billing Address Line 2"}
        type="text"
        id="billing_address_2"
        name="billing_address_2"
        className={`pro-input lg ${
          getFieldError("billing_address_2") && " error"
        }`}
        {...formik.getFieldProps("billing_address_2")}
        error={getFieldError("billing_address_2")}
        errorMessage={getFieldError("billing_address_2")}
      />
      <Input
        label={"Postal Code"}
        type="text"
        id="billing_address_postal_code"
        name="billing_address_postal_code"
        className={`pro-input lg ${
          getFieldError("billing_address_postal_code") && " error"
        }`}
        {...formik.getFieldProps("billing_address_postal_code")}
        error={getFieldError("billing_address_postal_code")}
        errorMessage={getFieldError("billing_address_postal_code")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country
        </label>
        <Select
          id="billing_country"
          name="billing_country"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("billing_country") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.country_list ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.billing_country}
          onBlur={formik.handleBlur("billing_country")}
          onChange={(value) =>
            formik?.setFieldValue("billing_country", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("billing_country") && (
          <span className="error-text">{getFieldError("billing_country")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          State
        </label>
        <Select
          id="billing_state"
          name="billing_state"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("billing_state") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.state_list?.filter(
            (item) => item?.country_id === formik?.values?.billing_country?.id
          )}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.billing_state}
          onBlur={formik.handleBlur("billing_state")}
          onChange={(value) =>
            formik?.setFieldValue("billing_state", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("billing_state") && (
          <span className="error-text">{getFieldError("billing_state")}</span>
        )}
      </div>
      <Input
        label={"City"}
        type="text"
        id="billing_city"
        name="billing_city"
        className={`pro-input lg ${getFieldError("billing_city") && " error"}`}
        {...formik.getFieldProps("billing_city")}
        error={getFieldError("billing_city")}
        errorMessage={getFieldError("billing_city")}
      />
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
                menuPlacement="auto"
              ></Select>
            </div>
            <input
              type="text"
              id="contact_number"
              name="contact_number"
              className={`pro-input lg ${
                formik.errors.contact_number &&
                formik.touched.contact_number &&
                " error"
              }`}
              {...formik.getFieldProps("contact_number")}
            />
          </div>
          {formik.errors.contact_number && formik.touched.contact_number && (
            <>
              <span className="error-text">{formik.errors.contact_number}</span>
            </>
          )}
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Add As
        </label>
        <Select
          id="add_as"
          name="add_as"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("add_as") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.add_as ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.add_as}
          onBlur={formik.handleBlur("add_as")}
          onChange={(value) => formik?.setFieldValue("add_as", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("add_as") && (
          <span className="error-text">{getFieldError("add_as")}</span>
        )}
      </div>
      <Input
        label={"LOT Number"}
        type="text"
        id="serial_number"
        name="serial_number"
        className={`pro-input lg ${getFieldError("serial_number") && " error"}`}
        {...formik.getFieldProps("serial_number")}
        error={getFieldError("serial_number")}
        errorMessage={getFieldError("serial_number")}
      />
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddShop;
