import React from "react";
import useFontCases from "../../../utils/hooks/useFontCases";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";

const FoldableCard = ({ data: printData }) => {
  const { getFontCase } = useFontCases();

  const { data } = printData; // Extract the "data" object from printData

  // Extract graded_values, center_stone, and side_stone from the "data" object
  const gradedValues = data?.graded_values;
  const centerStone = data?.center_stone;
  const sideStone = data?.side_stone;

  // Combine all values into a single array
  const fontCaseAllValues = [gradedValues, centerStone, sideStone]
    .filter(Boolean)
    .flat();

  const fontCases = fontCaseAllValues?.map((item) => item?.font_case);
  const fontCase = getFontCase(parseInt(fontCases));
  const renderText = (value) => {
    switch (fontCase) {
      case "Uppercase":
        return <span style={{ textTransform: "uppercase" }}>{value}</span>;
      case "Lowercase":
        return <span style={{ textTransform: "lowercase" }}>{value}</span>;
      case "Title_Case":
        return <span style={{ textTransform: "capitalize" }}>{value}</span>;
      case "Sentence_Case":
        const sentanceWords = value?.toLowerCase().split(" ");
        const sentenceCaseWords = sentanceWords.map(
          (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
        );
        return sentenceCaseWords.join(" ");
      case "Camel_Case":
        const camelWords = value?.toLowerCase().split(" ");
        const camelCaseWords = camelWords.map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        );
        return camelCaseWords.join("");
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <div>
      <div>
        {/* <div>
          <table
            border={0}
            cellSpacing={0}
            role="presentation"
            cellPadding={0}
            style={{
              backgroundColor: "#ffffff",
              borderCollapse: "collapse",
              maxWidth: 321.26,
              minWidth: 321.26,
              width: 321.26,
              maxHeight: 453.54,
              minHeight: 453.54,
              height: 453.54,
              margin: "0 56px 0 auto",
              border: "1px solid #dfe3eb",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: 321.26, height: 453.54 }}>
                  {data?.front_image && (
                    <img
                      src={data?.front_image ?? ""}
                      width={321.26}
                      height={453.54}
                      alt="fold_card"
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{ background: "black", margin:"5px"  }}
        ></div> */}
        <div style={{ margin: 10 }}>
          <table
            border={0}
            cellSpacing={0}
            role="presentation"
            cellPadding={0}
            style={{
              backgroundColor: "#ffffff",
              borderCollapse: "collapse",
              maxWidth: 642.52,
              minWidth: 642.52,
              width: 642.52,
              maxHeight: 453.54,
              minHeight: 453.54,
              height: 453.54,
              margin: "0 auto",
              border: "1px solid #dfe3eb",
              fontFamily: "Inter",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: 642.52,
                    height: 78,
                    padding: 0,
                    verticalAlign: "baseline",
                  }}
                >
                  <img
                    src={data?.front_top_image}
                    alt="fold_card"
                    width={643}
                    height={78}
                    style={{ width: 642.52, height: 78 }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <table
                    border={0}
                    cellSpacing={0}
                    role="presentation"
                    cellPadding={0}
                    style={{
                      verticalAlign: "baseline",
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: 321.26,
                            paddingLeft: 20,
                            paddingRight: 20,
                            verticalAlign: "baseline",
                            textAlign: "center",
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 85,
                                    paddingRight: 85,
                                    background: "#E1E1E1",
                                    whiteSpace: "nowrap",
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  GRADING REPORT
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 44.5,
                                    paddingRight: 44.5,
                                    paddingTop: 3,
                                  }}
                                >
                                  <table
                                    border={0}
                                    cellSpacing={0}
                                    role="presentation"
                                    cellPadding={0}
                                    style={{
                                      textAlign: "center",
                                      width: 192,
                                    }}
                                  >
                                    <tbody>
                                      {data?.data?.graded_values?.map(
                                        (val, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <tr>
                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: "regular",
                                                    verticalAlign: "top",
                                                    textAlign: "left",
                                                    width: 80,
                                                    paddingTop: 12,
                                                  }}
                                                >
                                                  {val?.bold === 1 ? (
                                                    <b>
                                                      {renderText(val?.text)}
                                                    </b>
                                                  ) : (
                                                    renderText(val?.text)
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: "regular",
                                                    verticalAlign: "top",
                                                    textAlign: "center",
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    paddingTop: 12,
                                                  }}
                                                >
                                                  :
                                                </td>
                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: "regular",
                                                    verticalAlign: "top",
                                                    textAlign: "left",
                                                    paddingTop: 12,
                                                  }}
                                                >
                                                  {val?.bold === 1 ? (
                                                    <b>
                                                      {renderText(val?.value)}
                                                    </b>
                                                  ) : (
                                                    renderText(val?.value)
                                                  )}
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td
                          style={{
                            width: 321.26,
                            borderLeft: "1px solid #DADADA",
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "right",
                                    paddingRight: 47.11,
                                    paddingTop: 57.41,
                                  }}
                                >
                                  {data?.image && (
                                    <img
                                      src={data?.image ?? ""}
                                      width={226.77}
                                      height={151.18}
                                      alt=""
                                    />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingRight: 47.52,
                                    paddingTop: 53.41,
                                    textAlign: "right",
                                  }}
                                >
                                  {data?.qr && (
                                    <QRCode
                                      value={data?.qr ?? ""}
                                      width={60}
                                      height={60}
                                      style={{
                                        width: 60,
                                        height: 60,
                                      }}
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td style={{ verticalAlign: "bottom" }}>
                  {parseInt(data?.footer_type) !== 2 ? (
                    <>
                      {data?.certificate_number && (
                        <div
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ffffff",
                            lineHeight: 0,
                          }}
                        >
                          <Barcode
                            value={data?.certificate_number}
                            height={16}
                            width={0.5}
                            displayValue={false}
                            margin={0}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        data?.footer_type !== 2 && data?.front_foot_image
                          ? data?.front_foot_image
                          : ""
                      }
                      alt="footer"
                      width={642.52}
                      height={56.81}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div
          style={{ background: "black" }}
          className="html2pdf__page-break"
        ></div> */}
      </div>
    </div>
  );
};

export default FoldableCard;
