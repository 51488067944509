import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBulkPass from "./useBulkPass";

const BulkPass = () => {
  const { formik, getFieldError, handleCloseModal } = useBulkPass();

  return (
    <div className="col pro-p-4">
      <Input
        label={"Remark"}
        type="text"
        id="remark"
        name="remark"
        className={`pro-input lg ${getFieldError("remark") && " error"}`}
        {...formik.getFieldProps("remark")}
        error={getFieldError("remark")}
        errorMessage={getFieldError("remark")}
      />
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default BulkPass;
