import {
  HeadingGroup,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Link } from "react-router-dom";
import useOtherReport from "./useOtherReport";
import BranchReport from "./BranchReport";
import EmployeeReport from "./EmployeeReport";
import DepartmentReport from "./DepartmentReport";
import ShopReport from "./ShopReport";
import Style from "../report.module.scss";

const OtherReport = () => {
  const { tabNavs, activeTab, basicData } = useOtherReport();

  return (
    <div>
      <HeadingGroup title={"Other Reports"} extraClassName={`pro-mb-1`} />
      <div className={`${Style.main_container}`}>
            <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      {activeTab === "Branch" && <BranchReport basicData={basicData} />}
      {activeTab === "Shop/Supplier" && <ShopReport basicData={basicData} />}
      {activeTab === "Employee" && <EmployeeReport basicData={basicData} />}
      {activeTab === "Department" && <DepartmentReport basicData={basicData} />}
    </div>
  );
};

export default OtherReport;
