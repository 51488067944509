import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showDeleteModal: false,
  showAddModal: false,
  clearSelection: false,
  selectedItem: "",
  isEdit: false,
  sourceData: "",
  itemData: [],
};

const engravingSlice = createSlice({
  name: "engraving",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = engravingSlice.actions;

export default engravingSlice.reducer;
