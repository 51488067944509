import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || 20}&${getParams(
            "search"
          )}&${getParams("type")}&${getParams("from_date")}
          &${getParams("to_date")}
          &${getParams("shop")}
          &${getParams("status")}
          page=${params?.page || 1}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const orders = createApi({
  reducerPath: "ordersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Orders", "Recheck", "Basic Data", "Reassign"],
  endpoints: (builder) => ({
    //orders  starts
    getOrdersListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/stock-receipt/list`,
      }),
      providesTags: ["Orders"],
    }),
    getOrdersBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/stock-receipt/basic-data`,
      }),
      providesTags: ["Orders"],
      keepUnusedDataFor: 1,
    }),

    updateOrderTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Orders"],
    }),

    // orders ends
    // recheck starts
    getRecheckListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/recheck/all-list`,
      }),
      providesTags: ["Recheck"],
    }),
    getReassignListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/reassign/list`,
      }),
      providesTags: ["Reassign"],
    }),
    updateRecheckTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Recheck"],
    }),
    getFilteredListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/filters/recheck-filter`,
      }),
      providesTags: ["Recheck"],
    }),
    getRecheckBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/recheck/basic-data`,
      }),
      providesTags: ["Basic Data"],
    }),
    getProcessListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/reassign/all-process`,
      }),
      providesTags: ["Reassign"],
    }),
    getStockListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/stock-receipt/stock-status`,
      }),
      providesTags: ["Reassign"],
    }),
    getUserListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/user/staff-list`,
      }),
      providesTags: ["Reassign"],
    }),
    //recheck ends
  }),
});

export const {
  useGetFilteredListDataQuery,
  useGetRecheckListDataQuery,
  useGetOrdersListDataQuery,
  useGetOrdersBasicDataQuery,
  useUpdateOrderTableHeadDataMutation,
  useUpdateRecheckTableHeadDataMutation,
  useGetRecheckBasicDataQuery,
  useGetReassignListDataQuery,
  useGetProcessListDataQuery,
  useGetUserListDataQuery,
  useGetStockListDataQuery
} = orders;
