import { getAxiosInstance } from "../../../api";

export const deleteReport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = api.post(`/qc-reports/delete-uploaded-report`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const downloadExcelFile = async (data) => {
  const api = await getAxiosInstance();
  try {
    const response = api?.get(data, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
