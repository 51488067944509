import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("receipt_id")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || "10"}&${getParams("search")}page=${
            params?.page || 1
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const mainReport = createApi({
  reducerPath: "mainReportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["mainReport", "viewReport"],
  endpoints: (builder) => ({
    getMainReportTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/qc-reports/receipt-list`,
      }),
      providesTags: ["mainReport"],
    }),
    getAvailableReportsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/qc-reports/available-reports`,
      }),
    }),
    updateMainReportTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["mainReport"],
    }),
    //starts view report section
    getViewReportsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/qc-reports/list-uploaded-reports`,
      }),
      transformResponse: (response) => {
        let newData = response?.data?.data?.map((item) => {
          return {
            ...item,
            download: "",
          };
        });
        let newFields = {
          ...response?.data?.fields,
          download: {
            label: "Download Excel",
            sortable: false,
            hidden: false,
          },
        };
        let updatedData = {
          ...response?.data,
          data: newData,
          fields: newFields,
        };
        return updatedData;
      },
      providesTags: ["viewReport"],
    }),
    updateViewReportTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["viewReport"],
    }),
    //ends view report section
  }),
});
export const {
  useGetMainReportTableDataQuery,
  useGetAvailableReportsDataQuery,
  useUpdateMainReportTableHeadDataMutation,
  useGetViewReportsDataQuery,
  useUpdateViewReportTableHeadDataMutation,
} = mainReport;
