import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logoPreview: "",
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddBranchModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedBranch: "",
  isEdit: false,
  branchData: [],
  activeTab: "Basic",
  completedTabs: {
    Basic: false,
    Address: false,
    Contact: false,
  },
  CompletedPercentage: {
    Basic: 0,
    Address: 0,
    Contact: 0,
  },
};

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = branchesSlice.actions;

export default branchesSlice.reducer;
