import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getEditedData, updateInvoice } from "../api";
import { toast } from "react-toastify";

export const useBillAddress = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { editModal, invoiceData } = useSelector((state) => state.staffBilling);
  const dispatch = useDispatch();
  const initialValues = {
    supplier_name: "",
    shop_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    postal_code: "",
    gst_number: "",
    discount_amount: "",
    courier_charge: "",
    sac_number: "",
    amount: "",
  };
  const validationSchema = Yup.object({
    // supplier_name: Yup.string().trim().required("Please enter supplier name"),
    shop_name: Yup.string().trim().required("Please enter shop name"),
    address_line1: Yup.string().trim().required("Please enter address"),
    address_line2: Yup.string().trim().required("Please enter address"),
    city: Yup.string().trim().required("Please enter city name"),
    postal_code: Yup.string()
      .trim()
      .required("Please enter postal code")
      .matches(/^[1-9][0-9]{5}$/, "Enter valid postal code"),
    gst_number: Yup.string()
      .required("Enter GST Number")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Enter Valid GST Number"
      ),
    sac_number:
      isChecked &&
      Yup.string()
        .required("Enter SAC number")
        .matches(/^[0-9]*$/, "Enter Valid SAC Number")
        .min(6, "Enter Valid SAC Number")
        .max(20, "Enter Valid SAC Number"),
    amount:
      isChecked &&
      Yup.string()
        .required("Enter Amount")
        .matches(/^[0-9]*$/, "Enter Valid Amount"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let data = {
        invoice_id: invoiceData?.invoice_id,
        supplier_name: values?.supplier_name,
        is_courier_charge: isChecked ? 1 : 2,
        sac_number: isChecked ? values?.sac_number : "",
        courier_charge: isChecked ? values?.amount : "",
        discount_amount: values?.discount_amount,
        gst_number: values?.gst_number,
        billing_address: {
          shop_name: values?.shop_name,
          address_line1: values?.address_line1,
          address_line2: values?.address_line2,
          city: values?.city,
          postal_code: values?.postal_code,
        },
      };
      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      updateInvoice(newObj)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Updated");
            getEditedData(invoiceData?.invoice_id);
            handleEdit();
          }
        })
        .catch((error) => toast.error(error));
    },
  });
  useEffect(() => {
    setValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData]);
  const setValues = () => {
    if (invoiceData?.courier_data?.is_courier_charge === 1) {
      setIsChecked(true);
      formik?.setFieldValue(
        "sac_number",
        invoiceData?.courier_data?.sac_number
      );
      formik?.setFieldValue(
        "amount",
        invoiceData?.courier_data?.courier_charge
      );
    }
    formik?.setFieldValue(
      "discount_amount",
      invoiceData?.courier_data?.discount_amount
    );
    formik?.setFieldValue(
      "supplier_name",
      invoiceData?.billing_data?.supplier_name
    );
    formik?.setFieldValue("shop_name", invoiceData?.billing_data?.shop_name);
    formik?.setFieldValue(
      "address_line1",
      invoiceData?.billing_data?.address_line1
    );
    formik?.setFieldValue(
      "address_line2",
      invoiceData?.billing_data?.address_line2
    );
    formik?.setFieldValue("city", invoiceData?.billing_data?.city);
    formik?.setFieldValue(
      "postal_code",
      invoiceData?.billing_data?.postal_code
    );
    formik?.setFieldValue("gst_number", invoiceData?.billing_data?.gst_number);
  };

  const getEditedValue = () => {
    if (invoiceData?.invoice_id) {
      getEditedData(invoiceData?.invoice_id)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            setValues();
            dispatch(
              updateConfig(
                (state) => (state.invoiceData = response?.data?.data)
              )
            );
          }
        })
        .catch((error) => toast.error(error));
    }
  };
  useEffect(() => {
    if (editModal) {
      getEditedValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModal]);

  const handleEdit = () => {
    dispatch(updateConfig((state) => (state.editModal = !state.editModal)));
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCheckChange = () => {
    setIsChecked(!isChecked);
  };

  return {
    formik,
    editModal,
    isChecked,
    handleCheckChange,
    handleEdit,
    getFieldError,
  };
};
