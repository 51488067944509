import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || 20}&${getParams("search")}page=${
            params?.page || 1
          }&${getParams("receipt_id")}&${getParams("process_slug")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const engraving = createApi({
  reducerPath: "engravingApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Engraving", "Engravingrecheck"],
  endpoints: (builder) => ({
    //orders  starts
    getEngravingCertificateListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `process-engraving/list`,
      }),
      providesTags: ["Engraving"],
    }),

    getRecheckEngravingCertificateListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `process-engraving/recheck/list`,
      }),
      providesTags: ["Engravingrecheck"],
    }),

    //recheck starts
  }),
});

export const {
  useGetEngravingCertificateListDataQuery,
  useGetRecheckEngravingCertificateListDataQuery,
} = engraving;
