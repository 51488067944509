import { useRef } from "react";
import { BigTemplateA } from "../../Employees/Printing/CertificateTypes/BigCard/bigTemplateA";
import { BigTemplateC } from "../../Employees/Printing/CertificateTypes/BigCard/bigTemplateC";
import html2canvas from "html2canvas";
import Assets from "../../../assets/Assets";
import Style from "../VerifyCertificate.module.scss"
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Printing/printingSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const Bigcard = ({ data: value }) => {
  const { verifyCertificateBtn } = useSelector(
    (state) => state.printing
  );

  const data = value?.data;
  const cardRef = useRef(null);
  const dispatch = useDispatch();
  
  const handleDownload = () => {
    dispatch(updateConfig((state) => (state.verifyCertificateBtn = true)));
    html2canvas(cardRef.current,  { scale: 4, useCORS: true, }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = `${data?.certificate_number}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
      dispatch(updateConfig((state) => (state.verifyCertificateBtn = false)))
    })
    .catch(() => {
      dispatch(updateConfig((state) => (state.verifyCertificateBtn = false)));
      toast.error("Something went wrong")
    });
  };

  return (
    <div className="pro-d-flex pro-items-center pro-flex-column pro-py-3">
      <div ref={cardRef} className={Style.overflow_wrap}>
              {data?.center_stone_side_stone ? (
                <BigTemplateC value={data}/>
              ) : (
                <BigTemplateA value={data}/>
              )}
          </div>
          <div className="pro-d-flex pro-justify-center pro-pt-3 pro-gap-3">
        <button
          className="pro-btn pro-btn-primary pro-px-5"
          onClick={() => handleDownload()}
          disabled={verifyCertificateBtn}
        >
         { verifyCertificateBtn ? "Loading..." : "Download"}
        </button>
      </div>
      <p className="pro-pt-3">For more details, <a href="https://dhcinternational.com">click here</a></p>
      <a href="https://dhcinternational.com" className={Style.logo_link}>
        <figure>
          <img src={Assets.LOGONEW} alt="DHC" />
        </figure>
      </a>
    </div>
  );
};

export default Bigcard;
