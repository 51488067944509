import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Billing/staffBillingSlice";
import { updateInvoiceHistory } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useEditForm = ({ refetch }) => {
  const dispatch = useDispatch();
  const { paymentData, slectedInvoice } = useSelector((state) => state.staffBilling);

  const validation = Yup.object({
    amount: Yup.string()
      .required("Enter Amount")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter a valid amount"),
    payment_mode: Yup.object().required("Select Payment Mode"),
  });
  const formik = useFormik({
    initialValues: {
      amount: "",
      payment_mode: "",
      tax_number: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        invoice_id: slectedInvoice,
        payment_mode: values?.payment_mode?.id,
        tax_number: values?.tax_number,
        amount: values?.amount,
      };
      updateInvoiceHistory(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Updated");
            handleCloseModal();
            refetch();
          }
        })
        .catch((error) => toast.error(error));
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => (state.invoiceModal = !state.invoiceModal))
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    paymentData,
    getFieldError,
    handleCloseModal,
  };
};

export default useEditForm;
