import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useUpload from "./useUpload";
import FileUpload from "../../../../Global/FileUpload";

const Upload = ({ refetch, tableType }) => {
  const {
    formik,
    editData,
    getFieldError,
    handleClose,
    handleFirstImageChange,
    handleFirstImageDelete,
    handleSecondImageChange,
    handleSecondImageDelete,
  } = useUpload({
    refetch,
    tableType,
  });
  return (
    <div className="input-wrap pro-mb-4">
      <div className="pro-d-flex pro-gap-3 pro-w-100">
        <div className="pro-mb-4 flex-fill">
          <FileUpload
            id={"photo1"}
            title={"First Image"}
            filePreview={editData?.photo1}
            handleFileChange={handleFirstImageChange}
            handleFileRemove={handleFirstImageDelete}
            isError={!!formik?.errors?.photo1}
            errorMsg={formik?.errors?.photo1}
            maxSize="5"
            status={"success"}
          />
        </div>
        <div className="pro-mb-4 flex-fill">
          <FileUpload
            id={"photo1"}
            title={"Second Image"}
            filePreview={editData?.photo2}
            handleFileChange={handleSecondImageChange}
            handleFileRemove={handleSecondImageDelete}
            isError={!!formik?.errors?.photo2}
            errorMsg={formik?.errors?.photo2}
            maxSize="5"
            status={"success"}
          />
        </div>
      </div>
      {/* <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
        Upload Photo
      </label>
      <div className="input-drag">
        <input
          type="file"
          accept="image/*"
          placeholder="placeholder"
          className={`pro-input lg ${getFieldError("photo") && " error"}`}
          id="photo"
          onBlur={formik.handleBlur("photo")}
          onChange={(e) => handlePhotoUpload(e)}
        />
        <span className="input-drag-box" style={{ padding: "50px" }}>
          {formik?.values?.photo?.name ? (
            formik?.values?.photo?.name
          ) : formik?.values?.photo ? (
            formik?.values?.photo
          ) : (
            <IconText title={`Drop files to attach or browse`} />
          )}
        </span>
        {getFieldError("photo") && (
          <span className="error-text">{getFieldError("photo")}</span>
        )}
      </div> */}
      <div className="input-wrap">
        <Input
          label={"Remark"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg ${getFieldError("remark") && " error"}`}
          {...formik.getFieldProps("remark")}
          error={getFieldError("remark")}
          errorMessage={getFieldError("remark")}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-mt-5  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default Upload;
