import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getStatusData, getPackingTypeData } from "./api";

export const useAddStatus = ({
  error,
  itemId,
  setPackingTypeId,
  setSubmissionStatus,
  setRemarks,
}) => {
  const [statusData, setStatusData] = useState();
  const [packingTypeData, setPackingTypeData] = useState();

  useEffect(() => {
    getStatusData().then((response) => {
      setStatusData(response?.data?.data);
    });

    getPackingTypeData(itemId).then((response) => {
      setPackingTypeData(response?.data?.data);
      formik?.setFieldValue(
        "packing_type",
        response?.data?.data?.packing_type_id
      );
    });
    //eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      status: "",
      packing_type: "",
      remarks: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Select status of submission"),
      packing_type: Yup.string().required("Select type of packing"),
    }),
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  useEffect(() => {
    if (error) {
      formik?.setFieldTouched("status", true);
      formik?.setFieldTouched("packing_type", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  let statusObj = statusData?.filter(
    (item) => item?.id === formik?.values?.status
  );
  let packingObj = packingTypeData?.list?.filter(
    (item) => item?._id === formik?.values?.packing_type
  );

  //passing value of the packing type and status to packing component
  setPackingTypeId(formik?.values?.packing_type);
  setSubmissionStatus(formik?.values?.status);
  setRemarks(formik?.values?.remarks);
  return {
    formik,
    statusData,
    statusObj,
    getFieldError,
    packingTypeData,
    packingObj,
  };
};

export default useAddStatus;
