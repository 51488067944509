import { useSelector } from "react-redux";
import {
  useGetSourceListDataQuery,
  useUpdateSourceTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Source/sourceSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSourceData, deleteSource, approveSourceRequest } from "./api";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useSource = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const [updateSourceFields] = useUpdateSourceTableHeadDataMutation();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const sourceState = useSelector((state) => state.source);
  const {
    data: sourceList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetSourceListDataQuery({
    page_size: sourceState.currentPageSize,
    page: sourceState.currentPage,
    sort_by: sourceState.sortBy,
    sort_order: sourceState.sortOrder,
    start: sourceState.startDate,
    end: sourceState.endDate,
    search: sourceState.search,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = sourceList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [sourceList]);
  useEffect(() => {
    if (sourceState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [sourceState.clearSelection]);

  useEffect(() => {
    if (sourceState?.selectedSource && sourceState?.isEdit) {
      fillSourceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceState?.isEdit, sourceState?.showAddSourceModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddSourceModal = !state.showAddSourceModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (field, data) =>
        data?.status === "Pending" && sourceList?.data?.approve_permission ? (
          <Button
            className={"pro-btn-primary sm"}
            type="button"
            onClick={() => handleApprovePermission(data?._id)}
          >
            {"Approve"}
          </Button>
        ) : data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleApprovePermission = (approveId) => {
    let params = {
      source_id: approveId,
    };
    approveSourceRequest(params).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        toast.success("Approved successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleDelete = () => {
    let data = {
      source_id: sourceState?.selectedSource,
    };
    deleteSource(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Source deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const fillSourceData = () => {
    getSourceData(sourceState?.selectedSource).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.sourceData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch source data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedSource = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddSourceModal = true;
        state.selectedSource = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (sourceState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = sourceState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    sourceState,
    showEditModal,
    tableFields,
    sourceList,
    isLoading,
    isFetching,
    paginationOptions,
    updateSourceFields,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useSource;
