import Barcode from "react-barcode";
import { useCardPrint } from "../VisitingCardOne/useCardPrint";
import QRCode from "react-qr-code";

export const BigTemplateC = ({ value }) => {
  const { renderText } = useCardPrint();

  const arrayItems = value?.data?.graded_values;
  const topArray = arrayItems?.filter(
    (item) => item?.slug !== "comments" && item?.slug !== "conclusion"
  );
  const bottomArray = arrayItems?.filter(
    (item) => item?.slug === "comments" || item?.slug === "conclusion"
  );

  //to change the length of key
  const maxKeyLength = arrayItems?.reduce(
    (max, str) => Math.max(max, str?.text?.length),
    0
  );
  const newKeyValue = {
    ...styles.key,
    width: maxKeyLength > 14 ? 100 : 90,
  };
  //end to change the length of key`

  return (
    <>
      <div style={styles.outer_wrapper} className="item-class-big-card">
        <div style={styles.header_wrap}>
          <img
            style={styles.header_img}
            src={value?.front_top_image || ""}
            alt="Header"
          />
          {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
            <span style={styles.qr_wrap}>
              <QRCode
                value={value?.qr}
                width={60}
                height={60}
                style={styles.qr_code}
              />
            </span>
          )}
        </div>
        <div style={styles.body}>
          <div style={styles.top_wrap}>
            <div style={styles.row}>
              <p style={newKeyValue}>
                {value?.bold === 1 ? (
                  <b style={{ fontSize: 9.5, fontWeight: "600" }}>SUMMARY NO</b>
                ) : (
                  "SUMMARY NO"
                )}
              </p>
              <p style={styles.separate}>:</p>
              <p style={styles.value}>
                {value?.bold === 1 ? (
                  <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                    {value?.certificate_number}
                  </b>
                ) : (
                  value?.certificate_number
                )}
              </p>
            </div>
            {topArray?.map((item, index) => (
              <div style={styles.row} key={index}>
                <p style={newKeyValue}>
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.text, item?.key_font_case)}
                    </b>
                  ) : (
                    renderText(item?.text, item?.key_font_case)
                  )}
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.value}>
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.value, item?.value_font_case)}
                    </b>
                  ) : (
                    renderText(item?.value, item?.value_font_case)
                  )}
                </p>
              </div>
            ))}
          </div>
          <div style={styles.middle_wrap}>
            <div style={styles.content_wrap}>
              <div style={styles.inner_table_wrap}>
                <div style={styles.centerstone_wrap}>
                  <p style={styles.centerstone_title}>CENTRE STONE</p>

                  {value?.data?.center_stone?.map((item, index) => (
                    <div style={styles.row} key={index}>
                      <p style={styles.inner_key}>
                        {item?.bold === 1 ? (
                          <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                            {renderText(item?.text, item?.key_font_case)}
                          </b>
                        ) : (
                          renderText(item?.text, item?.key_font_case)
                        )}
                      </p>
                      <p style={styles.separate}>:</p>
                      <p style={styles.inner_value}>
                        {item?.bold === 1 ? (
                          <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                            {renderText(item?.value, item?.value_font_case)}
                          </b>
                        ) : (
                          renderText(item?.value, item?.value_font_case)
                        )}
                      </p>
                    </div>
                  ))}
                </div>
                <div style={styles.centerstone_wrap}>
                  <p style={styles.centerstone_title}>SIDE STONE</p>

                  {value?.data?.side_stone?.map((item, index) => (
                    <div style={styles.row} key={index}>
                      <p style={styles.inner_key}>
                        {item?.bold === 1 ? (
                          <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                            {renderText(item?.text, item?.key_font_case)}
                          </b>
                        ) : (
                          renderText(item?.text, item?.key_font_case)
                        )}
                      </p>
                      <p style={styles.separate}>:</p>
                      <p style={styles.inner_value}>
                        {item?.bold === 1 ? (
                          <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                            {renderText(item?.value, item?.value_font_case)}
                          </b>
                        ) : (
                          renderText(item?.value, item?.value_font_case)
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={styles.img_wrap}>
              <img
                style={styles.main_img}
                src={value?.image || ""}
                alt="Card images"
              />
            </div>
          </div>
          <div style={styles.bottom_wrap}>
            {bottomArray?.map((item, index) => (
              <div style={styles.row} key={index}>
                <p style={styles.bottom_key}>
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.text, item?.key_font_case)}
                    </b>
                  ) : (
                    renderText(item?.text, item?.key_font_case)
                  )}
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.bottom_value}>
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.value, item?.value_font_case)}
                    </b>
                  ) : (
                    renderText(item?.value, item?.value_font_case)
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div style={styles.footer_wrap}>
          {parseInt(value?.qr_and_image_format) === 2 ? (
            <div style={styles.barcode_wrap}>
              {value?.certificate_number && (
                <Barcode
                  value={value?.certificate_number}
                  height={15}
                  width={0.5}
                  displayValue={false}
                  margin={0}
                />
              )}
            </div>
          ) : (
            <img
              style={styles.footer_img}
              src={value?.front_foot_image || ""}
              alt="Card footer"
            />
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  outer_wrapper: {
    width: 472.44,
    minWidth: 472.44,
    maxWidth: 472.44,
    height: 354.33,
    maxHeight: 354.33,
    minHeight: 354.33,
    border: "1px solid #f3f3f3",
    color: "#000000",
    overflow: "hidden",
    fontFamily: "'Graphik',san-serif",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  header_wrap: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    height: 79,
    // height : '1.2cm'
    width: "100%",
    position: "relative",
  },
  header_img: {
    maxWidth: 472.44,
    width: "100%",
    maxHeight: 79,
    height: 79,
  },
  qr_wrap: {
    width: 60,
    height: 60,
    display: "inline-block",
    position: "absolute",
    top: 10,
    right: 28,
  },
  qr_code: {
    width: 60,
    height: 60,
  },
  footer_wrap: {
    height: 41,
    // height : '0.8cm'
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  footer_img: {
    width: "100%",
    maxHeight: 41,
    objectFit: "contain",
  },
  body: {
    // height : 141.76,
    maxHeight: 232.33,
    padding: "1px 28px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  middle_wrap: {
    display: "flex",
    padding: "1px 0",
    flex: "1",
    alignItems: "center",
  },
  row: {
    display: "flex",
    padding: "0.9px 0px",
  },
  key: {
    width: 100,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom: 0,
  },
  value: {
    flex: "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom: 0,
    fontSize: 10,
  },
  bottom_key: {
    width: 100,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "1.46",
    marginBottom: 0,
  },
  bottom_value: {
    flex: "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.46",
    marginBottom: 0,
    fontSize: 10,
  },
  inner_key: {
    width: 75,
    fontSize: 9,
    fontWeight: 400,
    lineHeight: "1.35",
    marginBottom: 0,
  },
  inner_value: {
    flex: "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.35",
    marginBottom: 0,
    fontSize: 9,
  },
  separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight: 400,
    fontSize: 7.5,
    lineHeight: "1.35",
    marginBottom: 0,
    position: "relative",
    top: -1,
  },
  content_wrap: {
    flex: "1",
  },
  img_wrap: {
    width: 160,
    height: 100,
  },
  main_img: {
    width: "100%",
    maxWidth: 160,
    height: 100,
    maxHeight: 100,
  },
  inner_table_wrap: {
    display: "flex",
  },
  centerstone_wrap: {
    width: "50%",
  },
  centerstone_title: {
    marginBottom: 0,
    fontSize: 10,
    fontWeight: 500,
    textAlign: "center",
  },
  sidestone_wrap: {
    width: "50%",
  },
  barcode_wrap: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    lineHeight: 0,
    width: "100%",
  },
};
