import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useMenus from "../Global/useMenus";

const Employees = () => {
  const { drawerMenu } = useMenus();
  return (
    <div>
      <CommonLayout drawerMenu={drawerMenu}>
        <Outlet />
      </CommonLayout>
    </div>
  );
};

export default Employees;
