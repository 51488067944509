import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&per_page=${params?.page_size || "10"}&${getParams("search")}page=${
            params?.page || 1
          }&${getParams("date")}date=${params?.date || ""}&${getParams(
            "shop"
          )}shop=${params?.shop || ""}
          &${getParams("item_type")} item_type=${
            params?.item_type || ""
          }&${getParams("active_tab")}&${getParams("status")}status=${params?.status || ""}
          &${getParams("range")}range=${params?.range || ""}${getParams(
            "receipt_id"
          )}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const billing = createApi({
  reducerPath: "billingApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Billing",
    "Invoice",
    "RecheckBilling",
    "RecheckInvoice",
    "Bill-Estimate",
    "Bill-Reissue",
  ],
  endpoints: (builder) => ({
    getGliListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/back-office-billing/stock-receipt-list`,
      }),
      providesTags: ["Billing"],
      keepUnusedDataFor: 2,
    }),
    updateBillingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["Billing"],
    }),

    //Common data for filter
    getFilterListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/filters/common-search-filters`,
      }),
      providesTags: ["Billing"],
    }),
    getInvoiceHistoryListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/back-office-billing/invoice-history`,
      }),
      providesTags: ["Invoice"],
    }),

    getInvoiceHistoryBasicData: builder.query({
      query: (body) => ({
        method: "get",
        body,
        endpoint: `/back-office-billing/basic-data`,
      }),
      providesTags: ["Invoice"],
    }),
    updateInvoiceHistoryTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["Invoice"],
    }),

    getRecheckGliListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/back-office-billing/recheck/stock-receipt-list`,
      }),
      providesTags: ["RecheckBilling"],
      keepUnusedDataFor: 2,
    }),
    updateRecheckBillingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/recheck/save`,
      }),
      invalidatesTags: ["RecheckBilling"],
    }),
    getRecheckInvoiceHistoryListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/back-office-billing/recheck/invoice-history`,
      }),
      providesTags: ["RecheckInvoice"],
    }),
    updateRecheckInvoiceHistoryTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/recheck/save`,
      }),
      invalidatesTags: ["RecheckInvoice"],
    }),
    // bill-estimate
    getBillEstimateData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/back-office-billing/estimate-list`,
      }),
      providesTags: ["Bill-Estimate"],
    }),
    updateBillEstimateTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["Bill-Estimate"],
    }),

    //billing reissue section starts
    getBillingReissueListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/back-office-billing/reissue/stock-receipt-list`,
      }),
      providesTags: ["Bill-Reissue"],
    }),
    updateBillReissueTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["Bill-Reissue"],
    }),
    getBillingReissueCertificateListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/back-office-billing/reissue/certificate-list`,
      }),
      providesTags: ["Bill-Reissue"],
    }),
    updateBillingReissueCertificateTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["Bill-Reissue"],
    }),
    //billing reissue section ends
  }),
});

export const {
  useGetInvoiceHistoryListDataQuery,
  useGetGliListDataQuery,
  useGetFilterListDataQuery,
  useUpdateBillingTableHeadDataMutation,
  useUpdateInvoiceHistoryTableHeadDataMutation,
  useGetRecheckGliListDataQuery,
  useUpdateRecheckBillingTableHeadDataMutation,
  useGetRecheckInvoiceHistoryListDataQuery,
  useUpdateRecheckInvoiceHistoryTableHeadDataMutation,
  useGetBillEstimateDataQuery,
  useUpdateBillEstimateTableHeadDataMutation,
  useGetBillingReissueListDataQuery,
  useUpdateBillReissueTableHeadDataMutation,
  useGetBillingReissueCertificateListDataQuery,
  useUpdateBillingReissueCertificateTableHeadDataMutation,
  useGetInvoiceHistoryBasicDataQuery
} = billing;
