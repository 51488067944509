import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetReassignListDataQuery,
  useUpdateRecheckTableHeadDataMutation,
  useGetRecheckListDataQuery
} from "../../../store/queries/Orders";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Reassign/reassignSlice";
import { useEffect, useRef, useState } from "react";
import { addToReissueList, getExportedData, printReport, printSlip } from "./api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import html2pdf from "html2pdf.js";

const useReassign = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const [filterShow, setFilterShow] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const reassignState = useSelector((state) => state.reassign);
  const dispatch = useDispatch();

  const StockReceiptRef = useRef(null);
  const recheckOfficeSlipRef = useRef(null);

  const {
    data: reassignList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetReassignListDataQuery({
    per_page: reassignState?.currentPageSize,
    page: reassignState?.currentPage,
    sort_by: reassignState?.sortBy,
    sort_order: reassignState?.sortOrder,
    start: reassignState?.startDate,
    end: reassignState?.endDate,
    search: reassignState?.search,
  },
{
  skip: activeTab === "recheck"
});

const {
  data: recheckList = {},
  isLoading: recheckLoading,
  isFetching: recheckFetching,
  refetch: recheckFetch,
} = useGetRecheckListDataQuery({
  per_page: reassignState?.currentRecheckPageSize,
  page: reassignState?.currentRecheckPage,
  sort_by: reassignState?.sortBy,
  sort_order: reassignState?.sortOrder,
  start: reassignState?.startDate,
  end: reassignState?.endDate,
  search: reassignState?.search,
  type: 2,
},
{
skip: activeTab === "orders"
});

  const [updateRecheckTableData] = useUpdateRecheckTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = activeTab;
        state.clearSelection = true;
        state.currentPage = 1;
        state.currentPageSize = 20;
        state.search = "";
        state.currentRecheckPage = 1;
        state.currentRecheckPageSize = 20;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);


  const navigation = [
    {
      label: "Orders",
      title: "Orders",
      link: `/orders/reassign?activeTab=orders`,
      active: reassignState?.activeTab === "orders",
    },
    {
      label: "Recheck",
      title: "Recheck",
      link: `/orders/reassign?activeTab=recheck`,
      active: reassignState?.activeTab === "recheck",
    },
  ];

  

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = activeTab === "orders"
        ? reassignList?.data?.fields
        : recheckList?.data?.fields;

      })
    );
    // eslint-disable-next-line
  }, [reassignList, recheckList, activeTab]);
  useEffect(() => {
    if (reassignState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [reassignState.clearSelection]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      service: (feild, data) => (
        <ul>
          {data[feild]?.map(({ name }, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      ),
      priority: (_, data) =>
        data?.priority === "Urgent" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Urgent"}
          </span>
        ) : data?.priority === "Normal" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Normal"}
          </span>
        ) : (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {data?.work_status}
          </span>
        ),
      work_status: (_, data) =>
        data?.work_status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.work_status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.work_status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.work_status === "Approved" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Approved"}
          </span>
        ) : data?.work_status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.work_status}
          </span>
        ),
        status: (_, data) =>
          data?.status === "Rejected" ? (
            <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
              {"Rejected"}
            </span>
          ) : data?.status === "Marked" ? (
            <span className={"outline-primary pro-badge pro-d-inline-block"}>
              {"Marked"}
            </span>
          ) : data?.status === "Completed" ? (
            <span className={"outline-success pro-badge pro-d-inline-block"}>
              {"Completed"}
            </span>
          ) : data?.status === "Pending" ? (
            <span className={"outline-warning pro-badge pro-d-inline-block"}>
              {"Pending"}
            </span>
          ) : (
            <span className={"outline pro-badge pro-d-inline-block"}>
              {data?.status}
            </span>
          ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = (label) => {
    if (reassignState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reassignState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          if(activeTab === "orders") {
            state.currentPage = 1;
            state.sortBy = label;
            state.sortOrder = "asc";
          }
          else {
            state.currentRecheckPage = 1;
            state.sortBy = label;
            state.sortOrder = "asc";
          }
          
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        if(activeTab === "orders") {
          state.currentPage = page;
        }
        else {
          state.currentRecheckPage = page;
        }  
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        if(activeTab === "orders") {
          state.currentPageSize = page_size;
          state.currentPage = 1;
        }
        else {
          state.currentRecheckPageSize = page_size;
        state.currentRecheckPage = 1;
        }
        
      })
    );
  };

  const handleReassignModal = (e) => {
      // let recheckItems = [];
      dispatch(
        updateConfig((state) => {
          state.showReassignModal = !state.showReassignModal;
          state.selectedOption = false;
        })
      );
  };
  const handleStockUpdate = (e) => {
    // let recheckItems = [];
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedOption = false;
        state.selectedId = e[0]
      })
    );
};

  

  const clearSelectedList = () => {
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
        state.showAddRecheckModal = !state.showAddRecheckModal;
      })
    );
  };
  
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showReassignModal = false;
        state.showDeleteModal = false;
      })
    );
  };

  const handleExcelUploadClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.excelUploadModal = true;
        state.selectedId = e[0]
      })
    );
  };

  const handleExcelUploadModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.excelUploadModal = false;
      })
    );
    refetch();
  };

  const handleExcelFileDownload = (e) => {
    let downloadId = {
      // item_type_id: approvedState?.item_type_id,
      receipt_id: e[0],
    };
    getExportedData(downloadId).then((response) => {
      let dataType = response?.data?.type;
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_MM_SS")}${`Excel`}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };
  

  return {
    isLoading,
    filterShow,
    isFetching,
    tableFields,
    reassignList,
    reassignState,
    showEditModal,
    tabNavs: navigation,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleClose,
    handleSearch,
    setFilterShow,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleReassignModal,
    handleEditColumnsClick,
    updateRecheckTableData,
    activeTab,
    recheckList,
    handleStockUpdate,
    handleExcelUploadClick,
    handleExcelUploadModalClose,
    handleExcelFileDownload
  };
};

export default useReassign;
