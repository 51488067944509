import React from "react";
import useApprovedDetails from "./useApprovedDetails";
import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EditApprovedCertificate from "./EditApprovedCertificate";
import Style from "../../verifications.module.scss";
import ExcelUpload from "./ExcelUpload";

const ApprovedDetails = ({
  gliNumber,
  barCodeRef,
  officeSlipRef,
  StockReceiptRef,
}) => {
  const {
    basicData,
    isFetching,
    tableFields,
    approvedState,
    paginationOptions,
    ApprovedDetailsList,
    handleStockReceiptPrint,
    handleOfficeSlipPrint,
    handlePrintBarCode,
    refetch,
    getRow,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleApprovedData,
    handleExcelFileUpload,
    handleExcelFileDownload,
  } = useApprovedDetails({ barCodeRef, officeSlipRef, StockReceiptRef });

  return (
    <div className="pro-w-100">
      <div className={`pro-mb-5 ${Style.head}`}>
        <h5 className="pro-ttl h5 pro-mb-2">{gliNumber}</h5>
        <div className={Style.btns}>
          <button
            className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
            onClick={handleExcelFileDownload}
          >
            <span className="material-symbols-outlined">download</span>
            <span>Excel Download</span>
          </button>
          <button
            className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
            onClick={handleExcelFileUpload}
          >
            <span className="material-symbols-outlined">upload</span>
            <span>Excel Upload</span>
          </button>
          <button
            className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
            onClick={handleStockReceiptPrint}
          >
            <span className="material-symbols-outlined">list_alt</span>
            <span>Print Stock Receipt</span>
          </button>
          <button
            className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
            onClick={handleOfficeSlipPrint}
          >
            <span className="material-symbols-outlined">apartment</span>
            <span>Print Office Slip</span>
          </button>
          <button
            className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
            onClick={handlePrintBarCode}
          >
            <span className="material-symbols-outlined">barcode_scanner</span>
            <span>Print Barcode</span>
          </button>
        </div>
      </div>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={false}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: approvedState?.search }}
      />
      <Table
        multiSelect={false}
        data={ApprovedDetailsList?.data?.data}
        uniqueID={"id"}
        fields={tableFields}
        showCheckBox={true}
        clear={approvedState.clearSelection}
        SortIcon={<FaSort />}
        editIcon={<span className="material-symbols-outlined">edit</span>}
        deleteIcon={<span className="material-symbols-outlined">delete</span>}
        getRow={getRow}
        loading={isFetching}
        handleEdit={handleApprovedData}
        perpage={10}
        assignable={false}
        deletable={false}
      />
      <Pagination
        currentPage={ApprovedDetailsList?.data?.current_page}
        totalPageCount={Math.ceil(
          ApprovedDetailsList?.data?.total_count /
            ApprovedDetailsList?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout
        show={approvedState?.showAddOrderModal}
        handleClose={handleApprovedData}
        title={"Edit Verification Data"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      >
        <div className="pro-m-5">
          <EditApprovedCertificate
            handleApprovedData={handleApprovedData}
            basicData={basicData}
            refetch={refetch}
          />
        </div>
      </ModalLayout>
      <ModalLayout
        show={approvedState.showExcelUploadModal}
        handleClose={handleExcelFileUpload}
        title={`Excel Upload`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      >
        <div className="pro-m-5">
          <ExcelUpload />
        </div>
      </ModalLayout>
    </div>
  );
};

export default ApprovedDetails;
