import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Service/serviceSlice";
import * as Yup from "yup";
import { addProcessData, editProcessData } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetServiceBasicDataQuery } from "../../../../store/queries/Master";

const useAddService = ({ refetch }) => {
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state.service);
  const { data: basicData = {}, isLoading } = useGetServiceBasicDataQuery();

  useEffect(() => {
    const process = basicData?.data?.process?.filter(
      (item) => serviceState?.itemData?.process?.process_id === item?._id
    );

    const matchedItems = basicData?.data?.process?.filter((itemOne) =>
      serviceState?.itemData?.sub_process?.some(
        (itemTwo) => itemOne?._id === itemTwo?.sub_process_id
      )
    );

    const matchedCategory = basicData?.data?.category?.filter((itemOne) =>
      serviceState?.itemData?.category?.some(
        (itemTwo) => itemOne?._id === itemTwo?.id
      )
    );

    if (serviceState?.itemData && serviceState?.isEdit) {
      formik?.setFieldValue("name", serviceState?.itemData?.name);
      formik?.setFieldValue("process", process?.[0]);
      formik?.setFieldValue("category", matchedCategory);
      formik?.setFieldValue("sub_process", matchedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceState?.isEdit, serviceState?.itemData, isLoading]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Name")
      .max(50, "Enter valid Name"),
    process: Yup.object().required("Select A Process"),
    category: Yup.array().min(1, "Select Category").required("Select Category"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      category: [],
      process: "",
      sub_process: "",
      // invoice_description: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const subProcess =
        values?.sub_process?.length > 0
          ? values?.sub_process?.map((value) => value && value?._id)
          : "";

      const category =
        values?.category?.length > 0
          ? values?.category?.map((value) => value && value?._id)
          : "";
      let data = {
        name: values?.name,
        // invoice_description: values?.invoice_description,
        category_id: category,
        process_id: values?.process?._id,
        sub_process_id: subProcess,
      };
      let editData = {
        service_id: serviceState?.selectedItem,
        ...data,
      };
      if (serviceState?.isEdit) {
        editProcessData(editData).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Updated");
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addProcessData(data).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Added");
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddServiceModal = false)));
  };
  return {
    formik,
    serviceState,
    basicData: basicData?.data,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddService;
