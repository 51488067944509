import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Item/itemSlice";
import * as Yup from "yup";
import { addItemData, editItemData } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAddItem = ({ refetch }) => {
  const dispatch = useDispatch();
  const queryState = useSelector((state) => state.item);

  useEffect(() => {
    if (queryState?.isEdit && queryState?.itemData) {
      formik?.setFieldValue("name", queryState?.itemData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.isEdit, queryState?.itemData]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Name")
      .max(50, "Enter valid Name"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        name: values?.name,
      };
      let editData = {
        name: values?.name,
        item_id: queryState?.selectedItem,
      };
      if (queryState?.isEdit) {
        editItemData(editData).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Updated");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            refetch();
          } else if (response?.status_code === 422) {
            // displays backend errors
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addItemData(data).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Added");
            refetch();
          } else if (response?.status_code === 422) {
            // displays backend errors
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddItemModal = false)));
  };
  return {
    formik,
    queryState,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddItem;
