import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import RecheckFilterForm from "./RecheckFilterForm";
import {
  updateConfig,
  resetConfig,
} from "../../../../store/slices/Recheck/recheckSlice";
import { useGetFilteredListDataQuery } from "../../../../store/queries/Orders";

const useFilter = (setShowform) => {
  const dispatch = useDispatch();
  const recheckState = useSelector((state) => state.recheck);
  const { data: FilterData = {} } = useGetFilteredListDataQuery();

  const validationSchema = Yup.object({
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
    item_type: Yup.array().nullable(),
    shop: Yup.array().nullable(),
    status: Yup.array().nullable(),
  });

  // Set the initial values based on whether edit is true or false
  const initialValues = {
    from_date: recheckState.from_date,
    to_date: recheckState.to_date,
    item_type: recheckState.item_type,
    shop: recheckState.shop,
    status: recheckState.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { from_date, to_date, item_type, shop, status } = values;

      if (
        from_date === "" &&
        to_date === "" &&
        item_type === "" &&
        shop === "" &&
        status === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        let date = [];
        let range = [];
        if (values?.from_date && values?.to_date) {
          date.push(...[values?.from_date, values?.to_date]);
        }
        dispatch(
          updateConfig((state) => {
            state.date = date;
            state.range = range;
            state.item_type = values?.item_type;
            state.shop = values?.shop;
            state.status = values?.status;
            state.from_date = values?.from_date;
            state.to_date = values?.to_date;
          })
        );
        setShowform(false);
      }
    },
  });

  const renderTabContent = () => {
    return (
      <RecheckFilterForm
        FilterData={FilterData}
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    setShowform(false);
    dispatch(resetConfig());
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
