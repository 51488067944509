import { getAxiosInstance } from "../../../api";

export const getPackingData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/packing-type/edit?packing_type_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePackingData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/packing-type/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteItem = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/packing-type/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approvePackingRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/packing-type/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
