import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../components/Global/OrderColumn";
import useDepartment from "./useDepartment";
import AddDepartment from "./AddDepartment";
const Department = () => {
  const {
    departmentState,
    showEditModal,
    tableFields,
    departmentList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateDepartmentFields,
    handleClearClick,
    handlePageSize,
    refetch,
  } = useDepartment();
  return (
    <div>
      <HeadingGroup
        title={"All Departments"}
        className={`pro-mb-4`}
        buttonTitle={departmentList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: departmentState?.search }}
        />
        <Table
          multiSelect={false}
          data={departmentList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={departmentState.clearSelection}
          perpage={departmentState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={departmentList?.data?.delete_permission}
          editable={departmentList?.data?.edit_permission}
          showCheckBox={true}
        />
        {departmentList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={departmentState?.currentPage}
            totalPageCount={Math.ceil(
              departmentList.data.total_count / departmentState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={departmentState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected department ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={departmentList?.data?.fields}
              moduleId={departmentList?.data?.module_id}
              updateData={updateDepartmentFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={departmentState?.showAddDepartmentModal}
          handleClose={handleModalClick}
          title={
            departmentState?.isEdit ? "Update Department " : "Add Department"
          }
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddDepartment refetch={refetch} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default Department;
