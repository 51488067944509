import { getAxiosInstance } from "../../../api";

export const getCertificateData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/branch/edit?branch_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};