import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Master/Shop/shopSlice";
import countryData from "../../../../../utils/components/countryCode";

const useBilling = () => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddShopModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Contact";
      })
    );
    document.getElementById('element')?.scrollIntoView({ behavior: 'smooth' });
  };
  return {
    countryCodeOptions: countryData,
    handleCloseModal,
    handleNext,
  };
};

export default useBilling;
