import { getAxiosInstance } from "../../../api";

export const getItemTypeData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/item-type/edit?item_type_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addItemTypeData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item-type/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editItemTypeData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item-type/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteItemTypeData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/item-type/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const getExportedData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `item-type/download-excel-format?item_type_id=${id}`,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveItemTypeRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item-type/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
