import React from "react";
import useAddItemGrading from "./useAddItemGrading";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "../itemGrading.module.scss";
import Select from "react-select";

const AddItemGrading = (refetch) => {
  const { formik, basicData, queryState, getFieldError, handleCloseModal } =
    useAddItemGrading(refetch);
  return (
    <div className="row pro-p-4">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type
        </label>
        <Select
          id="type"
          name="type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.type}
          onBlur={formik.handleBlur("type")}
          onChange={(value) => formik?.setFieldValue("type", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("type") && (
          <span className="error-text">{getFieldError("type")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn ${Style.button}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {queryState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddItemGrading;
