import React from "react";
import Style from "./home.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";

const HomePage = () => {
  return (
    <div className={Style.container}>
      <div className={Style.image}>
        <Image src={Assets.HOME} alt={"home"} width={"100%"} />
      </div>
    </div>
  );
};

export default HomePage;
