import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useGetOtherReportBranchTableDataQuery } from "../../../../store/queries/Reports";
import { useFormik } from "formik";
import { updateConfig } from "../../../../store/slices/Reports/reportsSlice";
import { useState } from "react";
import { subDays } from "date-fns";
import { downloadShopReport } from "../../api";
import { toast } from "react-toastify";
import moment from "moment";

const useShopReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);

  const dispatch = useDispatch();
  const reportState = useSelector((state) => state.reports);

  const formik = useFormik({
    initialValues: {
      shop: "",
      branch: "",
      supplier: "",
      order_type: "",
    },
  });

  const { data: branchReportData = {}, isFetching } =
    useGetOtherReportBranchTableDataQuery(
      {
        type: 1,
        date: formattedDate,
        per_page: reportState?.otherReport?.currentPageSize,
        page: reportState?.otherReport?.currentPage,
        sort_by: reportState?.otherReport?.sortBy,
        sort_order: reportState?.otherReport?.sortOrder,
        search: reportState?.otherReport?.search,
        // branch_id: formik?.values?.branch?._id,
        branch_id: reportState?.otherReport?.currentBranchFilter,
        order_type: formik?.values?.order_type?.id,
        shop_id: formik?.values?.shop?._id,
        supplier_id: formik?.values?.supplier?._id,
      },
      {
        skip: !(
          // formik?.values?.branch &&
          formik?.values?.order_type &&
          (formik?.values?.shop || formik?.values?.supplier)
        ),
      }
    );
  const cardData = [
    {
      label: "Total GLI",
      value: branchReportData?.data?.total_gli ?? "--",
    },
    {
      label: "Total certificates",
      value: branchReportData?.data?.total_item_details_count ?? "--",
    },
    {
      label: "Completed",
      value: branchReportData?.data?.total_completed_count ?? "--",
    },
    {
      label: "Rejected",
      value: branchReportData?.data?.total_rejected_count ?? "--",
    },
    {
      label: "Marked",
      value: branchReportData?.data?.total_marked_count ?? "--",
    },
    {
      label: "Certification",
      value: branchReportData?.data?.total_certification ?? "--",
    },
    {
      label: "Quality Check",
      value: branchReportData?.data?.total_qc ?? "--",
    },
    {
      label: "CVD",
      value: branchReportData?.data?.total_cvd ?? "--",
    },
    {
      label: "Engraving",
      value: branchReportData?.data?.total_engraving ?? "--",
    },
    {
      label: "Metal QC",
      value: branchReportData?.data?.total_metal_qc ?? "--",
    },
  ];

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPageSize = page_size;
        state.otherReport.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPage = page;
      })
    );
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPage = 1;
        state.otherReport.currentBranchFilter = ids;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.otherReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortOrder =
            reportState.otherReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortBy = label;
          state.otherReport.sortOrder = "asc";
        })
      );
    }
  };

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.search = e.target.value;
        state.otherReport.currentPage = 1;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      service: (field, data) => <p className="pro-mb-0">{data[field]?.name}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleDownloadClick = () => {
    let data = {
      type: 1,
        date: formattedDate,
        per_page: reportState?.otherReport?.currentPageSize,
        page: reportState?.otherReport?.currentPage,
        sort_by: reportState?.otherReport?.sortBy,
        sort_order: reportState?.otherReport?.sortOrder,
        search: reportState?.otherReport?.search,
        // branch_id: formik?.values?.branch?._id,
        branch_id: reportState?.otherReport?.currentBranchFilter,
        order_type: formik?.values?.order_type?.id,
        shop_id: formik?.values?.shop?._id,
        supplier_id: formik?.values?.supplier?._id,
    };
    downloadShopReport(data).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else if (response?.status === 403) {
        toast.warning("Invalid Data");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    date,
    formik,
    cardData,
    isFetching,
    reportState,
    paginationOptions,
    branchReportData,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handleDateChange,
    handlePagination,
    handleBranchFilter,
    handleDownloadClick
  };
};

export default useShopReport;
