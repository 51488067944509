import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/QC/QCSlice";

const useVersionTab = () => {
  const { recheckTab } = useSelector((state) => state.qc);
  const dispatch = useDispatch();

  const handleTabClick = (e) => {
    if (e.name === "fields") {
      dispatch(updateConfig((state) => (state.recheckTab.activeTabId = 1)));
    } else {
      const inputString = e?.name;
      dispatch(
        updateConfig(
          (state) =>
            (state.recheckTab.activeTabId = +inputString?.slice(-1) + 1)
        )
      );
    }
  };

  return {
    recheckTab,
    handleTabClick,
  };
};

export default useVersionTab;
