import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import useCollection from "./useCollection";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const Collection = ({
  formik,
  getFieldError,
  isStickyFooter,
  isEditProfile,
  optionsData,
  collectionAgentList,
  setCollectionAgentList
}) => {
  const { handleCloseModal, handleNext, searchAgent, fetchCollectionAgents } = useCollection({ formik, setCollectionAgentList, collectionAgentList });
  const [billTo, setBillTo] = useState([]);

  let branchObj = optionsData?.branch_list?.filter(
    (value) => value?._id === formik?.values?.branch_id
  );
  let sourceObj = optionsData?.source_list?.filter(
    (value) => value?._id === formik?.values?.source
  );
  let supplierObj = optionsData?.supplier_list?.filter((value) => {
    return value?._id === formik?.values?.supplier;
  });
  let shopObj = optionsData?.shop_list?.filter(
    (value) => value?._id === formik?.values?.shop_name
  );
  let billObj = optionsData?.bill_to?.filter(
    (value) => value?._id === formik?.values?.bill_to
  );
  let priorityObj = optionsData?.priority_list?.filter((value) => {
    // eslint-disable-next-line eqeqeq
    return value?.id == formik?.values?.priority;
  });
  let isJobObj = optionsData?.common_list?.filter(
    // eslint-disable-next-line
    (item) => item?.id == formik?.values?.is_job
  );
  let isAddPrefixObj = optionsData?.common_list?.filter(
    // eslint-disable-next-line
    (item) => item?.id == formik?.values?.add_prefix
  );

  const formattedCollectionAgentList = collectionAgentList?.map(agent => ({
    value: agent._id,
    label: `${agent?.delivery_details?.person} (${agent?.delivery_details?.contact_number})`,
}));


  useEffect(() => {
    if (shopObj?.length > 0 && supplierObj?.length > 0) {
      const mergedArray = [
        ...shopObj,
        ...supplierObj.filter(
          (supplierItem) =>
            !shopObj.some((shopItem) => shopItem?._id === supplierItem?._id)
        ),
      ];
      setBillTo(mergedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.shop_name, formik?.values?.supplier]);

  return (
    <div>
      {" "}
      {optionsData?.branch_list?.length !== 0 && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Branch
          </label>
          <Select
            id="branch_id"
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("branch_id") && " error"
            }`}
            classNamePrefix="pro-input"
            options={optionsData?.branch_list ?? []}
            onChange={(value) =>
              formik?.setFieldValue("branch_id", value?._id || "")
            }
            getOptionLabel={(options) => options?.branch_name}
            getOptionValue={(options) => options?._id}
            onBlur={formik?.handleBlur("branch_id")}
            value={branchObj}
            menuPlacement="auto"
          />
          {getFieldError("branch_id") && (
            <span className="error-text">{getFieldError("branch_id")}</span>
          )}
        </div>
      )}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Source
        </label>
        <Select
          id="source"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("source") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.source_list ?? []}
          onChange={(value) =>
            formik?.setFieldValue("source", value?._id || "")
          }
          getOptionLabel={(options) => options?.source_name}
          getOptionValue={(options) => options?._id}
          onBlur={formik?.handleBlur("source")}
          value={sourceObj}
          menuPlacement="auto"
        />
        {getFieldError("source") && (
          <span className="error-text">{getFieldError("source")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Supplier
        </label>
        <Select
          id="supplier"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("supplier") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.supplier_list ?? []}
          onChange={(value) =>
            formik.setFieldValue("supplier", value?._id || "")
          }
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          onBlur={formik?.handleBlur("supplier")}
          value={supplierObj}
          menuPlacement="auto"
        />
        {getFieldError("supplier") && (
          <span className="error-text">{getFieldError("supplier")}</span>
        )}
      </div>
      <Input
        label={"Phone Number"}
        type="text"
        id="phone_number"
        name="phone_number"
        className={`pro-input lg ${getFieldError("phone_number") && " error"}`}
        {...formik.getFieldProps("phone_number")}
        error={getFieldError("phone_number")}
        errorMessage={getFieldError("phone_number")}
      />

<div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Collection Agent
        </label>
        <CreatableSelect
          id="collection_agent"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("collection_agent") && " error"}`}
          classNamePrefix="pro-input"
          options={formattedCollectionAgentList ?? []}
          onChange={(options) =>
            // formik.setFieldValue("collection_agent", value?._id || "")
            searchAgent(options)
          }
          onInputChange={(inputValue) => {
            // Call your API with the search term
            fetchCollectionAgents(inputValue);
          }}
      
          getOptionLabel={(options) => options?.label}
          getOptionValue={(options) => options?.value}
          onBlur={formik?.handleBlur("collection_agent")}
           value={formik?.values?.collection_agent}
          menuPlacement="auto"
        />
        {getFieldError("collection_agent") && (
          <span className="error-text">{getFieldError("collection_agent")}</span>
        )}
      </div>
      {/* <Input
        label={"Collection Agent"}
        type="text"
        id="collection_agent"
        name="collection_agent"
        className={`pro-input lg ${
          getFieldError("collection_agent") && " error"
        }`}
        {...formik.getFieldProps("collection_agent")}
        error={getFieldError("collection_agent")}
        errorMessage={getFieldError("collection_agent")}
      /> */}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Address
        </label>
        <textarea
          id="address"
          rows={4}
          name="address"
          className={`pro-input lg ${getFieldError("address") && " error"}`}
          onChange={(e) => formik?.setFieldValue("address", e.target.value)}
          onBlur={formik?.handleBlur("address")}
          value={formik?.values?.address}
        ></textarea>
        {getFieldError("address") && (
          <span className="error-text">{getFieldError("address")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop Name
        </label>
        <Select
          id="shop_name"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("shop_name") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.shop_list ?? []}
          onBlur={formik?.handleBlur("shop_name")}
          onChange={(value) =>
            formik?.setFieldValue("shop_name", value?._id || "")
          }
          value={shopObj}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          menuPlacement="auto"
        />
        {getFieldError("shop_name") && (
          <span className="error-text">{getFieldError("shop_name")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Bill to
        </label>
        <Select
          id="bill_to"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("bill_to") && " error"}`}
          classNamePrefix="pro-input"
          options={billTo ?? []}
          onBlur={formik?.handleBlur("bill_to")}
          onChange={(value) =>
            formik?.setFieldValue("bill_to", value?._id || "")
          }
          value={billObj}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?._id}
          menuPlacement="auto"
          isDisabled={billTo?.length <= 0}
        />
        {getFieldError("bill_to") && (
          <span className="error-text">{getFieldError("bill_to")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Priority
        </label>
        <Select
          id="priority"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("priority") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.priority_list ?? []}
          onBlur={formik?.handleBlur("priority")}
          onChange={(value) =>
            formik?.setFieldValue("priority", value?.id || "")
          }
          value={priorityObj}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?.id}
          menuPlacement="auto"
        />
        {getFieldError("priority") && (
          <span className="error-text">{getFieldError("priority")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Number of Changeable Stone"}
          type="text"
          id="no_stone"
          name="no_stone"
          className={`pro-input lg ${getFieldError("no_stone") && " error"}`}
          {...formik.getFieldProps("no_stone")}
          error={getFieldError("no_stone")}
          errorMessage={getFieldError("no_stone")}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Is Job Work
        </label>
        <Select
          id="is_job"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("is_job") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.common_list ?? []}
          getOptionLabel={(options) => options?.name}
          getOptionValue={(options) => options?.id}
          onChange={(value) => formik?.setFieldValue("is_job", value?.id)}
          onBlur={formik?.handleBlur("is_job")}
          value={isJobObj}
          menuPlacement="auto"
        />
        {getFieldError("is_job") && (
          <span className="error-text">{getFieldError("is_job")}</span>
        )}
      </div>
      {/* eslint-disable-next-line eqeqeq */}
      {formik?.values?.is_job == 1 && (
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="add_prefix"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Add Prefix
          </label>
          <Select
            id="add_prefix"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("add_prefix") && " error"
            }`}
            classNamePrefix="pro-input"
            options={optionsData?.common_list ?? []}
            getOptionLabel={(options) => options?.name}
            getOptionValue={(options) => options?.id}
            onChange={(value) => formik?.setFieldValue("add_prefix", value?.id)}
            onBlur={formik?.handleBlur("add_prefix")}
            value={isAddPrefixObj}
            menuPlacement="auto"
          />
          {getFieldError("add_prefix") && (
            <span className="error-text">{getFieldError("add_prefix")}</span>
          )}
        </div>
      )}
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="due_date"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Due Date
        </label>
        <input
          className={`pro-input lg`}
          type="date"
          name="due_date"
          placeholder="Due date"
          onChange={formik.handleChange}
          value={formik.values.due_date}
          min={new Date().toISOString().split("T")[0]}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="due_time"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Due Time
        </label>
        <input
          className={`pro-input lg`}
          type="time"
          name="due_time"
          placeholder="Due time"
          onChange={formik.handleChange}
          value={formik.values.due_time}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btn"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Collection;
