import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddEmployeeModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedEmployee: "",
  isEdit: false,
  employeeData: [],
  logoPreview: "",
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = employeeSlice.actions;

export default employeeSlice.reducer;
