import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/QC/QCSlice";

const useVersionOne = () => {
  const dispatch = useDispatch();
  const {
    excelFormData,
    showCompare,
    excelData,
    itemID,
    certificateNumber,
    versionOneViewMoreModal,
  } = useSelector((state) => state.qc);

  const handleViewMoreClick = () => {
    dispatch(updateConfig((state) => (state.versionOneViewMoreModal = true)));
  };

  const handleModalClose = () => {
    dispatch(updateConfig((state) => (state.versionOneViewMoreModal = false)));
  };

  return {
    itemID,
    excelData,
    showCompare,
    excelFormData,
    certificateNumber,
    versionOneViewMoreModal,
    handleModalClose,
    handleViewMoreClick,
  };
};

export default useVersionOne;
