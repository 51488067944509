import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddProcessModal: false,
  clearSelection: false,
  selectedItem: "",
  itemData: [],
};

const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = processSlice.actions;

export default processSlice.reducer;
