import { getAxiosInstance } from "../../../api";

export const getEngravingCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`process-engraving/recheck/list`, {
      params: params,
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const approveCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`process-engraving/recheck/submit`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const passAllRecheck = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `employee-dashboard/recheck/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
