import {
  DataContainer,
  SimpleReport,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./card.module.scss";

const CardHero = ({ data }) => {
  return (
    <DataContainer propStyle={{ root: Style.container_root }}>
      <SimpleReport
        data={data}
        propStyle={{
          item: `${Style.report_root}`,
          textInner : Style.textInner
        }}
      />
    </DataContainer>
  );
};

export default CardHero;
