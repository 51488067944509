import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Orders/orderSlice";
import { updateOrderData } from "../../../../../store/slices/Orders/addOrderSlice";
import moment from "moment";
import { getExportedData } from "../../../../Master/Itemtype/api";

const useItem = ({ formik, setValidationType, optionsData }) => {
  const dispatch = useDispatch();

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateOrderData((state) => {
        state.activeTab = "Service";
      })
    );
  };

  const handleAddItemClick = () => {
    let { group } = formik?.values;
    group = [
      ...group,
      {
        item_id: "",
        item_quantity: "",
        total_weight: "",
      },
    ];
    formik.setFieldValue("group", group);
    if(formik?.values?.group?.length > 0){
      formik?.values?.group?.map((value, index) => {
      })
    }
    
  };

  const handleDownloadSheet = () => {
    let downloadId = formik?.values?.item_type;
    getExportedData(downloadId).then((response) => {
      let dataType = response?.data?.type;
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  const handleItemTypeChange = (val) => {
    formik?.setFieldValue("item_type", val?._id);
    formik?.setFieldValue("service_type", "");

    if (val?.slug === "gemstone_jewellery" || val?.slug === "gemstone_loose") {
      formik?.setFieldValue(
        "double_grade",
        optionsData?.grade_list
          ?.filter((val) => val?.id === 3)
          ?.map((val) => val?.id)
      );
    } else {
      formik?.setFieldValue("double_grade", []);
    }
    // setting validation type based on client condition
    switch (val?.slug) {
      case "diamond_jewellery":
        setValidationType(1);
        break;
      case "center_and_side_stone":
        setValidationType(1);
        break;
      case "gemstone_jewellery":
        setValidationType(2);
        break;
      case "precious_jewellery":
        setValidationType(2);
        break;
      case "mixed_jewellery":
        setValidationType(3);
        break;
      case "diamond_loose":
        setValidationType(4);
        break;
      case "gemstone_loose":
        setValidationType(5);
        break;
      case "other":
        setValidationType(6);
        break;
      default:
        setValidationType(0);
        break;
    }
  };

  return {
    formik,
    handleNext,
    getFieldError,
    handleCloseModal,
    handleAddItemClick,
    handleDownloadSheet,
    handleItemTypeChange,
  };
};

export default useItem;
