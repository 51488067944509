import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/InvoiceNumberFormat/invoiceNumberFormatSlice";
import { addInoviceNumberData, editInoviceNumberData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useGetInvoiceNumberBasicDataQuery } from "../../../../store/queries/Master";

const useAddInvoiceNumberFormat = ({ refetch }) => {
  let numberOnly = /^[0-9]+(\.\d+)?$/;
  const dispatch = useDispatch();
  const queryState = useSelector((state) => state.invoiceNumberFormat);

  const { data: basicData = {}, isLoading } = useGetInvoiceNumberBasicDataQuery({
    skip: true,
  });

  useEffect(() => {
    if (queryState?.itemData && queryState?.isEdit && !isLoading) {
      formik?.setFieldValue("branch_prefix", queryState?.itemData?.branch_prefix);
      formik?.setFieldValue("serial_number",queryState?.itemData?.serial_number);
      Object.keys(queryState?.itemData || {}).forEach((key) => {
        if (key === "branch_id") {
          const branchType = basicData?.data?.branch?.filter(
            (val) => val?._id === queryState?.itemData?.branch_id
          )?.[0];

          formik?.setFieldValue("branch_id", branchType);
        } 
        if (key === "year") {
          const selectedYr = basicData?.data?.year?.filter(
            (val) => val?.id === queryState?.itemData?.year
          )?.[0];

          formik?.setFieldValue("year", selectedYr);
        } 
        if (key === "reset") {
          const selectedReset = basicData?.data?.reset?.filter(
            (val) => val?.id === queryState?.itemData?.reset
          )?.[0];

          formik?.setFieldValue("reset", selectedReset);
        } 
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.itemData, isLoading]);

  const validation = Yup.object({
    branch_id: Yup.object().required("Select one"),
    branch_prefix: Yup.string().required("*Required"),
    serial_number: Yup.string().required("*Required"),
    year: Yup.object().required("Select one"),
    reset: Yup.object().required("Select one"),
  });

  const formik = useFormik({
    initialValues: {
      branch_id: "",
      branch_prefix: "",
      serial_number: "",
      year: "",
      reset: "",
    },
    validationSchema: validation,

    onSubmit: (values) => {
      let data = {
        branch_prefix: values?.branch_prefix,
        year: values?.year?.id,
        
      };

      if (queryState?.isEdit) {
        let editData = {
          invoice_number_format_id: queryState?.selectedItem,
          ...data,
        };
        let newObj = Object.entries(editData);
        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        editInoviceNumberData(newObj).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Added successfully");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.selectedItem = "";
              })
            );
            formik?.resetForm();
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            if (response?.errors?.shop_id) {
              formik?.setFieldError("shop", "Bill already exist for this shop");
            }
            if (response?.errors?.item_type_id) {
              formik?.setFieldError("item_type", "Already taken");
            }
          } else {
            toast.error("Something went wrong");
            handleBackendError(response);
          }
        });
      } else {
        data.branch_id = values?.branch_id?._id;
        data.serial_number = values?.serial_number;
        data.reset = values?.reset?.id;
      
        let newObj = Object.entries(data);
        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
          addInoviceNumberData(newObj).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Added successfully");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.selectedItem = "";
              })
            );
            refetch();
            formik?.resetForm();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            // if (response?.errors?.shop_id) {
            //   formik?.setFieldError("shop", "Bill already exist for this shop");
            // }
            // if (response?.errors?.item_type_id) {
            //   formik?.setFieldError("item_type", "Already taken");
            // }
          } else {
            toast.error("Something went wrong");
            handleBackendError(response);
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.itemData = [];
      })
    );
  };

  const handleBackendError = (response) => {
    if (response?.status_code === 422) {
      let errors = response?.errors;
      let errorField = Object.keys(errors);
      errorField.forEach((field) => {
        formik?.setFieldError(field, errors[field]);
      });
    }
  };

  const resetFormFields = () => {
    formik.setFieldValue("carat_slab", "");
    formik.setFieldValue("service_type", "");
    formik.setFieldValue("rate_per_card", "");
    formik.setFieldValue("rate_per_piece", "");
    formik.setFieldValue("rate_per_carat", "");
    formik.setFieldValue("rate_per_stones", "");
    formik.setFieldValue("number_of_stones_slab", "");
    formik.setFieldValue("rate_per_carat_double_grade", "");
    formik.setFieldValue("rate_per_carat_natural_gold", "");
    formik.setFieldValue("rate_per_carat_single_grade", "");
    formik.setFieldValue("rate_per_piece_double_grade", "");
    formik.setFieldValue("rate_per_piece_natural_gold", "");
    formik.setFieldValue("rate_per_piece_single_grade", "");
    formik.setFieldValue("single_stone_charge", "");
    formik.setFieldValue("other_stone_charge", "");
  };

  return {
    formik,
    queryState,
    basicData: basicData?.data,
    getFieldError,
    handleCloseModal,
    isEdit: queryState?.isEdit,
  
  };
};

export default useAddInvoiceNumberFormat;
