import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import useFontCases from "../../../../../utils/hooks/useFontCases";
import { PaperStaticA } from "./PaperStaticA";
import { PaperStaticC } from "./PaperStaticC";
import { PaperStaticD } from "./PaperStaticD";

import Assets from "../../../../../assets/Assets";
import { PaperStaticE } from "./PaperStaticE";

const PapperDuplex = React.forwardRef(({ papperDuplexRef }, ref) => {
  const { getFontCase } = useFontCases();
  const { printingData } = useSelector((state) => state.printing);
  return (
    <div ref={papperDuplexRef} style={{ width: "100%", height: "100%" }}>
      <title>DHC PDF</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {printingData.map((value, mainIndex) => {
        return (
          <div
            //   key={mainIndex}
            //   style={{
            //   height:"1122px",
            //   overflow:'hidden',
            //   // backgroundColor: "red",
            // }}
            id="element-to-print"
            key={mainIndex}
          >
            <div
              key={mainIndex}
              style={{
                height: "1121px",
                overflow: "hidden",
                // backgroundColor: "red",
              }}
            >
              <Fragment key={mainIndex}>
                {!value?.shop_logo && value?.center_stone_side_stone ? (
                  <PaperStaticC value={value} />
                ) : !!value?.shop_logo && value?.center_stone_side_stone ? (
                  <PaperStaticE value={value} />
                ) : !!value?.shop_logo && !value?.center_stone_side_stone ? (
                  <PaperStaticD value={value} />
                ) : (
                  <PaperStaticA value={value} />
                )}
              </Fragment>
            </div>
            <div
              key={mainIndex}
              style={{
                paddingTop: 5,
                height: "1121px",
                overflow: "hidden",
                // backgroundColor: "red",
              }}
            >
              <div
                // key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  margin: 0,
                  padding: 0,
                }}
                id="element-to-print"
              >
                <figure
                  style={{
                    height: "100%",
                    maxWidth: 760,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <img
                    src={value?.back_image}
                    style={{ height: "100%", width: 760, objectFit: "contain" }}
                    alt="back_image"
                  />
                </figure>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default PapperDuplex;
