import React from "react";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";

const BarCodePrint = React.forwardRef(({ barCodeRef },ref) => {
  const { printData } = useSelector((state) => state.approved);
  return (
    <table
      ref={barCodeRef}
      border={0}
      cellSpacing={0}
      role="presentation"
      cellPadding={0}
      style={{
        backgroundColor: "#ffffff",
        maxWidth: 595,
        width: "100%",
        borderCollapse: "collapse",
        margin: "0 auto",
        // border: "1px solid #000000",
      }}
    >
      <tbody>
        <tr>
          <td>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {printData?.map((value, i) => {
                return (
                  <>
                    <div
                      key={i}
                      style={{
                        width: 148,
                        height: 40,
                        display: "flex",
                        padding: "0px 0px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {value?.certificate_number && (
                        <Barcode
                          value={value?.certificate_number}
                          width={0.9}
                          height={15}
                          fontSize={7}
                          fontOptions="bold"
                        />
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default BarCodePrint;
