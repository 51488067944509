import { getAxiosInstance } from "../../../api";

export const UploadPhoto = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/photo-uploading/designid-image-upload`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const BulkUploadPhoto = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/photo-uploading/bulk-image-upload`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const edittedData = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/photo-uploading/get-images?item_detail_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const edittedData2 = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/photo-uploading/get-designid-images?id=${id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePhoto = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/photo-uploading/delete-item-details-image`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deletePhoto2 = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/photo-uploading/delete-design-id-image`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
