import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetRecheckListDataQuery,
  useUpdateRecheckTableHeadDataMutation,
} from "../../../store/queries/Orders";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Recheck/recheckSlice";
import { useEffect, useRef, useState } from "react";
import { addToRecheckList, printReport, printSlip } from "./api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import html2pdf from "html2pdf.js";

const useRecheck = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const [filterShow, setFilterShow] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const recheckState = useSelector((state) => state.recheck);
  const dispatch = useDispatch();

  const StockReceiptRef = useRef(null);
  const recheckOfficeSlipRef = useRef(null);

  const {
    data: recheckList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRecheckListDataQuery({
    per_page: recheckState?.currentPageSize,
    page: recheckState?.currentPage,
    sort_by: recheckState?.sortBy,
    sort_order: recheckState?.sortOrder,
    start: recheckState?.startDate,
    end: recheckState?.endDate,
    search: recheckState?.search,
    date: recheckState.date ? recheckState.date : [],
    item_type: recheckState.item_type ? recheckState.item_type : [],
    shop: recheckState.shop ? recheckState.shop : [],
    status: recheckState.status ? recheckState.status : [],
    type: activeTab === "certificate" ? 1 : 2,
  });

  const [updateRecheckTableData] = useUpdateRecheckTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = activeTab;
        state.clearSelection = true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "Certificate",
      title: "Certificate",
      link: `/orders/recheck?activeTab=certificate`,
      active: recheckState?.activeTab === "certificate",
    },
    {
      label: "Stock Receipt",
      title: "Stock Receipt",
      link: `/orders/recheck?activeTab=stock-receipt`,
      active: recheckState?.activeTab === "stock-receipt",
    },
  ];

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = recheckList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [recheckList]);
  useEffect(() => {
    if (recheckState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [recheckState.clearSelection]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = (label) => {
    if (recheckState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = recheckState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleReissueModal = (e) => {
    // Report click based on tab
    if (recheckState?.activeTab === "certificate") {
      let recheckItems = [];
      dispatch(
        updateConfig((state) => {
          state.showAddReissueModal = !state.showAddReissueModal;
          recheckItems.push(e);
          state.selectedRecheck = recheckItems;
          state.selectedOption = false;
        })
      );
    } else {
      // print report function
      let data = {
        id: e?.[0],
      };
      printReport(data).then((response) => {
        if (response?.status === 200) {
          let dataType = response?.data?.type;
          let binaryData = [];
          binaryData.push(response?.data);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          downloadLink.setAttribute(
            "download",
            `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const handleRecheckModal = (e) => {
    // slip click based on tab
    if (recheckState?.activeTab === "certificate") {
      let recheckItems = [];
      dispatch(
        updateConfig((state) => {
          state.showAddRecheckModal = !state.showAddRecheckModal;
          recheckItems.push(e);
          state.selectedRecheck = recheckItems;
          state.selectedOption = true;
        })
      );
    } else {
      // print slip function
      let data = {
        id: e?.[0],
      };
      printSlip(data).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig(
              (state) => (state.stockPrintData = response?.data?.data)
            )
          );
        } else {
          toast.error("something went wrong");
        }
        const printInvoice = () => {
          const element = StockReceiptRef?.current;
          html2pdf(element, {
            margin: [5, 2, 2, 2],
            filename: `${
              "Stock Invoice" +
              "-" +
              moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        };
        setTimeout(() => {
          printInvoice();
        }, 500);
      });
    }
  };

  const clearSelectedList = () => {
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
        state.showAddRecheckModal = !state.showAddRecheckModal;
      })
    );
  };
  const handleRecheck = () => {
    const params = {
      item_details_id: recheckState.selectedRecheck[0],
    };
    addToRecheckList(params).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Certificate added to recheck list successfully");
        clearSelectedList();
        refetch();
      } else {
        toast.error("Something went wrong");
        clearSelectedList();
      }
    });
  };

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddRecheckModal = false;
        state.showAddReissueModal = false;
      })
    );
  };
  const handleOfficeSlipModal = (e) => {
    let data = {
      id: e?.[0],
    };
    printSlip(data).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.stockPrintData = response?.data?.data))
        );
      } else {
        toast.error("something went wrong");
      }
      const printInvoice = () => {
        const element = recheckOfficeSlipRef?.current;
        html2pdf(element, {
          margin: [2, 2, 2, 2],
          filename: `${
            "Office Slip" +
            "-" +
            moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
          }.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            dpi: 100,
            letterRendering: true,
            useCORS: true,
            logging: true,
            scale: 4,
            scrollY: 0,
          },
          jsPDF: { unit: "mm", format: "a6", orientation: "portrait" },
          pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
          DisablePdfCompression: true,
        });
      };
      setTimeout(() => {
        printInvoice();
      }, 500);
    });
  };

  return {
    isLoading,
    filterShow,
    isFetching,
    tableFields,
    recheckList,
    recheckState,
    showEditModal,
    StockReceiptRef,
    recheckOfficeSlipRef,
    tabNavs: navigation,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleClose,
    handleSearch,
    setFilterShow,
    handleRecheck,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleRecheckModal,
    handleReissueModal,
    handleEditColumnsClick,
    updateRecheckTableData,
    handleOfficeSlipModal,
  };
};

export default useRecheck;
