import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useResetPassword from "./useResetPassword";

const ResetPassword = () => {
  const { emailRef } = useOutletContext();

  const {
    formik,
    resetStatus,
    showPassword,
    showConfirmPassword,
    handleShowConfirmPassword,
    handleShowPassword,
  } = useResetPassword(emailRef);

  return (
    <Auth
      title={"Reset Password"}
      buttonText={"Submit"}
      handleClick={formik.handleSubmit}
      loading={resetStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Enter OTP sent to your email address
        </label>
        <input
          id="otp"
          name="otp"
          value={formik?.values?.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          className={`pro-input lg ${formik?.errors?.otp && "error"}`}
        />
        {(formik.touched.otp ||
          formik.errors.otp) && (
            <span className="error-text">{formik?.errors?.otp}</span>
          )}
      </div>

      <div className="input-wrap pro-mb-5 p-re">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Enter new password
        </label>
        <input
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="password"
          className={`pro-input lg ${formik.errors.password && formik.touched.password && "error"
            } ${resetStatus === "pending" ? "disabled" : ""} hide-icon-input`}
        />
        {showPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.errors.password && formik.touched.password && (
          <span className="error-text">{formik?.errors?.password}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-5 p-re">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Re-enter password
        </label>
        <input
          name="confirm_password"
          id="confirm_password"
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="password"
          className={`pro-input lg ${formik.errors.confirm_password &&
            formik.touched.confirm_password &&
            "error"
            } ${resetStatus === "pending" ? "disabled" : ""} hide-icon-input`}
        />
        {showConfirmPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowConfirmPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowConfirmPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.errors.confirm_password && formik.touched.confirm_password && (
          <span className="error-text">{formik?.errors?.confirm_password}</span>
        )}
      </div>
    </Auth>
  );
};

export default ResetPassword;
