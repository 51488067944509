import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddInvoiceNumberFormat from "./useAddInvoiceNumberFormat";

const AddInvoiceNumberFormat = ({ refetch }) => {
  const {
    formik,
    queryState,
    basicData,
    getFieldError,
    handleCloseModal,
    isEdit
  } = useAddInvoiceNumberFormat({ refetch });



  return (
    <div className={`row`}>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Branch
            </label>
            <Select
              id="branch_id"
              name="branch_id"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("branch_id") && " error"
              }`}
              classNamePrefix="pro-input"
              options={basicData?.branch ?? []}
              getOptionLabel={(option) => option?.branch_name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.branch_id}
              onBlur={formik.handleBlur("branch_id")}
              onChange={(value) => {
                formik?.setFieldValue("branch_id", value || null);
                formik?.setFieldValue("branch_prefix", value?.branch_prefix || null);
              }  
              }
              menuPlacement="auto"
              isDisabled={isEdit}
            />
            {getFieldError("branch_id") && (
              <span className="error-text">{getFieldError("branch_id")}</span>
            )}
          </div>
      <Input
          label={"Branch Prefix"}
          type="text"
          id="branch_prefix"
          name="branch_prefix"
          classbranch_prefix={`pro-input lg ${
            getFieldError("branch_prefix") && " error"
          }`}
          {...formik.getFieldProps("branch_prefix")}
          error={getFieldError("branch_prefix")}
          errorMessage={getFieldError("branch_prefix")}
        />
      <Input
          label={"Serial Number"}
          type="text"
          id="serial_number"
          name="serial_number"
          classserial_number={`pro-input lg ${
            getFieldError("serial_number") && " error"
          }`}
          {...formik.getFieldProps("serial_number")}
          error={getFieldError("serial_number")}
          errorMessage={getFieldError("serial_number")}
          disabled={isEdit}
        />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Year
        </label>
        <Select
          id="year"
          name="year"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("year") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.year ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.year}
          onBlur={formik.handleBlur("year")}
          onChange={(value) =>
            formik?.setFieldValue("year", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("year") && (
          <span className="error-text">{getFieldError("year")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Reset
        </label>
        <Select
          id="reset"
          name="reset"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("reset") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.reset ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.reset}
          onBlur={formik.handleBlur("reset")}
          onChange={(value) =>
            formik?.setFieldValue("reset", value || null)
          }
          menuPlacement="auto"
          isDisabled={isEdit}
        />
        {getFieldError("reset") && (
          <span className="error-text">{getFieldError("reset")}</span>
        )}
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {queryState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddInvoiceNumberFormat;
