import React from 'react'
import useMenus from '../Global/useMenus';
import CommonLayout from '../../pages/Layouts/CommonLayout';
import { Outlet } from 'react-router-dom';

const OverViews = () => {
    const { drawerMenu } = useMenus();
    return (
      <CommonLayout drawerMenu={drawerMenu}>
        <Outlet />
      </CommonLayout>
    );
}

export default OverViews