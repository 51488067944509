import { getAxiosInstance } from "../../../api";

export const globalRemarkTable = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/employee-dashboard/order/remarks-list?process_slug=${params?.slug}&item_detail_id=${params?.id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const globalRemarkTableRecheck = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/employee-dashboard/recheck/remarks-list?process_slug=${params?.slug}&recheck_item_detail_id=${params?.id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
