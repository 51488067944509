import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedItem: "",
  isEdit: false,
  itemData: [],
  serviceItems: [],
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = billingSlice.actions;

export default billingSlice.reducer;
