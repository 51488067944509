import { useEffect } from "react";

export const useItemField = (
  fields,
  itmIndex,
  formik,
  basicData,
  tempIds,
  tempPIds,
  isEdit
) => {
  let caseText;
  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue("qc_text", fields?.text);
    }
    // eslint-disable-next-line
  }, [isEdit]);
  let tempFilteredData = basicData?.data?.item_type?.filter(
    (item) => item?._id === formik?.values?.item_type_id
  );

  let qcFieldData = tempFilteredData?.[0]?.qc_fields?.filter(
    (item) => !tempIds?.includes(item?._id)
  );

  let priority = tempFilteredData?.[0]?.qc_fields?.map((item, index) => {
    return {
      id: index + 1,
      name: index + 1,
    };
  });

  let sectionU = [
    {
      id: 1,
      name: "Top"
    },
    {
      id: 2,
      name: "Middle"
    },
    {
      id: 3,
      name: "Bottom"
    }
  ]

  let priorityU = priority?.filter((item) => !tempPIds?.includes(item?.id));

  let fieldObj = tempFilteredData?.[0]?.qc_fields?.filter(
    (item) => item?._id === fields?.field_id
  );
  let keyFontCaseObj = basicData?.data?.font_case?.filter(
    (item) => item?.id === fields?.key_font_case
  );
  let valueFontCaseObj = basicData?.data?.font_case?.filter(
    (item) => item?.id === fields?.value_font_case
  );
  let priorityObj = priority?.filter(
    (item) => item?.id === formik?.values?.item_fields[itmIndex]?.priority
  );
  let sectionObj = sectionU?.filter(
    (item) => item?.id === formik?.values?.item_fields[itmIndex]?.section
  );

  const handleRemoveField = (index) => {
    const data = formik?.values?.item_fields?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("item_fields", data);
  };

  const titleCase = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .reduce(
        (s, c) => s + "" + (c.charAt(0).toUpperCase() + c.slice(1) + " "),
        ""
      );
  };
  const camelCase = (string) => {
    let ans = string.toLowerCase();
    return ans
      .split(" ")
      .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
  };
  const sentenceCase = (string) => {
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
  };
  switch (fields?.font_case) {
    case 1:
      caseText = formik?.values?.item_fields?.[itmIndex]?.text.toUpperCase();
      break;
    case 2:
      caseText = formik?.values?.item_fields?.[itmIndex]?.text.toLowerCase();
      break;
    case 3:
      caseText = titleCase(formik?.values?.item_fields?.[itmIndex]?.text);
      break;
    case 4:
      caseText = sentenceCase(formik?.values?.item_fields?.[itmIndex]?.text);
      break;
    case 5:
      caseText = camelCase(formik?.values?.item_fields?.[itmIndex]?.text);
      break;
    default:
      caseText = formik?.values?.item_fields?.[itmIndex]?.text;
      break;
  }

  return {
    caseText,
    priorityU,
    fieldObj,
    keyFontCaseObj,
    valueFontCaseObj,
    priorityObj,
    qcFieldData,
    handleRemoveField,
    sectionU,
    sectionObj
  };
};

export default useItemField;
