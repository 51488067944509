import React from "react";
import { useSelector } from "react-redux";
import { BigTemplateA } from "./bigTemplateA";
import { BigTemplateC } from "./bigTemplateC";

const BigFront = ({ bigFrontPrintRef }) => {

  const { printingData } = useSelector((state) => state.printing);

  return (
    <div ref={bigFrontPrintRef}>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>DHC Card</title>

      {printingData?.map((value, index) => {
        return (
          <div 
            style={{ 
              display: "flex", 
              paddingTop : index % 2 === 0 ? 0 : 30 ,
              fontFamily: "'Graphik',san-serif",
              columnGap : 140,
            }} 
            key={index} id="element-to-print"
            >
              {value?.center_stone_side_stone ? (
                <BigTemplateC value={value}/>
              ) : (
                <BigTemplateA value={value}/>
              )}

            <div
              style={{
                width: 472.44,
                minWidth: 472.44,
                maxWidth: 472.44,
                height : 354.33,
                maxHeight : 354.33,
                minHeight : 354.33,
                backgroundColor: "#ffffff",
                border : "1px solid #f3f3f3",
              }}
            >
              <img
                src={value?.back_image ?? ""}
                alt="Big card image1"
                width={472.44}
                height={472.44}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BigFront;
