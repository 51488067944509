import {
  DataContainer,
  Image,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCVD from "./useCVD";
import Style from "./cvd.module.scss";
import OrderCardListing from "../../Global/OrderCardListing";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form1Print from "./ReportPrint/Form1/Form1Print";
import Form2Print from "./ReportPrint/Form2/Form2print";
import GliList from "./GliList";

const CVD = () => {
  const {
    cvdData,
    showForm,
    itemID,
    showValues,
    allValues,
    valueType,
    formType,
    isLoading,
    totalLength,
    statusData,
    activeCard,
    form1PrintRef,
    form2PrintRef,
    apiStatus,
    showGli,
    gliNumber,
    handlePrintClick,
    handleConfirm,
    handleLoadMore,
    setIsLoading,
    handleCardClick,
    handleSearch,
  } = useCVD();

  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row pro-mb-4">
          <div className="col-auto">
            <SearchFilters
              showDateRange={false}
              onSearchInput={handleSearch}
              showFilters={true}
              showActions={false}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className={`col-xxl-5 col-5 ${Style.card_main_wrapper}`}>
            <div className={`pro-d-flex scroll-lg ${Style.card_flow_wrap}`}>
              <OrderCardListing
                hasButton={false}
                data={cvdData}
                isLoading={isLoading}
                totalLength={totalLength}
                activeCard={activeCard}
                apiStatus={apiStatus}
                setIsLoading={setIsLoading}
                handleChange={handleCardClick}
                handleLoadMore={handleLoadMore}
                extaClassName={Style.cvd_list}
              />
            </div>
          </div>
          {showForm ? (
            <div className={`col ${Style.table_main_wrapper}`}>
              <h6 className="pro-ttl h6">{itemID}</h6>
              {formType === "Form1" ? (
                <Form1 statusData={statusData} handleConfirm={handleConfirm} />
              ) : formType === "Form2" ? (
                <Form2 statusData={statusData} handleConfirm={handleConfirm} />
              ) : (
                <h6 className="pro-ttl h6">No forms </h6>
              )}
            </div>
          ) : showValues ? (
            <div className={`col ${Style.table_main_wrapper}`}>
              <div
                className={`pro-d-flex pro-justify-between pro-items-center pro-mb-2`}
              >
                <h6 className="pro-ttl pro-mb-0 h6">{itemID}</h6>

                <button
                  className="pro-btn pro-btn-outline"
                  onClick={() => handlePrintClick(valueType)}
                >
                  <span className="material-symbols-outlined">print</span>
                  <span>Print</span>
                </button>
              </div>
              {valueType === 1 ? (
                <>
                  {Object?.keys(allValues || {})?.map((key) => {
                    if (key === "image") {
                      return (
                        <div className={`${Style.diamond_img_wrap} pro-mt-5`}>
                          <Image
                            height={200}
                            width={200}
                            alt={"key"}
                            src={allValues?.image?.path}
                          />
                        </div>
                      );
                    } else {
                      const inputString = key;
                      const words = inputString.split("_");
                      const capitalizedWords = words
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                      return (
                        <p className="pro-mb-1 pro-fw-medium">
                          {capitalizedWords} :{" "}
                          <span className="pro-fw-bolder pro-font-">
                            {allValues[key]}
                          </span>
                        </p>
                      );
                    }
                  })}
                </>
              ) : valueType === 2 ? (
                <div className="row gy-3 gx-3">
                  {Object?.keys(allValues || {})?.map((key) => {
                    const formatKey = (key) => {
                      // Remove underscores and convert to uppercase
                      return key.replace(/_/g, " ").toUpperCase();
                    };
                    const formatSubKey = (subKey) => {
                      // Remove underscores and capitalize the first letter
                      return subKey
                        .replace(/_/g, " ")
                        .replace(/\w\S*/g, (txt) => {
                          return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                          );
                        });
                    };
                    return (
                      <div key={key} className="col-6">
                        <DataContainer>
                          <div className={Style.content_box}>
                            <h6 className="h6 pro-ttl">{formatKey(key)}</h6>
                            {Object.keys(allValues[key]).map((subKey) => (
                              <p key={subKey} className="pro-mb-0">
                                {formatSubKey(subKey)} :{" "}
                                <b>{allValues[key][subKey]}</b>
                              </p>
                            ))}
                          </div>
                        </DataContainer>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : showGli ? (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <GliList gliNumber={gliNumber} />
            </div>
          ) : (
            <div className="col">{<h3>Select One</h3>}</div>
          )}
        </div>
      </div>
      {/* prints */}
      <div className={Style.prints}>
        <Form1Print form1PrintRef={form1PrintRef} />
        <Form2Print form2PrintRef={form2PrintRef} />
      </div>
    </>
  );
};

export default CVD;
