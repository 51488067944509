import React from "react";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useAddCertificatePrefix from "./useAddCertificatePrefix";
import Style from "./addCertficatePrefix.module.scss";
import Select from "react-select";

const AddCertificatePrefix = (refetch) => {
  const {
    filteredOptions,
    formik,
    basicData,
    isEdit,
    exampleText,
    dayChecked,
    monthChecked,
    yearChecked,
    goldBasedChecked,
    platinumBasedChecked,
    checkboxFields,
    // certifecatePrefixData,
    getFieldError,
    handleInputChange,
    handleCloseModal,
    handleCheckboxChange,
    setDayChecked,
    setMonthChecked,
    setYearChecked,
    setGoldBasedChecked,
    setPlatinumBasedChecked,
  } = useAddCertificatePrefix(refetch);

  
  const renderCheckboxField = (
    id,
    label,
    checked,
    onChange,
    orderFieldName,
    disable,
    index
  ) => (
    <div className="pro-d-flex pro-items-center pro-py-2 pro-gap-2" key={index}>
      <div className="pro-check-box" key={id}>
        <input
          type="checkbox"
          className="pro-check"
          id={id}
          name="pro-checkbox"
          onChange={(e) => handleCheckboxChange(e, onChange, id, checked)}
          checked={checked}
          disabled={disable}
        />
        <label htmlFor={id} className="pro-check-label">
          {label}
        </label>
      </div>
      {/* eslint-disable-next-line eqeqeq */}
      {checked && !(id == "gold_based" || id == "platinum_based") && (
        <>
          {label === "Certification" && (
            <Input
              placeholder={"Character"}
              type="text"
              id="character"
              name="character"
              className={`pro-input lg pro-mt-4 ${
                getFieldError("character") && " error"
              }`}
              {...formik.getFieldProps("character")}
              error={getFieldError("character")}
              errorMessage={getFieldError("character")}
            />
          )}
          {id === "dhc_branch" && (
            <Input
              placeholder={"Prefix"}
              type="text"
              id="certificate_prefix_text"
              name="certificate_prefix_text"
              className={`pro-input lg pro-mt-4 ${
                getFieldError("certificate_prefix_text") && " error"
              }`}
              {...formik.getFieldProps("certificate_prefix_text")}
              error={getFieldError("certificate_prefix_text")}
              errorMessage={getFieldError("certificate_prefix_text")}
            />
          )}
          <Select
            id={orderFieldName}
            name={orderFieldName}
            placeholder={"Order"}
            className={`select-class-1 pro-input lg ${
              getFieldError(orderFieldName) && " error"
            }`}
            classNamePrefix="pro-input"
            options={filteredOptions}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={formik?.values?.[orderFieldName]}
            onBlur={formik.handleBlur(orderFieldName)}
            onChange={handleInputChange(orderFieldName, checked)}
            menuPlacement="auto"
          />
        </>
      )}
    </div>
  );

  const renderSelectField = (
    id,
    name,
    placeholder,
    options,
    getOptionLabel,
    getOptionValue,
    value,
    onBlur,
    onChange,
    disable
  ) => {
    
    return (
      <React.Fragment key={id}>
        <Select
          id={id}
          key={id}
          name={name}
          placeholder={placeholder}
          className={`pro-input lg ${getFieldError(name) && " error"}`}
          classNamePrefix="pro-input"
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          isDisabled={disable}
          menuPlacement="auto"
        />
      </React.Fragment>
    );
  };

  const renderSerialSelect = (id, name) => (
    
    <div className="pro-w-100">
      <Select
        id={`${id}_order`}
        name={`${name}_order`}
        placeholder="Order"
        className={`select-class-1 pro-input lg ${
          getFieldError(`${name}_order`) && "error"
        }`}
        classNamePrefix="pro-input"
        options={filteredOptions}
        getOptionLabel={(option) => option?.label}
        getOptionValue={(option) => option?.value}
        value={formik?.values[`${name}_order`]}
        onBlur={formik.handleBlur(`${name}_order`)}
        onChange={handleInputChange(`${name}_order`, "")}
        menuPlacement="auto"
      />
    </div>
  );

  const renderInputField = (
    label,
    type,
    id,
    name,
    error,
    errorMessage,
    ...props
  ) => (
    <div className="pro-d-flex pro-w-100 pro-gap-3">
      <div className="pro-d-flex pro-flex-column pro-w-100">
        <input
          type={type}
          id={id}
          name={name}
          className={`select-serial-class-1 pro-input lg ${
            getFieldError(name) && "error"
          }`}
          {...formik.getFieldProps(name)}
          {...props}
          disabled={isEdit}
        />
        {getFieldError(name) && (
          <span className="error-text">{errorMessage}</span>
        )}
      </div>
      {renderSerialSelect(id, name)}
    </div>
  );
  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <div className="pro-check-box pro-py-2">
            <input
              type="checkbox"
              className="pro-check"
              id="defult"
              name="pro-checkbox"
              checked={Boolean(formik.values?.format_type === 1) ?? false}
              onChange={(e) => {
                formik.setFieldValue(`format_type`, e?.target?.checked ? 1 : 2);
                formik?.setFieldValue("serial_number_basis", {
                  id: 2,
                  name: "Dhc Branch",
                });
              }}
              disabled={isEdit}
            />
            <label htmlFor="defult" className="pro-check-label">
              Default
            </label>
          </div>
        </div>

        {formik.values?.format_type === 2 && (
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Shop
            </label>
            {renderSelectField(
              "shop_id",
              "shop_id",
              "Select",
              basicData?.shop,
              (option) => option?.name,
              (option) => option?._id,
              formik?.values?.shop_id,
              formik.handleBlur("shop_id"),
              (value) => formik?.setFieldValue("shop_id", value || null),
              isEdit
            )}
            {getFieldError("shop_id") && (
              <span className="error-text">{getFieldError("shop_id")}</span>
            )}
          </div>
        )}

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Serial number reset
          </label>
          {renderSelectField(
            "serial_number_reset",
            "serial_number_reset",
            "Select",
            basicData?.serial_number_reset,
            (option) => option?.name,
            (option) => option?.id,
            formik?.values?.serial_number_reset,
            formik.handleBlur("serial_number_reset"),
            (value) =>
              formik?.setFieldValue("serial_number_reset", value || null),
            isEdit
          )}
          {getFieldError("serial_number_reset") && (
            <span className="error-text">
              {getFieldError("serial_number_reset")}
            </span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Serial number basis
          </label>
          {renderSelectField(
            "serial_number_basis",
            "serial_number_basis",
            "Select",
            basicData?.serial_number_basis,
            (option) => option?.name,
            (option) => option?.id,
            formik?.values?.serial_number_basis,
            formik.handleBlur("serial_number_basis"),
            (value) =>
              formik?.setFieldValue("serial_number_basis", value || null),
            formik?.values?.format_type === 1 || isEdit
          )}
          {getFieldError("serial_number_basis") && (
            <span className="error-text">
              {getFieldError("serial_number_basis")}
            </span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Status
          </label>
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("status") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.status_list ?? []
            }
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.status}
            onBlur={formik.handleBlur("status")}
            onChange={(value) => formik?.setFieldValue("status", value || null)}
            menuPlacement="auto"
          />
          {getFieldError("status") && (
            <span className="error-text">{getFieldError("status")}</span>
          )}
        </div>
        {formik?.values?.serial_number_basis?.id === 2 && (
          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="selected_dhc_branch"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Branch
            </label>
            <Select
              id="selected_dhc_branch"
              name="selected_dhc_branch"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("selected_dhc_branch") && " error"
              }`}
              classNamePrefix="pro-input"
              options={basicData?.branch ?? []}
              getOptionLabel={(option) => option?.branch_name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.selected_dhc_branch}
              onBlur={formik.handleBlur("selected_dhc_branch")}
              onChange={(value) =>
                formik?.setFieldValue("selected_dhc_branch", value || null)
              }
              menuPlacement="auto"
              // isDisabled={isEdit}
            />

            {getFieldError("selected_dhc_branch") && (
              <span className="error-text">
                {getFieldError("selected_dhc_branch")}
              </span>
            )}
          </div>
        )}
        <div className="input-wrap pro-mb-4">
          <label className="pro-font-sm pro-mb-1 pro-fw-medium">Serial</label>
          {renderInputField(
            "Serial",
            "text",
            "serial_number",
            "serial_number",
            getFieldError("serial_number"),
            getFieldError("serial_number")
          )}
        </div>

        {checkboxFields.map((field, index) =>
          renderCheckboxField(
            field.id,
            field.label,
            field.state,
            field.setState,
            field.name,
            false,
            index
          )
        )}
        <div className="pro-py-5">
          <div className="pro-py-2">
            <div className="row gx-2">
              <div className="col-md-4">
                <div className="pro-check-box">
                  <input
                    type="checkbox"
                    className="pro-check"
                    id="day"
                    name="pro-checkbox"
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        setDayChecked,
                        "day",
                        dayChecked
                      )
                    }
                    checked={dayChecked}
                    disabled={false}
                  />
                  <label htmlFor="day" className="pro-check-label">
                    Day
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                {dayChecked && (
                  <div className="input-wrap">
                    {renderSelectField(
                      "day",
                      "day",
                      "Select",
                      basicData?.day,
                      (option) => option?.name,
                      (option) => option?.id,
                      formik?.values?.day,
                      formik.handleBlur("day"),
                      (value) => formik?.setFieldValue("day", value || null),
                      false
                    )}
                    {getFieldError("day") && (
                      <span className="error-text">{getFieldError("day")}</span>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {dayChecked && (
                  <Select
                    id="day_order"
                    name="day_order"
                    placeholder={"Order"}
                    className={`select-class-1 pro-input lg ${
                      getFieldError("day_order") && " error"
                    }`}
                    classNamePrefix="pro-input"
                    options={filteredOptions}
                    getOptionLabel={(option) => option?.label}
                    getOptionValue={(option) => option?.value}
                    value={formik?.values?.["day_order"]}
                    onBlur={formik.handleBlur("day_order")}
                    onChange={handleInputChange("day_order", dayChecked)}
                    menuPlacement="auto"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="pro-py-2">
            <div className="row gx-2">
              <div className="col-md-4">
                <div className="pro-check-box">
                  <input
                    type="checkbox"
                    className="pro-check"
                    id="month"
                    name="pro-checkbox"
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        setMonthChecked,
                        "month",
                        monthChecked
                      )
                    }
                    checked={monthChecked}
                  />
                  <label htmlFor="month" className="pro-check-label">
                    Month
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                {monthChecked && (
                  <div className="input-wrap">
                    {renderSelectField(
                      "month",
                      "month",
                      "Select",
                      basicData?.month,
                      (option) => option?.name,
                      (option) => option?.id,
                      formik?.values?.month,
                      formik.handleBlur("month"),
                      (value) => formik?.setFieldValue("month", value || null),
                      false
                    )}
                    {getFieldError("month") && (
                      <span className="error-text">
                        {getFieldError("month")}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {monthChecked && (
                  <Select
                    id="month_order"
                    name="month_order"
                    placeholder={"Order"}
                    className={`select-class-1 pro-input lg ${
                      getFieldError("month_order") && " error"
                    }`}
                    classNamePrefix="pro-input"
                    options={filteredOptions}
                    getOptionLabel={(option) => option?.label}
                    getOptionValue={(option) => option?.value}
                    value={formik?.values?.["month_order"]}
                    onBlur={formik.handleBlur("month_order")}
                    onChange={handleInputChange(
                      "month_order",
                      monthChecked
                    )}
                    menuPlacement="auto"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="pro-py-2">
            <div className="row gx-2">
              <div className="col-md-3">
                <div className="pro-check-box">
                  <input
                    type="checkbox"
                    className="pro-check"
                    id="year"
                    name="pro-checkbox"
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        setYearChecked,
                        "year",
                        yearChecked
                      )
                    }
                    checked={yearChecked}
                    disabled={false}
                  />
                  <label htmlFor="year" className="pro-check-label">
                    Year
                  </label>
                </div>
              </div>
              <>
                {yearChecked && (
                  <>
                    <div className="col-md-3">
                      <div className="input-wrap">
                        {renderSelectField(
                          "year_type",
                          "year_type",
                          "Select",
                          basicData?.year_type,
                          (option) => option?.name,
                          (option) => option?.id,
                          formik?.values?.year_type,
                          formik.handleBlur("year_type"),
                          (value) =>
                            formik?.setFieldValue("year_type", value || ""),
                          false
                        )}
                        {getFieldError("year_type") && (
                          <span className="error-text">
                            {getFieldError("year_type")}
                          </span>
                        )}
                      </div>
                    </div>
                    {formik?.values?.year_type?.id === "character" ? (
                      <div className="col-md-3">
                        <Input
                          type="text"
                          id="year_char"
                          name="year_char"
                          className={`pro-input lg ${
                            getFieldError("year_char") && " error"
                          }`}
                          {...formik.getFieldProps("year_char")}
                          error={getFieldError("year_char")}
                          errorMessage={getFieldError("year_char")}
                          placeholder="Character"
                        />
                      </div>
                    ) : (
                      <div className="col-md-3">
                        <div className="input-wrap">
                          {renderSelectField(
                            "year",
                            "year",
                            "Select",
                            basicData?.year,
                            (option) => option?.name,
                            (option) => option?.id,
                            formik?.values?.year,
                            formik.handleBlur("year"),
                            (value) =>
                              formik?.setFieldValue("year", value || null),
                            false
                          )}
                          {getFieldError("year") && (
                            <span className="error-text">
                              {getFieldError("year")}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
              <div className="col-md-3">
                {yearChecked && (
                  <Select
                    id="year_order"
                    name="year_order"
                    placeholder={"Order"}
                    className={`select-class-1 pro-input lg ${
                      getFieldError("year_order") && " error"
                    }`}
                    classNamePrefix="pro-input"
                    options={filteredOptions}
                    getOptionLabel={(option) => option?.label}
                    getOptionValue={(option) => option?.value}
                    value={formik?.values?.["year_order"]}
                    onBlur={formik.handleBlur("year_order")}
                    onChange={handleInputChange(
                      "year_order",
                      yearChecked
                    )}
                    menuPlacement="auto"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-w-100">
        {renderCheckboxField(
          "gold_based",
          "Gold Based",
          goldBasedChecked,
          setGoldBasedChecked,
          "",
          platinumBasedChecked
        )}
        {renderCheckboxField(
          "platinum_based",
          "Platinum Based",
          platinumBasedChecked,
          setPlatinumBasedChecked,
          "",
          goldBasedChecked
        )}
        {!goldBasedChecked &&
          !platinumBasedChecked &&
          getFieldError("gold_based") && (
            <span className="goldBasedErr">{getFieldError("gold_based")}</span>
          )}
      </div>

      <div>{exampleText && <p>Preview : {exampleText}</p>}</div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn ${Style.button}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-px-5 pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </>
  );
};

export default AddCertificatePrefix;
