import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/ProtectRoute";

const Index = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      navigate("/home");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
