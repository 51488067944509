import { useRef } from "react";
import Style from "./Auth.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { Outlet } from "react-router-dom";
import Assets from "../../assets/Assets";

const AuthModule = () => {
  const emailRef = useRef("");

  return (
    <div className={Style.root}>
      <div className={Style.root_inner}>
        <div className={`${Style.logoWrapper} pro-pb-5`}>
          <Image
            src={Assets.LOGONEW}
            width={350.51}
            height={75.03}
            alt={`DHC Logo`}
          />
        </div>
        <div className={`${Style.auth} pro-pt-5`}>
          <Outlet context={{ emailRef }} />
        </div>
      </div>
    </div>
  );
};

export default AuthModule;
