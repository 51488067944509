import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../components/Global/OrderColumn";
import useSource from "./useSource";
import AddSource from "./AddSource";

const Source = () => {
  const {
    sourceState,
    showEditModal,
    tableFields,
    sourceList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateSourceFields,
    handleClearClick,
    handlePageSize,
    refetch,
  } = useSource();
  return (
    <div>
      <HeadingGroup
        title={"All Sources"}
        className={`pro-mb-4`}
        buttonTitle={sourceList?.data?.create_permission && "Add New"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: sourceState?.search }}
          editable={sourceList?.data?.edit_permission}
          deletable={sourceList?.data?.delete_permission}
        />
        <Table
          multiSelect={false}
          data={sourceList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          clear={sourceState.clearSelection}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={sourceState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={true}
          showCheckBox={true}
        />
        {sourceList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={sourceState?.currentPage}
            totalPageCount={Math.ceil(
              sourceList.data.total_count / sourceState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={sourceState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected source ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={sourceState?.showAddSourceModal}
          handleClose={handleModalClick}
          title={"Add Source"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <AddSource refetch={refetch} />
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={sourceList?.data?.fields}
              moduleId={sourceList?.data?.module_id}
              updateData={updateSourceFields}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Source;
