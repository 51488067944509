import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import Style from "./remark_card.module.scss";

const RemarkCard = ({ formik, formData, itmIndex }) => {
  let typeObj = formData?.type?.filter(
    (item) => item?.name === formik?.values?.group[itmIndex].type
  );

  const handleRemoveField = (index) => {
    const data = formik?.values?.group?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("group", data);
  };

  const handleCountChange = (e, index) => {
    if (formik?.values?.status !== 2) {
      if (
        Object.keys(formik?.values)?.includes("number_of_stones") ||
        Object.keys(formik?.values)?.includes("diamond_number")
      ) {
        let mainValue =
          formik?.values?.diamond_number ?? formik?.values?.number_of_stones;
        formik.setFieldValue(`group.[${index}].count`, e.target.value);
        if (mainValue !== 0) {
          let tempValue = (e.target.value / mainValue) * 100;
          const newValue =
            isNaN(tempValue) ||
            !isFinite(tempValue) ||
            tempValue === Infinity ||
            tempValue === -Infinity
              ? ""
              : tempValue;

          const roundedValue =
            typeof newValue === "number" && !Number.isInteger(newValue)
              ? newValue.toFixed(2)
              : newValue;

          formik.setFieldValue(`group[${index}].percentage`, roundedValue);
        }
      } else {
        formik.setFieldValue(`group.[${index}].count`, e.target.value);
      }
    }
  };

  return (
    <div className={Style.container} key={itmIndex}>
      {formik?.values?.group?.length !== 1 && (
        <span
          className={`${Style.corner_button} btn-close`}
          onClick={() => handleRemoveField(itmIndex)}
        ></span>
      )}
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor={`type${itmIndex}`}
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Type
        </label>
        <Select
          id={`type${itmIndex}`}
          name={`type${itmIndex}`}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.touched?.group?.[itmIndex]?.type &&
            formik.errors?.group?.[itmIndex]?.type &&
            "error"
          }`}
          classNamePrefix="pro-input"
          options={formData?.type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          onBlur={formik?.handleBlur(`group.${itmIndex}.type`)}
          isMulti={false}
          onChange={(value) =>
            formik?.setFieldValue(`group.${itmIndex}.type`, value?.name || "")
          }
          menuPlacement="auto"
          value={typeObj}
        />
        {formik.touched?.group?.[itmIndex]?.type &&
          formik.errors?.group?.[itmIndex]?.type && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.type}
            </span>
          )}
      </div>
      {
        formik.values?.group?.[itmIndex]?.type === "OTHERS" && (

          <Input
        label={"Others"}
        type="text"
        id={`others${itmIndex}`}
        name={`others${itmIndex}`}
        className={`pro-input lg ${
          formik.touched?.group?.[itmIndex]?.others &&
          formik.errors?.group?.[itmIndex]?.others &&
          "error"
        }`}
        onChange={(e) =>
          formik?.setFieldValue(`group.${itmIndex}.others`, e?.target?.value)
          // handleOthersChange(e, itmIndex)
        }
        error={
          formik.touched?.group?.[itmIndex]?.others &&
          formik.errors?.group?.[itmIndex]?.others && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.others}
            </span>
          )
        }
        errorMessage={
          formik.touched?.group?.[itmIndex]?.others &&
          formik.errors?.group?.[itmIndex]?.others && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.others}
            </span>
          )
        }
        onBlur={formik?.handleBlur(`group.${itmIndex}.others`)}
        value={formik.values?.group?.[itmIndex]?.others}
      />
        )
      }
      
      <Input
        label={"Count"}
        type="text"
        id={`count${itmIndex}`}
        name={`count${itmIndex}`}
        className={`pro-input lg ${
          formik.touched?.group?.[itmIndex]?.count &&
          formik.errors?.group?.[itmIndex]?.count &&
          "error"
        }`}
        onChange={(e) =>
          // formik?.setFieldValue(`group.${itmIndex}.count`, e?.target?.value)
          handleCountChange(e, itmIndex)
        }
        error={
          formik.touched?.group?.[itmIndex]?.count &&
          formik.errors?.group?.[itmIndex]?.count && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.count}
            </span>
          )
        }
        errorMessage={
          formik.touched?.group?.[itmIndex]?.count &&
          formik.errors?.group?.[itmIndex]?.count && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.count}
            </span>
          )
        }
        onBlur={formik?.handleBlur(`group.${itmIndex}.count`)}
        value={formik.values?.group?.[itmIndex]?.count}
      />
      <Input
        label={"Percentage"}
        type="text"
        id={`percentage${itmIndex}`}
        name={`percentage${itmIndex}`}
        className={`pro-input lg ${
          formik.touched?.group?.[itmIndex]?.percentage &&
          formik.errors?.group?.[itmIndex]?.percentage &&
          "error"
        }`}
        onChange={(e) =>
          formik?.setFieldValue(
            `group.${itmIndex}.percentage`,
            e?.target?.value
          )
        }
        error={
          formik.touched?.group?.[itmIndex]?.percentage &&
          formik.errors?.group?.[itmIndex]?.percentage && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.percentage}
            </span>
          )
        }
        errorMessage={
          formik.touched?.group?.[itmIndex]?.percentage &&
          formik.errors?.group?.[itmIndex]?.percentage && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.percentage}
            </span>
          )
        }
        onBlur={formik?.handleBlur(`group.${itmIndex}.percentage`)}
        value={
          !isFinite(formik.values?.group?.[itmIndex]?.percentage)
            ? ""
            : formik.values?.group?.[itmIndex]?.percentage
        }
      />
    </div>
  );
};

export default RemarkCard;
