import { getAxiosInstance } from "../../../api";

export const addToRecheckList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/recheck/item`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const reAssignMember = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/employee-dashboard/order/employee-work-assign`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateStatus = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/stock-receipt/update-stock-status`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const reAssignRecheckMember = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/employee-dashboard/recheck/employee-work-assign`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addToReissueList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/recheck/reissue`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getCommittedList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/reassign/process-commited`, {params});
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const printReport = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/qc-reports/recheck?id=${params?.id}`, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const printSlip = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/recheck/slip?id=${params?.id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadExcelFiles = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/stock-receipt/excel-upload`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getExportedData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `stock-receipt/download-excel-format?receipt_id=${params?.receipt_id}`,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
