import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCertificatePrefix from "./useCertificatePrefix";
import { FaSort } from "react-icons/fa";
import AddCertificatePrefix from "./AddCertificatePrefix";
import OrderColumn from "../../../components/Global/OrderColumn";
import Style from "./AddCertificatePrefix/addCertficatePrefix.module.scss";

const CertificatePrefix = () => {
  const {
    certificatePrefixState,
    certificatePrefixList,
    isFetching,
    isLoading,
    tableFields,
    showEditModal,
    paginationOptions,
    refetch,
    getRow,
    handleDelete,
    handleModalClick,
    handleDeleteModal,
    handleEditAction,
    handleEditColumnsClick,
    handlePageSize,
    handlePagination,
    handleSort,
    updateCertificatePrefixFields,
  } = useCertificatePrefix();

  return (
    <div>
      <HeadingGroup
        title={"Certificate Prefix"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          searchable={false}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
        />
        <Table
          multiSelect={false}
          data={certificatePrefixList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={certificatePrefixState.clearSelection}
          perpage={certificatePrefixState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={true}
          showCheckBox={true}
        />
        {certificatePrefixList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={certificatePrefixState?.currentPage}
            totalPageCount={Math.ceil(
              certificatePrefixList.data.total_count /
                certificatePrefixState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={certificatePrefixState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected certificate prefix ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={certificatePrefixList?.data?.fields}
              moduleId={certificatePrefixList?.data?.module_id}
              updateData={updateCertificatePrefixFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          className={Style.offCanvas}
          show={certificatePrefixState?.showAddCertificatePrefixModal}
          handleClose={handleModalClick}
          title={
            certificatePrefixState?.isEdit
              ? "Update Certificate Prefix"
              : "Add Certificate Prefix"
          }
          closeicon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddCertificatePrefix refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default CertificatePrefix;
