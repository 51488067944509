import { useDispatch } from "react-redux";
import { useGetGliListsRecheckQuery } from "../../../../store/queries/Printing";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import { useFormik } from "formik";
import { useState } from "react";

const useMultiGliPrint = () => {
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  const { data: gliList } = useGetGliListsRecheckQuery({
    process_slug: slug,
  });

  const [gliItems, setGliItems] = useState([]);

  const uniqueItemTypes = [
    ...new Map(
      gliList?.data?.data?.map((item) => [
        item.item_type,
        { name: item.item_type, id: item._id },
      ])
    ).values(),
  ];

  const formik = useFormik({
    initialValues: {
      item_type: "",
      gli: [],
    },
    validate: (values) => {
      let errors = {};
      if (!values?.gli || values?.gli.length === 0) {
        errors.gli = "Select one or more GLI(s)";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const Ids = values?.gli?.map((val) => val?._id);
      dispatch(
        updateConfig((state) => {
          state.selectedId = Ids;
          state.showTable = true;
          state.isMultiGliPrint = false;
        })
      );
      setSubmitting(false);
    },
  });

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.isMultiGliPrint = false;
        state.isMultiPrint = false;
      })
    );
  };

  const handleChange = (value) => {
    formik.setFieldValue("gli", value);
  };
  const handleItemChange = (val) => {
    formik?.setFieldValue("item_type", val);
    formik.setFieldValue("gli", []);
    const items = gliList?.data?.data?.filter(
      (v) => v?.item_type === val?.name
    );
    setGliItems(items);
  };

  return {
    formik,
    gliList,
    gliItems,
    uniqueItemTypes,
    handleChange,
    handleCancel,
    handleItemChange,
  };
};

export default useMultiGliPrint;
