import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../components/Global/OrderColumn";
import useProcess from "./useProcess";
import AddProcess from "./AddProcess";
const Process = () => {
  const {
    processState,
    showEditModal,
    tableFields,
    processList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateProcessFields,
    handleClearClick,
    handlePageSize,
    refetch,
  } = useProcess();
  return (
    <div>
      <HeadingGroup title={"Process"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: processState?.search }}
        />
        <Table
          multiSelect={false}
          data={processList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={processState.clearSelection}
          perpage={processState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          showCheckBox={true}
          deletable={false}
          editable={processList?.data?.edit_permission}
        />
        {processList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={processState?.currentPage}
            totalPageCount={Math.ceil(
              processList.data.total_count / processState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={processState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected branch ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={processList?.data?.fields}
              moduleId={processList?.data?.module_id}
              updateData={updateProcessFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={processState?.showAddProcessModal}
          handleClose={handleModalClick}
          title={"Update Process "}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddProcess refetch={refetch} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default Process;
