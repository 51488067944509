import {
  DatePickerWithInput,
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEmployeeDetail from "./useEmployeeDetail";
import EmptyData from "../../../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import Select from "react-select";

const EmployeeDetail = () => {
  const {
    date,
    isFetching,
    isLoading,
    basicData,
    formik,
    reportState,
    paginationOptions,
    employeeDetailData,
    getRow,
    handlePageSize,
    handleDateChange,
    handlePagination,
    handleSearch,
    handleSort,
  } = useEmployeeDetail();
  return (
    <div>
      <HeadingGroup title={"Details"} className={`pro-mb-4`} />
      <div className="col-12">
        <div className="row gx-2 gy-2 pro-items-end">
          <div className={`col-3`}>
            <div className="input-wrap">
              <input
                type="text"
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder="Search this table"
                className={`pro-input icon-r lg`}
                value={reportState?.otherReport?.details?.search}
              />
              <span className="pro-icon">
                {<span className="material-symbols-outlined">search</span>}
              </span>
            </div>
          </div>
          <div className={`col-3`}>
            <DatePickerWithInput
              CalendarOutlineIcon={
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
              }
              range={true}
              initialValue={date}
              onDateChange={handleDateChange}
            ></DatePickerWithInput>
          </div>
          <div className="col-3">
            <div className="input-wrap ">
              <label
                htmlFor="order_type"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Order Type
              </label>
              <Select
                id="order_type"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                name="order_type"
                options={basicData?.order_types}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option?.name}
                value={formik?.values?.order_type}
                onChange={(value) => formik.setFieldValue("order_type", value)}
                menuPlacement="auto"
              />
            </div>
          </div>
        </div>
      </div>
      {employeeDetailData?.data?.data?.length > 0 ? (
        <div className="pro-mt-4 pro-pb-6">
          <Table
            multiSelect={false}
            data={employeeDetailData?.data?.data || []}
            uniqueID={"id"}
            fields={employeeDetailData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching || isLoading}
            perpage={reportState?.otherReport?.details?.currentPageSize}
            assignable={false}
            editable={false}
            deletable={false}
            showCheckBox={false}
          />
          {employeeDetailData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={reportState?.otherReport?.details?.currentPage}
              totalPageCount={Math.ceil(
                employeeDetailData?.data?.total_count /
                  reportState?.otherReport?.details?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default EmployeeDetail;
