import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEngraving from "./useEngraving";
import Style from "./engravingSection.module.scss";
import OrderCardListing from "../../Global/OrderCardListing";
import EngravingCertificate from "./EngravingCertificate";

const EngravingSectionRecheck = () => {
  const {
    gliNumber,
    showDetail,
    activeCard,
    engravingData,
    isLoading,
    totalLength,
    apiStatus,
    setIsLoading,
    handleSearch,
    handleChange,
    handleLoadMore,
  } = useEngraving();
  return (
    <>
      <div className="row pro-mb-4 gx-2">
        <div className="col-auto">
          <SearchFilters
            showDateRange={false}
            onSearchInput={handleSearch}
            showFilters={true}
            showActions={false}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
          />
        </div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-4 ${Style.card_main_wrapper}`}>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                data={engravingData}
                handleChange={handleChange}
                activeCard={activeCard}
                isLoading={isLoading}
                totalLength={totalLength}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          {showDetail === true && (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                <EngravingCertificate
                  gliNumber={gliNumber}
                  states={engravingData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EngravingSectionRecheck;
