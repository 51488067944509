import React from "react";
import useCardDetails from "./useCardDetails";
import { Table } from "@wac-ui-dashboard/wac_component_library";

const CardDetails = () => {
  const { empDetails, getRow } = useCardDetails();
  return (
    <div style={{ margin: 20 }}>
      <Table
        multiSelect={false}
        data={empDetails?.data || []}
        uniqueID={"_id"}
        fields={empDetails?.fields}
        getRow={getRow}
        assignable={false}
        deletable={false}
        showCheckBox={false}
      />
    </div>
  );
};

export default CardDetails;
