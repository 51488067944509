import React from "react";

import useAddShop from "./useAddShop";
import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import Style from "./addShop.module.scss";
const AddShop = (refetch) => {
  const { formik, tabs, activeTab, handleTabClick, renderTabContent } =
    useAddShop(refetch);
  return (
    <div id="element">
      <FormTabs
        propStyle={{ root: Style.formTab_root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      <form onSubmit={formik.handleSubmit}>{renderTabContent()}</form>
    </div>
  );
};

export default AddShop;
