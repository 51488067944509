import React from "react";
import Assets from "../../../../../assets/Assets";
import { useSelector } from "react-redux";

const PapperBack = React.forwardRef(({ papperBackPrintRef },ref) => {
  const { printingData } = useSelector((state) => state.printing);

  return (
    <div ref={papperBackPrintRef} style={{ width: "100%", height: "100%" }}>
      <title>DHC PDF</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {printingData?.map((value, index) => {
        return (
          <div
                key={index}
                style={{
                  // paddingTop:5,
                  height:"1080px",
                  overflow:'hidden',
                // backgroundColor: "red",                               
              }}  
            >
          <div
           // key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              width:'100%',
              height:'100%',
              margin:0,
              padding:0
            }}
            id="element-to-print"
          >
            <figure style={{
              height : "100%",
              maxWidth : 760,
              margin:0,
              padding:0
            }}>
                {/* <img src={Assets.PDF_BACK} style={{height:'100%', width:760, objectFit:'contain'}} alt="Back Image"/> */}
                <img src={value?.back_image} style={{height:'100%', width:760, objectFit:'contain'}} alt="back_image"/>
            </figure>
            <div style={{ pageBreakBefore: "always" }}></div>
          </div>
          </div>
         );
      })}
    </div>
  );
});

export default PapperBack;
