import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddDeliverySecModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedCardId: "",
  selectedRecheckCardId: "",
  selectedDeliverySection: "",
  isEdit: false,
  deliverySectionData: {
    Pending: [],
  },
  deliverySectionRecheckData: {
    Pending: [],
  },
  logoPreview: "",
  deliverySectionDataStatus: "idle",
  deliveryRecheckSectionDataStatus: "idle",
  deliveryChallanEditStatus: "idle",
  deliveryRecheckChallanEditStatus: "idle",
  total: {
    Pending: "",
  },
  printData: "",
  printChellanData: "",
  clear: false,
  showPrintEditModal: false,
  deliveryChallanEditData: {},
  deliveryRecheckChallanEditData: {},
};

export const getDeliveryListData = createAsyncThunk(
  "/delivery",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//delivery recheck section
export const getDeliveryRecheckListData = createAsyncThunk(
  "/delivery/recheck",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDeliveryChallanEditData = createAsyncThunk(
  "/delivery/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/delivery/edit?stock_receipt_id=${params?.stock_receipt_id}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDeliveryRecheckChallanEditData = createAsyncThunk(
  "/delivery/recheck/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/delivery/recheck/edit?stock_receipt_id=${params?.stock_receipt_id}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getDeliveryListData.pending]: (state) => {
      state.deliverySectionDataStatus = "pending";
    },
    [getDeliveryListData.fulfilled]: (state, action) => {
      // if (state.deliverySectionData.Pending.length) {
      //   state.deliverySectionData.Pending.concat(action.payload.data.data.data);
      // } else {
      //   state.deliverySectionData.Pending = action.payload.data.data.data;
      // }
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.deliverySectionData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });

      if(state.search != "") {
        
        if(state.currentPage == 1) {
          state.deliverySectionData.Pending = newData;
        }
        else {
          if(state.deliverySectionData.Pending?.length <= action.payload.data.data.total_count) {
            state.deliverySectionData.Pending = [...state.deliverySectionData.Pending, ...newData];
          }  
        }
        
      }
      else {
        state.deliverySectionData.Pending = combinedPending;
    } 
      // state.deliverySectionData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.deliverySectionDataStatus = "fulfilled";
    },
    [getDeliveryListData.rejected]: (state) => {
      state.deliverySectionDataStatus = "failed";
    },
    //recheck section
    [getDeliveryRecheckListData.pending]: (state) => {
      state.deliveryRecheckSectionDataStatus = "pending";
    },
    [getDeliveryRecheckListData.fulfilled]: (state, action) => {
      // if (state.deliverySectionRecheckData.Pending.length) {
      //   state.deliverySectionRecheckData.Pending.concat(
      //     action.payload.data.data.data
      //   );
      // } else {
      //   state.deliverySectionRecheckData.Pending =
      //     action.payload.data.data.data;
      // }
      // state.deliveryRecheckSectionDataStatus = "fulfilled";
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.deliverySectionRecheckData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.deliverySectionRecheckData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.deliveryRecheckSectionDataStatus = "fulfilled";
    },
    [getDeliveryRecheckListData.rejected]: (state) => {
      state.deliverySectionDataStatus = "failed";
    },

    // delivery challan edit section
    [getDeliveryChallanEditData.pending]: (state) => {
      state.deliveryChallanEditStatus = "pending";
    },
    [getDeliveryChallanEditData.fulfilled]: (state, action) => {
      state.deliveryChallanEditStatus = "fulfilled";
      state.deliveryChallanEditData = action.payload.data;
    },
    [getDeliveryChallanEditData.rejected]: (state) => {
      state.deliveryChallanEditStatus = "failed";
    },
    //delivery challan recheck edit
    [getDeliveryRecheckChallanEditData.pending]: (state) => {
      state.deliveryRecheckChallanEditStatus = "pending";
    },
    [getDeliveryRecheckChallanEditData.fulfilled]: (state, action) => {
      state.deliveryRecheckChallanEditStatus = "fulfilled";
      state.deliveryRecheckChallanEditData = action.payload.data;
    },
    [getDeliveryRecheckChallanEditData.rejected]: (state) => {
      state.deliveryRecheckChallanEditStatus = "failed";
    },
  },
});

export const { updateConfig, resetConfig } = deliverySlice.actions;

export default deliverySlice.reducer;
