import React from "react";
import Select from "react-select";
import useEditForm from "./useEditForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const EditForm = ({ refetch }) => {
  const { formik, paymentData, getFieldError, handleCloseModal } = useEditForm({
    refetch,
  });
  return (
    <div>
      <div className={`row`}>
        <Input
          label={"Amount"}
          type="text"
          id="amount"
          name="amount"
          className={`pro-input lg ${getFieldError("amount") && " error"}`}
          {...formik.getFieldProps("amount")}
          error={getFieldError("amount")}
          errorMessage={getFieldError("amount")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Payment Mode
          </label>
          <Select
            id="payment_mode"
            name="payment_mode"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("payment_mode") && " error"
            }`}
            classNamePrefix="pro-input"
            options={paymentData ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.payment_mode}
            onBlur={formik.handleBlur("payment_mode")}
            onChange={(value) =>
              formik?.setFieldValue("payment_mode", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("payment_mode") && (
            <span className="error-text">{getFieldError("payment_mode")}</span>
          )}
        </div>
        <Input
          label={"Tax Number"}
          type="text"
          id="tax_number"
          name="tax_number"
          className={`pro-input lg ${getFieldError("tax_number") && " error"}`}
          {...formik.getFieldProps("tax_number")}
          error={getFieldError("tax_number")}
          errorMessage={getFieldError("tax_number")}
        />
        <div>
          <div
            className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
          >
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              type="submit"
              onClick={formik.handleSubmit}
            >
              {"Update"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditForm;
