import React, { useState } from "react";
import { useGetApprovedListDataQuery } from "../../../../store/queries/Verification";
import { updateConfig as updateConfigGlobal } from "../../../../store/slices/Global";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Verification/approvedSlice";
import {
  getExportedData,
  getPrintBarCode,
  getVerificationEditBasicData,
} from "./api";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { getPrintData } from "../../../Orders/Order/api";
import Style from "../../verifications.module.scss";

const useApprovedDetails = ({ barCodeRef, officeSlipRef, StockReceiptRef }) => {
  const [basicData, setBasicData] = useState();
  const approvedState = useSelector((state) => state.approved);
  const { tableFields } = useSelector((state) => state.global);
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const {
    data: ApprovedDetailsList = {},
    isFetching,
    refetch,
  } = useGetApprovedListDataQuery({
    receipt_id: approvedState.receipt_id,
    search: approvedState.search,
    page_size: approvedState.currentPageSize,
    page: approvedState.currentPage,
  });
  useEffect(() => {
    dispatch(
      updateConfigGlobal((state) => {
        state.tableFields = ApprovedDetailsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [ApprovedDetailsList]);

  useEffect(() => {
    if (approvedState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
          state.showExcelUploadModal = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [approvedState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleCopyText = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success("Copied");
    } catch (error) {
      toast.error("Failed to copy");
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
        certificate_number : (field, data) => 
          data?.[field] ? (
            <div className="pro-d-flex pro-items-center pro-gap-4 no-wrap">
              <p className="pro-mb-0">{data[field]}</p>
              <span
                className={Style.copy_text}
                onClick={() => handleCopyText(data[field])}
              >
                <span className="material-symbols-outlined">content_copy</span>
              </span>
            </div>
          ) : (
            ""
          ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleApprovedData = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = !state.showAddOrderModal;
        state.selectedOrder = e?.[0];
        state.isEdit = !state.isEdit;
      })
    );
    if (e?.[0]) {
      getVerificationEditBasicData(e?.[0]).then((res) => {
        setBasicData(res?.data?.data);
      });
    }
  };

  const handlePrintBarCode = () => {
    const stockId = approvedState?.receipt_id;
    getPrintBarCode(stockId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
      const printSlip = () => {
        const element = barCodeRef?.current;
        html2pdf(element, {
          margin: [11, 0],
          filename: `${
            // eslint-disable-next-line no-useless-concat
            "Barcode" + "-" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
          }.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            dpi: 100,
            letterRendering: true,
            useCORS: true,
            logging: true,
            scale: 4,
            scrollY: 0,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
          DisablePdfCompression: true,
        });
      };
      setTimeout(() => {
        printSlip();
      }, 500);
    });
  };

  const handleOfficeSlipPrint = () => {
    const printId = approvedState?.receipt_id;
    getPrintData(printId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.stockPrintData = response?.data?.data))
        );
      } else {
        toast.error("something went wrong");
      }
    });
    const printInvoice = () => {
      const element = officeSlipRef?.current;
      html2pdf(element, {
        margin: [2, 2, 2, 2],
        filename: `${
          "Office Slip" +
          "-" +
          moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
        }.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 4,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a6", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      });
    };
    setTimeout(() => {
      printInvoice();
    }, 500);
  };

  const handleStockReceiptPrint = () => {
    const printId = approvedState?.receipt_id;
    getPrintData(printId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.stockPrintData = response?.data?.data))
        );
      } else {
        toast.error("something went wrong");
      }
    });
    const printInvoice = () => {
      const element = StockReceiptRef?.current;
      html2pdf(element, {
        margin: [5, 2, 2, 2],
        filename: `${
          "Stock Invoice" +
          "-" +
          moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
        }.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 4,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      })
        .from("element-to-print")
        .outputPdf() // add this to replace implicite .save() method, which triggers file download
        .get("pdf")
        .then(function (pdfObj) {
          pdfObj.autoPrint();
          window.open(pdfObj.output("bloburl"), "F");
        });
    };
    setTimeout(() => {
      printInvoice();
    }, 500);
  };

  const handleExcelFileUpload = () => {
    dispatch(
      updateConfig((state) => {
        state.showExcelUploadModal = !state.showExcelUploadModal;
      })
    );
  };

  const handleExcelFileDownload = () => {
    let downloadId = {
      item_type_id: approvedState?.item_type_id,
      receipt_id: approvedState?.receipt_id,
    };
    getExportedData(downloadId).then((response) => {
      let dataType = response?.data?.type;
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_MM_SS")}${`Excel`}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  return {
    basicData,
    isFetching,
    tableFields,
    approvedState,
    paginationOptions,
    ApprovedDetailsList,
    handleStockReceiptPrint,
    handleOfficeSlipPrint,
    handlePrintBarCode,
    refetch,
    getRow,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    handleApprovedData,
    handleExcelFileUpload,
    handleExcelFileDownload,
  };
};

export default useApprovedDetails;
