import {
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useGliList from "./useGliList";
import Style from "../qc.module.scss";
import PassAll from "../../../Global/PassAll";
import RemarksTable from "../../../Global/RemarksTable";

const GliList = ({ gliNumber }) => {
  const {
    pendingTableData,
    tableFields,
    qcState,
    formik,
    isFetching,
    passAllModal,
    paginationOptions,
    globalRemarkModal,
    handleModalClose,
    getRow,
    handlePageSize,
    handlePagination,
    handlePassAll,
    handlePassAllClick,
  } = useGliList();
  return (
    <div>
      <h6 className="pro-ttl h6">{gliNumber}</h6>
      <div className="pro-d-flex pro-justify-end">
        <div className={Style.btns}>
          <button
            className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
            onClick={handlePassAllClick}
          >
            <span className="material-symbols-outlined">check_circle</span>
            <span>Pass All</span>
          </button>
        </div>
      </div>
      <Table
        data={pendingTableData?.data?.data ?? []}
        uniqueID={"_id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={<span className="material-symbols-outlined">badge</span>}
        getRow={getRow}
        loading={isFetching}
        perpage={qcState?.currentPageSize}
        assignable={false}
        deletable={false}
        editable={false}
        showCheckBox={false}
        extraClasssName={`pro-pb-4`}
      />
      <Pagination
        currentPage={pendingTableData?.data?.current_page}
        totalPageCount={Math.ceil(
          pendingTableData?.data?.total_count / pendingTableData?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout
        show={passAllModal}
        title={"Pass All"}
        handleClose={handlePassAllClick}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <PassAll
          formik={formik}
          handleSubmit={handlePassAll}
          handleCancel={handlePassAllClick}
        />
      </ModalLayout>
      <ModalLayout
        show={globalRemarkModal}
        handleClose={handleModalClose}
        propStyle={{root : 'pro-modal-xxl'}}
        title={"Remarks"}
        
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <RemarksTable />
        </div>
      </ModalLayout>
    </div>
  );
};

export default GliList;
