import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/PhotoSection/PhotoSectionSlice";
import { useSelector } from "react-redux";
import { BulkUploadPhoto } from "../../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import * as Yup from "yup";

const useBulkUpload = ({ refetch, tableType }) => {
  const dispatch = useDispatch();
  const { selectedId, certificateId, editData, values, errors } = useSelector(
    (state) => state?.photoSection
  );

  let typeList = [
    {
      label: "Image",
      value: 1,
    },
    {
      label: "Second Image",
      value: 2,
    },
  ];

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.bulkUploadModal = false;
        state.values.assetsImages = [];
        state.errors.assetsImages = "";
      })
    );
    refetch();
  };

  // dispatch(
    //   updateConfig((state) => {
    //     initializeAssetsImages(state);
    //     state.errors.assetsImages = "";
    //     // state.uploadStatus.assetsImages = "pending";
    //   })
    // );

  useEffect(() => {
    formik?.setFieldValue("remark", editData?.remark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  // const formik = useFormik({
  //   initialValues: {
  //     assetsImages: [],
  //     image_type:"",
  //     remark: "",
  //   },
  //   validationSchema: Yup.object({
  //     image_type: Yup.string().required("Type is required")
  //   }),
    
  //   onSubmit: (values,{setSubmitting}) => {
  //     setSubmitting(true)
  //     let formData = new FormData();
  //     const images = values?.assetsImages.map(item => item.file) || [];
  //     formData?.append("table_type", tableType);
  //     images.forEach((image, index) => {
  //       formData.append(`images[${index}]`, image);
  //     });      
  //     formData?.append("remarks", values?.remark);
  //     formData?.append("image_type", values?.image_type ?? "");
  //     formData?.append("receipt_id", selectedId);
  //     BulkUploadPhoto(formData).then((response) => {
        
  //       if (response?.data?.status_code === 200) {
  //         toast?.success("Success");
  //         handleClose();
  //         refetch();
  //         dispatch(updateConfig((state) => (state.clear = true)));
  //         dispatch(
  //           updateConfig((state) => {
  //             initializeAssetsImages(state);
  //             state.values.assetsImages = [];
  //             state.errors.assetsImages = "";
  //           })
  //         );
  //         const timeOut = setTimeout(() => {
  //           dispatch(
  //             updateConfig((state) => (state.clear = false)));
  //           clearTimeout(timeOut);
  //         }, 1000);
  //       } else {
  //         toast?.error("Something went wrong");
  //       }

  //       setSubmitting(false);
  //     });
  //   },
  // });


  const formik = useFormik({
    initialValues: {
      assetsImages: [],
      image_type: "",
      remark: "",
    },
    validationSchema: Yup.object({
      image_type: Yup.string().required("Type is required")
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      toast.warning("Upload is in progress...");
  
      const processBatch = async (batch) => {
        let formData = new FormData();
        formData.append("table_type", tableType);
        batch.forEach((image, index) => {
          formData.append(`images[${index}]`, image.file);
        });
        formData.append("remarks", values.remark);
        formData.append("image_type", values.image_type ?? "");
        formData.append("receipt_id", selectedId);
  
        const response = await BulkUploadPhoto(formData);
        if (response?.data?.status_code === 200) {
          
        } else {
          toast.error("Something went wrong with bulk upload");
        }
        return response;
      };
  
      const images = values?.assetsImages || [];
      const batchSize = 100;
  
      for (let i = 0; i < images.length; i += batchSize) {
        const batch = images.slice(i, i + batchSize);
        const response = await processBatch(batch);
        if (response?.data?.status_code !== 200) {
          toast.error("Something went wrong with bulk upload");
          break; // Stop processing if any batch fails
        }
      }
  
      setSubmitting(false);
      toast.success("Bulk Upload Completed");
      handleClose();
      refetch();
      dispatch(updateConfig((state) => (state.clear = true)));
      dispatch(
        updateConfig((state) => {
          initializeAssetsImages(state);
          state.values.assetsImages = [];
          state.errors.assetsImages = "";
        })
      );
      const timeOut = setTimeout(() => {
        dispatch(
          updateConfig((state) => (state.clear = false))
        );
        clearTimeout(timeOut);
      }, 1000);
    },
  });

  

  // useEffect(() => {
  //   if (editData && editData?.remark) {
  //     formik.setFieldValue("remark", editData?.remark);
  //   }
  //   //eslint-disable-next-line
  // }, [editData?.remark]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const initializeAssetsImages = (state) => {
    if (!state.values) {
      state.values = {};
    }
    if (!state.values.assetsImages) {
      state.values.assetsImages = [];
    }
  };

  // const handleAssetsImagesChange = (e) => {
  //   const files = e?.target?.files;
  //   if (files?.length > 0) {
  //     const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
  //     const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  //     const errors = [];
  //     const validFiles = [];
  
  //     Array.from(files).forEach((file) => {
  //       if (!allowedTypes.includes(file.type)) {
  //         errors.push(`File ${file.name} is not a valid image type`);
  //       } else if (file.size > maxSize) {
  //         errors.push(`File ${file.name} exceeds the maximum size of 5MB`);
  //       } else {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           validFiles.push({
  //             file: file,
  //             thumb: reader.result,
  //           });
  
  //           // After all files are processed
  //           if (validFiles.length === files.length) {
  //             // Retrieve the existing assetsImages from Formik
  //             const existingAssetsImages = formik.values.assetsImages || [];
              
  //             // Combine the existing and new valid files
  //             const combinedAssetsImages = [...existingAssetsImages, ...validFiles];
  
  //             // Update Formik with the combined array
  //             formik.setFieldValue("assetsImages", combinedAssetsImages);
  
  //             // Dispatch to update the state
  //             dispatch(
  //               updateConfig((state) => {
  //                 initializeAssetsImages(state);
  //                 state.values.assetsImages = combinedAssetsImages;
  //                 state.errors.assetsImages = "";
  //               })
  //             );
  //           }
  //         };
  //         reader.readAsDataURL(file);
  //       }
  //     });
  
  //     if (errors.length > 0) {
  //       dispatch(
  //         updateConfig((state) => {
  //           initializeAssetsImages(state);
  //           state.errors.assetsImages = errors[0];
  //         })
  //       );
  //     }
  //   }
  // };

  const handleAssetsImagesChange = (e) => {
    const files = e?.target?.files;
    if (files?.length > 0) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      const errors = [];
      const validFiles = [];
  
      Array.from(files).forEach((file) => {
        if (!allowedTypes.includes(file.type)) {
          errors.push(`File ${file.name} is not a valid image type`);
        } else if (file.size > maxSize) {
          errors.push(`File ${file.name} exceeds the maximum size of 5MB`);
        } else {
          validFiles.push(file);
        }
      });
  
      if (errors.length > 0) {
        dispatch(
          updateConfig((state) => {
            initializeAssetsImages(state);
            state.errors.assetsImages = errors[0];
          })
        );
        return;
      }
  
      const readFiles = (files) => {
        return Promise.all(files.map((file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve({
                file: file,
                thumb: reader.result,
              });
            };
            reader.readAsDataURL(file);
          });
        }));
      };
  
      readFiles(validFiles).then((validFilesWithThumbs) => {
        // Retrieve the existing assetsImages from Formik
        const existingAssetsImages = formik.values.assetsImages || [];
        
        // Combine the existing and new valid files
        const combinedAssetsImages = [...existingAssetsImages, ...validFilesWithThumbs];
  
        // Update Formik with the combined array
        formik.setFieldValue("assetsImages", combinedAssetsImages);
  
        // Dispatch to update the state
        dispatch(
          updateConfig((state) => {
            initializeAssetsImages(state);
            state.values.assetsImages = combinedAssetsImages;
            state.errors.assetsImages = "";
          })
        );
      });
    }
  };
  
  
  
  

  const handleAssetsImagesDelete = async (data) => {
    const file = values?.assetsImages?.filter(
      (value) => value?.thumb === data?.thumb
    );

    const newImages = values?.assetsImages?.filter(
      (value) => value?.file !== file?.[0]?.file
    );
    dispatch(
      updateConfig((state) => {
        state.values.assetsImages = newImages;
      })
    );
    formik.setFieldValue("assetsImages", newImages);
  };

  return {
    formik,
    editData,
    getFieldError,
    handleClose,
    handleAssetsImagesChange,
    handleAssetsImagesDelete,
    typeList
  };
};

export default useBulkUpload;
