import { getAxiosInstance } from "../../../api";

export const getItemData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/item/edit?item_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addItemData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editItemData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteItem = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/item/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveItemRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
