import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePhotoSectionTable from "./usePhotoSectionTable";
import { FaSort } from "react-icons/fa";
import Upload from "./Upload";
import { ProfileCard } from "../../../Master/Branches/ProfileCard";
import RemarksTable from "../../../Global/RemarksTable";
import Style from "../photosection.module.scss";
import BulkUpload from "./BulkUpload";

const PhotoSectionTable = ({ gliNumber }) => {
  const {
    state,
    tableData,
    tableFields,
    imageData,
    showImageModal,
    paginationOptions,
    isFetching,
    globalRemarkModal,
    getRow,
    refetch,
    closeModal,
    handlePagination,
    handlePageSize,
    handleModalClose,
    handleUploadModalClick,
    handleUploadModalClose,
    handleBulkUploadModalClose,
    handleBulkImageUploadClick,
    handleSearch,
    isLoading
  } = usePhotoSectionTable();
  return (
    <div>
      <h6 className="pro-ttl h6">{gliNumber}</h6>
      
          <SearchFilters
        onSearchInput={handleSearch}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters={false}
        searchInputProps={{ value: state?.pendingSearch }}
        showActions={false}
        // handleActionClick={handleEditColumnsClick}
        loading={isLoading}
        filterChildren={
          <div className="col pro-d-flex pro-justify-end">
            <button
              className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline lg`}
              onClick={handleBulkImageUploadClick}
            >
              <span className="material-symbols-outlined">check_circle</span>
              <span>Bulk Upload</span>
            </button>
          </div>
        }
      />
      <Table
        multiSelect={false}
        data={tableData?.data?.data ?? []}
        uniqueID={"_id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={
          <span className="material-symbols-outlined">cloud_upload</span>
        }
        getRow={getRow}
        loading={isFetching}
        perpage={state?.currentPageSize}
        assignable={true}
        deletable={false}
        showCheckBox={true}
        editable={false}
        handleAssign={handleUploadModalClick}
        assignText={`Upload`}
        clear={state?.clear}
      />
      {tableData?.data?.data && (
        <Pagination
          currentPage={parseInt(tableData?.data?.current_page)}
          totalPageCount={Math.ceil(
            tableData?.data?.total_count / tableData?.data?.per_page
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
      <ModalLayout
        show={state.uploadModal}
        handleClose={handleUploadModalClose}
        title={`Upload Photo`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      >
        <div className="pro-m-5">
          <Upload refetch={refetch} tableType={tableData?.data?.table_type} />
        </div>
      </ModalLayout>

      <ModalLayout
        show={state.bulkUploadModal}
        handleClose={handleBulkUploadModalClose}
        title={`Upload Photos`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      >
        <div className="pro-m-5">
          <BulkUpload refetch={refetch} tableType={tableData?.data?.table_type} values={state.values} errors={state.errors} />
        </div>
      </ModalLayout>

      <ModalLayout show={showImageModal} handleClose={closeModal}>
        <ProfileCard data={imageData} handleClose={closeModal} />
      </ModalLayout>
      <ModalLayout
        show={globalRemarkModal}
        handleClose={handleModalClose}
        title={"Remarks"}
        propStyle={{ root: "pro-modal-xxl" }}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <RemarksTable />
        </div>
      </ModalLayout>
    </div>
  );
};

export default PhotoSectionTable;
