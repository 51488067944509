import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getDeliveryRecheckChallanEditData,
  updateConfig,
} from "../../../../store/slices/Delivery/deliverySlice";
import {
  approveCertificate,
  // getDeliveryCertificate,
  getPrintChellan,
  getPrintSlip,
  passRecheckAll,
} from "../api";

import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useGetRecheckCertificateListDataQuery } from "../../../../store/queries/Delivery";
import { useFormik } from "formik";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useDeliveryCertificate = (slipRef, chellanRef) => {
  const deliveryState = useSelector((state) => state.delivery);
  const { passAllModal, globalRemarkModal } = useSelector(
    (state) => state.global
  );
  const slug = localStorage?.getItem("slug");

  const {
    data: certificateList,
    refetch,
    isFetching,
  } = useGetRecheckCertificateListDataQuery({
    page_size: deliveryState.currentPageSize,
    page: deliveryState.currentPage,
    sort_by: deliveryState.sortBy,
    sort_order: deliveryState.sortOrder,
    search: deliveryState.search,
    process_slug: slug,
    receipt_id: deliveryState.selectedRecheckCardId,
  });

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const formik = useFormik({
    initialValues: {
      remark: "",
    },
  });

  const dispatch = useDispatch();

  const disableMultiSelect = useMemo(
    () => certificateList?.data?.data.every((item) => item.disable === true),
    //eslint-disable-next-line
    [certificateList]
  );

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleConfirmModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddDeliverySecModal = !state.showAddDeliverySecModal;
        state.selectedDeliverySection = e;
      })
    );
  };

  const handlePassAllClick = () => {
    dispatch(
      globalUpdateConfig((state) => (state.passAllModal = !state.passAllModal))
    );
  };

  const handleConfirm = (values) => {
    let data = {
      recheck_item_details_id: deliveryState.selectedDeliverySection,
      status: values?.status,
      remarks: values?.remarks,
    };
    let newObj = Object.entries(data);
    newObj = newObj
      .filter(
        (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
      )
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
    approveCertificate(newObj).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Success");
        handleConfirmModal();
        refetch();
        dispatch(updateConfig((state) => (state.clear = true)));
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);
        // getDeliveryCertificate(deliveryState.selectedCardId).then(
        //   (response) => {
        //     const certificateList = response?.data;
        //     setCertificateList(certificateList);
        //   }
        // );
      } else {
        toast.error("Something went wrong");
        handleConfirmModal();
      }
    });
  };

  const handlePrintSlip = () => {
    const stockId = deliveryState?.selectedRecheckCardId;
    getPrintSlip(stockId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
      const printSlip = () => {
        const element = slipRef?.current;
        html2pdf(element, {
          margin: [2, 2, 2, 2],
          filename: `${
            "Delivery Invoice" +
            "-" +
            moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
          }.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            dpi: 100,
            letterRendering: true,
            useCORS: true,
            logging: true,
            scale: 4,
            scrollY: 0,
          },
          jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
          pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
          DisablePdfCompression: true,
        });
      };
      setTimeout(() => {
        printSlip();
      }, 500);
    });
  };

  const handlePrintChellan = () => {
    const stockId = deliveryState?.selectedRecheckCardId;
    getPrintChellan(stockId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.printChellanData = response?.data?.data)
          )
        );
      } else {
        toast.error("Something went wrong");
      }
      const printSlip = () => {
        const element = chellanRef?.current;
        html2pdf(element, {
          margin: [10, 2, 2, 2],
          filename: `${
            "Delivery Challan" +
            "-" +
            moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
          }.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            dpi: 100,
            letterRendering: true,
            useCORS: true,
            logging: true,
            scale: 4,
            scrollY: 0,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
          DisablePdfCompression: true,
        });
      };
      setTimeout(() => {
        printSlip();
      }, 500);
    });
  };
  const handlePassAll = () => {
    let data = {
      stock_receipt_id: deliveryState?.selectedRecheckCardId,
      process_slug: slug,
      remark: formik?.values?.remark,
    };

    passRecheckAll(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success(response?.data?.message);
        refetch();
        handlePassAllClick();
        formik?.resetForm();
        dispatch(updateConfig((state) => (state.clear = true)));
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);
      } else if (response?.status_code === 403) {
        toast.error(response?.message ?? "Error ");
      } else {
        toast.error(response?.data?.message);
      }
    });
  };
  const handleEditPrintModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showRecheckPrintEditModal = !state.showRecheckPrintEditModal;
        state.isEdit = true;
      })
    );
    let params = {
      stock_receipt_id: deliveryState.selectedRecheckCardId,
    };
    dispatch(getDeliveryRecheckChallanEditData(params));
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showRecheckPrintEditModal = false;
        state.isEdit = false;
        state.deliveryRecheckChallanEditData = {};
      })
    );
  };

  return {
    paginationOptions,
    disableMultiSelect,
    deliveryState,
    certificateList,
    isFetching,
    formik,
    passAllModal,
    globalRemarkModal,
    handlePrintChellan,
    handleModalClose,
    handlePrintSlip,
    getRow,
    handlePageSize,
    handleConfirm,
    handlePagination,
    handlePassAllClick,
    handleConfirmModal,
    handlePassAll,
    handleCloseModal,
    handleEditPrintModal,
  };
};

export default useDeliveryCertificate;
