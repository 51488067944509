import { getAxiosInstance } from "../../../api";

export const empDetails = async ({ data }) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/work/employee-list?stock_receipt_id=${data?.id}&process_slug=${data?.slug}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const subCardsDetailsRecheck = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/work/recheck-receipt-detail?stock_receipt_id=${id} `
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const subCardsDetails = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/work/receipt-detail?stock_receipt_id=${id} `);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
