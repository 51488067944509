import React from "react";
import useVersionTwo from "./useVersionTwo";
import {
  Button,
  ConfirmationBox,
  Input,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../compareTab.module.scss";
import Select from "react-select";

const VersionTwo = () => {
  const {
    itemID,
    excelEditedFormData,
    excelFormData,
    formik,
    activeId,
    dropdownValues,
    certificateNumber,
    currentTab,
    cnfModal,
    handleModal,
    handleYes,
    getFieldError,
    handleReset,
  } = useVersionTwo();
  const forms =
    excelEditedFormData?.length > 0
      ? excelEditedFormData?.[
          !currentTab?.[0]?.disabled
            ? excelEditedFormData?.length - 1
            : activeId - 2
        ]?.data
      : excelFormData?.data;

  const centerForms =
    excelEditedFormData?.length > 0
      ? excelEditedFormData?.[
          !currentTab?.[0]?.disabled
            ? excelEditedFormData?.length - 1
            : activeId - 2
        ]?.center_stone
      : excelFormData?.center_stone;

  const sideForms =
    excelEditedFormData?.length > 0
      ? excelEditedFormData?.[
          !currentTab?.[0]?.disabled
            ? excelEditedFormData?.length - 1
            : activeId - 2
        ]?.side_stone
      : excelFormData?.side_stone;
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h6 className="pro-ttl h6">{itemID}</h6>
          <p className="pro-ttl">{certificateNumber}</p>
          {forms?.map((value, index) => {
            return (
              <React.Fragment key={index}>
                {value?.slug === "color" ||
                value?.slug === "clarity" ||
                value?.slug === "grade" ||
                value?.slug === "finish" ||
                value?.slug === "status" ||
                value?.slug === "shape" ? (
                  <div className="input-wrap pro-mb-4" key={index}>
                    <label
                      htmlFor=""
                      className="pro-font-sm pro-mb-1 pro-fw-medium"
                    >
                      {value?.name}
                    </label>
                    <Select
                      id={value?.slug}
                      placeholder={"Select"}
                      className={`pro-input lg  ${
                        getFieldError(`${value?.slug}`) && "error"
                      }`}
                      classNamePrefix="pro-input"
                      name={value?.slug}
                      options={dropdownValues?.[value?.slug]}
                      getOptionValue={(option) =>
                        value?.slug === "status" ? option?.id : option?._id
                      }
                      getOptionLabel={(option) => option?.name}
                      onBlur={formik.handleBlur(`${value?.slug}`)}
                      value={
                        value?.slug === "status"
                          ? dropdownValues?.[value?.slug]?.filter(
                              (val) => val?.id === formik?.values?.[value?.slug]
                            )?.[0]
                          : dropdownValues?.[value?.slug]?.filter(
                              (val) =>
                                val?.name === formik?.values?.[value?.slug]
                            )?.[0]
                      }
                      onChange={(e) =>
                        value?.slug === "status"
                          ? formik?.setFieldValue(`${value?.slug}`, e?.id)
                          : formik?.setFieldValue(`${value?.slug}`, e?.name)
                      }
                      menuPlacement="auto"
                      isDisabled={currentTab?.[0]?.disabled}
                    />
                    {getFieldError(`${value?.slug}`) && (
                      <span className="error-text">
                        {getFieldError(`${value?.slug}`)}
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    <Input
                      key={index}
                      label={value?.name}
                      type="text"
                      id={`${value?.slug}`}
                      name={`${value?.slug}`}
                      className={`pro-input lg ${
                        getFieldError(`${value?.slug}`) && " error"
                      }`}
                      onChange={(e) =>
                        formik?.setFieldValue(
                          `${value?.slug}`,
                          e?.target?.value
                        )
                      }
                      onBlur={formik?.handleBlur(`${value?.slug}`)}
                      error={getFieldError(value?.slug)}
                      errorMessage={getFieldError(value?.slug)}
                      value={formik?.values[value?.slug]}
                      disabled={currentTab?.[0]?.disabled}
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
          {centerForms && (
            <>
              <small>Center Stone</small>
              {centerForms?.map((value, index) => {
                return (
                  <React.Fragment key={index}>
                    {value?.slug === "color" ||
                    value?.slug === "clarity" ||
                    value?.slug === "grade" ||
                    value?.slug === "finish" ||
                    value?.slug === "shape" ? (
                      <div className="input-wrap pro-mb-4" key={index}>
                        <label
                          htmlFor=""
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          {value?.name}
                        </label>
                        <Select
                          id={`center_stone_${value?.slug}`}
                          placeholder={"Select"}
                          className={`pro-input lg  ${
                            getFieldError(`center_stone_${value?.slug}`) &&
                            "error"
                          }`}
                          classNamePrefix="pro-input"
                          name={`center_stone_${value?.slug}`}
                          options={dropdownValues?.[value?.slug]}
                          getOptionValue={(option) => option?._id}
                          getOptionLabel={(option) => option?.name}
                          onBlur={formik.handleBlur(
                            `center_stone_${value?.slug}`
                          )}
                          value={
                            dropdownValues?.[value?.slug]?.filter(
                              (val) =>
                                val?.name ===
                                formik?.values?.[`center_stone_${value?.slug}`]
                            )?.[0]
                          }
                          onChange={(e) =>
                            formik?.setFieldValue(
                              `center_stone_${value?.slug}`,
                              e?.name
                            )
                          }
                          menuPlacement="auto"
                          isDisabled={currentTab?.[0]?.disabled}
                        />
                        {getFieldError(`center_stone_${value?.slug}`) && (
                          <span className="error-text">
                            {getFieldError(`center_stone_${value?.slug}`)}
                          </span>
                        )}
                      </div>
                    ) : (
                      <Input
                        key={index}
                        label={value?.name}
                        type="text"
                        id={`center_stone_${value?.slug}`}
                        name={`center_stone_${value?.slug}`}
                        className={`pro-input lg ${
                          getFieldError(`center_stone_${value?.slug}`) &&
                          " error"
                        }`}
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `center_stone_${value?.slug}`,
                            e?.target?.value
                          )
                        }
                        onBlur={formik?.handleBlur(
                          `center_stone_${value?.slug}`
                        )}
                        error={getFieldError(`center_stone_${value?.slug}`)}
                        errorMessage={getFieldError(
                          `center_stone_${value?.slug}`
                        )}
                        value={formik?.values[`center_stone_${value?.slug}`]}
                        disabled={currentTab?.[0]?.disabled}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
          {sideForms && (
            <>
              <small>Side Stone</small>
              {sideForms?.map((value, index) => {
                return (
                  <React.Fragment key={index}>
                    {value?.slug === "color" ||
                    value?.slug === "clarity" ||
                    value?.slug === "grade" ||
                    value?.slug === "finish" ||
                    value?.slug === "shape" ? (
                      <div className="input-wrap pro-mb-4" key={index}>
                        <label
                          htmlFor=""
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          {value?.name}
                        </label>
                        <Select
                          id={`side_stone_${value?.slug}`}
                          placeholder={"Select"}
                          className={`pro-input lg  ${
                            getFieldError(`side_stone_${value?.slug}`) &&
                            "error"
                          }`}
                          classNamePrefix="pro-input"
                          name={`side_stone_${value?.slug}`}
                          options={dropdownValues?.[value?.slug]}
                          getOptionValue={(option) => option?._id}
                          getOptionLabel={(option) => option?.name}
                          onBlur={formik.handleBlur(
                            `side_stone_${value?.slug}`
                          )}
                          value={
                            dropdownValues?.[value?.slug]?.filter(
                              (val) =>
                                val?.name ===
                                formik?.values?.[`side_stone_${value?.slug}`]
                            )?.[0]
                          }
                          onChange={(e) =>
                            formik?.setFieldValue(
                              `side_stone_${value?.slug}`,
                              e?.name
                            )
                          }
                          menuPlacement="auto"
                          isDisabled={currentTab?.[0]?.disabled}
                        />
                        {getFieldError(`side_stone_${value?.slug}`) && (
                          <span className="error-text">
                            {getFieldError(`side_stone_${value?.slug}`)}
                          </span>
                        )}
                      </div>
                    ) : (
                      <Input
                        key={index}
                        label={value?.name}
                        type="text"
                        id={`side_stone_${value?.slug}`}
                        name={`side_stone_${value?.slug}`}
                        className={`pro-input lg ${
                          getFieldError(`side_stone_${value?.slug}`) && " error"
                        }`}
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `side_stone_${value?.slug}`,
                            e?.target?.value
                          )
                        }
                        onBlur={formik?.handleBlur(`side_stone_${value?.slug}`)}
                        error={getFieldError(`side_stone_${value?.slug}`)}
                        errorMessage={getFieldError(
                          `side_stone_${value?.slug}`
                        )}
                        value={formik?.values[`side_stone_${value?.slug}`]}
                        disabled={currentTab?.[0]?.disabled}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-py-3 pro-justify-end ${Style.footer_btn_wrap}`}
      >
        <Button
          disabled={currentTab?.[0]?.disabled}
          className={"pro-btn-outline lg pro-px-5"}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          disabled={currentTab?.[0]?.disabled}
          className={"pro-btn-primary lg pro-ms-3"}
          type="button"
          onClick={formik?.handleSubmit}
        >
          Update
        </Button>
      </div>
      <ModalLayout show={cnfModal} handleClose={handleModal}>
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Do You want to see extra details?"}
            subTitle={"Click yes to continue"}
            cancelText={"No"}
            cancelAction={handleModal}
            submitText={"Yes"}
            submitAction={handleYes}
            isRight={true}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default VersionTwo;
