import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEstimate from "./useEstimate";
import { FaSort } from "react-icons/fa";
import Select from "react-select";
import SubmitForm from "./SumbitForm";

const Estimate = () => {
  const {
    isPending,
    estimateResponse,
    estimateModal,
    paginationOptions,
    getRow,
    handleModal,
    handleYes,
    handlePagination,
    handlePageSize,
    handleGenerateEstimate,
    formik,
    handleStatusChange,
  } = useEstimate();
  const statusList = [
    {
      name: "Completed",
      id: 2,
    },
    {
      name: "Rejected",
      id: 4,
    },
    {
      name: "Marked",
      id: 5,
    },
  ]
  return (
    <div>
      <div className="pro-d-flex pro-flex-column pro-h-100">
        <HeadingGroup 
        title={`Invoice`} 
        className={`pro-mb-4`}
        propStyle={{root_child :"pro-ms-3 pro-justify-end pro-d-flex pro-items-end flex-fill"}}
        >
          <div className={`col-3`}>
          <div className="input-wrap ">
                <label
                  htmlFor="branch"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Status
                </label>
                <Select
                  id="status"
                  name="status"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  options={statusList}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  onBlur={formik?.handleBlur("status")}
                  isMulti={true}
                  onChange={(value) => handleStatusChange(value)}
                  menuPlacement="auto"
                  value={statusList?.filter((m) =>
                    formik.values.status?.includes?.(m?.id)
                  )}
                />
              </div>
          </div>

          <div className="col-auto dropdown-filter pro-d-flex">
            <button
              className={`pro-btn pro-btn-outline-primary no-wrap pro-items-center pro-ms-3 lg`}
              type="submit"
              onClick={() => estimateResponse?.special_permission ? handleGenerateEstimate("generate") : handleGenerateEstimate(null)}
              disabled={isPending}
            >
              <span>{`Generate Estimate`}</span>
            </button>
          </div>
        </HeadingGroup>
        <div className="col-12">
          <div className="row gx-2 gy-2">
            <div className="col-6 col-lg-4 col-xxl-2">
              
            </div>
          </div>
        </div>
        <div className="col-auto pro-pt-3 pro-pb-0 flex-fill pro-d-flex pro-flex-column">
          <Table
            multiSelect={true}
            data={estimateResponse?.data || []}
            uniqueID={"id"}
            fields={estimateResponse?.fields || []}
            showCheckBox={true}
            SortIcon={<FaSort />}
            getRow={getRow}
            perpage={10}
            assignText={"Genarate Estimate"}
            assignIcon={
              <span className="material-symbols-outlined">print</span>
            }
            assignable={isPending ? false : true}
            editable={false}
            deletable={false}
            handleAssign={handleModal}
          />
          {estimateResponse?.data?.length > 0 && (
            <Pagination
              currentPage={estimateResponse?.current_page}
              totalPageCount={Math.ceil(
                estimateResponse?.total_count / estimateResponse?.per_page
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          <ModalLayout show={estimateModal} handleClose={handleModal}>
            <div className="pro-m-5">
              {estimateResponse?.special_permission ? (
                <SubmitForm handleClose={handleModal}/>
              ) : (
                <ConfirmationBox
                title={"Are you sure generate estimate ?"}
                subTitle={"This action can't be undo "}
                cancelText={"No"}
                cancelAction={handleModal}
                submitText={"Yes"}
                a
                submitAction={handleYes}
                isRight={true}
              />
              )}
    
            </div>
          </ModalLayout>
        </div>
      </div>
    </div>
  );
};

export default Estimate;
