import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  receipt_id: "",
  selectedId: "",
  search: "",
  searchRecheck: "",
  showAddPackingModal: false,
  isEdit: false,
  selectedPacking: "",
  submissionStatus: "",
  myQueueList: {
    Pending: [],
  },
  myQueueRecheckList: {
    Pending: [],
  },
  packingData: [],
  packingRecheckData: [],
  packingStatus: "idle",
  queueStatus: "idle",
  RecheckPackingStatus: "idle",
  RecheckQueueStatus: "idle",
  showDeleteModal: false,
  engravingImagePreview: "",
  certificateImagePreview: "",
  total: {
    Pending: "",
  },
  showCertificate: false,
  cerificateData: [],
  clear: false,
  viewModal: false,
  viewData: "",
  table: {
    currentPage: 1,
    currentFilter: "all",
    currentPageSize: 20,
  },
  showPassAllModal: false,
  showPassAllRecheckModal: false,
  remark: "",
};
//packing call
export const getMyQueueList = createAsyncThunk(
  "employee-dashboard/order/assigned-to-me",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/assigned-to-me`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPackingsList = createAsyncThunk(
  "/packing/list",
  async ({ id, slug }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/packing/list?receipt_id=${id}&process_slug=${slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//packing recheck call
export const getMyQueueRecheckList = createAsyncThunk(
  "employee-dashboard/recheck/assigned-to-me",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/assigned-to-me`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPackingsRecheckList = createAsyncThunk(
  "/packing/recheck/list",
  async ({ id, slug }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/packing/recheck/list?receipt_id=${id}&process_slug=${slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//slice functions
const packingsSlice = createSlice({
  name: "packings",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getPackingsList.pending]: (state, action) => {
      state.packingStatus = "pending";
    },
    [getPackingsList.fulfilled]: (state, action) => {
      state.packingData = action?.payload?.data?.data;

      state.packingStatus = "fulfilled";
    },
    [getPackingsList.rejected]: (state) => {
      state.packingStatus = "failed";
    },
    [getMyQueueList.pending]: (state, action) => {
      state.queueStatus = "pending";
    },
    [getMyQueueList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.myQueueList.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.myQueueList.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.queueStatus = "fulfilled";
    },
    [getMyQueueList.rejected]: (state) => {
      state.queueStatus = "failed";
    },
    //package recheck section
    [getMyQueueRecheckList.pending]: (state, action) => {
      state.RecheckQueueStatus = "pending";
    },
    [getMyQueueRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.myQueueRecheckList.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.myQueueRecheckList.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.RecheckQueueStatus = "fulfilled";
    },
    [getMyQueueRecheckList.rejected]: (state) => {
      state.RecheckQueueStatus = "failed";
    },
    [getPackingsRecheckList.pending]: (state, action) => {
      state.RecheckPackingStatus = "pending";
    },
    [getPackingsRecheckList.fulfilled]: (state, action) => {
      state.packingRecheckData = action?.payload?.data?.data;

      state.RecheckPackingStatus = "fulfilled";
    },
    [getPackingsRecheckList.rejected]: (state) => {
      state.RecheckPackingStatus = "failed";
    },
  },
});

export const { updateConfig } = packingsSlice.actions;

export default packingsSlice.reducer;
