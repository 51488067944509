import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Employee/employeeSlice";
import { getBasicData, getPostEmployeeData, updateEmployeeData } from "../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import countryData from "../../../../utils/components/countryCode";

export const useEmployeeMaster = ({ refetch }) => {
  const [basicData, setBasicData] = useState();
  const dispatch = useDispatch();
  const { employeeData, isEdit, showPassword } = useSelector(
    (state) => state.employee
  );

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddEmployeeModal = false;
        state.isEdit = false;
      })
    );
  };
  useEffect(() => {
    getBasicData().then((response) => {
      setBasicData(response?.data);
    });
    if (!isEdit) {
      setPhoneCodes();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (employeeData && isEdit && basicData) {
      let roleValue = basicData?.data?.roles_list?.filter(
        (item) => item?._id === employeeData?.role_id
      );
      let deptValue = basicData?.data?.department_list?.filter(
        (item) => item?._id === employeeData?.registerd_department
      );
      let regBranch = basicData?.data?.branch_list?.filter(
        (item) => item?._id === employeeData?.registred_branch
      );
      let supervisorValue = basicData?.data?.supervisor_list?.filter(
        (item) => item?._id === employeeData?.supervisor_id
      );
      let countryValue = countryData?.filter(
        (item) => item?.value === employeeData?.country_code
      );
      // let branchValue = basicData?.data?.branch_list?.filter((item, i) => {
      //   return item?._id === employeeData?.branches[i];
      // });

      let branchValue = basicData?.data?.branch_list.filter((obj) =>
        employeeData?.branches.includes(obj._id)
      );
      formik.setFieldValue("first_name", employeeData?.first_name);
      formik.setFieldValue("last_name", employeeData?.last_name);
      formik.setFieldValue("user_name", employeeData?.user_name);
      formik.setFieldValue("phone", employeeData?.phone);
      formik.setFieldValue("country_code", countryValue[0]);
      formik.setFieldValue("email", employeeData?.email);
      formik.setFieldValue("role_id", roleValue[0]);
      formik.setFieldValue("registerd_department", deptValue[0]);
      formik.setFieldValue("registred_branch", regBranch[0]);
      formik.setFieldValue("supervisor_id", supervisorValue[0]);
      formik.setFieldValue("branches", branchValue);
      formik.setFieldValue("password", employeeData?.password);
    }

    // eslint-disable-next-line
  }, [employeeData, basicData]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      user_name: "",
      phone: "",
      country_code: "",
      email: "",
      role_id: "",
      registerd_department: "",
      registred_branch: "",
      supervisor_id: "",
      branches: "",
      password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim().required("First Name is required"),
      last_name: Yup.string(),
      user_name: Yup.string().trim().required("User Name is required"),
      phone: Yup.string()
        .min(7, "Enter Valid Phone Number")
        .max(14, "Enter valid Phone Number")
        .matches(/^[0-9]*$/, "Enter Valid Phone Number"),
      email:
        !isEdit &&
        Yup.string()
          .required("Email Id is required")
          .email("Enter valid email address"),
      password: !isEdit && Yup.string().required("Password is required"),
      role_id: Yup.object().required("User Role is required"),
      registerd_department: Yup.object().required("Department is required"),
      registred_branch: Yup.object().required("Registered Branch is required"),
      branches: Yup.array()
        .required("Branch is required")
        .min(1, "Branch is required"),
      supervisor_id: Yup.object(),
    }),
    onSubmit: async (values) => {
      const branches = values?.branches?.map((items) => {
        return items?._id;
      });
      const EmpData = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        user_name: values?.user_name,
        phone: values?.phone ?? "",
        email: values?.email,
        role_id: values?.role_id?._id,
        registerd_department: values?.registerd_department?._id,
        registred_branch: values?.registred_branch?._id,
        supervisor_id: values?.supervisor_id?._id,
        branches: branches,
        password: values?.password,
        country_code: values?.phone ? values?.country_code?.label : "",
      };
      if (!isEdit) {
        try {
          const response = await getPostEmployeeData(EmpData);
          if (response?.status === 200) {
            toast.success("Employee details added");
            handleCloseModal();
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        try {
          const updatedEmpData = { ...EmpData, employee_id: employeeData?._id };
          const response = await updateEmployeeData(updatedEmpData);
          if (response?.status === 200) {
            toast.success("Employee details updated");
            handleCloseModal();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    },
  });
  const handleShowPassword = (e) => {
    e.preventDefault();
    dispatch(
      updateConfig((state) => {
        state.showPassword = !state.showPassword;
      })
    );
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const setPhoneCodes = () => {
    formik?.setFieldValue("country_code", {
      name: "India",
      flag: "🇮🇳",
      code: "IN",
      label: "+91",
      value: "+91",
    });
  };

  return {
    formik,
    isEdit,
    basicData,
    countryData,
    showPassword,
    handleShowPassword,
    getFieldError,
    handleCloseModal,
  };
};
