import React from "react";
import { useItemFields } from "./useItemFields";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "../editPrint.module.scss";

const ItemFields = ({ formik, field, fieldIndex }) => {
  const { handleRemoveField } = useItemFields(formik);

  return (
    <>
      <div className={Style.formFields}>
        {formik?.values?.item_fields?.length !== 1 && (
          <span
            className={`${Style.corner_button} btn-close`}
            onClick={() => handleRemoveField(fieldIndex)}
          ></span>
        )}
        <div className="row">
          <div className="col-12">
            <div className="input-wrap">
              <Input
                label={"Serial Number"}
                type="text"
                id="serial_number"
                name="serial_number"
                className={`pro-input lg ${
                  formik.touched?.item_fields?.[fieldIndex]?.serial_number &&
                  formik.errors?.item_fields?.[fieldIndex]?.serial_number &&
                  "error"
                }`}
                {...formik.getFieldProps(
                  `item_fields.${fieldIndex}.serial_number`
                )}
                error={
                  formik.touched?.item_fields?.[fieldIndex]?.serial_number &&
                  formik.errors?.item_fields?.[fieldIndex]?.serial_number && (
                    <span className="error-text">
                      {formik.errors?.item_fields?.[fieldIndex]?.serial_number}
                    </span>
                  )
                }
                errorMessage={
                  formik.touched?.item_fields?.[fieldIndex]?.serial_number &&
                  formik.errors?.item_fields?.[fieldIndex]?.serial_number && (
                    <span className="error-text">
                      {formik.errors?.item_fields?.[fieldIndex]?.serial_number}
                    </span>
                  )
                }
              />
            </div>
          </div>
          <div className="col-12">
            <div className="input-wrap">
              <Input
                label={"Description of goods"}
                type="text"
                id="serial_number"
                name="description_of_goods"
                className={`pro-input lg ${
                  formik.touched?.item_fields?.[fieldIndex]
                    ?.description_of_goods &&
                  formik.errors?.item_fields?.[fieldIndex]
                    ?.description_of_goods &&
                  "error"
                }`}
                {...formik.getFieldProps(
                  `item_fields.${fieldIndex}.description_of_goods`
                )}
                error={
                  formik.touched?.item_fields?.[fieldIndex]
                    ?.description_of_goods &&
                  formik.errors?.item_fields?.[fieldIndex]
                    ?.description_of_goods && (
                    <span className="error-text">
                      {
                        formik.errors?.item_fields?.[fieldIndex]
                          ?.description_of_goods
                      }
                    </span>
                  )
                }
                errorMessage={
                  formik.touched?.item_fields?.[fieldIndex]
                    ?.description_of_goods &&
                  formik.errors?.item_fields?.[fieldIndex]
                    ?.description_of_goods && (
                    <span className="error-text">
                      {
                        formik.errors?.item_fields?.[fieldIndex]
                          ?.description_of_goods
                      }
                    </span>
                  )
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <Input
                label={"SAC Code"}
                type="text"
                id="sac_code"
                name="sac_code"
                className={`pro-input lg ${
                  formik.touched?.item_fields?.[fieldIndex]?.sac_code &&
                  formik.errors?.item_fields?.[fieldIndex]?.sac_code &&
                  " error"
                }`}
                {...formik.getFieldProps(`item_fields.${fieldIndex}.sac_code`)}
                error={
                  formik.touched?.item_fields?.[fieldIndex]?.sac_code &&
                  formik.errors?.item_fields?.[fieldIndex]?.sac_code && (
                    <span className="error-text">
                      {formik.errors?.item_fields?.[fieldIndex]?.sac_code}
                    </span>
                  )
                }
                errorMessage={
                  formik.touched?.item_fields?.[fieldIndex]?.sac_code &&
                  formik.errors?.item_fields?.[fieldIndex]?.sac_code && (
                    <span className="error-text">
                      {formik.errors?.item_fields?.[fieldIndex]?.sac_code}
                    </span>
                  )
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <Input
                label={"Amount"}
                type="text"
                id="amount"
                name="amount"
                className={`pro-input lg ${
                  formik.touched?.item_fields?.[fieldIndex]?.amount &&
                  formik.errors?.item_fields?.[fieldIndex]?.amount &&
                  " error"
                }`}
                {...formik.getFieldProps(`item_fields.${fieldIndex}.amount`)}
                error={
                  formik.touched?.item_fields?.[fieldIndex]?.amount &&
                  formik.errors?.item_fields?.[fieldIndex]?.amount && (
                    <span className="error-text">
                      {formik.errors?.item_fields?.[fieldIndex]?.amount}
                    </span>
                  )
                }
                errorMessage={
                  formik.touched?.item_fields?.[fieldIndex]?.amount &&
                  formik.errors?.item_fields?.[fieldIndex]?.amount && (
                    <span className="error-text">
                      {formik.errors?.item_fields?.[fieldIndex]?.amount}
                    </span>
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemFields;
