import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  getOverView,
  updateConfig,
} from "../../../../store/slices/OverSlice/overViewSlice";
import { useGetFilterListDataQuery } from "../../../../store/queries/Verification";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useFilterForm = () => {
  const dispatch = useDispatch();
  const overViewState = useSelector((state) => state.overview);
  const formik = useFormik({
    initialValues: {
      itemType: [],
      shop: "",
      supplier: "",
      priority: "",
    },
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.filter.itemType = values?.itemType;
          state.filter.shop = values?.shop;
          state.filter.supplier = values?.supplier;
          state.filter.priority = values?.priority;
          state.overViewData = [];
        })
      );
      let itemTypesIds = values?.itemType?.map((values) => values?._id);
      let shopIds = values?.shop?.map((values) => values?._id);
      let supplierIds = values?.supplier?.map((values) => values?._id);
      let statusIds = values?.priority?.map((values) => values?.id);
      dispatch(
        getOverView({
          shop: shopIds,
          status: statusIds,
          supplier: supplierIds,
          item_type: itemTypesIds,
          date: overViewState?.date,
        })
      );
      handleCloseModal();
    },
  });

  useEffect(() => {
    formik?.setFieldValue("itemType", overViewState?.filter?.itemType);
    formik?.setFieldValue("shop", overViewState?.filter?.shop);
    formik?.setFieldValue("supplier", overViewState?.filter?.supplier);
    formik?.setFieldValue("priority", overViewState?.filter?.priority);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overViewState]);

  const { data: FilterData = {} } = useGetFilterListDataQuery({});
  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.filterModal = !state.filterModal)));
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    filterFormData: FilterData?.data,
    handleCloseModal,
    getFieldError,
  };
};

export default useFilterForm;
