import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Master/Branches/branchesSlice";

const useAddress = () => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Contact";
      })
    );
  };
  return { handleCloseModal, handleNext };
};

export default useAddress;
