import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePrintType from "./usePrintType";
import Select from "react-select";

const PrintType = ({
  visitingOneFrontPrintRef,
  visitingOneBackPrintRef,
  papperFrontPrintRef,
  papperBackPrintRef,
  bigFrontPrintRef,
  vistingDuplexRef,
  papperDuplexRef,
  foldableCardRef,
}) => {
  const {
    formik,
    basicData,
    handleCloseModal,
    getFieldError,
    reportListData,
    setCardSize,
    isLoading,
  } = usePrintType({
    visitingOneFrontPrintRef,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
    vistingDuplexRef,
    papperDuplexRef,
    foldableCardRef,
  });

  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Certificate Type
        </label>
        <Select
          id="certificate_type"
          name="certificate_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("certificate_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.certificate_types}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.certificate_type}
          onBlur={formik.handleBlur("certificate_type")}
          onChange={(value) => {
            formik?.setFieldValue("certificate_type", value || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("certificate_type") && (
          <span className="error-text">
            {getFieldError("certificate_type")}
          </span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Report Type
        </label>
        <Select
          id="report_id"
          name="report_id"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("report_id") && " error"}`}
          classNamePrefix="pro-input"
          options={reportListData}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.report_id}
          onBlur={formik.handleBlur("report_id")}
          onChange={(value) => {
            formik?.setFieldValue("report_id", value || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("report_id") && (
          <span className="error-text">{getFieldError("report_id")}</span>
        )}
      </div>

      {(formik?.values?.certificate_type?._id === 1 ||
        formik?.values?.certificate_type?._id === 3) && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Side
          </label>
          <Select
            id="side"
            name="side"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("side") && " error"}`}
            classNamePrefix="pro-input"
            options={[
              {
                name: "Front",
                id: 1,
              },
              {
                name: "Back",
                id: 2,
              },
              {
                name: "Duplex",
                id: 3,
              },
            ]}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.side}
            onBlur={formik.handleBlur("side")}
            onChange={(value) => {
              formik?.setFieldValue("side", value || null);
            }}
            menuPlacement="auto"
          />
          {getFieldError("side") && (
            <span className="error-text">{getFieldError("side")}</span>
          )}
        </div>
      )}
      {formik?.values?.certificate_type?._id === 1 && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Card Size
          </label>
          <Select
            id="card_size"
            name="card_size"
            placeholder={"Select"}
            className={`pro-input lg ${getFieldError("card_size") && " error"}`}
            classNamePrefix="pro-input"
            options={[
              {
                name: "Normal (7.8)",
                id: 1,
              },
              {
                name: "Large (8.5)",
                id: 2,
              },
            ]}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.card_size}
            onBlur={formik.handleBlur("card_size")}
            onChange={(value) => {
              setCardSize(value);
              // formik?.setFieldValue("card_size", card_size || null);
            }}
            menuPlacement="auto"
          />
          {getFieldError("card_size") && (
            <span className="error-text">{getFieldError("card_size")}</span>
          )}
        </div>
      )}

      {((formik?.values?.certificate_type?._id === 1 &&
        formik?.values?.side?.id === 1) ||
        formik?.values?.certificate_type?._id === 2) && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Certificate Format
          </label>
          <Select
            id="certificate_format"
            name="certificate_format"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("certificate_format") && " error"
            }`}
            classNamePrefix="pro-input"
            options={[
              {
                name: "PDF",
                id: 1,
              },
              {
                name: "Image",
                id: 2,
              },
            ]}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.certificate_format}
            onBlur={formik.handleBlur("certificate_format")}
            onChange={(value) => {
              formik?.setFieldValue("certificate_format", value || null);
            }}
            menuPlacement="auto"
          />
          {getFieldError("certificate_format") && (
            <span className="error-text">
              {getFieldError("certificate_format")}
            </span>
          )}
        </div>
      )}
      <div className="input-wrap">
        <Input
          label={"Remarks"}
          type="text"
          id="remarks"
          name="remarks"
          className={`pro-input lg ${getFieldError("remarks") && " error"}`}
          {...formik.getFieldProps("remarks")}
          error={getFieldError("remarks")}
          errorMessage={getFieldError("remarks")}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            isLoading ? "loading" : ""
          }`}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {"Print"}
        </Button>
      </div>
    </div>
  );
};

export default PrintType;
