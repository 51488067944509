import React from "react";
import useOverViewRecheck from "./useOverViewRecheck";
import Style from "./oveview.module.scss";
import GliCardListing from "../../Global/GliCardLIsting";
import QCCardListing from "./QCCardLIsting";
import {
  Button,
  DatePickerWithInput,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import FilterForm from "./FilterForm";
import CardDetails from "../OverView/CardDetails";
import SubCards from "../OverView/SubCards";

const OverViewRecheck = () => {
  const {
    states,
    state,
    dataArray,
    date,
    apiStatus,
    totalLength,
    isLoading,
    scrollRef,
    handleClick,
    handleCardView,
    setIsLoading,
    handleLoadMore,
    handleSearch,
    handleDateChange,
    handleClearClick,
    handleFilterModal,
  } = useOverViewRecheck();


  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col ${Style.card_main_wrapper}`}>
            {/* sura didn't added filter in wac pro  */}
            {/* <SearchFilters
              showDateRange={true}
              onSearchInput={handleSearch}
              showFilters={true}
              showActions={false}
              showAdvancedFilterButton={true}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              onDateChange={handleDateChange}
              showClearFilters={true}
              handleClear={handleClearClick}
              initialDateRange={date}
            /> */}
            <div className="row gx-2 gy-2 flex-fill">
              <div className={`col-auto`}>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Search this table"
                    className={`pro-input icon-r lg ${Style.search_table}`}
                  />
                  <span className="pro-icon">
                    {<span className="material-symbols-outlined">search</span>}
                  </span>
                </div>
              </div>
              <div className={`col-auto`}>
                <DatePickerWithInput
                  CalendarOutlineIcon={
                    <span className="material-symbols-outlined">
                      calendar_month
                    </span>
                  }
                  range={true}
                  initialValue={date}
                  onDateChange={handleDateChange}
                ></DatePickerWithInput>
              </div>
              <div className={`col-auto`}>
                <button
                  className="pro-btn pro-btn-outline lg"
                  onClick={handleFilterModal}
                >
                  <span className="material-symbols-outlined">tune</span>
                  Filters
                </button>
              </div>
              <div className={`col-auto`}>
                <Button
                  className={"pro-btn-link lg pro-px-5"}
                  onClick={handleClearClick}
                >
                  Clear
                </Button>
              </div>
            </div>
            <ModalLayout
              show={state?.filterModal}
              title={"Filter"}
              handleClose={handleFilterModal}
            >
              <FilterForm />
            </ModalLayout>
            <ModalLayout
              show={state?.viewModal}
              title={"Card Details"}
              handleClose={handleCardView}
              propStyle={{ root: Style.modal_root }}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <CardDetails />
            </ModalLayout>
            <ModalLayout
              show={state?.viewSubModal}
              title={"Card Details"}
              handleClose={handleClick}
              propStyle={{ root: Style.modal_root }}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <SubCards />
            </ModalLayout>
            <div className={`${Style.card_scroll_wrap} pro-mt-3`}>
              <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
                {states?.length === 0 ? null : (
                  <GliCardListing
                    isOverView
                    title={"Pending Queue"}
                    extraClassName={Style.glicard_list}
                    data={states}
                    apiStatus={apiStatus}
                    totalLength={totalLength}
                    isLoading={isLoading}
                    scrollRef={scrollRef}
                    setIsLoading={setIsLoading}
                    handleLoadMore={handleLoadMore}
                    handleChange={handleClick}
                  />
                )}
                {dataArray.map((data, index) => (
                  <QCCardListing
                    data={data.data}
                    dataKey={data.key}
                    title={data.title}
                    key={index}
                    handleChange={handleCardView}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverViewRecheck;
