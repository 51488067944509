import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useDetailsTable from "./useDetailsTable";
import Edit from "./Edit";

const DetailsTable = ({ staticPagination = true }) => {
  const {
    getRow,
    handlePageSize,
    handlePagination,
    handleSort,
    handleEditClick,
    handleClose,
    queryState,
    isFetching,
    queryList,
    paginationOptions,
    tableFields,
  } = useDetailsTable();
  return (
    <div>
      <div className="col-auto">
        <HeadingGroup title={"Extra Details"} className={`pro-mb-4`} />
        <Table
          multiSelect={false}
          data={queryList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={queryState?.formtable?.currentPageSize}
          assignable={false}
          deletable={false}
          editable={true}
          handleEdit={handleEditClick}
          showCheckBox={true}
        />
        {queryList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={queryState?.formtable?.currentPage}
            totalPageCount={Math.ceil(
              queryList?.data?.total_count /
                queryState?.formtable?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center={staticPagination}
          />
        )}
      </div>

      <OffCanvasLayout
        show={queryState?.editForm}
        title={"Edit"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        handleClose={handleClose}
      >
        <Edit />
      </OffCanvasLayout>
    </div>
  );
};

export default DetailsTable;
