import { useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  useGetInternalReportDataQuery,
  useGetInternalReportSummaryDataQuery,
  useUpdateInternalReportTableHeadDataMutation,
} from "../../../store/queries/Reports";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

import { updateConfig } from "../../../store/slices/Reports/reportsSlice";
import { downloadFile } from "../api";
import moment from "moment";
import { toast } from "react-toastify";

const useDWSRReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const reportsState = useSelector((state) => state.reports);

  const globalState = useSelector((state) => state.global);
  const [updateReportFields] = useUpdateInternalReportTableHeadDataMutation();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const today = new Date();
  const todayFormatted = formatDate(today);
  const [formattedDate, setFormattedDate] = useState([
    todayFormatted,
    todayFormatted,
  ]);

  const formik = useFormik({
    initialValues: {
      branch: "",
    },
  });

  const {
    data: reportsData,
    isLoading,
    isFetching,
    refetch,
  } = useGetInternalReportDataQuery({
    table_type:
      activeTab === "delivery_pending"
        ? 3
        : activeTab === "work_pending"
        ? 2
        : 1,
    date: formattedDate,
    branch_id: formik?.values?.branch?._id ?? null,
    per_page: reportsState?.currentPageSize,
    page: reportsState?.currentPage,
    sort_by: reportsState?.sortBy,
    sort_order: reportsState?.sortOrder,
  },
  {
    skip: activeTab === "summary"
  }
);

const {
  data: reportsSummaryData,
  isLoading :isSummaryLoading ,
  isFetching: isSummaryFetching,
  refetch: summaryRefetch,
} = useGetInternalReportSummaryDataQuery({
  
  date: formattedDate,
  branch_id: formik?.values?.branch?._id ?? null,
},
{
  skip: activeTab !== "summary"
}
);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: today,
    endDate: today,
    key: "selection",
  });

  const navigation = [
    {
      label: "Summary",
      title: "Summery",
      link: `/report/dwsr?activeTab=summary`,
      active: activeTab === "summary",
    },
    {
      label: formattedDate?.map((v, index) => (index === 1 ? v : null)),
      title: "Today",
      link: `/report/dwsr?activeTab=today`,
      active: activeTab === "today",
    },
    {
      label: "Delivery Pending",
      title: "Delivery Pending",
      link: `/report/dwsr?activeTab=delivery_pending`,
      active: activeTab === "delivery_pending",
    },
    {
      label: "Work Pending",
      title: "Work Pending",
      link: `/report/dwsr?activeTab=work_pending`,
      active: activeTab === "work_pending",
    },
  ];

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reportsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.BillEstimate.sortOrder = "asc";
        })
      );
    }
  };

  const handleClick = () => {
    let data = {
      date: formattedDate,
      branch_id: formik?.values?.branch?._id ?? null,
    };
    downloadFile(data).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    formik,
    date,
    globalState,
    reportsData,
    isLoading,
    isFetching,
    tabNavs: navigation,
    paginationOptions,
    handleSort,
    handleClick,
    handlePageSize,
    handlePagination,
    handleDateChange,
    getRow,
    refetch,
    updateReportFields,
    handleEditColumnsClick,
    reportsSummaryData,
    activeTab,
  };
};

export default useDWSRReport;
