import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddSource from "./useAddSource";
import Style from "./addSource.module.scss";

const AddSource = (refetch) => {
  const { formik, getFieldError, sourceState, handleCloseModal } =
    useAddSource(refetch);
  return (
    <div>
      <div className={`pro-p-4`}>
        <Input
          label={"Source Name"}
          type="text"
          id="source_name"
          name="source_name"
          className={`pro-input lg ${getFieldError("source_name") && " error"}`}
          {...formik.getFieldProps("source_name")}
          error={getFieldError("source_name")}
          errorMessage={getFieldError("source_name")}
        />
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn ${Style.button}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </Button>
          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {sourceState?.isEdit ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddSource;
