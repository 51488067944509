import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getMenuPermissions,
  getBranchList,
} from "../../../store/slices/Global/index";
import { useEffect } from "react";
import { useLocation } from "react-router";

const useProtectRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state.global);
  const getMenus = async () => {
    const branchList = JSON.parse(localStorage?.getItem("branch_list"));
    if (branchList) {
      const current_branch = branchList?.branch_list?.filter(
        (value) => value?._id === branchList?.branch_id
      );
      dispatch(
        updateConfig((state) => {
          state.branchList = branchList;
          state.branch_list_status = "fulfilled";
          state.currentBranch = current_branch;
        })
      );
    } else {
      dispatch(
        getBranchList({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }
    const dashboardMenus = JSON?.parse(
      localStorage?.getItem("dashboard_permissions")
    );
    if (dashboardMenus) {
      dispatch(
        updateConfig((state) => {
          state.dashboard_permissions = dashboardMenus;
          state.dashboard_permissions_status = "fulfilled";
        })
      );
    } else {
      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      getMenus();
    }
    // eslint-disable-next-line
  }, []);
  return {
    state,
  };
};

export default useProtectRoute;
