/* eslint-disable eqeqeq */
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateOrderData } from "../../../../store/slices/Orders/addOrderSlice.js";
import { useGetOrdersBasicDataQuery } from "../../../../store/queries/Orders/index.js";
import { useFormik } from "formik";
import Collection from "./Collection/index.js";
import Item from "./Item/index.js";
import Service from "./Service/index.js";
import Engraving from "./Engraving/index.js";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getAgentData, updateOrder } from "../api.js";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Orders/orderSlice.js";

const useAddOrder = ({ refetch }) => {
  const [validationType, setValidationType] = useState("");
  const [moveToTab, setMoveToTab] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // const [isDLoose, setIsDLoose] = useState(false);
  const [initialData, setInitialData] = useState({
    branch_id: "",
    source: "",
    supplier: "",
    collection_agent: "",
    no_stone: "",
    is_job: "",
    add_prefix: "",
    phone_number: "",
    address: "",
    shop_name: "",
    bill_to: "",
    priority: "",
    item_type: "",
    group: [
      {
        item_id: "",
        total_certificate: "",
        item_quantity: "",
        total_weight: "",
      },
    ],
    total_item: "",
    total_certificate: "",
    service_type: "",
    color: "",
    clarity: "",
    cut: "",
    finish: "",
    double_grade: [],
    packaging_type: "",
    additional_document: "",
    excel_file: "",
    engraving_format: "",
    engraving_remarks: "",
    engraving_text: "",
    engraving_image: "",
    engraving_font: "",
    payment_mode: "",
    certificate_type: "",
    prepared_by: "",
    remarks: "",
    box_weight: "",
    due_date: "",
    due_time: "",
    image_for_certificate: "",
  });
  const dispatch = useDispatch();

  const { activeTab, isEditOrder, completedTabs } = useSelector(
    (state) => state.addOrder
  );

  const { orderData, isEdit } = useSelector((state) => state.order);

  const { data: basicData = {}, isLoading } = useGetOrdersBasicDataQuery();

  useEffect(() => {
    if (orderData && isEdit) {
      let newObj = {
        value: orderData?.delivery_details?.person,
        label: `${orderData?.delivery_details?.person} (${orderData?.delivery_details?.contact_number})`,
      }
      setInitialData({
        branch_id: orderData?.branch_id,
        source: orderData?.source_id,
        supplier: orderData?.supplier_id,
        shop_name: orderData?.shop_id,
        bill_to: orderData?.billing_address_id,
        priority: orderData?.priority,
        item_type: orderData?.item_type_id,
        group: orderData?.item,
        color: orderData?.color_id,
        clarity: orderData?.clarity_id,
        cut: orderData?.shape_id,
        finish: orderData?.finish_id,
        remarks: orderData?.remarks,
        packaging_type: orderData?.packing_type_id,
        additional_document: orderData?.additional_document,
        excel_file: orderData?.excel_file,
        engraving_format: orderData?.engraving?.id,
        engraving_image: orderData?.engraving?.engraving_logo,
        payment_mode: orderData?.payment_mode,
        certificate_type: orderData?.certificate_type,
        image_for_certificate: orderData?.image_for_certificate,
        engraving_text: orderData?.engraving?.engraving_text,
        engraving_remarks: orderData?.engraving?.engraving_remark,
        engraving_font: orderData?.engraving?.engraving_text_font,
        collection_agent: newObj,
        phone_number: orderData?.delivery_details?.contact_number,
        address: orderData?.delivery_details?.address,
        prepared_by: orderData?.prepared_by,
        box_weight: orderData?.box_weight,
        service_type: orderData?.service,
        is_job: orderData?.is_job_work,
        add_prefix: orderData?.is_job_work_prefix,
        no_stone: orderData?.no_changeable_stone,
        due_date: orderData?.due_date,
        due_time: orderData?.due_time,
        double_grade: orderData?.grade?.map((val) => val?.id),
      });
      if (isChecked) {
        formik?.setFieldValue(
          "engraving_image",
          isEdit
            ? orderData?.engraving?.engraving_logo === undefined
              ? "Drop files to attach or browse"
              : orderData?.engraving?.engraving_logo
            : ""
        );
      }
      if (orderData?.is_engraving === "1") {
        setIsChecked(true);
      } else if (orderData?.is_engraving === "2") {
        setIsChecked(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, isLoading, basicData, isEdit]);

  const handleCloseAddOrderModal = () => {
    dispatch(updateOrderData((state) => (state.orderStatus = "success")));
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
        state.isEdit = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  const validation = Yup.object({
    branch_id:
      basicData?.data?.branch_list?.length &&
      Yup.string().required("Select Branch"),
    source: Yup.string().required("Select source"),
    supplier: Yup.string().required("Select supplier"),
    collection_agent: Yup.object()
      .required("Enter Name"),
      // .max(80, "Enter Valid Name")
      // .matches(/^[a-zA-Z ]+$/, "Enter Valid Name"),
    phone_number: Yup.string()
      .required("Enter Phone number")
      .max(11, "Enter valid number")
      .min(8, "Enter valid number")
      .matches(/^[0-9]*$/, "Enter valid number"),
    address: Yup.string()
      .trim()
      .required("Enter Address")
      .min(5, "Not a valid address"),
    shop_name: Yup.string().required("Select Shop"),
    bill_to: Yup.string().required("Select Billing Shop"),
    priority: Yup.string().required("Select Priority"),
    no_stone: Yup.string()
      // .required("Please enter atleast 1 number")
      .matches(/^[0-9]*$/, "Enter Valid Number"),
    // removed validation as per client
    // is_job: Yup.string().required("Select Job Work"),
    add_prefix: Yup.string().when("is_job", {
      is: (value) => value === 1, // Value of 'is_job' for which validation will be applied
      then: (schema) => schema.required("Select Add Prefix"),
      otherwise: (schema) => schema,
    }),
    item_type: Yup.string().required("Select Item type"),
    service_type: Yup.array()
      .required("Select Service types")
      .min(1, "Select Service types"),
    color:
      (validationType === 1 || validationType === 3) &&
      Yup.string().required("Select Color"),
    clarity:
      (validationType === 1 || validationType === 3) &&
      Yup.string().required("Select Clarity"),
    // cut: validationType === 4 && Yup.string().required("Select Cut"),
    finish:
      (validationType === 1 || validationType === 3) &&
      Yup.string().required("Select Finish"),
    double_grade:
      (validationType === 2 || validationType === 3 || validationType === 5) &&
      Yup.array().required("Select Grade").min(1, "Select Grade"),
    remarks: Yup.string().required("Enter Remarks"),
    // packaging_type:
    //   (validationType === 5 || validationType === 4) &&
    //   Yup.string().required("Select packing Type"),
    // additional_document: Yup.mixed()
    //   .test("fileType", "Invalid file format", (value) => {
    //     // Check if the value is a string, and if it is, bypass validation
    //     if (typeof value === "string") return true;
    //     if (!value) return true;
    //     // Perform file format validation for non-string values
    //     return (
    //       value &&
    //       (value.type ===
    //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
    //         value.type === "application/vnd.ms-excel") // .xls
    //     );
    //   })
    //   .nullable(),
    // excel_file:
    //   validationType === 1 || validationType === 2 || validationType === 3
    //     ? Yup.mixed()
    //         .test("fileType", "Invalid file format", (value) => {
    //           return typeof value !== "string"
    //             ? !value || // Allow null/undefined values
    //                 (value &&
    //                   (value.type ===
    //                     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
    //                     value.type === "application/vnd.ms-excel"))
    //             : true; // .xls
    //         })
    //         .required("Upload Excel File")
    //     : Yup.mixed().test("fileType", "Invalid file formats", (value) => {
    //         return typeof value !== "string"
    //           ? !value || // Allow null/undefined values
    //               (value &&
    //                 (value.type ===
    //                   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
    //                   value.type === "application/vnd.ms-excel"))
    //           : true; // .xls
    //       }),
    engraving_format: isChecked && Yup.string(),
    engraving_font: isChecked && Yup.string(),
    engraving_text: isChecked && Yup.string().trim(),
    engraving_image: isChecked && Yup.string(),
    payment_mode: Yup.string().required("Select Payment"),
    // certificate_type: Yup.string().required("Select Type"),
    prepared_by: Yup.string().required("Select Name"),
    box_weight: Yup.string()
      .max(15, "Invalid weight")
      .matches(/^(?!0(\.0+)?$)\d*(\.\d+)?$/, "Invalid Weight ")
      .nullable(),
    image_for_certificate: Yup.mixed(),
    group: Yup.array().of(
      Yup.object().shape({
        item_id: Yup.string().required("Item is required"),
        total_certificate: Yup.string()
          .required("No of certificate is required")
          .matches(/^[1-9][0-9]*$/, "Invalid Number "),
        // .test(
        //   "totalCertificate",
        //   "Invalid certificate count",
        //   function (value, context) {
        //     const itemQuantity = context.parent.item_quantity; // Get the value of item_quantity from the parent context
        //     const totalCertificate = parseInt(value, 10);
        //     const parsedItemQuantity = parseInt(itemQuantity, 10);
        //     if (totalCertificate <= parsedItemQuantity) {
        //       return true; // Validation passed
        //     }
        //     return false; // Validation failed
        //   }
        // ),
        item_quantity: Yup.string()
          // .required("Total Items is required")
          .matches(/^[1-9][0-9]*$/, "Invalid Number ")
          .nullable(),
        total_weight: Yup.string()
          // .required("Total weight is required")
          .max(10, "Invalid weight")
          .matches(/^(?!0$)([0-9]*\.[0-9]+|[1-9][0-9]*)$/, "Invalid Number ")
          .nullable(),
      })
    ),
    // group: Yup.array().of(
    //   Yup.object().shape({
    //     item_id: Yup.string().required("Item is required"),
    //     total_certificate: Yup.string()
    //       .required("No of certificate is required")
    //       .matches(/^[1-9][0-9]*$/, "Invalid Number "),
    //     item_quantity: Yup.string()
    //       .required("Total Items is required")
    //       .matches(/^[1-9][0-9]*$/, "Invalid Number "),
    //     total_weight: Yup.string()
    //       .required("Total weight is required")
    //       .max(5, "Invalid weight")
    //       .matches(/^[1-9][0-9]*$/, "Invalid Number "),
    //   })
    // ),
  })
    .test(
      "at-least-one-field",
      "At least one field is required",
      function (values) {
        const { engraving_format, engraving_text, engraving_image } = values;
        if (
          isChecked &&
          !engraving_format &&
          !engraving_text &&
          !engraving_image
        ) {
          return this.createError({
            path: "engraving_format", // Choose a relevant field for the error
            message: "At least one field is required",
          });
        }
        return true;
      }
    )
    .test(
      "engraving-font-required",
      "Engraving Font is required",
      function (values) {
        const { engraving_format, engraving_font, engraving_text } = values;

        if (
          isChecked &&
          (engraving_format || engraving_text) &&
          !engraving_font
        ) {
          return this.createError({
            path: "engraving_font", // Choose a relevant field for the error
            message:
              "Engraving Font is required when Engraving Format is selected and Engraving Text is empty",
          });
        }

        return true;
      }
    );

  // Set the initial values based on whether edit is true or false

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const name = values?.collection_agent?.label?.split(' (')[0];
      dispatch(updateOrderData((state) => (state.orderStatus = "pending")));
      let data = {
        delivery_person: name,
        delivery_person_number: values?.phone_number,
        delivery_person_address: values?.address,
        source_id: values?.source,
        branch_id:
          basicData?.data?.branch_list?.length !== 0 ? values?.branch_id : "",
        supplier_id: values?.supplier,
        shop_id: values?.shop_name,
        priority: values?.priority,
        no_changeable_stone: values?.no_stone,
        is_job_work: values?.is_job,
        is_job_work_prefix: values?.is_job == 1 ? values?.add_prefix : "",
        item_type_id: values?.item_type,
        billing_address_id: values?.bill_to,
        packing_type_id: values?.packaging_type,
        is_engraving: isChecked ? 1 : 2,
        engraving_type_id: isChecked ? values?.engraving_format : "",
        engraving_text: isChecked ? values?.engraving_text : "",
        engraving_text_font: isChecked ? values?.engraving_font : "",
        engraving_remark: isChecked ? values?.engraving_remarks : "",
        payment_mode: values?.payment_mode,
        certificate_type: values?.certificate_type,
        prepared_by: values?.prepared_by,
        remarks: values?.remarks,
        box_weight: values?.box_weight,
        due_date: values?.due_date,
        due_time: values?.due_time,
        "item_grading[color_id]": values?.color,
        "item_grading[clarity_id]": values?.clarity,
        "item_grading[shape_id]": values?.cut,
        "item_grading[finish_id]": values?.finish,
      };
      // removed as per client review
      // if (typeof values?.additional_document !== "string") {
      //   data.additional_document = values?.additional_document;
      // }
      // if (typeof values?.excel_file !== "string") {
      //   data.excel_file = values?.excel_file;
      // }
      if (values?.engraving_image?.name) {
        data.engraving_logo = values?.engraving_image;
      }
      if (values?.image_for_certificate?.name) {
        data.image_for_certificate = values?.image_for_certificate;
      }

      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      const formData = new FormData();
      Object.keys(newObj).forEach((key) => {
        formData.append(key, data[key]);
      });
      // eslint-disable-next-line array-callback-return
      values?.service_type?.map((value, index) => {
        formData?.append(`service_id[${index}]`, value?._id);
      });

      if (values?.double_grade?.length !== 0) {
        // eslint-disable-next-line array-callback-return
        values?.double_grade?.map((value, index) => {
          formData?.append(`grade[${index}]`, value);
        });
      }
      // formData.append("image_for_certificate", values?.image_for_certificate);
      // values?.group?.forEach((value, index) => {
      //   // if (
      //   //   value?.item !== undefined &&
      //   //   value?.item !== null &&
      //   //   value?.item !== ""
      //   // )
      //   return (
      //     formData.append(`item[${index}][item_id]`, value?.item_id),
      //     formData.append(
      //       `item[${index}][total_certificate]`,
      //       value?.total_certificate
      //     ),
      //     formData.append(
      //       `item[${index}][item_quantity]`,
      //       value?.item_quantity
      //     ),
      //     formData.append(`item[${index}][total_weight]`, value?.total_weight)
      //   );
      // });
      values?.group?.forEach((value, index) => {
        Object.entries(value).forEach(([key, val]) => {
          // Check if the value is not null before appending
          if (val !== null) {
            formData.append(`item[${index}][${key}]`, val);
          }
        });
      });

      if (!isEdit) {
        updateOrder(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            dispatch(
              updateOrderData((state) => (state.orderStatus = "success"))
            );
            toast.success("Order Created successfully");
            handleCloseAddOrderModal();
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            dispatch(
              updateOrderData((state) => (state.orderStatus = "failed"))
            );
          } else {
            toast.error("Something went wrong");
            updateOrderData((state) => (state.orderStatus = "failed"));
          }
        });
      } else {
        formData.append(`stock_receipt_id`, orderData?._id);
        updateOrder(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            updateOrderData((state) => (state.orderStatus = "success"));
            toast.success("Order Updated successfully");
            handleCloseAddOrderModal();
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            dispatch(
              updateOrderData((state) => (state.orderStatus = "failed"))
            );
          } else {
            updateOrderData((state) => (state.orderStatus = "failed"));
            toast.error("Something went wrong");
          }
        });
      }
    },
  });



  const numberOfItem =  formik?.values?.group?.reduce((acc , item ) =>  acc + +item?.item_quantity , 0)
  const numberOfCertificate =  formik?.values?.group?.reduce((acc , item ) =>  acc + +item?.total_certificate , 0)




  // removed validation as per client new changes
  // useEffect(() => {
  //   let itemTypeObj = basicData?.data?.item_types?.filter(
  //     (item) => item?._id === formik?.values?.item_type
  //   );

  //   if (itemTypeObj?.[0]?.slug === "diamond_loose") {
  //     setIsDLoose(true);
  //   } else {
  //     setIsDLoose(false);
  //   }

  //   const itemTypeCategory = itemTypeObj?.[0]?.item_type_category;

  //   dispatch(
  //     updateOrderData(
  //       (state) => (state.itemTypeCategory = itemTypeCategory ?? "")
  //     )
  //   );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik?.values?.item_type]);

  useEffect(() => {
    if (basicData?.data?.prepared_by) {
      formik?.setFieldValue(
        "prepared_by",
        basicData?.data?.prepared_by?.[0]?._id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicData, isLoading]);

  useEffect(() => {
    if (!isEdit) {
      if (
        formik?.values?.phone_number?.length > 8 &&
        formik?.values?.phone_number?.length < 15
      ) {
        getAgentData(formik?.values?.phone_number).then((response) => {
          if (response?.data?.status_code === 200) {
            if(response?.data?.data?.length !== 0) {
              formik?.setFieldValue(
                "address",
                response?.data?.data?.[0]?.delivery_details?.address
              );
  
              let newObj = {
                value: response?.data?.data?.[0]?._id,
                label: `${response?.data?.data?.[0]?.delivery_details?.person} (${response?.data?.data?.[0]?.delivery_details?.contact_number})`,
              }
  
              formik?.setFieldValue(
                "collection_agent",
                newObj
              );
            }
            
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.phone_number, isEdit]);

  const [collectionAgentList, setCollectionAgentList] = useState([])

 

  const tabs = [
    {
      label: "Collection",
      completed: calculateCompletionStatus([
        "branch_id",
        "source",
        "supplier",
        "phone_number",
        "collection_agent",
        "address",
        "shop_name",
        "bill_to",
        "priority",
        "no_stone",
        "is_job",
        "add_prefix",
        "due_date",
        "due_time",
      ]),
    },
    {
      label: "Item",
      completed: calculateCompletionStatus([
        "item_type",
        "box_weight",
        "item_id",
        "total_certificate",
        "item_quantity",
        "total_weight",
      ]),
    },
    {
      label: "Service",
      completed: calculateCompletionStatus([
        "service_type",
        "color",
        "clarity",
        "cut",
        "finish",
        "double_grade",
        "packaging_type",
        "certificate_type",
        "prepared_by",
        "payment_mode",
        "remarks",
      ]),
    },
    {
      label: "Engraving",
      completed: calculateCompletionStatus([
        "engraving_format",
        "engraving_text",
        "engraving_font",
        "engraving_remarks",
        "engraving_image",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Collection",
      fields: [
        "branch_id",
        "source",
        "supplier",
        "phone_number",
        "collection_agent",
        "address",
        "shop_name",
        "bill_to",
        "priority",
        "no_stone",
        "is_job",
        "add_prefix",
        "due_date",
        "due_time",
      ],
    },
    {
      label: "Item",
      fields: [
        "item_type",
        "box_weight",
        "item_id",
        "total_certificate",
        "item_quantity",
        "total_weight",
      ],
    },
    {
      label: "Service",
      fields: [
        "service_type",
        "color",
        "clarity",
        "cut",
        "finish",
        "double_grade",
        "packaging_type",
        "certificate_type",
        "prepared_by",
        "payment_mode",
        "remarks",
      ],
    },
    {
      label: "Engraving",
      fields: [
        "engraving_format",
        "engraving_text",
        "engraving_font",
        "engraving_remarks",
        "engraving_image",
      ],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "item_id" ||
        field === "total_certificate" ||
        field === "item_quantity" ||
        field === "item_weight"
      ) {
        return !!formik?.values?.group?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    return (completedFields.length / fields.length) * 130;
  }

  const handleTabClick = (tab) => {
    if (completedTabs[tab?.label] === true) {
      dispatch(
        updateOrderData((state) => {
          state.activeTab = tab?.label;
        })
      );
    } else if (isEditOrder) {
      dispatch(
        updateOrderData((state) => {
          state.activeTab = tab?.label;
        })
      );
    }
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Collection":
        return (
          <Collection
            formik={formik}
            tabs={tabs}
            optionsData={basicData?.data}
            getFieldError={getFieldError}
            collectionAgentList={collectionAgentList}
            setCollectionAgentList={setCollectionAgentList}
          />
        );
      case "Item":
        return (
          <Item
            tabs={tabs}
            isEdit={isEdit}
            formik={formik}
            // isDLoose={isDLoose}
            optionsData={basicData?.data}
            getFieldError={getFieldError}
            setValidationType={setValidationType}
            numberOfCertificate={numberOfCertificate}
            numberOfItem={numberOfItem}
          />
        );
      case "Service":
        return (
          <Service
            isEdit={isEdit}
            formik={formik}
            optionsData={basicData?.data}
            getFieldError={getFieldError}
            tabs={tabs}
          />
        );
      case "Engraving":
        return (
          <Engraving
            formik={formik}
            optionsData={basicData?.data}
            getFieldError={getFieldError}
            tabs={tabs}
            orderData={orderData}
            handleMoveToErrorTab={handleMoveToErrorTab}
            isEngraving={orderData?.is_engraving}
            isChecked={isChecked}
            handleChecked={handleChecked}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateOrderData((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    // Move activeTab to the first tab with errors
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab === "group") {
      setActiveTabByName("Item");
      return "";
    }
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields?.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    tabs,
    formik,
    orderData,
    basicData,
    activeTab,
    isEditOrder,
    completedTabs,
    // eslint-disable-next-line no-dupe-keys
    basicData: basicData?.data,
    getFieldError,
    renderTabContent,
    handleTabClick,
    numberOfCertificate,
    numberOfItem,
    
  };
};

export default useAddOrder;
