import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import useShowReport from "./useShowReport";
const ShowReport = () => {
  const {
    isLoading,
    isFetching,
    reportState,
    showEditModal,
    reportViewList,
    paginationOptions,
    handleEditColumnsClick,
    updateViewReportFields,
    handlePagination,
    handleDeleteModal,
    handleClearClick,
    // handleDownload,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useShowReport();

  return (
    <>
      <HeadingGroup title="View Report" className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          handleClear={handleClearClick}
          searchInputProps={{ value: reportState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        <Table
          multiSelect={false}
          data={reportViewList?.data || []}
          uniqueID={"id"}
          fields={reportViewList?.fields || []}
          SortIcon={<FaSort />}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          assignIcon={
            <span className="material-symbols-outlined">download</span>
          }
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={reportState?.clearSelection}
          perpage={reportState?.currentPageSize}
          assignable={false}
          editable={false}
          assignText={"Download"}
          handleDelete={handleDeleteModal}
          // handleAssign={handleDownload}
          deletable={true}
          showCheckBox={true}
        />
        {reportViewList?.data?.length > 10 && (
          <Pagination
            currentPage={reportState?.currentPage}
            totalPageCount={Math.ceil(
              reportViewList.total_count / reportState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === reportState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={reportState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Tier ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={reportViewList?.data?.fields || []}
              moduleId={reportViewList?.data?.module_id}
              updateData={updateViewReportFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default ShowReport;
