import { getAxiosInstance } from "../../../api";

export const getShopData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/supplier-shop/edit?supplier_shop_id=${id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addShopData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/supplier-shop/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editShopData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/supplier-shop/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteShop = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/supplier-shop/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveShopRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/supplier-shop/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
