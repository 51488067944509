import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useGetFormTableDataQuery } from "../../../../../store/queries/QC";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../../../store/slices/Global";
import { updateConfig } from "../../../../../store/slices/QC/QCSlice";

const useDetailsTable = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const { tableFields } = useSelector((state) => state.global);
  const queryState = useSelector((state) => state.qc);
  const slug = localStorage?.getItem("slug");

  const {
    data: queryList = {},
    isLoading,
    isFetching,
  } = useGetFormTableDataQuery({
    page_size: queryState?.formTable?.currentPageSize,
    page: queryState?.formTable?.currentPage,
    sort_by: queryState?.formTable?.sortBy,
    sort_order: queryState?.formTable?.sortOrder,
    receipt_id: queryState?.assignedID,
    process_slug: slug,
    item_details_id: queryState?.certificateID,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = queryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [queryList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.formTable.currentPageSize = page_size;
        state.formTable.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.formTable.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (queryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.formTable.currentPage = 1;
          state.formTable.sortOrder =
            queryState.formTable.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.formTable.currentPage = 1;
          state.formTable.sortBy = label;
          state.formTable.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditClick = (e) => {
    let id = e?.[0];
    dispatch(
      updateConfig((state) => {
        state.editId = id;
        state.editForm = true;
      })
    );
  };

  const handleClose = () => {
    dispatch(updateConfig((state) => (state.editForm = false)));
  };

  return {
    getRow,
    handlePageSize,
    handlePagination,
    handleSort,
    handleEditClick,
    handleClose,
    queryState,
    isFetching,
    isLoading,
    queryList,
    paginationOptions,
    tableFields,
  };
};

export default useDetailsTable;
