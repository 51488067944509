import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddReportModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedReport: "",
  isEdit: false,
  reportData: [],
  logoPreview: "",
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = reportSlice.actions;

export default reportSlice.reducer;
