import { getAxiosInstance } from "../../../api";

export const getEngravingData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/engraving/edit?engraving_type_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateEngravingData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/engraving/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteEngraving = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/engraving/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveEngravingRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/engraving/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
