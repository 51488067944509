import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { reissueBulkPass } from "../api";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import { toast } from "react-toastify";

const useBulkPass = () => {
  const dispatch = useDispatch();
  const { selectedId } = useSelector((state) => state.printing);
  const slug = localStorage?.getItem("slug");

  const formik = useFormik({
    initialValues: {
      remark: "",
    },
    onSubmit: (values) => {
      try {
        let body = {
          process_slug: slug,
          remarks: values?.remark ?? "",
          stock_receipt_id: selectedId?.[0],
        };
        reissueBulkPass(body).then((resp) => {
          if (resp?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Success");
          } else {
            toast.error("something went wrong");
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.bulkPassModal = false)));
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  return { formik, handleCloseModal, getFieldError };
};

export default useBulkPass;
