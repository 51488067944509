import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/QC/QCSlice";
import { getExcelData, getReport } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useCompareTab = ({ reportRef }) => {
  const dispatch = useDispatch();
  const { certificateID, data, activeId, showVersionTwoTable, excelCopyPermission } = useSelector(
    (state) => state.qc
  );
  const slug = localStorage?.getItem("slug");

  // const handleAdd = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       const currentVersions = state.data.map((item) => item.name);
  //       let versionNumber = 1;
  //       while (currentVersions.includes(`Version ${versionNumber}`)) {
  //         versionNumber++;
  //       }
  //       const newVersionName = `Version ${versionNumber}`;
  //       state.data.push({ name: newVersionName });
  //       state.activeId = state.data.length; // Set the activeId to the index of the newly added version.
  //     })
  //   );
  // };
  const handleAdd = () => {
    dispatch(
      updateConfig((state) => {
        const currentVersions = state.data.map((item) => item.name);
        let versionNumber = 1;
        while (currentVersions.includes(`Version ${versionNumber}`)) {
          versionNumber++;
        }
        const newVersionName = `Version ${versionNumber}`;

        // Disable all existing versions
        state.data.forEach((item) => {
          item.disabled = true;
        });

        // Add the new version and enable it
        state.data.push({ name: newVersionName, disabled: false });
        state.activeId = state.data.length; // Set the activeId to the index of the newly added version.
      })
    );
  };

  const handleClick = (e) => {
    if (e.name === "Version 1") {
      dispatch(updateConfig((state) => (state.activeId = 1)));
    } else {
      const inputString = e?.name;
      const numericPart = inputString.match(/\d+/);
      const numericValue = parseInt(numericPart[0]);
      dispatch(updateConfig((state) => (state.activeId = numericValue)));
    }
  };

  const handleCompare = () => {
    dispatch(updateConfig((state) => (state.showCompare = true)));
    getExcelData(certificateID, slug).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(updateConfig((state) => (state.excelData = response?.data)));
      } else {
        toast.error("Can't genarate excel data");
      }
    });
  };

  const handleDownload = () => {
    getReport(certificateID, slug).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });

    const printReport = () => {
      const element = reportRef?.current;
      html2pdf(element, {
        margin: [0, 0, 0, 0],
        filename: `${
          " Grading Report" +
          "-" +
          moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
        }.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 4,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      });
    };
    setTimeout(() => {
      printReport();
    }, 500);
  };
  return {
    data,
    activeId,
    showVersionTwoTable,
    handleAdd,
    handleClick,
    handleCompare,
    handleDownload,
    excelCopyPermission
  };
};

export default useCompareTab;
