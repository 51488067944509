/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getQcAssignedRecheckList,
  getQcCompletedRecheckList,
  getQcPendingRecheckList,
  getQcMarkedRecheckList,
  getQcRejectedRecheckList,
  updateConfig,
} from "../../../store/slices/QC/QCSlice";
import { useSelector } from "react-redux";
import { getFormData, submitForm, getExcelFormData } from "./api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { debounce } from "../../../utils/hooks/useDebounce";
import { getStatusData } from "../../Global/AddStatus/api";
import { useGetDescriptionDataQuery } from "../../../store/queries/QC";
import { startTimerRecheck } from "../api";

const useQCRecheck = () => {
  const {
    qcDataRecheck,
    showForm,
    showQcModal,
    formData,
    total,
    certificateID,
    certificateNumber,
    assignedID,
    showValues,
    itemID,
    showGli,
    gliNumber,
    qcRecheckPendingStatus,
    qcRecheckAssignedStatus,
    qcRecheckCompletedStatus,
    qcRecheckMarkedStatus,
    qcRecheckRejectedStatus,
    itemTypeCat,
    enterMoreModal,
    enterMoreFormModal,
    showMoreFormTable,
    recheckTab,
  } = useSelector((state) => state.qc);
  const [assignedPage, setAssignedPage] = useState(1);
  const [pendingPage, setPendingPage] = useState(1);
  const [markedPage, setMarkedPage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [completedPage, setCompletedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const reportRef = useRef(null);
  const [statusData, setStatusData] = useState([]);
  const [isNewDesc, setIsNewDesc] = useState(false);

  const slug = localStorage?.getItem("slug");
  const dispatch = useDispatch();

  const { data: descriptions } = useGetDescriptionDataQuery(
    {
      process_slug: slug,
      recheck_item_details_id: certificateID,
    },
    {
      skip: !certificateID,
    }
  );

  useEffect(() => {
    dispatch(
      getQcAssignedRecheckList({
        page: 1,
        per_page: 10,
        date: [],
        item_type: [],
        shop: [],
        status: [],
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pendingPage !== 1) {
      dispatch(
        getQcPendingRecheckList({
          page: pendingPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);

  useEffect(() => {
    if (assignedPage !== 1) {
      dispatch(
        getQcAssignedRecheckList({
          page: assignedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);

  useEffect(() => {
    if (completedPage !== 1) {
      dispatch(
        getQcCompletedRecheckList({
          page: completedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);

  useEffect(() => {
    if (markedPage !== 1) {
      dispatch(
        getQcMarkedRecheckList({
          page: markedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedPage]);

  useEffect(() => {
    if (rejectedPage !== 1) {
      dispatch(
        getQcRejectedRecheckList({
          page: rejectedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedPage]);

  let dynamicStructure = {};

  useEffect(() => {
    // Iterate over the qc_fields array unconditionally
    if (formData?.length !== 0) {
      for (const field of formData?.qc_fields) {
        const slug = field?.slug;
        dynamicStructure[slug] = "";
        dynamicStructure["status"] = "";
      }
      // Check if cstone_sstone is 1 before adding center_stone_fields and side_stone_fields
      if (formData.cstone_sstone === 1) {
        for (const field of formData?.center_stone_fields) {
          const slug = field?.slug;
          dynamicStructure[`center_stone_${slug}`] = "";
        }

        for (const field of formData?.side_stone_fields) {
          const slug = field?.slug;
          dynamicStructure[`side_stone_${slug}`] = "";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const formik = useFormik({
    initialValues: dynamicStructure,
    validate: (values) => {
      let errors = {};
      Object.keys(values || {}).forEach((key) => {
        // if (
        //   values[key] === null ||
        //   values[key] === undefined ||
        //   values[key] === ""
        // ) {
        //   errors[`${key}`] = `This field is required`;
        // }
        if (!values?.status) {
          errors.status = "Select Status";
        }
        if (key === "image") {
          const image = values[key];
          if (image) {
            // Check image type
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(image.type)) {
              errors.image = "Image must be in JPEG or PNG format";
            }
            // Check image size (less than 2MB)
            const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
            if (image.size > maxSizeInBytes) {
              errors.image = "Image size must be less than 2MB";
            }
          }
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      if (values?.status) {
        handleConfirm(values);
      } else {
        formik?.setFieldTouched("status", true);
        formik?.setFieldError("status", "Select Status");
      }
    },
  });

  useEffect(() => {
    if (formData) {
      if (Object.keys(formData)?.includes("marked")) {
        formik?.setFieldValue("status", 5);
        dynamicStructure["status"] = 5;
        formik.setFieldValue(
          "group",
          formData.marked.map((item) => ({
            type: item?.type ?? "",
            count: item?.count ?? "",
            percentage: item?.percentage ?? "",
            others: item?.others ?? "",
          }))
        );
      } else if (Object.keys(formData)?.includes("rejected")) {
        formik?.setFieldValue("status", 4);
        dynamicStructure["status"] = 4;
        formik.setFieldValue(
          "group",
          formData.marked.map((item) => ({
            type: item?.type ?? "",
            count: item?.count ?? "",
            percentage: item?.percentage ?? "",
            others: item?.others ?? "",
          }))
        );
      } else {
        formik?.setFieldValue("status", "");
        dynamicStructure["status"] = "";
      }
      dynamicStructure["remarks"] = "";
      formik?.setFieldValue("remarks", "");
      formik?.setErrors({});
      // eslint-disable-next-line array-callback-return
      formData?.qc_fields?.map((value) => {
        if (value?.slug === "description") {
          setIsNewDesc(true);
          formik?.setFieldValue(value?.slug, value?.value);
        } else {
          formik?.setFieldValue(value?.slug, value?.value);
        }
      });
      // eslint-disable-next-line array-callback-return
      formData?.center_stone_fields?.map((value) => {
        formik?.setFieldValue(`center_stone_${value?.slug}`, value?.value);
      });
      // eslint-disable-next-line array-callback-return
      formData?.side_stone_fields?.map((value) => {
        formik?.setFieldValue(`side_stone_${value?.slug}`, value?.value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const startTimerQC = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimerRecheck(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = (item, key) => {
    formik?.setErrors({});
    setActiveCard({ id: item?._id, key: key });
    dispatch(
      updateConfig((state) => {
        state.item = item;
        state.showMoreFormTable = false;
      })
    );
    if (key === "Assigned") {
      startTimerQC(item);
      dispatch(
        updateConfig((state) => {
          state.assignedID = item?._id;
          state.qcDataRecheck.Passed = [];
          state.qcDataRecheck.Pending = [];
          state.qcDataRecheck.Marked = [];
          state.qcDataRecheck.Rejected = [];
          state.showValues = false;
          state.showForm = false;
          state.showGli = true;
          state.gliNumber = item?.gli_number;
          setImagePreview("");
        })
      );
      dispatch(
        getQcPendingRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getQcCompletedRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getQcMarkedRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getQcRejectedRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 4,
        })
      );
    } else if (key === "Pending") {
      setImagePreview("");
      getStatusData().then((response) => {
        if (response?.data?.status_code === 200) {
          setStatusData(response?.data?.data);
        }
      });
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.certificateNumber = item?.certificate_number;
          state.itemID = item?.gli_number;
          state.itemTypeCat = item?.item_type_category ?? "";
        })
      );
      let data = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getFormData(data).then((response) => {
        if (response?.data?.status_code === 200) {
          let previousData = response?.data?.data?.previous_data ?? [];
          let recheckTabs = previousData?.map((_, index) => ({
            name: `version ${index + 1}`,
            id: `version ${index + 1}`,
          }));
          recheckTabs = [{ name: "fields", id: "fields" }, ...recheckTabs];
          dispatch(
            updateConfig((state) => {
              state.formData = response?.data?.data;
              state.showValues = false;
              state.showForm = true;
              state.showGli = false;
              state.recheckTab.tabs = recheckTabs;
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    } else if (key === "Passed") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          state.certificateNumber = item?.certificate_number;
          state.showCompare = false;
          state.data = [{ name: "Version 1" }];
          state.activeId = 1;
          state.showGli = false;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2).then((response) => {
        if (response?.data?.status_code === 200) {
          if (response?.data?.data?.updated_values.length !== 0) {
            const newData = response.data.data.updated_values.map(
              (_, index) => ({
                name: `Version ${index + 2}`,
                disabled: true, // Set the disabled property to true for all versions
              })
            );
            dispatch(
              updateConfig((state) => {
                state.data = [
                  { name: "Version 1", disabled: true },
                  ...newData,
                ]; // Disable all versions in the data array
              })
            );
          }
          dispatch(
            updateConfig((state) => {
              state.excelFormData = response?.data?.data?.graded_values;
              state.excelCopyPermission =
                response?.data?.data?.create_copy_permission;
              state.excelEditedFormData =
                response?.data?.data?.updated_values.length === 0
                  ? (state.excelFormData = response?.data?.data?.graded_values)
                  : response?.data?.data?.updated_values;
              state.showValues = true;
              state.showForm = false;
              state.dropdownValues =
                response?.data?.data?.dropdown_values ?? {};
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    } else if (key === "Marked") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?.id;
          state.certificateNumber = item?.certificate_number;
          state.itemId = item?.gli_number;
          state.showCompare = false;
          state.data = [{ name: "Version 1" }];
          state.activeId = 1;
          state.showGli = false;
        })
      );

      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2).then((response) => {
        if (response?.data?.status_code === 200) {
          if (response?.data?.data?.updated_values.length !== 0) {
            const newData = response.data.data.updated_values.map(
              (_, index) => ({
                name: `Version ${index + 2}`,
                disabled: true, // Set the disabled property to true for all versions
              })
            );
            dispatch(
              updateConfig((state) => {
                state.data = [
                  { name: "Version 1", disabled: true },
                  ...newData,
                ]; // Disable all versions in the data array
              })
            );
          }
          dispatch(
            updateConfig((state) => {
              state.excelFormData = response?.data?.data?.graded_values;
              state.excelCopyPermission =
                response?.data?.data?.create_copy_permission;
              state.excelEditedFormData =
                response?.data?.data?.updated_values.length === 0
                  ? (state.excelFormData = response?.data?.data?.graded_values)
                  : response?.data?.data?.updated_values;
              state.showValues = true;
              state.showForm = false;
              state.dropdownValues =
                response?.data?.data?.dropdown_values ?? {};
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    } else if (key === "Rejected") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          state.certificateNumber = item?.certificate_number;
          state.showCompare = false;
          state.showGli = false;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2).then((response) => {
        if (response?.data?.status_code === 200) {
          if (response?.data?.data?.updated_values.length !== 0) {
            const newData = response.data.data.updated_values.map(
              (_, index) => ({
                name: `Version ${index + 2}`,
                disabled: true, // Set the disabled property to true for all versions
              })
            );
            dispatch(
              updateConfig((state) => {
                state.data = [
                  { name: "Version 1", disabled: true },
                  ...newData,
                ]; // Disable all versions in the data array
              })
            );
          }
          dispatch(
            updateConfig((state) => {
              state.excelFormData = response?.data?.data?.graded_values;
              state.excelCopyPermission =
                response?.data?.data?.create_copy_permission;
              state.excelEditedFormData =
                response?.data?.data?.updated_values.length === 0
                  ? (state.excelFormData = response?.data?.data?.graded_values)
                  : response?.data?.data?.updated_values;
              state.showValues = true;
              state.showForm = false;
              state.dropdownValues =
                response?.data?.data?.dropdown_values ?? {};
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const getFieldError = (fieldName) => {
    if (formik?.touched?.[fieldName] && formik?.errors?.[fieldName]) {
      return formik?.errors?.[fieldName];
    }
    return "";
  };

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;
    dispatch(
      updateConfig((state) => {
        state.qcDataRecheck.Pending = [];
        state.qcDataRecheck.Passed = [];
        state.qcDataRecheck.Assigned = [];
        state.qcDataRecheck.Marked = [];
        state.qcDataRecheck.Rejected = [];
      })
    );
    dispatch(
      getQcAssignedRecheckList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
    if (assignedID) {
      dispatch(
        getQcCompletedRecheckList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getQcPendingRecheckList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getQcMarkedRecheckList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getQcRejectedRecheckList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
  };

  const handleSearch = debounce(handleSearchInput, 1000);

  const handleConfirm = (values) => {
    let data = {
      process_slug: slug,
      recheck_item_details_id: certificateID,
      ...values,
    };

    let formDatas = new FormData();
    for (let key in data) {
      if (key.startsWith("center_stone")) {
        const formattedKey = key.replace("center_stone_", ""); // Remove the prefix
        formDatas.append(`center_stone[${formattedKey}]`, data[key]);
      } else if (key.startsWith("side_stone")) {
        const formattedKey = key.replace("side_stone_", ""); // Remove the prefix
        formDatas.append(`side_stone[${formattedKey}]`, data[key]);
      } else {
        let statusType =
          values?.status == 5
            ? "marked"
            : values?.status == 4
            ? "rejected"
            : "passed";
        // For other keys, append them as they are
        if (key === "group") {
          // eslint-disable-next-line array-callback-return
          values?.group?.map((val, index) => {
            if (val?.type !== "") {
              let type = formData?.type?.filter(
                (typ) => typ?.name === val?.type
              );
              formDatas?.append(`${statusType}[${index}][type]`, val?.type);
              formDatas?.append(
                `${statusType}[${index}][type_id]`,
                type?.[0]?.id
              );
            }
            if (val?.count !== "") {
              formDatas?.append(`${statusType}[${index}][count]`, val?.count);
            }

            if (val?.type === "OTHERS") {
              formDatas?.append(`${statusType}[${index}][others]`, val?.others);
            }

            if (val?.percentage !== "") {
              formDatas?.append(
                `${statusType}[${index}][percentage]`,
                val?.percentage
              );
            }
          });
        } else {
          formDatas.append(key, data[key] ?? "");
          formDatas?.delete("status");
          formDatas?.delete("remarks");
          formDatas?.append("remarks", values?.remarks);
          formDatas?.append("status", values?.status);
        }
      }
    }

    if (values?.status === 2) {
      formDatas?.delete("reason");
      formDatas?.delete("image");
      formDatas?.delete("marked[type]");
      formDatas?.delete("rejected[type]");
      formDatas?.delete("marked[others]");
      formDatas?.delete("rejected[others]");
      formDatas?.delete("marked[count]");
      formDatas?.delete("rejected[count]");
      formDatas?.delete("marked[percentage]");
      formDatas?.delete("rejected[percentage]");
      formDatas?.delete("group");
    }

    submitForm(formDatas)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Success");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            updateConfig((state) => {
              state.qcDataRecheck.Pending = [];
              state.qcDataRecheck.Passed = [];
              state.qcDataRecheck.Marked = [];
              state.qcDataRecheck.Rejected = [];
              // state.enterMoreModal = true;
            })
          );

          if (
            itemTypeCat === "diamond_jewellery" ||
            itemTypeCat === "mixed_jewellery" ||
            itemTypeCat === "diamond_loose" ||
            itemTypeCat === "center_and_side_stone" ||
            itemTypeCat === "other"
          ) {
            dispatch(updateConfig((state) => (state.enterMoreModal = true)));
          }

          formik?.resetForm();
          formik?.setErrors({});
          dispatch(
            getQcPendingRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getQcCompletedRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getQcMarkedRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 5,
            })
          );
          dispatch(
            getQcRejectedRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 4,
            })
          );
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        }
      })
      .catch((error) => {
        toast.error(error);
        setImagePreview("");
      });
  };
  // const handleConfirmModal = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showQcModal = !state.showQcModal;
  //     })
  //   );
  // };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      qcDataRecheck?.Pending?.length !== total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      qcDataRecheck?.Assigned?.length !== total?.Assigned
    ) {
      setAssignedPage(assignedPage + 1);
    }
    if (
      mouseHover === "Passed" &&
      qcDataRecheck?.Completed?.length !== total?.Completed
    ) {
      setCompletedPage(completedPage + 1);
    }
    if (
      mouseHover === "Marked" &&
      qcDataRecheck?.Marked?.length !== total?.Marked
    ) {
      setMarkedPage(markedPage + 1);
    }
    if (
      mouseHover === "Rejected" &&
      qcDataRecheck?.Rejected?.length !== total?.Rejected
    ) {
      setRejectedPage(rejectedPage + 1);
    }
  };

  const handleImageChange = (event) => {
    const image = event?.target?.files[0];
    formik.setFieldValue("image", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleStatusChange = (value) => {
    formik?.setFieldValue("status", value?.id || "");
    if (value?.id !== 2) {
      formik?.setFieldValue("group", [
        {
          type: "",
          count: "",
          percentage: "",
          others: "",
        },
      ]);
    } else {
      formik?.setFieldValue("group", []);
    }
  };

  const handleAddRemarkkCardClick = () => {
    let { group } = formik?.values;
    group = [
      ...group,
      {
        type: "",
        count: "",
        percentage: "",
        others: "",
      },
    ];
    formik.setFieldValue("group", group);
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    qcRecheckPendingStatus,
    qcRecheckAssignedStatus,
    qcRecheckCompletedStatus,
  ]);

  const handleEnterMoreModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreModal = false;
        state.showMoreFormTable = true;
      })
    );
  };

  const handleDescChange = (e, slug) => {
    if (e?.__isNew__) {
      setIsNewDesc(true);
      formik?.setFieldValue(slug, e?.label);
    } else {
      setIsNewDesc(false);
      formik?.setFieldValue(slug, e?.label);
    }
  };

  const handleEnterMoreYes = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreModal = false;
        state.enterMoreFormModal = true;
      })
    );
  };

  const handleEnterMoreFormModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreFormModal = false;
        state.showMoreFormTable = true;
      })
    );
  };

  return {
    qcDataRecheck,
    showForm,
    formik,
    showQcModal,
    activeCard,
    reportRef,
    showValues,
    isLoading,
    itemID,
    recheckTab,
    showGli,
    gliNumber,
    formData,
    isNewDesc,
    imagePreview,
    statusData,
    enterMoreModal,
    enterMoreFormModal,
    certificateNumber,
    showMoreFormTable,
    descriptions: descriptions?.data,
    totalLength: {
      Pending: total?.Pending,
      Assigned: total?.Assigned,
      Completed: total?.Completed,
      Marked: total?.Marked,
      Rejected: total?.Rejected,
    },
    apiStatus: {
      Pending: qcRecheckPendingStatus,
      Assigned: qcRecheckAssignedStatus,
      Completed: qcRecheckCompletedStatus,
      Marked: qcRecheckMarkedStatus,
      Rejected: qcRecheckRejectedStatus,
    },
    getFieldError,
    handleStatusChange,
    handleCardClick,
    handleSearch,
    handleConfirm,
    handleImageChange,
    handleDescChange,
    handleLoadMore,
    setIsLoading,
    handleEnterMoreYes,
    handleEnterMoreModalClose,
    handleAddRemarkkCardClick,
    handleEnterMoreFormModalClose,
  };
};

export default useQCRecheck;
