import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddPacking from "./useAddPacking";

const AddPacking = (refetch) => {
  const { formik, queryState, getFieldError, handleCloseModal } =
    useAddPacking(refetch);
  return (
    <div className="row pro-p-4">
      <Input
        label={"Name"}
        type="text"
        id="packing_type"
        name="packing_type"
        className={`pro-input lg ${getFieldError("packing_type") && " error"}`}
        {...formik.getFieldProps("packing_type")}
        error={getFieldError("packing_type")}
        errorMessage={getFieldError("packing_type")}
      />
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {queryState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddPacking;
