import React from "react";
import Style from "./shimmer.module.scss";

const ShimmerCard = () => {
  return (
    <section className={Style.dots_container}>
      <div className={Style.dot}></div>
      <div className={Style.dot}></div>
      <div className={Style.dot}></div>
      <div className={Style.dot}></div>
      <div className={Style.dot}></div>
    </section>
  );
};

export default ShimmerCard;
