import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  cardPendingStatus: "idle",
  cardAssignedStatus: "idle",
  cardData: {
    Pending: [],
    Assigned: [],
  },
  tableFields: {},
  formData: [],
  showFilter: false,
  showTable: false,
  gliData: {
    name: "",
    id: "",
    shop: "",
  },
  search: "",
  assignedSearch: "",
  pendingSearch: "",
  from_date: "",
  to_date: "",
  item_type: [],
  shop: [],
  status: [],
  date: [],
  certificateData: [],
  showAssignedTable: false,
  curretPageSize: 10,
  currentPage: 1,
};

export const getCardPendingList = createAsyncThunk(
  "card/pending-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCardAssignedList = createAsyncThunk(
  "card/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const recheckStaffSlice = createSlice({
  name: "recheckStaff",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => initialState,
  },
  extraReducers: (builder) => {
    // pending list
    builder.addCase(getCardPendingList.pending, (state) => {
      state.cardPendingStatus = "pending";
    });
    builder.addCase(getCardPendingList.fulfilled, (state, action) => {
      state.cardData.Pending = action.payload.data.data.data;
      state.cardPendingStatus = "fulfilled";
    });
    builder.addCase(getCardPendingList.rejected, (state) => {
      state.cardPendingStatus = "failed";
    });
    // assigned list
    builder.addCase(getCardAssignedList.pending, (state) => {
      state.cardAssignedStatus = "pending";
    });
    builder.addCase(getCardAssignedList.fulfilled, (state, action) => {
      state.cardData.Assigned = action.payload.data.data.data;
      state.cardAssignedStatus = "fulfilled";
    });
    builder.addCase(getCardAssignedList.rejected, (state) => {
      state.cardAssignedStatus = "failed";
    });
  },
});

export const { updateConfig, resetConfig } = recheckStaffSlice.actions;

export default recheckStaffSlice.reducer;
