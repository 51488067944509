import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useStockUpdateModel from "./useStockUpdateModel";

const StockUpdateModel = ({ refetch }) => {
  const { formik, statusList, statusObj, getFieldError, handleCancel, 
   } =
    useStockUpdateModel({
      refetch
    });
  return (
    <div className="pro-p-5">
      <div>
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="process_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Status
          </label>
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("status") && " error"}`}
            classNamePrefix="pro-input"
            options={statusList}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={statusObj}
            onBlur={formik?.handleBlur("status")}
            onChange={(value) =>
            {
              formik?.setFieldValue("status", value?._id || "");
              
            }
            }
            menuPlacement="auto"
            isClearable
          />
          {getFieldError("process_id") && (
            <span className="error-text">{getFieldError("process_id")}</span>
          )}
        </div>
        
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik?.handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default StockUpdateModel;
