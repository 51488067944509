import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Reports/reportsSlice";
import { useSelector } from "react-redux";
import {
  useGetOtherReportBasicDataQuery,
  useGetOtherReportEmployeeDetailsTableDataQuery,
} from "../../../../../store/queries/Reports";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { subDays } from "date-fns";
import TinyTable from "./TinyTable";

const useEmployeeDetail = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const reportState = useSelector((state) => state.reports);
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get("id");
  const bid = params.get("bid");

  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);

  const formik = useFormik({
    initialValues: {
      order_type: "",
    },
  });
  const {
    data: employeeDetailData = {},
    isLoading,
    isFetching,
  } = useGetOtherReportEmployeeDetailsTableDataQuery(
    {
      user_id: id,
      branch_id: bid,
      date: formattedDate,
      order_type: formik?.values?.order_type?.id,
      per_page: reportState?.otherReport?.details?.currentPageSize,
      page: reportState?.otherReport?.details?.currentPage,
      sort_by: reportState?.otherReport?.details?.sortBy,
      sort_order: reportState?.otherReport?.details?.sortOrder,
      search: reportState?.otherReport?.details?.search,
    },
    {
      skip: !formik?.values?.order_type,
    }
  );

  const { data: basicData = {}, isLoading: basicLoading } =
    useGetOtherReportBasicDataQuery({});

  useEffect(() => {
    formik?.setFieldValue("order_type", basicData?.data?.order_types?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicLoading]);

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.details.search = e.target.value;
        state.otherReport.details.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.details.currentPageSize = page_size;
        state.otherReport.details.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.details.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.otherReport.details.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.otherReport.details.currentPage = 1;
          state.otherReport.details.sortOrder =
            reportState.otherReport.details.sortOrder === "asc"
              ? "desc"
              : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.otherReport.details.currentPage = 1;
          state.otherReport.details.sortBy = label;
          state.otherReport.details.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      work_details: (field, data) => (
        <p className="pro-mb-0">
          <TinyTable data={data[field]} />
        </p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    date,
    isFetching,
    isLoading,
    formik,
    reportState,
    paginationOptions,
    employeeDetailData,
    basicData: basicData?.data,
    getRow,
    handlePageSize,
    handleSort,
    handlePagination,
    handleDateChange,
    handleSearch,
  };
};

export default useEmployeeDetail;
