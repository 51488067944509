import React from "react";
import useDeliveryCertificate from "./useDeliveryCertificate";
import {
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import AddStatus from "../../../Global/AddStatus";
import Style from "../delivery.module.scss";
import EditPrint from "../EditPrint";
import PassAll from "../../../Global/PassAll";
import RemarksTableRecheck from "../../../Global/RemarksTableRecheck";

const DeliveryCertificate = ({ gliNumber, slipRef, chellanRef }) => {
  const {
    paginationOptions,
    deliveryState,
    disableMultiSelect,
    certificateList,
    isFetching,
    globalRemarkModal,
    formik,
    passAllModal,
    handlePrintChellan,
    handleModalClose,
    handlePrintSlip,
    getRow,
    handleConfirm,
    handlePageSize,
    handlePagination,
    handleConfirmModal,
    handlePassAll,
    handlePassAllClick,
    handleEditPrintModal,
    handleCloseModal,
  } = useDeliveryCertificate(slipRef, chellanRef);

  return (
    <>
      <div className={Style.print_head}>
        <h5 className="pro-ttl h5 pro-mb-2">{gliNumber}</h5>
        <div className={`pro-d-flex pro-justify-end `}>
          <div className={Style.btns}>
          <button
              className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
              onClick={handleEditPrintModal}
            >
              <span className="material-symbols-outlined">edit</span>
              <span>Edit</span>
            </button>
            <button
              className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
              onClick={handlePassAllClick}
            >
              <span className="material-symbols-outlined">check_circle</span>
              <span>Pass All</span>
            </button>
            <button
            className={`pro-btn pro-btn-outline pro-items-center`}
            onClick={handlePrintSlip}
          >
            <span className="material-symbols-outlined">print</span>
            <span>Print Slip</span>
          </button>
          <button
            className={`pro-btn pro-btn-outline pro-items-center`}
            onClick={handlePrintChellan}
          >
            <span className="material-symbols-outlined">print</span>
            <span>Print Challan</span>
          </button>
          </div>
        </div>
      </div>
      <Table
        multiSelect={disableMultiSelect ? false : true}
        data={certificateList?.data?.data || []}
        uniqueID={"id"}
        fields={certificateList?.data?.fields || []}
        showCheckBox={true}
        SortIcon={<FaSort />}
        getRow={getRow}
        isLoading={isFetching}
        perpage={10}
        assignable={true}
        editable={false}
        deletable={false}
        assignText={"Submit"}
        assignIcon={
          <span className="material-symbols-outlined">check_circle</span>
        }
        handleAssign={handleConfirmModal}
        clear={deliveryState?.clear}
      />
      <ModalLayout
        show={deliveryState?.showAddDeliverySecModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
          />
        </div>
      </ModalLayout>
      <OffCanvasLayout
        show={deliveryState?.showRecheckPrintEditModal}
        handleClose={handleCloseModal}
        title={"Edit Certificate"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <EditPrint />
      </OffCanvasLayout>

      <Pagination
        currentPage={certificateList?.data?.current_page}
        totalPageCount={Math.ceil(
          certificateList?.data?.total_count / certificateList?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout
        show={passAllModal}
        title={"Pass All"}
        handleClose={handlePassAllClick}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <PassAll
          formik={formik}
          handleSubmit={handlePassAll}
          handleCancel={handlePassAllClick}
        />
      </ModalLayout>
      <ModalLayout
        show={globalRemarkModal}
        handleClose={handleModalClose}
        title={"Remarks"}
        propStyle={{ root: "pro-modal-xxl" }}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <RemarksTableRecheck />
        </div>
      </ModalLayout>
    </>
  );
};

export default DeliveryCertificate;
