import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  clearSelection: false,
  printModal: false,
  selectedItem: "",
  showExcelUploadModal: false,
  selectedId: "",
};
const mainReportSlice = createSlice({
  name: "mainReport",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => initialState,
  },
});

export const { updateConfig, resetConfig } = mainReportSlice.actions;

export default mainReportSlice.reducer;
