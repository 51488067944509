import { useSelector } from "react-redux";
import { useGetGliTableDataQuery } from "../../../../store/queries/CVD";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CVD/CVDSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { passAll } from "../api";
import { useFormik } from "formik";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useGliList = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const cvdState = useSelector((state) => state.cvd);
  const { tableFields, passAllModal, globalRemarkModal } = useSelector(
    (state) => state.global
  );
  const slug = localStorage?.getItem("slug");
  const {
    data: pendingTableData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetGliTableDataQuery({
    receipt_id: cvdState.assignedID,
    search: cvdState.pendingSearch,
    page_size: cvdState.currentPageSize,
    page: cvdState.currentPage,
    process_slug: slug,
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
    },
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = pendingTableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [pendingTableData]);

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePassAllClick = () => {
    dispatch(
      globalUpdateConfig((state) => (state.passAllModal = !state.passAllModal))
    );
  };

  const handlePassAll = () => {
    let data = {
      stock_receipt_id: cvdState?.assignedID,
      process_slug: slug,
      remarks: formik?.values?.remark,
    };
    passAll(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success(response?.data?.message);
        refetch();
        handlePassAllClick();
        formik?.resetForm();
        dispatch(updateConfig((state) => (state.clear = true)));
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);
      } else if (response?.status_code === 403) {
        toast.error(response?.message ?? "Error ");
      } else {
        toast.error(response?.data?.message);
      }
    });
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  return {
    paginationOptions,
    tableFields,
    pendingTableData,
    globalRemarkModal,
    passAllModal,
    isFetching,
    isLoading,
    cvdState,
    formik,
    getRow,
    handlePageSize,
    handlePagination,
    handlePassAllClick,
    handleModalClose,
    refetch,
    handlePassAll,
  };
};

export default useGliList;
