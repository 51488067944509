import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateConfig } from "../../../../store/slices/Delivery/deliverySlice";
import { useSelector } from "react-redux";
import { updatePrintData } from "../api";
import { toast } from "react-toastify";

const useEditPrint = () => {
  const [initialData, setInitialData] = useState({
    customer_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    contact_number: "",
    gst_number: "",
    item_fields: [
      {
        serial_number: "",
        description_of_goods: "",
        sac_code: "",
        amount: "",
      },
    ],
    remarks: "",
    total_amount: "",
    vendor_details: {},
  });
  const dispatch = useDispatch();
  const { deliveryChallanEditData, isEdit, selectedCardId } = useSelector(
    (state) => state.delivery
  );
  useEffect(() => {
    if (deliveryChallanEditData && isEdit) {
      let challan_details = deliveryChallanEditData?.data?.challan_details;
      let modifiedChallanDetails = challan_details?.map((item) => {
        let newItem = {};
        for (let key in item) {
          newItem[key] = item[key] === null ? "" : item[key];
        }
        return newItem;
      });
      setInitialData({
        customer_name: deliveryChallanEditData?.data?.supplier?.name ?? "",
        address_line1:
          deliveryChallanEditData?.data?.supplier?.address?.address_line1 ?? "",
        address_line2:
          deliveryChallanEditData?.data?.supplier?.address?.address_line2 ?? "",
        city: deliveryChallanEditData?.data?.supplier?.address?.city ?? "",
        contact_number:
          deliveryChallanEditData?.data?.supplier?.contact_number ?? "",
        gst_number: deliveryChallanEditData?.data?.gst_number ?? "",
        item_fields: modifiedChallanDetails ?? [],
        remarks: deliveryChallanEditData?.data?.remarks ?? "",
        total_amount: deliveryChallanEditData?.data?.total_amount ?? "",
        vendor_details: deliveryChallanEditData?.data?.vendor_details ?? "",
      });
    }
  }, [deliveryChallanEditData, isEdit]);

  const validation = Yup.object({
    customer_name: Yup.string()
      .trim()
      .max(80, `Enter Valid Name`)
      .matches(/^[a-zA-Z ]+$/, "Enter Valid Name")
      .required(`Enter customer name`),
    address_line1: Yup.string().trim().required(`Enter address`),
    address_line2: Yup.string().trim().required(`Enter address`),
    city: Yup.string().trim().required(`Enter city name`),
    contact_number: Yup.string()
      .min(6, `Enter valid mobile number`)
      .max(14, `Enter valid mobile number`)
      .matches(/^[0-9]*$/, "Enter valid number")
      .required(`Enter mobile number`),
    gst_number: Yup.string()
      .trim()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Enter Valid GST Number"
      ),
    remarks: Yup.string().nullable(),
    total_amount: Yup.string().matches(
      /^[0-9]*\.?[0-9]*$/,
      "Enter Valid Amount"
    ),
    item_fields: Yup.array().of(
      Yup.object().shape({
        description_of_goods: Yup.string().required(
          `Enter description of goods`
        ),
        sac_code: Yup.string()
          .matches(/^[0-9]*$/, "Enter Valid SAC Number")
          .min(6, "Enter Valid SAC Number")
          .max(20, "Enter Valid SAC Number")
          .nullable(),
        amount: Yup.string().matches(/^[0-9]*\.?[0-9]*$/, "Enter Valid Amount"),
      })
    ),
  });
  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};
      if (!values?.vendor_details?.person?.trim()) {
        errors.vendor_details = errors.vendor_details || {};
        errors.vendor_details.person = "Person is required";
      }
      if (!values?.vendor_details?.contact_number) {
        errors.vendor_details = errors.vendor_details || {};
        errors.vendor_details.contact_number = "Number is required";
      } else if (!/^\d{7,12}$/.test(values.vendor_details.contact_number)) {
        errors.vendor_details = errors.vendor_details || {};
        errors.vendor_details.contact_number = "Invalid phone number";
      }
      if (!values?.vendor_details?.address?.trim()) {
        errors.vendor_details = errors.vendor_details || {};
        errors.vendor_details.address = "Address is required";
      }
      // if (values?.item_fields) {
      //   values.item_fields.forEach((item, index) => {
      //     if (index === 0 && !item.serial_number) {
      //       errors.item_fields = errors.item_fields || [];
      //       errors.item_fields[index] = errors.item_fields[index] || {};
      //       errors.item_fields[index].serial_number =
      //         "Serial number is required for the first item";
      //     }
      //     if (index === 0 && !item.amount) {
      //       errors.item_fields = errors.item_fields || [];
      //       errors.item_fields[index] = errors.item_fields[index] || {};
      //       errors.item_fields[index].amount =
      //         "Amount is required for the first item";
      //     }
      //   });
      // }
      return errors;
    },
    onSubmit: async (values) => {
      let data = {
        stock_receipt_id: selectedCardId,
        supplier: {
          name: values?.customer_name ?? "",
          contact_number: values?.contact_number ?? "",
          address: {
            address_line1: values?.address_line1 ?? "",
            address_line2: values?.address_line2 ?? "",
            city: values?.city ?? "",
          },
        },
        gst_number: values?.gst_number ?? "",
        challan_details: values?.item_fields ?? [],
        remarks: values?.remarks ?? "",
        total_amount: values?.total_amount ?? "",
        vendor_details: values?.vendor_details ?? "",
      };
      const response = await updatePrintData(data);
      try {
        if (response?.data?.status_code === 200) {
          toast.success(response?.data?.message);
          dispatch(
            updateConfig((state) => {
              state.showPrintEditModal = false;
              state.isEdit = false;
            })
          );
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(field, response?.errors[field]);
          });
        } else {
          toast.error(`Delivery challan edit failed, Please try again`);
        }
      } catch (error) {
        console.error(`Error:`, error);
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleAddField = () => {
    let { item_fields } = formik?.values;
    item_fields = [
      ...item_fields,
      {
        serial_number: "",
        description_of_goods: "",
        sac_code: "",
        amount: "",
      },
    ];
    formik.setFieldValue("item_fields", item_fields);
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showPrintEditModal = false;
        state.isEdit = false;
        state.deliveryChallanEditData = {};
      })
    );
  };

  useEffect(() => {
    if (formik?.values) {
      let totalAmount = 0; // Initialize totalAmount outside the map function
      formik.values.item_fields.forEach((item) => {
        totalAmount += parseFloat(+item?.amount); // Accumulate the total amount
      });
      formik.setFieldValue(
        "total_amount",
        totalAmount > 0 ? totalAmount.toFixed(2) : ""
      );
    }
    //eslint-disable-next-line
  }, [formik?.values?.item_fields]);

  return {
    formik,
    getFieldError,
    handleAddField,
    handleCloseModal,
  };
};

export default useEditPrint;
